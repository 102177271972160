import React from 'react';
import { useTranslation } from 'react-i18next';
import { Result, Image } from 'antd';
import GoHomeButton from 'components/GoHomeButton';
import notFoundImage from 'assets/images/notFoundImage.png';

const NotFound = () => {
    const { t } = useTranslation();
    return (
        <div>
            <Result
                icon={<Image src={notFoundImage} preview={false} className="not-found-image" />}
                // status="404"
                title="404"
                subTitle={t('Sorry, the page you visited does not exist.')}
                extra={<GoHomeButton />}
            />
        </div>
    );
};

export default NotFound;
