import { createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'companyDetail';

const companyDetailSlice = createSlice({
    name,
    initialState: {
        company: null,
    },
    reducers: {
        setCompany(state, action) {
            state.company = action.payload;
        },

        setCompanyCode(state, action) {
            state.company.company_code = action.payload;
        },
        setCompanyState(state, action) {
            state.company.state = action.payload;
        },
    },
});

export const { setCompany, setCompanyCode, setCompanyState } = companyDetailSlice.actions;

export const getCompany = id => async dispatch => {
    const data = await api.get(APP_URLS.ADMIN_COMPANIES_DETAIL.replace(':id', id));
    if (data) {
        dispatch(setCompany(data));
    }
};

export default companyDetailSlice.reducer;
