import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper';
import moment from 'moment';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import ReservationStatus from 'enums/reservation_status.enum';
import {
    convertReservationToCalendarEvent,
    filteredBusySlots,
    filterRegistered,
    filterReservations,
} from 'helpers/calendar.helper';
import { setMasterRegistered } from 'pages/counselor/calendar/calendar.slice';

const name = 'calendar';

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.ADMIN_CALENDARS, ...props })
);

export const defaultFilters = {
    company_id: null,
    program_id: null,
    start_date: moment().startOf('week'),
    end_date: moment().endOf('week'),
};

const initialState = {
    master: {
        company: {
            order: [],
            byId: {},
        },
        program: {
            order: [],
            byId: {},
        },
        companyProgram: {},
        registered: {},
        reservedAndBlockedFrames: {},
        depends: {},
    },
    filters: defaultFilters,
    sorter: {
        field: 'date',
        order: 'descend',
    },
    displayType: 'week',
    layoutType: 'calendar',
    reservations: {},
    afterDeleted: {},
};

const calendarSlice = createSlice({
    name,
    initialState: { ...baseInitialState, ...initialState },
    reducers: {
        ...baseReducers,
        setMasterCompany(state, action) {
            state.master.company = action.payload;
        },
        setMasterProgram(state, action) {
            state.master.program = action.payload;
        },
        setMasterCompanyProgram(state, action) {
            state.master.companyProgram = action.payload;
        },
        setMasterRegistered(state, action) {
            state.master.registered = action.payload;
        },
        setMasterReservedAndBlockedFrames(state, action) {
            state.master.reservedAndBlockedFrames = action.payload;
        },
        setMasterDepends(state, action) {
            state.master.depends = action.payload;
        },
        setCompany(state, action) {
            state.filters.company_id = action.payload;
        },
        setProgram(state, action) {
            state.filters.program_id = action.payload;
        },
        setStartDate(state, action) {
            state.filters.start_date = action.payload;
        },
        setEndDate(state, action) {
            state.filters.end_date = action.payload;
        },
        goPrev(state) {
            if (state.displayType === 'month') {
                state.filters.start_date = state.filters.start_date
                    .clone()
                    .add('-1', state.displayType)
                    .startOf(state.displayType);
                state.filters.end_date = state.filters.end_date
                    .clone()
                    .add('-1', state.displayType)
                    .endOf(state.displayType);
            } else {
                state.filters.start_date = state.filters.start_date.clone().add('-1', state.displayType);
                state.filters.end_date = state.filters.end_date.clone().add('-1', state.displayType);
            }

            state.pagination = { ...state.pagination, current: 1 };
        },
        goNext(state) {
            if (state.displayType === 'month') {
                state.filters.start_date = state.filters.start_date
                    .clone()
                    .add('1', state.displayType)
                    .startOf(state.displayType);
                state.filters.end_date = state.filters.end_date
                    .clone()
                    .add('1', state.displayType)
                    .endOf(state.displayType);
            } else {
                state.filters.start_date = state.filters.start_date.clone().add('1', state.displayType);
                state.filters.end_date = state.filters.end_date.clone().add('1', state.displayType);
            }
            state.pagination = { ...state.pagination, current: 1 };
        },
        goToday(state) {
            const now = moment();
            state.filters.start_date = now.clone().startOf(state.displayType);
            state.filters.end_date = now.clone().endOf(state.displayType);
            state.pagination = { ...state.pagination, current: 1 };
        },
        setDisplayType(state, action) {
            if (state.displayType !== action.payload) {
                state.displayType = action.payload;
                const now = moment();
                state.filters.start_date = now.clone().startOf(action.payload);
                state.filters.end_date = now.clone().endOf(action.payload);
            }
        },
        setLayoutType(state, action) {
            state.layoutType = action.payload;
        },
        setReservations(state, action) {
            state.reservations = action.payload;
        },
        setAfterDeleted(state, action) {
            state.afterDeleted = action.payload;
        },
    },
    extraReducers: baseExtraReducers(getData),
});

export const {
    setMasterCompany,
    setMasterProgram,
    setMasterCompanyProgram,
    setMasterReservedAndBlockedFrames,
    setMasterDepends,
    setCompany,
    setProgram,
    setStartDate,
    setEndDate,
    goPrev,
    goNext,
    goToday,
    setDisplayType,
    setLayoutType,
    setReservations,
    setAfterDeleted,
    setPagination,
    setFilters,
} = calendarSlice.actions;
export default calendarSlice.reducer;

export const getMasterData = () => async dispatch => {
    const data = await api.get(APP_URLS.ADMIN_CALENDAR_MASTER);
    if (data) {
        dispatch(setMasterCompany(data.company));
        dispatch(setMasterProgram(data.program));
        dispatch(setMasterCompanyProgram(data.companyProgram));
        dispatch(setMasterRegistered(data.registered));
        dispatch(setMasterReservedAndBlockedFrames(data.reservedAndBlockedFrames));
        dispatch(setMasterDepends(data.depends));
    }
};

export const getReservations = (startDate, endDate) => async dispatch => {
    const data = await api.get(APP_URLS.ADMIN_CALENDAR_RESERVATIONS, { start_date: startDate, end_date: endDate });
    if (data) {
        let reservations = {};
        for (const date of Object.keys(data)) {
            reservations[date] = data[date].map(r => convertReservationToCalendarEvent(r));
        }
        dispatch(setReservations(reservations));
    }
};

const filterSelector = state => state.filters;
const masterSelector = state => state.master;
const layoutTypeSelector = state => state.layoutType;
const registeredSelector = state => state.master.registered;
const reservationsSelector = state => state.reservations;
const reservedAndBlockedFramesSelector = state => state.master.reservedAndBlockedFrames;
const dependsSelector = state => state.master.depends;

export const programIdsSelector = createSelector(masterSelector, filterSelector, (master, filters) => {
    if (!filters.company_id) return master.program.order;
    return master.companyProgram[filters.company_id] || [];
});

export const filteredReservationsSelector = createSelector(
    filterSelector,
    layoutTypeSelector,
    reservationsSelector,
    filterReservations
);

export const filteredRegisteredSelector = createSelector(
    filterSelector,
    layoutTypeSelector,
    registeredSelector,
    filterRegistered
);

export const filteredBusySlotsSelector = createSelector(
    filterSelector,
    layoutTypeSelector,
    reservedAndBlockedFramesSelector,
    dependsSelector,
    filteredBusySlots
);
