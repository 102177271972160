import React from 'react';
import history from 'helpers/history.helper';
import Routes from 'routes';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import { useTranslation } from 'react-i18next';
import ReservationForm from 'pages/client/reservation/ReservationForm';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const mapState = state => ({
    program: state.client.programDetail.program,
});
const ReservationCreate = ({ program }) => {
    const { t } = useTranslation();

    const createReservation = async params => {
        const data = await api.post(APP_URLS.CLIENT_RESERVATIONS, params, {}, {}, true);
        if (data) {
            history.replace(`${Routes.private.CLIENT_RESERVATION_DETAIL.path.replace(':id', data.id)}?created=true`);
        }
    };

    return (
        <div>
            <BackBtnWithTitle title={t('Consultation reservation creation')} onClick={() => history.goBack()} />
            <ReservationForm
                onSubmit={values => createReservation(values)}
                program={program ? program.program : {}}
                companyProgramId={program ? program.id : {}}
                hiddenSubmit={false}
            />
        </div>
    );
};

export default connect(mapState)(ReservationCreate);

ReservationCreate.propTypes = {
    programs: PropTypes.object,
};
