import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Col, Row } from 'antd';
import { DateRangeIcon, LineIcon } from 'components/SvgIcon';
import { ProgramPropTypes, UserPropTypes } from 'constants/proptypes.constant';
import history from 'helpers/history.helper';
import Routes from 'routes';
import TdtAvatar from 'components/TdtAvatar';

const ReservationItem = ({ data, showBadge, showStart = true }) => {
    const { t } = useTranslation();

    let badgeIcon, badgeBg;
    if (showBadge === 'complete') {
        badgeIcon = <LineIcon />;
        badgeBg = '#13CBCB';
    }

    const isAutomaticAllocateCounselor = data.program.auto_allocate_counselor;

    return (
        <div className="reservation-item flex-row">
            <div className="icon">
                <TdtAvatar
                    avatarIcon={<DateRangeIcon size={28} />}
                    avatarSize={48}
                    avatarBgColor={showBadge ? '#D1D0D6' : '#13CBCB'}
                    badgeIcon={badgeIcon}
                    badgeBgColor={badgeBg}
                />
            </div>
            <div className="info">
                <p className="fs-18 mb-4 reservation-program-name">{data.program.name}</p>
                <p className="fs-20 mb-4 reservation-date-time">
                    {moment(data.date).format('M月D日(dd)')} {data.start_time}
                    {data.session_end_time ? `〜${data.session_end_time}` : ''}
                </p>
                <div className="counselor">
                    <span className="label">{t('entity:Counselor')}</span>
                    <span className="fs-14 reservation-counselor-name">
                        {isAutomaticAllocateCounselor ? t('Td counselor') : data.counselor.full_name}
                    </span>
                </div>
            </div>
            <div className="action">
                <Button
                    className="fw-b reservation-button detail-btn double-btn"
                    onClick={() => history.push(Routes.private.CLIENT_RESERVATION_DETAIL.path.replace(':id', data.id))}
                >
                    {t('Detail')}
                </Button>
                {showStart && (
                    <Button
                        type="primary"
                        className="fw-b ml-8 reservation-button consultation-started-btn double-btn"
                        target="_blank"
                        href={data.zoom && data.zoom.join_url}
                    >
                        {t('Consultation started')}
                    </Button>
                )}
            </div>
        </div>
    );
};

export default ReservationItem;

ReservationItem.propTypes = {
    data: PropTypes.shape({
        id: PropTypes.any,
        program: ProgramPropTypes,
        date: PropTypes.string,
        start_time: PropTypes.string,
        end_time: PropTypes.string,
        counselor: UserPropTypes,
    }),
};
