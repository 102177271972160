import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import QuestionnairePreview from 'pages/admin/interview_sheet/preview/QuestionnairePreview';
import { connect } from 'react-redux';
import { hasAnyRole, hasRole } from 'helpers/role.helper';
import RoleEnum from 'enums/role.enum';
import history from 'helpers/history.helper';
import Routes from 'routes';
import ReservationAnswerStatus from 'enums/reservation_answer_status.enum';

const mapState = state => ({
    verified: state.auth.isVerified,
    user: state.auth.user,
});
const mapDispatch = {};

const Questionnaire = ({ user }) => {
    const { uuid } = useParams();
    const [questionnaire, setQuestionnaire] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const autoRedirect = user && hasRole(user, RoleEnum.USER.value);
    const redirectToLogin = !user;

    const getQuestionnaire = async () => {
        const res = await api.get(APP_URLS.QUESTIONNAIRE_DETAIL.replace(':uuid', uuid));
        if (res) {
            setQuestionnaire(res);
        }
        setIsLoading(false);
    };

    const createQuestionnaire = async () => {
        const res = await api.post(APP_URLS.QUESTIONNAIRE_DETAIL.replace(':uuid', questionnaire.id));
        if (res) {
            // if (res.status === ReservationAnswerStatus.FILLED_IN.value) {
            //     history.push(Routes.private.CLIENT_INTERVIEW_SHEET_DISPLAY.path.replace(':id', res.id));
            // } else {
            history.push(Routes.private.CLIENT_INTERVIEW_SHEET_DETAIL.path.replace(':id', res.id));
            // }
        }
    };

    useEffect(() => {
        getQuestionnaire().then(() => {});
        return () => {
            setQuestionnaire(null);
        };
    }, [uuid]);

    if (isLoading) {
        return <Loader className="loader-center" />;
    }

    if (!questionnaire) {
        return <></>;
    }

    if (autoRedirect) {
        createQuestionnaire().then(() => {});
        return <></>;
    } else if (redirectToLogin) {
        history.push(Routes.public.LOGIN.path + '?questionnaire=' + questionnaire.id);
        return <></>;
    } else if (user && hasAnyRole(user, [RoleEnum.ADMIN.value, RoleEnum.ADMIN_STAFF.value])) {
        history.push(Routes.private.ADMIN_INTERVIEW_SHEETS_DETAIL.path.replace(':id', questionnaire.id));
        return <></>;
    }

    return (
        <div style={{ maxWidth: 820, padding: 24, margin: '24px auto 24px' }}>
            <QuestionnairePreview data={questionnaire} />
        </div>
    );
};

export default connect(mapState, mapDispatch)(Questionnaire);
