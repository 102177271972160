import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'antd';
import DataTable from 'components/DataTable';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { imageUrlAdapter } from 'helpers/image_url.helper';
import { CheckmarkCheckedIcon, CheckmarkEmptyIcon } from 'components/SvgIcon';
import NoImage from 'components/NoImage';

const TargetProgramTable = ({ companyId, selectedRecord, setSelectedRecord }) => {
    const { t } = useTranslation();

    const [data, setData] = useState([]);
    const getNewPrograms = async sorter => {
        const res = await api.get(
            APP_URLS.ADMIN_COMPANIES_DETAIL_PROGRAMS_LIST_FOR_MESSAGE_NEW.replace(':id', companyId),
            {
                company_id: companyId,
                order_by:
                    sorter && sorter.field === 'name'
                        ? sorter.order === 'ascend'
                            ? 'name'
                            : sorter.order === 'descend'
                            ? '-name'
                            : '-created_at'
                        : '-created_at',
            }
        );
        if (res && res.data && res.data.length > 0) {
            setData(res.data);
        }
    };

    const columns = [
        {
            title: t('Name'),
            dataIndex: 'name',
            sorter: true,
            render: (v, r) => {
                const mediaUrl = `${APP_URLS.STATIC_BASE_URL}/${r.cover_img}`;
                return (
                    <span className="d-flex align-items-center">
                        {isSelectedRow(r) ? (
                            <CheckmarkCheckedIcon className="mr-10 color-blue-100" />
                        ) : (
                            <CheckmarkEmptyIcon className="mr-10 color-blg-30" />
                        )}
                        <span className="mr-2 pr-8 pt-8 pb-8 d-flex">
                            {r.cover_img ? (
                                <Image width={46} height={46} preview={false} src={imageUrlAdapter(mediaUrl, 64)} />
                            ) : (
                                <NoImage />
                            )}
                        </span>
                        <span>{r.name}</span>
                    </span>
                );
            },
        },
        {
            title: t('Send status'),
            dataIndex: 'send_status',
            width: 214,
            render: (v, r) => {
                const sent_at = r.program_companies[0].sent_at;
                return sent_at ? `${sent_at}に送信済み` : '未送信';
            },
        },
    ];

    useEffect(() => {
        getNewPrograms().then(() => {});
    }, []);

    const isSelectedRow = record => selectedRecord && selectedRecord.id === record.id;

    return (
        <>
            <DataTable
                className="collapse-table mt-8 target-program-table"
                columns={columns}
                data={data}
                onRow={record => ({
                    onClick: () => {
                        setSelectedRecord(isSelectedRow(record) ? null : record);
                    },
                })}
                rowClassName={record => (isSelectedRow(record) ? 'checked-row' : '')}
                onTableChange={(pagination, filter, sorter) => {
                    getNewPrograms(sorter);
                }}
            />
        </>
    );
};

export default TargetProgramTable;
