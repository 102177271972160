import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';

const AccountDeletionModal = ({ visible, onClose, onUpdate }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={t('Account deletion')}
            visible={visible}
            width={320}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <p>{t('If you delete your account, you will not be able to log in.')}</p>
            <div className="text-align-right">
                <Button
                    className="cancel-button fw-b disable-color"
                    onClick={() => {
                        onClose();
                    }}
                >
                    <span className={'title-button'}>{t('Cancel')}</span>
                </Button>
                <Button
                    className="delete-button fw-b"
                    onClick={() => {
                        onUpdate && onUpdate();
                        onClose();
                    }}
                >
                    <span className={'title-button'}>{t('Delete')}</span>
                </Button>
            </div>
        </Modal>
    );
};

AccountDeletionModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default AccountDeletionModal;
