import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Menu, Select } from 'antd';
import TdtSelect from 'components/TdtSelect';
import DataTable from 'components/DataTable';
import TdtButton from 'components/TdtButton';
import {
    getData,
    getMasterData,
    programIdsSelector,
    setDisplayType,
    setLayoutType,
    setFilters,
    setPagination,
    filteredReservationsSelector,
    getReservations,
    goPrev,
    goNext,
    goToday,
    getBlockedSlot,
    addBlockedSlot,
    removeBlockedSlot,
    filteredRegisteredSelector,
    filteredBusySlotsSelector,
} from 'pages/counselor/calendar/calendar.slice';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { setBackUrl, setSidebarActive } from 'core.slice';
import { dateToStr } from 'helpers/date.helper';
import { AddIcon, ArrowDropDownIcon, DateRangeIcon, ListsIcon } from 'components/SvgIcon';
import FloatBar from 'components/FloatBar';
import CustomDropdown from 'components/CustomDropdown';
import TdtCalendar from 'components/calendar/TdtCalendar';
import TdtCalendarControl from 'components/calendar/TdtCalendarControl';
import { hasRole } from 'helpers/role.helper';
import RoleEnum from 'enums/role.enum';
import TdtCalendarDefaultDetailModal from 'components/calendar/TdtCalendarDefaultDetailModal';

const mapState = state => ({
    user: state.auth.user,
    state: state.counselor.calendar,
    companies: state.counselor.calendar.master.company,
    programs: state.counselor.calendar.master.program,
    selectableProgramIds: programIdsSelector(state.counselor.calendar),
    programCompany: state.counselor.calendar.master.programCompany,
    busySlots: filteredBusySlotsSelector(state.admin.calendar),
    registeredFiltered: filteredRegisteredSelector(state.counselor.calendar),
    startDate: state.counselor.calendar.filters.start_date,
    endDate: state.counselor.calendar.filters.end_date,
    displayType: state.counselor.calendar.displayType,
    layoutType: state.counselor.calendar.layoutType,
    reservations: filteredReservationsSelector(state.counselor.calendar),
    blocked: state.counselor.calendar.blocked,
});
const mapDispatch = {
    getMasterData,
    getReservations,
    getBlockedSlot,
    setDisplayType,
    setLayoutType,
    getData,
    setSidebarActive,
    setFilters,
    setPagination,
    goPrev,
    goNext,
    goToday,
    addBlockedSlot,
    removeBlockedSlot,
    setBackUrl,
};

const Calendar = ({
    user,
    state,
    companies,
    programs,
    busySlots,
    registeredFiltered,
    startDate,
    endDate,
    displayType,
    layoutType,
    selectableProgramIds,
    getMasterData,
    getBlockedSlot,
    getReservations,
    setDisplayType,
    setLayoutType,
    getData,
    setFilters,
    setPagination,
    reservations,
    goPrev,
    goNext,
    goToday,
    blocked,
    addBlockedSlot,
    removeBlockedSlot,
    setBackUrl,
}) => {
    const { t } = useTranslation();
    const isDoctor = hasRole(user, RoleEnum.DOCTOR.value);
    const isStaff = hasRole(user, RoleEnum.STAFF.value);

    const columns = [
        {
            title: '予約日時',
            dataIndex: 'date',
            key: 'date',
            width: 160,
            render: (value, record) =>
                `${dateToStr(value, 'DD日(ddd)')} ${record.start_time}-${record.session_end_time}`,
            sorter: true,
        },
        {
            title: '企業名',
            dataIndex: 'company|name',
            width: 242,
            render: (_, record) => (record.client.employee_company[0] || {}).name,
            sorter: true,
        },
        {
            title: 'プログラム名',
            dataIndex: 'program_name',
            width: 134,
            render: (_, record) => (
                <>
                    <span className={`eclipse-status eclipse-status-${record.program.color} mr-10`} />
                    {record.program.name}
                </>
            ),
        },
        {
            title: '相談者',
            dataIndex: 'client|first_name',
            width: 108,
            render: (_, record) => {
                const client = record.client || {};
                return (
                    <TdtButton
                        buttonStyle="plain"
                        onClick={event => {
                            event.stopPropagation();
                            setBackUrl(Routes.private.COUNSELOR_CALENDAR.path);
                            history.push(Routes.private.COUNSELOR_DETAIL_CONSULTATION.path.replace(':id', client.id));
                        }}
                        disabled={!!client.deleted_by_id}
                        className={client.deleted_by_id ? 'btn-plain-disable' : ''}
                    >{`${client.full_name}`}</TdtButton>
                );
            },
            sorter: true,
        },
        {
            title: 'カウンセラー',
            dataIndex: 'counselor|first_name',
            width: 134,
            render: (_, record) => {
                const counselor = record.counselor || {};
                return <span>{`${counselor.full_name}`}</span>;
            },
            sorter: true,
        },
    ];

    const getCalendars = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        getData({ pagination, filters, sorter });
    };

    useEffect(() => {
        getMasterData();
    }, []);

    useEffect(() => {
        if (layoutType === 'table') {
            getCalendars();
        } else {
            const startDateStr = startDate.format('YYYY-MM-DD');
            const endDateStr = endDate.format('YYYY-MM-DD');
            getReservations(startDateStr, endDateStr);
            if (isDoctor) getBlockedSlot(startDateStr, endDateStr);
        }
    }, [isDoctor, layoutType, startDate, endDate]);

    const metaData = state.metaData;

    const handleFilterByCompany = key => {
        const newFilters = { ...state.filters, company_id: key };
        const newPagination = { ...state.pagination, current: 1 };
        setFilters(newFilters);
        setPagination(newPagination);
        getCalendars(newPagination, newFilters, state.sorter);
    };

    const handleFilterByProgram = key => {
        const newFilters = { ...state.filters, program_id: key };
        const newPagination = { ...state.pagination, current: 1 };
        setFilters(newFilters);
        setPagination(newPagination);
        getCalendars(newPagination, newFilters, state.sorter);
    };

    const eventModalRenderer = useCallback(event => {
        return (
            <TdtCalendarDefaultDetailModal
                event={event}
                setBackUrl={() => setBackUrl(Routes.private.COUNSELOR_CALENDAR.path)}
            />
        );
    }, []);

    const createMenu = useCallback(
        (date, time, isBlocked) => {
            let items = [];
            if (isDoctor) {
                if (isBlocked) {
                    items.push(<Menu.Item key="unblock">{t('Unblock reservation slot')}</Menu.Item>);
                } else {
                    items.push(<Menu.Item key="create">{t('Create new reservation')}</Menu.Item>);
                    items.push(<Menu.Item key="block">{t('Block reservation slot')}</Menu.Item>);
                }
            } else if (isStaff) {
                items.push(<Menu.Item key="create">{t('Create new reservation')}</Menu.Item>);
            }
            return (
                <Menu
                    onClick={item => {
                        let path;
                        switch (item.key) {
                            case 'create':
                                path = `${Routes.private.COUNSELOR_CALENDAR_RESERVATION_CREATE.path}?date=${date}`;
                                if (time) {
                                    path += `&time=${time}`;
                                }
                                history.push(path);
                                break;
                            case 'block':
                                addBlockedSlot(date, time);
                                break;
                            case 'unblock':
                                removeBlockedSlot(date, time);
                                break;
                        }
                    }}
                >
                    {items}
                </Menu>
            );
        },
        [addBlockedSlot, removeBlockedSlot]
    );

    return (
        <div className="self-wrapper d-block">
            <h1 className="page-title mb-24">{t('Calendar')}</h1>
            <div className="controls">
                <div>
                    <TdtSelect
                        value={state.filters.company_id}
                        size="large"
                        className="fs-14 mr-8"
                        style={{ width: 120 }}
                        onChange={handleFilterByCompany}
                    >
                        <Select.Option value={null}>{t('All companies')}</Select.Option>
                        {companies.order.map(id => {
                            return (
                                <Select.Option key={id} value={id}>
                                    {companies.byId[id].name}
                                </Select.Option>
                            );
                        })}
                    </TdtSelect>
                    <TdtSelect
                        value={state.filters.program_id}
                        size="large"
                        className="fs-14 mr-8"
                        style={{ width: 160 }}
                        onChange={handleFilterByProgram}
                    >
                        <Select.Option value={null}>{t('All programs')}</Select.Option>
                        {selectableProgramIds.map(id => {
                            if (!programs.byId[id]) {
                                return null;
                            }
                            return (
                                <Select.Option key={id} value={id}>
                                    {programs.byId[id].name}
                                </Select.Option>
                            );
                        })}
                    </TdtSelect>
                </div>
                <div className="right-controls">
                    <TdtCalendarControl
                        displayType={displayType}
                        startDate={startDate}
                        endDate={endDate}
                        goNext={goNext}
                        goToday={goToday}
                        goPrev={goPrev}
                    />
                    <Button
                        type={layoutType === 'table' ? 'primary' : ''}
                        className="layout-btn mr-8"
                        onClick={() => {
                            setDisplayType('month');
                            setLayoutType('table');
                        }}
                    >
                        <ListsIcon class="m-0" />
                    </Button>
                    <CustomDropdown
                        trigger={['click']}
                        options={[
                            {
                                key: 'month',
                                text: t('Month display'),
                            },
                            {
                                key: 'week',
                                text: t('Week display'),
                            },
                            {
                                key: 'day',
                                text: t('Day display'),
                            },
                        ]}
                        onSelect={opt => {
                            setDisplayType(opt.key);
                            setLayoutType('calendar');
                        }}
                        separator
                        ovlClassName="calendar-dropdown-range-type"
                    >
                        <Button type={layoutType === 'calendar' ? 'primary' : ''} className="type-dropdown">
                            <DateRangeIcon className="ml-0" />
                            <ArrowDropDownIcon className="ml-0" />
                        </Button>
                    </CustomDropdown>
                </div>
            </div>
            {layoutType === 'table' && (
                <DataTable
                    className="collapse-table mt-16"
                    columns={columns}
                    hideColumns={state.hideColumns}
                    data={state.data}
                    loading={state.loading}
                    pagination={state.pagination}
                    filters={state.filters}
                    sorter={state.sorter}
                    onTableChange={(pagination, filter, sorter) => {
                        getCalendars(pagination, state.filters, sorter);
                    }}
                    noDataText={'データがありません'}
                    onRow={record => {
                        return {
                            onClick: () => {
                                if (record.client.deleted_by_id) return;
                                history.push(
                                    Routes.private.COUNSELOR_DETAIL_CONSULTATION.path.replace(':id', record.client_id) +
                                        `/${record.id}`
                                );
                            },
                        };
                    }}
                />
            )}
            {layoutType === 'calendar' && (
                <TdtCalendar
                    type={displayType}
                    events={reservations}
                    startDate={startDate}
                    active={registeredFiltered}
                    blocked={blocked}
                    disableDate={date => {
                        return moment(date).isBefore(moment().startOf('day'));
                    }}
                    createMenu={createMenu}
                    eventModalRenderer={eventModalRenderer}
                    busySlots={busySlots}
                />
            )}
            {layoutType === 'table' && (
                <FloatBar>
                    <Button
                        icon={<AddIcon />}
                        className="fw-b"
                        onClick={() => history.push(Routes.private.COUNSELOR_CALENDAR_RESERVATION_CREATE.path)}
                        type="primary"
                    >
                        {t('Create new reservation')}
                    </Button>
                </FloatBar>
            )}
        </div>
    );
};

export default connect(mapState, mapDispatch)(Calendar);

Calendar.propTypes = {
    state: PropTypes.any,
    getData: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired,
};
