import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'companyDetailProgram';

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props =>
        await baseGetData({ url: APP_URLS.ADMIN_COMPANIES_DETAIL_PROGRAMS.replace(':id', props['id']), ...props })
);

export const defaultFilters = {};

const companyDetailProgramSlice = createSlice({
    name,
    initialState: {
        ...baseInitialState,
        filters: defaultFilters,
        sorter: {
            field: 'display_order',
            order: 'descend',
        },
        testState: '1',
    },
    reducers: {
        ...baseReducers,
    },
    extraReducers: baseExtraReducers(getData),
});

export const { setLoading, setData, setPagination, setFilters, setSorter, setHideColumns, setSelection } =
    companyDetailProgramSlice.actions;

export default companyDetailProgramSlice.reducer;
