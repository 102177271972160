import React from 'react';
import PropTypes from 'prop-types';
import QuestionCommonFields from 'pages/admin/interview_sheet/questions/QuestionCommonFields';
import FloatBarQuestion from './FloatBarQuestion';
import HeaderQuestion from './HeaderQuestion';

const FreeInputQuestion = ({
    className,
    id,
    question,
    addition,
    setQuestion,
    setAddition,
    no,
    type,
    setType,
    isRequired,
    setIsRequired,
}) => {
    return (
        <div className={`question-block ${className ? className : ''}`}>
            <HeaderQuestion no={no} />
            <QuestionCommonFields
                id={id}
                question={question}
                addition={addition}
                setAddition={setAddition}
                setQuestion={setQuestion}
                type={type}
                setType={setType}
            />
            <FloatBarQuestion id={id} isRequired={isRequired} setIsRequired={setIsRequired} />
        </div>
    );
};

export default FreeInputQuestion;

FreeInputQuestion.propTypes = {
    className: PropTypes.string,
    id: PropTypes.any.isRequired,
    question: PropTypes.string,
    addition: PropTypes.string,
    setQuestion: PropTypes.func.isRequired,
    setAddition: PropTypes.func.isRequired,
    no: PropTypes.number,
    type: PropTypes.any,
    setType: PropTypes.func,
};
