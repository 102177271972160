import React, { useEffect, useRef, useState } from 'react';
import { Input, InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import TdtButton from 'components/TdtButton';
import { CheckboxIcon, DeleteIcon, DragIndicatorIcon, RadioIcon } from 'components/SvgIcon';
import QuestionTypeEnum from 'enums/question_type.enum';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import TdtSelect from 'components/TdtSelect';
import { randomId } from 'helpers/common.helper';

const DndOptionsTable = React.memo(
    ({
        questionId,
        options,
        optionsOrder,
        onOrderChange,
        type,
        updateOption,
        removeOption,
        displayColumns,
        flattenQuestions,
        no,
        setRefs,
        addOption,
        hasCheckErr = false,
    }) => {
        const { t } = useTranslation();
        const { TextArea } = Input;
        const [memoRefs, setMemoRefs] = useState({});
        const inputRef = useRef([]);

        const onDragEnd = result => {
            if (!result.destination) return;
            onOrderChange(result.source.index, result.destination.index);
        };

        useEffect(() => {
            if (displayColumns.includes('set_next_question')) {
                Object.keys(options)
                    .filter(
                        key => options[key].next_question < no || options[key].next_question > flattenQuestions.length
                    )
                    .map(key => {
                        updateOption({
                            optionId: key,
                            data: {
                                ...options[key],
                                next_question: null,
                            },
                        });
                    });
            }

            return () => {
                setMemoRefs({});
            };
        }, [flattenQuestions.length]);

        return (
            <>
                {optionsOrder.length > 0 && (
                    <div className="dnd-table">
                        <div className="dnd-table-header color-blg-60 f-14-700 d-flex">
                            <div style={{ flex: 320 }}>{'選択肢'}</div>
                            {displayColumns.includes('set_score') && (
                                <div style={{ flex: '0 0 96px', paddingLeft: 8 }}>{'スコア'}</div>
                            )}
                            {displayColumns.includes('set_next_question') && (
                                <div style={{ flex: '0 0 168px', paddingLeft: 8 }}>{'次の質問'}</div>
                            )}
                            <div style={{ flex: '0 0 48px', paddingLeft: 8 }} />
                        </div>
                        <div className="dnd-table-body mt-8">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                    {provided => (
                                        <div ref={provided.innerRef}>
                                            {optionsOrder.map((id, index) => {
                                                const option = options[id];

                                                const hasNameErr = hasCheckErr && !option.value;
                                                const hasScoreErr = hasCheckErr && !option.score && option.score !== 0;

                                                return (
                                                    <Draggable
                                                        key={id}
                                                        draggableId={`dnd_option_table_${id}`}
                                                        index={index}
                                                    >
                                                        {provided => (
                                                            <div ref={provided.innerRef} {...provided.draggableProps}>
                                                                <div className={`d-flex mb-8 align-items-center option-row-check-err ${(hasNameErr || hasScoreErr) ? 'option-has-row-err' : ''}`}>
                                                                    <div
                                                                        style={{ flex: 320 }}
                                                                        className={`d-flex align-items-center position-relative ${hasNameErr ? 'has-error-item' : ''}`}
                                                                    >
                                                                        <span {...provided.dragHandleProps}>
                                                                            <DragIndicatorIcon className="color-blg-50 d-flex" />
                                                                        </span>

                                                                        {type ===
                                                                        QuestionTypeEnum.SINGLE_SELECTION.value ? (
                                                                            <RadioIcon className="color-blg-50 mr-8" />
                                                                        ) : (
                                                                            <CheckboxIcon className="color-blg-50 mr-8" />
                                                                        )}
                                                                        {option.type !== 'other' ? (
                                                                            <></>
                                                                        ) : (
                                                                            <span className="f16-24 ml-8">
                                                                                {'その他'}
                                                                            </span>
                                                                        )}
                                                                        {option.type !== 'other' && (
                                                                            <>
                                                                                <TextArea
                                                                                    autoSize
                                                                                    placeholder="選択肢を入力"
                                                                                    className="bg-white fs-16"
                                                                                    value={option.value}
                                                                                    onChange={e => {
                                                                                        updateOption({
                                                                                            optionId: id,
                                                                                            data: {
                                                                                                ...options[id],
                                                                                                value: e.target.value,
                                                                                            },
                                                                                        });
                                                                                    }}
                                                                                    ref={el => {
                                                                                        inputRef.current[id] = el;
                                                                                        if (!memoRefs[id]) {
                                                                                            setMemoRefs({
                                                                                                ...memoRefs,
                                                                                                [id]: id,
                                                                                            });
                                                                                            setRefs({ id, el: el });
                                                                                        }
                                                                                    }}
                                                                                    onPressEnter={e => {
                                                                                        e.preventDefault();
                                                                                        if (
                                                                                            index ===
                                                                                            optionsOrder.length - 1
                                                                                        ) {
                                                                                            addOption({
                                                                                                questionId: questionId,
                                                                                                optionId: randomId(),
                                                                                                data: {
                                                                                                    value: '',
                                                                                                },
                                                                                            });
                                                                                        }

                                                                                        if (
                                                                                            index <
                                                                                                optionsOrder.length -
                                                                                                    1 &&
                                                                                            inputRef.current[
                                                                                                optionsOrder[index + 1]
                                                                                            ]
                                                                                        ) {
                                                                                            inputRef.current[
                                                                                                optionsOrder[index + 1]
                                                                                            ].focus();
                                                                                        }
                                                                                        // inputRef.current[id] &&
                                                                                        //     inputRef.current[
                                                                                        //         id
                                                                                        //     ].focus();
                                                                                    }}
                                                                                />
                                                                            </>
                                                                        )}

                                                                        {hasNameErr && (
                                                                            <span className="fs-12-12 position-absolute color-red-100 option-row-check-err-name">{'選択肢を入力してください'}</span>
                                                                        )}
                                                                    </div>

                                                                    {displayColumns.includes('set_score') && (
                                                                        <div
                                                                            style={{ flex: '0 0 96px', paddingLeft: 8 }}
                                                                            className={`position-relative ${hasScoreErr ? 'has-error-item' : ''}`}
                                                                        >
                                                                            <InputNumber
                                                                                className="bg-white text-align-right w-100p input-number-dnd"
                                                                                type="number"
                                                                                min={0}
                                                                                value={option.score}
                                                                                onChange={value => {
                                                                                    updateOption({
                                                                                        optionId: id,
                                                                                        data: {
                                                                                            ...options[id],
                                                                                            score: value,
                                                                                        },
                                                                                    });
                                                                                }}
                                                                                // ref={el => {
                                                                                //     inputRef.current[id] = el;
                                                                                // }}
                                                                            />

                                                                            {hasScoreErr && (
                                                                                <span className="fs-12-12 position-absolute color-red-100 option-row-check-err-score">{'スコアを入力してください'}</span>
                                                                            )}
                                                                        </div>
                                                                    )}

                                                                    {displayColumns.includes('set_next_question') && (
                                                                        <div
                                                                            style={{
                                                                                flex: '0 0 168px',
                                                                                paddingLeft: 8,
                                                                            }}
                                                                        >
                                                                            <TdtSelect
                                                                                size="large"
                                                                                className="fs-16 bg-white h-40 w-100p select-40 limit-text"
                                                                                value={option.next_question || null}
                                                                                onChange={value => {
                                                                                    updateOption({
                                                                                        optionId: id,
                                                                                        data: {
                                                                                            ...options[id],
                                                                                            next_question: value,
                                                                                        },
                                                                                    });
                                                                                }}
                                                                                optionLabelProp="label"
                                                                                dropdownClassName="dropdown-popup-width"
                                                                            >
                                                                                <Select.Option
                                                                                    value={null}
                                                                                    label={t('Next')}
                                                                                >
                                                                                    {t('Next')}
                                                                                </Select.Option>
                                                                                {flattenQuestions.map((q, index) => {
                                                                                    if (index < no) {
                                                                                        return null;
                                                                                    }

                                                                                    return (
                                                                                        <Select.Option
                                                                                            key={q.id}
                                                                                            value={index + 1 + ''}
                                                                                            label={`設問${index + 1}`}
                                                                                        >
                                                                                            {`設問${index + 1}` +
                                                                                                '　' +
                                                                                                (q.question || '')}
                                                                                        </Select.Option>
                                                                                    );
                                                                                })}
                                                                                <Select.Option
                                                                                    value={'finish'}
                                                                                    label={t('Finish')}
                                                                                >
                                                                                    {t('Finish')}
                                                                                </Select.Option>
                                                                            </TdtSelect>
                                                                        </div>
                                                                    )}

                                                                    <div style={{ flex: '0 0 48px', paddingLeft: 8 }}>
                                                                        <TdtButton
                                                                            buttonStyle="outline"
                                                                            buttonSize="big"
                                                                            className="pink-color btn-radius-4"
                                                                            onClick={() =>
                                                                                removeOption({
                                                                                    questionId,
                                                                                    optionId: id,
                                                                                })
                                                                            }
                                                                        >
                                                                            <DeleteIcon />
                                                                        </TdtButton>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                )}
            </>
        );
    }
);

export default DndOptionsTable;
