import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { UserPropTypes } from 'constants/proptypes.constant';
import RoleEnum from 'enums/role.enum';
import { getEnumText } from 'helpers/enum.helper';
import BackBtn from 'components/BackBtn';
import FieldInfo from 'components/FieldInfo';
import UpdateRoleModal from 'pages/admin/counselor/UpdateRoleModal';
import UpdateEmailModal from 'pages/admin/counselor/UpdateEmailModal';
import {
    filterCompanies,
    filterRegistered,
    getCounselor,
    setFilter,
    updateEmail,
    updateRole,
} from 'pages/admin/counselor/counselor_detail.slice';
import { Button, Row, Col, Select } from 'antd';
import history from 'helpers/history.helper';
import DataTable from 'components/DataTable';
import { AddIcon, DeleteIcon, EditIcon } from 'components/SvgIcon';
import TdtSelect from 'components/TdtSelect';
import CustomDropdown from 'components/CustomDropdown';
import Routes from 'routes';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import CounselorDeleteModal from 'pages/admin/counselor/CounselorDeleteModal';
import CounselorDeleteProgramModal from 'pages/admin/counselor/CounselorDeleteProgramModal';
import CounselorCantDeleteModal from 'pages/admin/counselor/CounselorCantDeleteModal';
import CounselorDeleteStaffModal from 'pages/admin/counselor/CounselorDeleteStaffModal';
import Loader from 'components/Loader';
import { emailDeletedShow } from 'helpers/string.helper';
import CounselorProgramTable from 'pages/admin/counselor/program_table/CounselorProgramTable';
import FloatBar from 'components/FloatBar';
import { isAdminStaff } from 'helpers/role.helper';

const mapState = state => ({
    counselor: state.admin.counselorDetail.counselor,
    registeredLoading: state.admin.counselorDetail.registeredLoading,
    registered: filterRegistered(state.admin.counselorDetail),
    companies: filterCompanies(state.admin.counselorDetail),
    programs: state.admin.counselorDetail.programs,
    companiesForFilter: state.admin.counselorDetail.companiesForFilter,
    filter: state.admin.counselorDetail.filter,
    isStaff: state.admin.counselorDetail.isStaff,
    user: state.auth.user,
});
const mapDispatch = { getCounselor, updateRole, updateEmail, setFilter };
const CounselorDetail = ({
    counselor,
    registeredLoading,
    registered,
    companies,
    programs,
    companiesForFilter,
    filter,
    getCounselor,
    updateRole,
    updateEmail,
    setFilter,
    isStaff,
    user,
}) => {
    const { t } = useTranslation();
    const { id } = useParams();

    const companyColumns = [
        {
            title: t('Company in charge'),
            dataIndex: 'name',
            width: 50,
            render: (_, r) => `${r.name}`,
        },
        {
            title: t('Program'),
            dataIndex: 'programs',
            width: 150,
            render: (_, r) => {
                const programs = r.programs || [];

                return (
                    <>
                        {programs.map((p, index) => {
                            const isLast = index === programs.length - 1;
                            return (
                                <React.Fragment key={`programs_${index}`}>
                                    {p.name_mngt}
                                    {!isLast ? <>, </> : ''}
                                </React.Fragment>
                            );
                        })}
                    </>
                );
            },
        },
    ];

    useEffect(() => {
        counselor = null;
        getCounselor(id);
    }, [id]);

    useEffect(() => {
        if (counselor && counselor.is_disabled) {
            showNormal('', t('Has been deleted'), 3);
        }
    }, [counselor && counselor.is_disabled]);

    const [modalVisible, setModalVisible] = useState({
        role: false,
        email: false,
        delete: false,
        cantDelete: false,
        deleteProgram: false,
        deleteStaff: false,
        programId: null,
        companyId: null,
        id: null,
    });
    const showRoleModal = () => setModalVisible({ role: true, email: false, delete: false, deleteProgram: false });
    const showEmailModal = () => setModalVisible({ role: false, email: true, delete: false, deleteProgram: false });
    const showDeleteModal = () => setModalVisible({ role: false, email: false, deleteProgram: false, delete: true });
    const showCantDeleteModal = () =>
        setModalVisible({ cantDelete: true, role: false, email: false, deleteProgram: false, delete: false });
    const showDeleteStaffModal = (id, companyId) =>
        setModalVisible({
            role: false,
            email: false,
            delete: false,
            deleteProgram: false,
            deleteStaff: true,
            id,
            companyId,
        });
    const closeModal = () =>
        setModalVisible({ role: false, email: false, delete: false, deleteProgram: false, deleteStaff: false });

    const deleteCounselorProgram = async (companyProgramId, counselorId) => {
        let deleteAPIPath = APP_URLS.ADMIN_COUNSELORS_PROGRAM_DELETE.replace(
            ':programCompanyId',
            companyProgramId
        ).replace(':id', counselorId);
        const res = await api.delete(deleteAPIPath);
        if (res) {
            showNormal('', t('message:Deleted the responsible company / program'), 5);
            getCounselor(id);
        }
    };

    const deleteStaffCompany = async (counselorId, companyId) => {
        let deleteAPIPath = APP_URLS.ADMIN_COMPANIES_DETAIL_STAFFS_DELETE.replace(':id', companyId).replace(
            ':staffsId',
            counselorId
        );
        const res = await api.delete(deleteAPIPath);
        if (res) {
            showNormal('', t('message:I deleted the company in charge'), 5);
            getCounselor(id);
        }
    };

    const deleteCounselor = async () => {
        const res = await api.delete(APP_URLS.ADMIN_COUNSELORS_DELETE.replace(':id', counselor.id));
        if (res) {
            showNormal('', t('message:Counselor has been deleted'), 5);
        }
        history.push(Routes.private.ADMIN_COUNSELOR.path);
    };

    const renderCompaniesTable = () => (
        <DataTable
            className="collapse-table mt-24"
            columns={companyColumns}
            loading={registeredLoading}
            data={companies}
            noDataText={'データがありません'}
            actionColumn={{
                title: t('Edit'),
                className: 'action-with-icon',
                width: 120,
                render: r => {
                    return (
                        <CustomDropdown
                            trigger={['click']}
                            options={[
                                {
                                    key: 'delete',
                                    text: (
                                        <>
                                            <DeleteIcon /> <span>{'スタッフ削除'}</span>
                                        </>
                                    ),
                                    className: 'pink-color fw-b',
                                },
                            ]}
                            onSelect={opt => {
                                if (opt.key === 'delete') {
                                    // deleteStaffCompany(counselor.id, r.id);
                                    showDeleteStaffModal(counselor.id, r.id);
                                }
                            }}
                            separator
                        >
                            <EditIcon className="primary-color custom-icon c-pointer" />
                        </CustomDropdown>
                    );
                },
            }}
        />
    );

    const selectorFilter = () => {
        let data = isStaff ? companiesForFilter : programs;
        return (
            <>
                {Object.keys(data).map(id => (
                    <Select.Option key={id} value={id}>
                        {data[id]}
                    </Select.Option>
                ))}
            </>
        );
    };

    const handleAddCompanyProgram = () => {
        history.push(Routes.private.ADMIN_COUNSELORS_ADD_COMPANY_PROGRAM.path.replace(':id', counselor.id));
    };

    if (!counselor) {
        return <Loader className="loader-center" />;
    }

    return (
        <div className="self-wrapper px-48 d-block">
            <BackBtn label={`${counselor.full_name}`} />
            <FieldInfo
                className="mt-32 mb-40"
                label={t('field:Role')}
                value={t(`enum:${getEnumText(RoleEnum, counselor.role)}`)}
                onEdit={() => {
                    showRoleModal();
                }}
                showEdit={counselor && !counselor.is_disabled && !isAdminStaff(user)}
            />
            <FieldInfo
                className="mb-40"
                label={t('field:Email')}
                value={emailDeletedShow(counselor.email)}
                onEdit={() => {
                    showEmailModal();
                }}
                showEdit={
                    counselor &&
                    !counselor.is_disabled &&
                    (!isAdminStaff(user) || (isAdminStaff(user) && !isAdminStaff(counselor)))
                }
            />

            {isAdminStaff(counselor) ? (
                <></>
            ) : (
                <>
                    <div className="fs-16 fw-b mt-56">{t('Company/program in charge')}</div>
                    <TdtSelect
                        style={{ width: 180 }}
                        size="large"
                        className="fs-14 mt-16"
                        value={filter}
                        onChange={value => {
                            setFilter(value);
                        }}
                    >
                        <Select.Option value="" disabled={counselor && counselor.is_disabled}>
                            {t('All program')}
                        </Select.Option>
                        {selectorFilter()}
                    </TdtSelect>

                    {counselor.companies ? (
                        renderCompaniesTable()
                    ) : (
                        <CounselorProgramTable
                            counselor={counselor}
                            registered={registered}
                            registeredLoading={registeredLoading}
                            setModalVisible={setModalVisible}
                        />
                    )}
                </>
            )}

            <CounselorDeleteModal
                onClose={closeModal}
                onUpdate={deleteCounselor}
                visible={modalVisible.delete}
                isAdminStaff={isAdminStaff(counselor)}
            />

            <CounselorDeleteStaffModal
                onClose={() => closeModal()}
                onUpdate={() => deleteStaffCompany(modalVisible.id, modalVisible.companyId)}
                visible={modalVisible.deleteStaff}
            />

            <CounselorCantDeleteModal onClose={closeModal} onUpdate={closeModal} visible={modalVisible.cantDelete} />

            <CounselorDeleteProgramModal
                onClose={() => closeModal()}
                onUpdate={() => deleteCounselorProgram(modalVisible.programId, modalVisible.id)}
                visible={modalVisible.deleteProgram}
            />

            <UpdateRoleModal
                visible={modalVisible.role}
                onClose={() => closeModal()}
                initialValue={counselor.role}
                onUpdate={role => updateRole(id, role, t('message:Role change saved'))}
                counselor={counselor}
            />
            <UpdateEmailModal
                visible={modalVisible.email}
                onClose={() => closeModal()}
                initialValue={counselor.email}
                onUpdate={email => updateEmail(id, email)}
            />
            <FloatBar>
                <Row className="w-100p" justify="space-between" align="middle">
                    <Button
                        className="fw-b pink-color"
                        icon={<DeleteIcon />}
                        hidden={(counselor && counselor.is_disabled) || (isAdminStaff(user) && isAdminStaff(counselor))}
                        onClick={() => {
                            counselor && counselor.exist_reservations ? showCantDeleteModal() : showDeleteModal();
                        }}
                    >
                        {t('Delete member')}
                    </Button>
                    {isAdminStaff(counselor) ? (
                        <></>
                    ) : (
                        <Button className="fw-b" icon={<AddIcon />} onClick={handleAddCompanyProgram}>
                            {t('Addition of responsible company/program')}
                        </Button>
                    )}
                </Row>
            </FloatBar>
        </div>
    );
};

export default connect(mapState, mapDispatch)(CounselorDetail);

CounselorDetail.propTypes = {
    counselor: UserPropTypes,
    registeredLoading: PropTypes.bool,
    registered: PropTypes.array,
    companies: PropTypes.array,
    programs: PropTypes.object,
    companiesForFilter: PropTypes.object,
    filter: PropTypes.any,
    getCounselor: PropTypes.func,
    updateRole: PropTypes.func,
    updateEmail: PropTypes.func,
    setFilter: PropTypes.func,
    isStaff: PropTypes.bool,
};
