import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackBtn from 'components/BackBtn';
import { InfoIcon } from 'components/SvgIcon';
import { Button, Col, Form, Input, Row } from 'antd';
import FloatBar from 'components/FloatBar';
import MessageNewPreviewModal from 'pages/admin/company/tabs/message/MessageNewPreviewModal';
import TargetSessionTable from 'pages/admin/company/tabs/message/TargetSessionTable';
import DeleteScheduledModal from 'pages/admin/company/tabs/message/DeleteScheduledModal';
import api from 'helpers/api.helper';
import { showNormal } from 'helpers/notification.helper';
import { APP_URLS } from 'constants/url.constant';
import history from 'helpers/history.helper';
import CompanyDetailRoutes from 'pages/admin/company/company_detail_routes';

const MessageNewSession = ({ company, baseUrl }) => {
    const { t } = useTranslation();

    const selectFromId = new URLSearchParams(useLocation().search).get('selected_id');

    const [note, setNote] = useState('');
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [modalVisible, setModalVisible] = useState({
        preview: false,
        deleteScheduled: false,
    });
    const showPreviewModal = () => setModalVisible({ ...modalVisible, preview: true });
    const showDeleteScheduledModal = () => setModalVisible({ ...modalVisible, preview: false, deleteScheduled: true });
    const closeModal = () => setModalVisible({ preview: false, deleteScheduled: false });

    const submitMessageNewSession = async () => {
        const res = await api.post(
            APP_URLS.ADMIN_COMPANIES_SEMINAR_SEND_MESSAGE_NEW.replace(':id', selectedRecord.id),
            { note, company_id: company.id }
        );
        if (res) {
            history.push(`${baseUrl}${CompanyDetailRoutes.MESSAGE.path}`);
            showNormal('', t('message:I sent a notice'), 3);
        }
    };

    return (
        <div className="px-24">
            <BackBtn className="mb-32" label={t('Manually send notifications')} />

            <div className="mt-24 mb-32 fw-b f20-36">{t('Notice (session)')}</div>

            <p className="fw-b mt-32 mb-8">{t('field:Company name')}</p>
            <div className="mb-8 h7">{company.name}</div>
            <div className="mb-32 body2 d-flex">
                <span className="medium-color">
                    <InfoIcon size={20} className="mr-4" />
                </span>
                <span>
                    <p className="mb-0">
                        {'・送信者はTeam Doctorsカスタマーサポートで、社員から返信はできない形式で送信されます。'}
                    </p>
                </span>
            </div>

            <Form layout="vertical" requiredMark={false}>
                <Row gutter={16}>
                    <Col span={24} className="mb-8">
                        <Form.Item label={t('Additional notes')} validateTrigger={false}>
                            <Input.TextArea
                                style={{ maxWidth: 480 }}
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                value={note}
                                onChange={v => setNote(v.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mb-8">
                        <Form.Item label={t('Target session')} validateTrigger={false}>
                            <p className="body2 mb-4">{'セッションを１つ選択してください(必須)'}</p>
                            <p className="body2 mb-4">
                                {
                                    '※送信予定日が設定されているセッションは、手動で送信した場合送信予定日は削除されます。'
                                }
                            </p>
                            <TargetSessionTable
                                companyId={company.id}
                                selectedRecord={selectedRecord}
                                setSelectedRecord={setSelectedRecord}
                                selectFromId={selectFromId}
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <MessageNewPreviewModal
                    record={selectedRecord}
                    visible={modalVisible.preview}
                    onClose={() => closeModal()}
                    onUpdate={() => {
                        if (selectedRecord.transmission_date && selectedRecord.transmission_time) {
                            showDeleteScheduledModal();
                        } else {
                            submitMessageNewSession();
                            closeModal();
                        }
                    }}
                    note={note}
                />

                <DeleteScheduledModal
                    record={selectedRecord}
                    visible={modalVisible.deleteScheduled}
                    onClose={() => closeModal()}
                    onUpdate={() => {
                        submitMessageNewSession();
                    }}
                />

                <FloatBar>
                    <div className="float-bar-company">
                        <Button className="fw-b" onClick={() => showPreviewModal()} disabled={!selectedRecord}>
                            {t('Preview and submit')}
                        </Button>
                    </div>
                </FloatBar>
            </Form>
        </div>
    );
};

export default MessageNewSession;

MessageNewSession.propTypes = {
    initialValues: PropTypes.object,
    company: PropTypes.object,
};
