import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmailTabList from 'pages/admin/email/EmailTabList';
import TdtSelect from 'components/TdtSelect';
import { Button, Select, Typography } from 'antd';
import { getEnumText } from 'helpers/enum.helper';
import DeliveryStatus from 'enums/delivery_status.enum';
import DataTable from 'components/DataTable';
import { AddIcon, DeleteIcon, DotsHorizontalRoundedIcon, DuplicateIcon } from 'components/SvgIcon';
import history from 'helpers/history.helper';
import Routes from 'routes';
import FloatBar from 'components/FloatBar';
import { getData, setFilters, setSorter, setPagination } from 'pages/admin/email/email.slice';
import { connect } from 'react-redux';
import { truncateStr } from 'helpers/string.helper';
import { dateToStr } from 'helpers/date.helper';
import CustomDropdown from 'components/CustomDropdown';
import DeleteEmailModal from 'pages/admin/email/modal/DeleteEmailModal';
import CloneEmailModal from 'pages/admin/email/modal/CloneEmailModal';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';

const mapState = state => ({
    state: state.admin.email,
});
const mapDispatch = { getData, setFilters, setSorter, setPagination };

const Email = ({ state, getData, setFilters, setSorter, setPagination }) => {
    const { t } = useTranslation();

    const [modalVisible, setModalVisible] = useState({
        clone: false,
        delete: false,
        record: null,
    });

    const closeModal = () =>
        setModalVisible({
            clone: false,
            delete: false,
            record: null,
        });

    const showDeleteModal = r => setModalVisible({ ...modalVisible, delete: true, record: r });
    const showCloneModal = r => setModalVisible({ ...modalVisible, clone: true, record: r });

    const deleteEmail = async emailId => {
        const response = await api.delete(APP_URLS.ADMIN_EMAILS_DELETE.replace(':id', emailId));
        if (response) {
            history.push(Routes.private.ADMIN_EMAIL.path);
            showNormal('', t('message:Data has been deleted'), 3);
            getEmailHtmls();
        }
    };

    const columns = [
        {
            title: '送信日時',
            dataIndex: 'delivery_date',
            width: 120,
            sorter: true,
            render: (delivery_date, r) =>
                (r.delivery_date && r.delivery_time) ? dateToStr(`${r.delivery_date} ${r.delivery_time}`, 'YYYY/M/DD(ddd) HH:mm') : '-',
        },
        {
            title: '件名',
            dataIndex: 'title',
            render: (title, r) => {
                return (
                    <div>
                        <div className="fw-b high-color">
                            {r.title}
                            {!!r.ref_seminar_id && (
                                <span className='small-note-interview-sheet ml-10 bg-white d-inline-flex'>
                                    <Typography className='text-small-note-interview-sheet '>
                                        {'自動配信'}
                                    </Typography>
                                </span>
                            )}
                        </div>
                        <div className="text-small">{truncateStr(r.additional_notes, 70)}</div>
                    </div>
                );
            },
        },
        {
            title: '配信数',
            dataIndex: 'number_of_deliveries',
            width: 88,
            sorter: true,
            className: 'text-right',
        },
        {
            title: '配信状況',
            dataIndex: 'status',
            width: 80,
            render: status => t(`enum:${getEnumText(DeliveryStatus, status)}`),
        },
        {
            title: '',
            dataIndex: 'dots-select',
            render: (_, r) => {
                return (
                    <CustomDropdown
                        ovlClassName={'dots-dropdown z-index-above-modal'}
                        getPopupContainer={node => node}
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        trigger={['click']}
                        options={[
                            {
                                key: 'duplicate',
                                text: (
                                    <>
                                        <DuplicateIcon /> <span>{'複製'}</span>
                                    </>
                                ),
                                className: 'primary-color fw-b',

                            },
                            {
                                key: 'delete',
                                text: (
                                    <>
                                        <DeleteIcon /> <span>{'削除'}</span>
                                    </>
                                ),
                                className: 'pink-color fw-b',
                            },
                        ].filter(o => o.key !== 'duplicate' || !r.ref_seminar_id)}
                        onSelect={opt => {
                            opt.domEvent.stopPropagation();
                            if (opt.key === 'delete') {
                                showDeleteModal(r);
                            } else {
                                showCloneModal(r);
                            }
                        }}
                        separator
                    >
                        <DotsHorizontalRoundedIcon className=" dots-icon c-pointer" />
                    </CustomDropdown>
                );
            },
            width: 40,
        },
    ];

    const getEmailHtmls = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        if (!sorter || !sorter.field) {
            sorter = state.sorter;
        }
        setSorter(sorter);
        getData({ pagination, filters, sorter });
    };

    useEffect(() => {
        getEmailHtmls();
    }, []);

    return (
        <div className="self-wrapper px-0 pb-64">
            <h1 className="page-title mb-16 ml-24">{t('Email')}</h1>
            <EmailTabList tab="delivery" />
            <div className="p-24">
                <TdtSelect
                    size="large"
                    className="fs-14"
                    defaultValue="all"
                    value={state.filters.status || 'all'}
                    onChange={value => {
                        const newFilters = Object.assign({}, state.filters, { status: value });
                        const newPagination = { ...state.pagination, current: 1 };
                        setFilters(newFilters);
                        setPagination(newPagination);
                        getEmailHtmls(newPagination, newFilters, state.sorter);
                    }}
                >
                    <Select.Option value="all">{t('All delivery status')}</Select.Option>
                    {Object.values(DeliveryStatus).map(o => (
                        <Select.Option key={o.value} value={o.value}>
                            {t(`enum:${getEnumText(DeliveryStatus, o.value)}`)}
                        </Select.Option>
                    ))}
                </TdtSelect>
                <DataTable
                    className="mt-16 collapse-table"
                    columns={columns}
                    data={state.data}
                    loading={state.loading}
                    pagination={state.pagination}
                    filters={state.filters}
                    sorter={state.sorter}
                    onTableChange={(pagination, filter, sorter) => {
                        getEmailHtmls(pagination, state.filters, sorter);
                    }}
                    noDataText={t('No emails have been sent.')}
                    onRow={record => ({
                        onClick: () => {
                            if (record.status === DeliveryStatus.DELIVERED.value) {
                                history.push(Routes.private.ADMIN_EMAIL_DETAIL.path.replace(':id', record.id));
                            } else {
                                history.push(Routes.private.ADMIN_EMAIL_UPDATE.path.replace(':id', record.id));
                            }
                        },
                    })}
                />
            </div>
            <DeleteEmailModal
                onClose={() => closeModal()}
                onUpdate={() => deleteEmail(modalVisible.record.id)}
                visible={modalVisible.delete}
                record={modalVisible.record}
            />
            <CloneEmailModal onClose={() => closeModal()} visible={modalVisible.clone} record={modalVisible.record} />
            <FloatBar>
                <Button
                    icon={<AddIcon />}
                    className="fw-b"
                    onClick={() => history.push(Routes.private.ADMIN_EMAIL_CREATE.path)}
                >
                    {'新規メール作成'}
                </Button>
            </FloatBar>
        </div>
    );
};

export default connect(mapState, mapDispatch)(Email);
