import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper';
import { APP_URLS } from 'constants/url.constant';
import api from 'helpers/api.helper';

const name = 'consultation_consultant';

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.COUNSELOR_CONSULTANTS, ...props })
);

export const defaultFilters = {
    company_id: null,
    program_id: null,
    keyword: '',
};
const consultantSlice = createSlice({
    name,
    initialState: {
        ...baseInitialState,
        filters: defaultFilters,
        sorter: {
            field: 'created_at',
            order: 'descend',
        },
        metaData: {
            programs: [],
            companies: {},
        },
        pagination: {
            current: 1,
            pageSize: 10,
            total: 1,
        },
    },
    reducers: {
        ...baseReducers,
        setMetaData(state, action) {
            state.metaData = action.payload;
        },
    },
    extraReducers: baseExtraReducers(getData),
});

export const { setLoading, setData, setPagination, setFilters, setSorter, setHideColumns, setSelection, setMetaData } =
    consultantSlice.actions;

export default consultantSlice.reducer;

export const getMetaData = () => async dispatch => {
    const metaData = await api.get(APP_URLS.COUNSELOR_CONSULTANTS_META_DATA); // metaData['programs']
    if (metaData) {
        let programs = [];
        let companies = {};
        for (const program of metaData.programs) {
            let tmpProgram = { id: program.id, name: program.name };
            let tmpCompanyIdsForProgram = [];
            for (const reservation of program.reservations) {
                const company = reservation.client.employee_company[0];
                if (!tmpCompanyIdsForProgram.includes(company.id)) {
                    tmpCompanyIdsForProgram.push(company.id);
                }

                if (!companies[company.id]) {
                    companies[company.id] = { id: company.id, name: company.name };
                }
            }
            tmpProgram.companyIds = tmpCompanyIdsForProgram;
            programs.push(tmpProgram);
        }

        dispatch(setMetaData({ programs, companies }));
    }
};
