import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row, Input } from 'antd';
import { CloseIcon, WeightIcon } from 'components/SvgIcon';
import DateChangeInModalComponent from 'pages/client/health_data/tabs/date_time/DateChangeInModalComponent';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import HealthTypeEnum from 'enums/health_type.enum';

const CreateWeightModal = ({ value = {}, setValue, visible, onClose, onUpdate, getHealthPerType, isHealthList }) => {
    const { t } = useTranslation();

    const creatWeightData = async (values, type) => {
        const response = await api.post(APP_URLS.CLIENT_HEALTH_DATA_CREATE, {
            value: value.weight,
            created_at: value.created_at,
            health_images: [{ images: value.images, category: value.category }],
            // category: values.category,
            type,
        });
        if (response) {
            showNormal('', t('message:Data has been saved'), 3);
            getHealthPerType();
        }
    };
    return (
        <>
            <Modal
                title={
                    <>
                        <Row align={'middle'}>
                            <WeightIcon size={39} className="pr-8" />
                            {t('Body weight')}
                        </Row>
                    </>
                }
                visible={visible}
                width={320}
                footer={null}
                closeIcon={<CloseIcon />}
                onCancel={() => onClose()}
                destroyOnClose={true}
            >
                <DateChangeInModalComponent value={value} setValue={setValue} />
                <Row>
                    <Col>
                        <Input
                            className="heath-data-modal-input weight mt-16 mr-8"
                            value={value.weight || null}
                            type="number"
                            min={0}
                            autoFocus={true}
                            onChange={e =>
                                setValue({
                                    ...value,
                                    weight:
                                        Number(e.target.value) < 0
                                            ? Number(e.target.value) * -1
                                            : Number(e.target.value) <= 500
                                            ? Number(e.target.value)
                                            : null,
                                })
                            }
                        />
                        <span>kg</span>
                    </Col>
                </Row>
                <Button
                    type="primary"
                    block
                    className="mt-32 disable-gray-btn"
                    disabled={!value.weight || value.weight.length < 1}
                    onClick={values => {
                        isHealthList
                            ? creatWeightData(values, HealthTypeEnum.WEIGHT.value).then(() => {})
                            : onUpdate && onUpdate(value);
                        setValue({ ...value, weight: [] });
                        onClose();
                    }}
                >
                    {t('Save')}
                </Button>
            </Modal>
        </>
    );
};

CreateWeightModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func,
    value: PropTypes.any,
    setValue: PropTypes.func,
    getHealthPerType: PropTypes.func,
    isHealthList: PropTypes.any,
};

export default CreateWeightModal;
