import { notification } from 'antd';
import React from 'react';
import moment from 'moment';

export const showSuccess = (title, message, duration = 3) => showNotification('success', title, message, duration);
export const showInfo = (title, message, duration = 3) => showNotification('info', title, message, duration);
export const showError = (title, message, duration = 10, style = {}, className = 'custom-notification') =>
    showNotificationNonIcon('error', title, message, duration, style, className);
export const showNormal = (
    title,
    message,
    duration = 10,
    style = { width: 'max-content' },
    className = 'custom-notification',
    key = moment.utc()
) => showNotificationNonIcon('', title, message, duration, style, className, key);

const showNotification = (type, title, msg, duration, className) => {
    notification[type]({
        message: title,
        description: processMessage(msg),
        className: className,
        duration: duration,
    });
};

const showNotificationNonIcon = (type, title, msg, duration, style, className, key) => {
    if (className === null || className === '') {
        className = 'custom-notification';
    }
    notification.open({
        message: title,
        description: processMessage(msg),
        className: className,
        duration: duration,
        style: style,
        key: key,
    });
    if (type === 'error') {
        //history.push('/');
    }
};

const showNotificationWithButton = (type, title, msg, duration, className) => {
    if (className === null || className === '') {
        className = 'custom-notification';
    }
    notification.open({
        message: title,
        description: processMessage(msg),
        className: className,
        duration: duration,
    });
};

const processMessage = msg => {
    if (typeof msg !== 'string') return msg;
    let newMsg = msg;
    if (newMsg.length > 100) {
        newMsg = newMsg.substr(0, 100) + '...';
    }
    return newMsg;
};
