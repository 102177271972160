import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { APP_URLS } from 'constants/url.constant';
import api from 'helpers/api.helper';
import history from 'helpers/history.helper';
import BackBtn from 'components/BackBtn';
import CompanyForm from 'pages/admin/company/CompanyForm';

const CompanyUpdate = () => {
    const { t } = useTranslation();

    const { id } = useParams();
    const [company, setCompany] = useState({});
    const [loading, setLoading] = useState(true);

    const updateCompany = async values => {
        const response = await api.patch(APP_URLS.ADMIN_COMPANIES_UPDATE.replace(':id', id), values);
        if (response) {
            history.goBack();
        }
    };

    const getCompany = async id => {
        const response = await api.get(APP_URLS.ADMIN_COMPANIES_DETAIL.replace(':id', id));
        if (response) {
            setCompany(response);
            setLoading(false);
        }
    };

    useEffect(() => {
        getCompany(id).then(() => {});
    }, [id]);

    if (loading) return <></>;

    return (
        <div className="self-wrapper px-48">
            <BackBtn label={t('Edit {{entity}}', { entity: t('entity:company') })} />
            <CompanyForm isEdit={true} initialValues={company} onFinish={updateCompany} />
        </div>
    );
};

export default CompanyUpdate;
