import React, { useEffect } from 'react';
import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import SidebarTopClient from 'layouts/SidebarTopClient';
import { CatIcon } from 'components/SvgIcon';
import { useDispatch } from 'react-redux';
import { setLoading } from 'core.slice';

const AccountDeleted = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch({ type: 'RESET' });
        dispatch(setLoading(false));
    }, []);

    return (
        <>
            <SidebarTopClient />
            <div className="p-16 account-deleted">
                <Row justify="center" align="middle">
                    <Col xs={24} sm={24} md={12}>
                        <div className="account-deleted-container text-align-center">
                            <h2 className="td-header mb-4">{t('The account has been deleted')}</h2>
                            <CatIcon size={148} />
                            <div className="account-deleted-text">{t('Thank you for using.')}</div>
                            <div className="account-deleted-text mb-24">
                                {t('We look forward to seeing you again.')}
                            </div>
                            <Button
                                type="primary"
                                className="fw-b pl-40 pr-40 mb-24"
                                href="https://teamdoctors.jp/"
                                target="_blank"
                            >
                                {'サービスサイトへ'}
                            </Button>
                            <p className="contact-info-label mb-0">{'お問い合わせ先'}</p>
                            <p className="contact-info-as-link">
                                <a href="mailto:cs@bynds.jp">{'cs@bynds.jp'}</a>
                            </p>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default AccountDeleted;
