import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import Routes from 'routes';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import UpdateEmailModal from 'pages/counselor/account/UpdateEmailModal';
import DataTable from 'components/DataTable';
import FieldInfo from 'components/FieldInfo';
import { useTranslation } from 'react-i18next';
import RoleEnum from 'enums/role.enum';
import { allowedAdminPage, getFirstRoleEnum } from 'helpers/role.helper';
import { showNormal } from 'helpers/notification.helper';
import { logout, setUser } from 'pages/auth/auth.slice';
import history from 'helpers/history.helper';
import { getCompanies, getPrograms } from 'pages/counselor/account/account.slice';
import LogOutModal from 'pages/client/account/LogOutModal';

const mapState = state => ({
    account: state.auth.user,
    companies: state.counselor.account.companies,
    loading: state.counselor.account.loading,
    registered: state.counselor.account.registered,
});

const mapDispatch = { logout, setUser, getCompanies, getPrograms };
const Account = ({ account, logout, setUser, getCompanies, getPrograms, companies, registered, loading }) => {
    const { t } = useTranslation();
    const isAdmin = allowedAdminPage(account);
    const roleUserEnum = getFirstRoleEnum(account.roles);

    const companyColumns = [
        {
            title: t('Company in charge'),
            dataIndex: 'name',
            width: 50,
            render: (_, r) => `${r.name}`,
        },
        {
            title: t('Program'),
            dataIndex: 'programs',
            width: 150,
            render: (_, r) => {
                const programs = r.programs || [];

                return (
                    <>
                        {programs.map((p, index) => {
                            const isLast = index === programs.length - 1;
                            return (
                                <>
                                    {p.name}
                                    {!isLast ? <>, </> : ''}
                                </>
                            );
                        })}
                    </>
                );
            },
        },
    ];

    const programColumns = [
        {
            title: t('Company name'),
            dataIndex: 'companyName',
            sorter: false,
        },
        {
            title: t('Program'),
            dataIndex: 'programName',
            sorter: false,
        },
        {
            title: t('Same-day reservation'),
            dataIndex: 'allowSameDayBooking',
            sorter: false,
            render: value => {
                return value ? t('Possible') : t('Impossible');
            },
        },
        {
            title: t('Reservation frame'),
            dataIndex: 'days',
            sorter: false,
            render: value => {
                return value.map((v, i) =>
                    i === value.length - 1 ? (
                        v
                    ) : (
                        <>
                            {v}
                            <br />
                        </>
                    )
                );
            },
        },
    ];

    const [modalVisible, setModalVisible] = useState({
        emailModal: false,
        logOut: false,
    });
    const [loadingButton, setLoadingButton] = useState(false);
    useEffect(() => {
        switch (roleUserEnum.value) {
            case RoleEnum.STAFF.value:
                getCompanies();
                break;
            case RoleEnum.DOCTOR.value:
                getPrograms(account.id);
                break;
        }
    }, []);

    const showEmailModal = () => {
        setLoadingButton(true);
        setModalVisible({ emailModal: true, logOut: false });
    };
    const showLogOutModal = () => {
        setModalVisible({ logOut: true, emailModal: false });
    };
    const closeModal = () => {
        setLoadingButton(false);
        setModalVisible({ emailModal: false, logOut: false });
    };

    const renderProgramsTable = () => (
        <DataTable
            className="mt-24 collapse-table"
            columns={programColumns}
            loading={loading}
            data={registered}
            noDataText={'データがありません'}
        />
    );

    const renderCompaniesTable = () => (
        <DataTable
            className="collapse-table mt-24"
            columns={companyColumns}
            loading={loading}
            data={companies}
            noDataText={'データがありません'}
        />
    );

    const adminProfile = () => {
        if (isAdmin) {
            history.push(Routes.private.ADMIN_PROFILE_UPDATE_EMAIL.path);
        } else {
            history.push(Routes.private.COUNSELOR_ACCOUNT_UPDATE_EMAIL.path);
        }
    };

    return (
        <div className="self-wrapper d-block">
            <div className="ml-24 mr-24 account-settings">
                <h1 className="page-title mb-32">{'アカウント'}</h1>
                <Row className="account" justify="space-between" align="middle">
                    <Col className=" mr-16" span={4}>
                        <p className="mb-8 main-content">{`${account.full_name}`}</p>
                        <p className="mb-0 sub-content text-gray-color">{`${account.first_name_kana || ''} ${
                            account.last_name_kana || ''
                        } `}</p>
                    </Col>

                    <Col className="health-data-col-flex-end" span={4}>
                        <Button
                            style={{ width: 120 }}
                            className="fw-b tdt-justify-center"
                            type="primary"
                            onClick={() => {
                                if (isAdmin) {
                                    history.push(Routes.private.ADMIN_PROFILE_UPDATE_NAME.path);
                                } else {
                                    history.push(Routes.private.COUNSELOR_ACCOUNT_UPDATE_NAME.path);
                                }
                            }}
                        >
                            {'編集'}
                        </Button>
                    </Col>
                </Row>

                <FieldInfo
                    label={t('Role')}
                    showEdit={false}
                    className={'mt-48'}
                    value={t(`enum:${roleUserEnum.text}`)}
                />

                {roleUserEnum.value === RoleEnum.DOCTOR.value && renderProgramsTable()}
                {roleUserEnum.value === RoleEnum.STAFF.value && renderCompaniesTable()}

                <Row className="account mt-24" justify="space-between" align="middle">
                    <Col className=" mr-16" span={4}>
                        <p className="mb-6 main-content fw-b fs-16">{'メールアドレス'}</p>
                        <p className="mb-0 main-content">{`${account.email}`}</p>
                    </Col>

                    <Col className="health-data-col-flex-end" span={4}>
                        <Button
                            style={{ width: 120 }}
                            className="fw-b tdt-justify-center"
                            type="primary"
                            loading={loadingButton}
                            onClick={() => showEmailModal()}
                        >
                            {'編集'}
                        </Button>
                    </Col>
                    <UpdateEmailModal
                        visible={modalVisible.emailModal}
                        onClose={() => closeModal()}
                        onUpdate={value => {
                            setModalVisible({ emailModal: false });
                            showNormal('', t('message:We sent an email enclosed authentication code'), 5);
                            setUser({ ...account, tmpEmail: value });
                            adminProfile();
                            setLoadingButton(false);
                            closeModal();
                        }}
                    />
                </Row>
                <Row className="account" justify="space-between" align="middle">
                    <Col className=" mr-16" span={4}>
                        <p className="mb-8 main-content fw-b fs-16">{'パスワード'}</p>
                        <p className="mb-0 sub-content text-gray-color">{'●●●●●●'}</p>
                    </Col>

                    <Col className="health-data-col-flex-end" span={4}>
                        <Button
                            style={{ width: 120 }}
                            className="fw-b tdt-justify-center"
                            type="primary"
                            onClick={() => {
                                if (isAdmin) {
                                    history.push(Routes.private.ADMIN_PROFILE_UPDATE_PASSWORD.path);
                                } else {
                                    history.push(Routes.private.COUNSELOR_ACCOUNT_UPDATE_PASSWORD.path);
                                }
                            }}
                        >
                            {'編集'}
                        </Button>
                    </Col>
                </Row>

                <Button
                    onClick={showLogOutModal}
                    style={{ width: 144 }}
                    className="mt-60 fw-b ext-align-right btn-account tdt-justify-center"
                >
                    {'ログアウト'}
                </Button>
                <LogOutModal onClose={closeModal} onUpdate={logout} visible={modalVisible.logOut} />
            </div>
        </div>
    );
};

export default connect(mapState, mapDispatch)(Account);

Account.propTypes = {
    account: PropTypes.any,
    logout: PropTypes.func,
    setUser: PropTypes.func,
    companies: PropTypes.object,
    registered: PropTypes.array,
    loading: PropTypes.bool,
    getPrograms: PropTypes.func,
    getCompanies: PropTypes.func,
};
