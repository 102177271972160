import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';

const CounselorCantDeleteModal = ({ visible, onClose, onUpdate }) => {
    const { t } = useTranslation();
    return (
        <Modal
            title={<p className="pink-color">この企業は削除できません</p>}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <span>
                {t('There is a consultation reservation that has not been implemented yet so it cannot be deleted')}
            </span>
            <div className="delete-action-panel">
                <Button
                    type=""
                    className="cancel-button fw-b"
                    onClick={() => {
                        onUpdate();
                    }}
                >
                    <span className={'title-button'}>{'OK'}</span>
                </Button>
            </div>
        </Modal>
    );
};

CounselorCantDeleteModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default CounselorCantDeleteModal;
