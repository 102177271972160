import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import {
    EditorBoldIcon,
    EditorItalicIcon,
    EditorLinkIcon,
    EditorListBulletedIcon,
    EditorListNumberedIcon,
    EditorUnderlinedIcon,
} from 'components/SvgIcon';
import { randomId } from 'helpers/common.helper';

const icons = ReactQuill.Quill.import('ui/icons');
icons['color'] = null;
icons['bold'] = null;
icons['underline'] = null;
icons['italic'] = null;
icons['link'] = null;
icons['bullet'] = null;
icons['list'] = null;

let Link = ReactQuill.Quill.import('formats/link');
class CustomLink extends Link {
    static create(value) {
        const node = super.create(value);
        //node.setAttribute('style', 'color: #E63E55;');
        node.style.color = '#E63E55';
        return node;
    }
}
ReactQuill.Quill.register(CustomLink, true);

const CustomToolbar = ({ editorId }) => (
    <div id={`custom-toolbar-${editorId}`}>
        <select className="ql-color" defaultValue="#000000">
            <option value="#EC4739" />
            <option value="#3A8025" />
            <option value="#0808F6" />
            <option value="#F4B454" />
            <option value="#E291E9" />
            <option value="#D5D6D7" />
            <option value="#000000" />
        </select>
        <button className="ql-bold">
            <EditorBoldIcon />
        </button>
        <button className="ql-underline">
            <EditorUnderlinedIcon />
        </button>
        <button className="ql-italic">
            <EditorItalicIcon />
        </button>
        <button className="ql-link">
            <EditorLinkIcon />
        </button>
        <button className="ql-list ml-20" value="bullet">
            <EditorListBulletedIcon />
        </button>
        <button className="ql-list" value="ordered">
            <EditorListNumberedIcon />
        </button>
    </div>
);

const TextEditor = React.memo(
    ({ className = '', value, setValue, id, setRefs }) => {
        const [editorId] = useState(randomId());

        const modules = {
            toolbar: {
                container: `#custom-toolbar-${editorId}`,
                handlers: {},
            },
            clipboard: {
                matchVisual: false,
            },
        };

        const formats = ['color', 'bold', 'underline', 'italic', 'link', 'bullet', 'list'];

        return (
            <div className={`custom-quill-editor ${className}`}>
                <CustomToolbar editorId={editorId} />
                <ReactQuill
                    theme="snow"
                    modules={modules}
                    formats={formats}
                    value={value}
                    onChange={setValue}
                    ref={el => {
                        if (el) {
                            setRefs && setRefs({ id, el });
                        }
                    }}
                />
            </div>
        );
    },
    (prevProps, nextProps) => {
        if (prevProps.value === nextProps.value) {
            return true;
        }
        return false;
    }
);

export default TextEditor;
