import React from 'react';
import logo from 'assets/images/html-mail/logo.png';
import PageElementTypeEnum from 'enums/page_element_type.enum';
import { APP_URLS } from 'constants/url.constant';
import { imageUrlAdapter } from 'helpers/image_url.helper';
import { HMFooter } from 'pages/admin/common/page-elements/html_mail/HMFooter';
import { HMPersonProfile } from 'pages/admin/common/page-elements/html_mail/HMPersonProfile';
import { HMImage } from 'pages/admin/common/page-elements/html_mail/HMImage';
import { HMNotice } from 'pages/admin/common/page-elements/html_mail/HMNotice';
import { HMCoverImage } from 'pages/admin/common/page-elements/html_mail/HMCoverImage';
import { HMHeading } from './html_mail/HMHeading';
import { HMText } from './html_mail/HMText';
import { HMButton } from 'pages/admin/common/page-elements/html_mail/HMButton';
import { HMDivider } from 'pages/admin/common/page-elements/html_mail/HMDivider';
import HMSessionInformation from 'pages/admin/common/page-elements/html_mail/HMSessionInformation';
import EmailType from 'enums/email_type.enum';

const RenderHTMLMail = ({ info, details = [], isProgram = false }) => {
    const renderDetail = detail => {
        if (!detail.is_displayed) {
            return <></>;
        }

        switch (detail.type) {
            case PageElementTypeEnum.HEADING.value:
                return <>{renderHeadline(detail)}</>;
            case PageElementTypeEnum.TEXT.value:
                return <>{renderText(detail)}</>;
            case PageElementTypeEnum.IMAGE.value:
                return <>{renderImage(detail)}</>;
            case PageElementTypeEnum.PERSON_PROFILE.value:
                return <>{renderPersonProfile(detail)}</>;
            case PageElementTypeEnum.HTML_CODE.value:
                return <>{renderHTMLCode(detail)}</>;
            case PageElementTypeEnum.BUTTON.value:
                return <>{renderButton(detail)}</>;
        }
    };

    const renderLogo = () => {
        return (
            <div className="hm_logo">
                <img src={logo} />
            </div>
        );
    };

    const renderNotice = () => {
        if (info && (info.manual || info.type === EmailType.HTML_EMAIL.value)) {
            return <></>;
        }

        return (
            <div className="hm_subtitle">{!isProgram ? 'セッション開催のお知らせ' : '新プログラム開始のお知らせ'}</div>
        );
    };

    const renderTitle = () => {
        return (
            <>
                <div className="hm_title">{`${info.name || ''}`}</div>
                <div className="hm_description">{`${info.description || ''}`}</div>
            </>
        );
    };

    const renderCoverImage = () => {
        if (!info.cover_img) {
            return <></>;
        }

        const mediaUrl = info.cover_img.includes('http')
            ? info.cover_img
            : `${APP_URLS.STATIC_BASE_URL}/${info.cover_img}`;

        return (
            <>
                <HMCoverImage cover_img={imageUrlAdapter(mediaUrl)} />
            </>
        );
    };

    const renderHeadline = detail => {
        return (
            <>
                <HMHeading detail={detail} />
            </>
        );
    };

    const renderText = detail => {
        return (
            <>
                <HMText detail={detail} />
            </>
        );
    };

    const renderPersonProfile = detail => {
        const mediaUrl = detail.media_url
            ? `${APP_URLS.STATIC_BASE_URL}/${detail.media_url}`
            : detail.image_media_url
            ? detail.image_media_url.url
            : null;

        return (
            <>
                <HMPersonProfile
                    detail={{ ...detail, media_url: imageUrlAdapter(mediaUrl, 480) }}
                    isProgram={isProgram}
                />
            </>
        );
    };

    const renderHTMLCode = detail => {
        return (
            <>
                <div className="mt-32 mb-24 ml-16 mr-16" dangerouslySetInnerHTML={{ __html: detail.content }} />
            </>
        );
    };

    const renderButton = detail => {
        return (
            <>
                <HMButton detail={detail} />
            </>
        );
    };

    const renderImage = detail => {
        const mediaUrl = detail.media_url
            ? `${APP_URLS.STATIC_BASE_URL}/${detail.media_url}`
            : detail.image_media_url
            ? detail.image_media_url.url
            : null;
        return (
            <>
                <HMImage detail={{ ...detail, media_url: imageUrlAdapter(mediaUrl) }} />
            </>
        );
    };

    // for session
    const renderSessionInformation = () => {
        if (isProgram) {
            return <></>;
        }

        return (
            <>
                <HMSessionInformation info={info} />
            </>
        );
    };

    const renderFooter = () => <HMFooter />;

    return (
        <div className="html-mail">
            <table
                role="presentation"
                cellSpacing="0"
                cellPadding="0"
                border="0"
                width="100%"
                style={{ margin: 'auto' }}
            >
                {renderLogo()}
                {renderNotice()}
                {renderTitle()}
                {renderCoverImage()}
                {details.map(detail => (
                    <React.Fragment key={detail.id}>{renderDetail(detail)}</React.Fragment>
                ))}

                {renderSessionInformation()}

                <HMDivider />
                <HMNotice />
                {renderFooter()}
            </table>
        </div>
    );
};

export default RenderHTMLMail;
