import { getEnumText } from 'helpers/enum.helper';
import ActivityLogEnum from 'enums/activity_log.enum';

export const getActionName = (action, value) => {
    const data = JSON.parse(value);
    if ('subject_name' in data) {
        const subject_name = data.subject_name;
        if (
            action === ActivityLogEnum.CREATE_INTERVIEW_SHEET.value ||
            action === ActivityLogEnum.CREATE_PROGRAM.value ||
            action === ActivityLogEnum.CREATE_STAFF.value
        ) {
            return ActivityLogEnum.CREATE.text + ' (' + subject_name + ')';
        } else if (
            action === ActivityLogEnum.UPDATE_INTERVIEW_SHEET.value ||
            action === ActivityLogEnum.UPDATE_PROGRAM.value ||
            action === ActivityLogEnum.UPDATE_COUNSELOR_PROGRAM_RESERVATION_FRAME.value
        ) {
            return ActivityLogEnum.UPDATE.text + '(' + subject_name + ')';
        } else if (
            action === ActivityLogEnum.DELETE_INTERVIEW_SHEET.value ||
            action === ActivityLogEnum.DELETE_PROGRAM.value ||
            action === ActivityLogEnum.DELETE_STAFF.value ||
            action === ActivityLogEnum.DELETE_COUNSELOR.value
        ) {
            return ActivityLogEnum.DELETE.text + '(' + subject_name + ')';
        } else if (action === ActivityLogEnum.ADD_COUNSELOR_PROGRAM.value) {
            return ActivityLogEnum.ADD_COUNSELOR_PROGRAM.text + '(' + subject_name + ')';
        } else if (action === ActivityLogEnum.SESSION_TIME_EDIT.value) {
            return ActivityLogEnum.SESSION_TIME_EDIT.text + '(' + subject_name + ')';
        } else {
            return getEnumText(ActivityLogEnum, action);
        }
    } else {
        return getEnumText(ActivityLogEnum, action);
    }
};

export const getCauserName = value => {
    const data = JSON.parse(value);
    if ('causer_name' in data) {
        return data.causer_name;
    } else {
        return '';
    }
};

export const getCauserEmail = value => {
    const data = JSON.parse(value);
    if ('causer_email' in data) {
        return data.causer_email;
    } else {
        return '';
    }
};

export const getCompanyName = value => {
    const data = JSON.parse(value);
    if ('company_name' in data) {
        return data.company_name;
    } else {
        return '';
    }
};
