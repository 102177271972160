import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Col, DatePicker, Row, Typography } from 'antd';
import { APP_URLS } from 'constants/url.constant';
import HealthTypeEnum from 'enums/health_type.enum';
import api from 'helpers/api.helper';
import { dateFormatForHealth } from 'helpers/date.helper';
import { showNormal } from 'helpers/notification.helper';
import FloatBar from 'components/FloatBar';
import { AddIcon, DeleteIcon, EditIcon } from 'components/SvgIcon';
import LineChart from 'components/chart/LineChart';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import CreateWeightModal from 'pages/client/health_data/CreateWeightModal';
import DeleteDataModal from 'pages/client/health_data/DeleteDataModal';
import DateChangeComponent from 'pages/client/health_data/tabs/date_time/DateChangeComponent';
import {
    chartDataSelector,
    getWeights,
    goNext,
    goPrev,
    goToday,
    setDisplayType,
    setMonthFilter,
    weightsFilterSelector,
} from 'pages/client/health_data/charts/weight.slice';
import NoData from 'components/NoData';
import ReservationStatus from 'enums/reservation_status.enum';

const mapState = state => ({
    startDate: state.client.healthDataWeight.startDate,
    endDate: state.client.healthDataWeight.endDate,
    displayType: state.client.healthDataWeight.displayType,
    monthFilter: state.client.healthDataWeight.monthFilter,
    weights: weightsFilterSelector(state.client.healthDataWeight),
    hasData: state.client.healthDataWeight.weights.length > 0,
    chartData: chartDataSelector(state.client.healthDataWeight),
    user: state.auth.user,
});

const mapDispatch = { goPrev, goNext, goToday, setDisplayType, setMonthFilter, getWeights };

const xLabelRenderer = date => moment(date).format('MM/DD');

const WeightChart = ({
    startDate,
    endDate,
    displayType,
    monthFilter,
    weights,
    hasData,
    chartData,
    goPrev,
    goNext,
    goToday,
    setDisplayType,
    setMonthFilter,
    getWeights,
    user,
}) => {
    const { t } = useTranslation();
    const weekFormat = 'YYYY年MM月';
    const isStateEnd = user.employee_company[0].state === ReservationStatus.END.value;
    const [value, setValue] = useState({});
    const [modalVisible, setModalVisible] = useState({
        weight: false,
        deleteWeight: false,
        id: null,
    });
    const [modalCreateVisible, setModalCreateVisible] = useState({
        createWeight: false,
    });

    const closeCreateModal = () => setModalCreateVisible({ createWeight: false });
    const showCreateModal = () => setModalCreateVisible({ createWeight: true });

    const showUpdateWeightModal = id => setModalVisible({ weight: true, deleteWeight: false, id });
    const showDeleteModal = id => setModalVisible({ deleteWeight: true, weight: false, id });
    const closeModal = () => setModalVisible({ weight: false, deleteWeight: false, id: null });

    const updateWeightData = async value => {
        const response = await api.put(APP_URLS.CLIENT_HEALTH_DATA_UPDATE.replace(':id', modalVisible.id), {
            created_at: value.created_at,
            value: value.weight,
        });
        if (response) {
            showNormal('', t('message:Data has been saved'), 3);
            getWeights();
        } else {
            showNormal('', t('message:Invalid data'), 3);
        }
    };
    const deleteWeightData = async () => {
        const response = await api.delete(APP_URLS.CLIENT_HEALTH_DATA_DELETE.replace(':id', modalVisible.id));
        if (response) {
            showNormal('', t('message:Data has been deleted'), 3);
            getWeights();
        } else {
            showNormal('', t('message:Cannot delete data'), 3);
        }
    };

    useEffect(() => {
        getWeights();
    }, [startDate, endDate]);

    const creatEntryData = async (values, type) => {
        const response = await api.post(APP_URLS.CLIENT_HEALTH_DATA_CREATE, {
            value: values.weight,
            created_at: values.created_at,
            health_images: [{ images: values.images, category: values.category }],
            // category: values.category,
            type,
        });
        if (response) {
            showNormal('', t('message:Data has been saved'), 3);
            getWeights();
        } else {
            showNormal('', t('message:Invalid data'), 3);
        }
    };

    const tooltipRenderer = useCallback(
        points => {
            return (
                <>
                    <rect
                        x={points[0].x - 50}
                        y={points[0].y + 8}
                        width={100}
                        height={52}
                        rx={4}
                        ry={4}
                        fill="#ffffff"
                        stroke="#D0D0D0"
                    />
                    <text
                        x={points[0].x - 42}
                        y={points[0].y + 32}
                        fill="#222126"
                        fontSize={12}
                        alignmentBaseline="top"
                    >
                        {chartData.datesHasAverage.includes(points[0].name) && <tspan>{t('Average')}</tspan>}
                        <tspan fontSize={16} fontWeight={500} dx={4}>
                            {points[0].value.toFixed(1)} kg
                        </tspan>
                    </text>
                    <text
                        x={points[0].x - 42}
                        y={points[0].y + 50}
                        fill="#222126"
                        fontSize={12}
                        alignmentBaseline="top"
                    >
                        {moment(points[0].name).format(displayType === 'year' ? 'M月' : 'M月D日(dd)')}
                    </text>
                </>
            );
        },
        [displayType, chartData.datesHasAverage]
    );
    return (
        <>
            <div className="mb-62 chart-detail-container">
                <BackBtnWithTitle title="体重" />
                <DateChangeComponent
                    startDate={startDate}
                    endDate={endDate}
                    displayType={displayType}
                    goPrev={goPrev}
                    goNext={goNext}
                    goToday={goToday}
                    setDisplayType={setDisplayType}
                />
                <div className="chart-wrapper">
                    <LineChart
                        className="health-data-chart"
                        data={chartData}
                        width={1000}
                        height={320}
                        yStepSize={10}
                        yMin={!hasData ? 30 : null}
                        yCount={!hasData ? 5 : null}
                        xLabelRender={xLabelRenderer}
                        circleOnHoverOnly={displayType !== 'week'}
                        tooltipRender={tooltipRenderer}
                        isDecimal={true}
                    />
                </div>

                <Row justify="space-between" align="bottom">
                    <Col className="mt-48">
                        <Typography className="fw-b data-title">{t('Data details')}</Typography>
                    </Col>
                    <Col>
                        {displayType === 'year' && (
                            <DatePicker
                                className="chart-month-picker"
                                value={monthFilter}
                                picker="month"
                                format={weekFormat}
                                allowClear={false}
                                onChange={value => {
                                    setMonthFilter(value);
                                }}
                                inputReadOnly={true}
                            />
                        )}
                    </Col>
                </Row>
                {weights.length === 0 ? (
                    <NoData />
                ) : (
                    weights.map(w => {
                        return (
                            <Row
                                key={w.id}
                                className="health-data-row data-details"
                                justify="space-between"
                                align="middle"
                            >
                                <Col className="health-data-col-center" xs={12} xl={4}>
                                    <Typography className="data-date-text">
                                        {dateFormatForHealth(w.created_at)}
                                    </Typography>
                                    <Typography className="health-data-value-none-pc">
                                        {w.value === '' ? '0' : w.value}kg
                                    </Typography>
                                </Col>
                                <Col xs={4} xl={4} className="flex">
                                    <Typography className="health-data-value-pc">
                                        {w.value === '' ? '0' : w.value}kg
                                    </Typography>
                                    {!isStateEnd && (
                                        <Button
                                            icon={<EditIcon />}
                                            type="link"
                                            className="edit-btn-pc pl-6"
                                            onClick={() => {
                                                setValue({ weight: w.value, created_at: moment.utc(w.created_at) });
                                                showUpdateWeightModal(w.id);
                                            }}
                                        />
                                    )}
                                </Col>

                                <Col className="health-data-col-flex-end" xs={4} xl={14}>
                                    {!isStateEnd && (
                                        <Button
                                            icon={<DeleteIcon size={30} />}
                                            type="link"
                                            className="btn-pink btn-delete-health-data"
                                            onClick={() => showDeleteModal(w.id)}
                                        />
                                    )}
                                    {!isStateEnd && (
                                        <Button
                                            icon={<EditIcon />}
                                            type="link"
                                            className="edit-btn-mb pr-12"
                                            onClick={() => {
                                                setValue({ weight: w.value, created_at: moment.utc(w.created_at) });
                                                showUpdateWeightModal(w.id);
                                            }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        );
                    })
                )}

                <DeleteDataModal
                    visible={modalVisible.deleteWeight}
                    onClose={() => closeModal()}
                    initialValue={{}}
                    onUpdate={async () => {
                        await deleteWeightData();
                        closeModal();
                    }}
                />

                <CreateWeightModal
                    visible={modalVisible.weight}
                    onClose={() => closeModal()}
                    // initialValue={w.value}
                    value={value}
                    setValue={setValue}
                    onUpdate={async value => {
                        await updateWeightData(value);
                    }}
                />
            </div>
            <FloatBar>
                {!isStateEnd && (
                    <Button
                        icon={<AddIcon />}
                        className="fw-b"
                        type="primary"
                        onClick={() => {
                            setValue({ created_at: moment.utc() });
                            showCreateModal();
                        }}
                    >
                        {t('Data entry')}
                    </Button>
                )}
                <CreateWeightModal
                    visible={modalCreateVisible.createWeight}
                    value={value}
                    setValue={setValue}
                    onClose={() => {
                        closeCreateModal();
                    }}
                    onUpdate={values => {
                        creatEntryData(values, HealthTypeEnum.WEIGHT.value).then(() => {});
                    }}
                />
            </FloatBar>
        </>
    );
};

export default connect(mapState, mapDispatch)(WeightChart);

WeightChart.propTypes = {
    tab: PropTypes.string,
    url: PropTypes.string,
};
