import React from 'react';

export const HMNotice = () => {
    const style_list_item =
        'font-family:Noto Sans JP;font-size:12px;font-weight:400;line-height:150%;text-align:left;color:#000000;';
    const style_link =
        'font-family:Noto Sans JP;font-size:12px;font-weight:400;line-height:150%;text-align:left;color:#E63E55;';

    const raw = `<tr>
    <td style="direction:ltr;font-size:0px;padding:0 0 32px;text-align:center;">
        <!--[if mso | IE]><table role="presentation" border="0" cellpadding="0" cellspacing="0"><tr><td class="" style="vertical-align:top;width:600px;" ><![endif]-->
        <div class="mj-column-per-100 mj-outlook-group-fix" style="font-size:0px;text-align:left;direction:ltr;display:inline-block;vertical-align:top;width:100%;">
            <table border="0" cellpadding="0" cellspacing="0" role="presentation" style="vertical-align:top;" width="100%">
                <tbody>
                <tr>
                    <td align="left" style="font-size:0px;padding:0px 16px 16px;word-break:break-word;">
                        <div style="${style_list_item}">・このお知らせはHTML形式・テキスト形式の両方を含むマルチパート形式で作成されています。お使いのお知らせソフトによってはHTML形式で内容が正しく表示されない可能性があります。その場合はお知らせソフトの設定を変更のうえ、テキスト形式でご覧ください。</div>
                    </td>
                </tr>
                <tr>
                    <td align="left" style="font-size:0px;padding:0px 16px 16px;word-break:break-word;">
                        <div style="${style_list_item}">・このお知らせはシステムからの自動通知です。本お知らせに直接送信ご返信いただいてもお問い合わせにはお答えできませんので、予めご了承ください。</div>
                    </td>
                </tr>
                <tr>
                    <td align="left" style="font-size:0px;padding:0px 16px;word-break:break-word;">
                        <div style="${style_list_item}">・ご不明な点などございましたら、こちらのお知らせアドレス宛にご連絡ください。</div>
                    </td>
                </tr>
                <tr>
                    <td align="left" style="font-size:0px;padding:0px 16px;word-break:break-word;">
                        <div style="${style_link}">
                            <a href="mailto:cs@bynds.jp" target="_blank" style="${style_link}; text-decoration: underline;"> cs@bynds.jp </a>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <!--[if mso | IE]></td></tr></table><![endif]-->
    </td>
</tr>
`;

    return <tr dangerouslySetInnerHTML={{ __html: raw }} />;
};
