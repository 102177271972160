import React from 'react';
import { useTranslation } from 'react-i18next';
import Routes from 'routes';
import { fullDateFormatExceptHour } from 'helpers/date.helper';
import history from 'helpers/history.helper';
import TdtButton from 'components/TdtButton';
import { setAfterDeleted } from 'pages/admin/calendar/calendar.slice';
import { connect } from 'react-redux';

const mapDispatch = {
    setAfterDeleted,
};

const TdtCalendarDefaultDetailModal = ({ event, setBackUrl, setAfterDeleted }) => {
    const { t } = useTranslation();
    return (
        <div>
            <div>
                {fullDateFormatExceptHour(event.date)} {event.start}〜{event.end}
            </div>
            <div className="mt-8">{event.program_name}</div>
            <TdtButton
                className={`mt-12 ${event.deleted_by_id ? 'btn-plain-disable' : ''}`}
                buttonStyle="plain"
                onClick={() => {
                    setBackUrl && setBackUrl();
                    setAfterDeleted(Routes.private.ADMIN_CALENDAR.path);
                    history.push(
                        Routes.private.COUNSELOR_DETAIL_CONSULTATION.path.replace(':id', event.user_id) + `/${event.id}`
                    );
                }}
                disabled={!!event.deleted_by_id}
            >
                {t('Detail')}
            </TdtButton>
        </div>
    );
};

export default connect(null, mapDispatch)(TdtCalendarDefaultDetailModal);
