import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackBtn from 'components/BackBtn';
import { getCommonSession } from './common_session_detail.slice';
import {
    dateTimeToMoment,
    dateToStr,
    fullDateFormatExceptHour,
    getDuration,
    momentToStr,
    strToMoment,
} from 'helpers/date.helper';
import Loader from 'components/Loader';
import { Image, Typography, Button, Form, Col, Row, Tooltip, Switch } from 'antd';
import { APP_URLS } from 'constants/url.constant';
import { useTranslation } from 'react-i18next';
import {
    AddIcon,
    DeleteIcon,
    DotsHorizontalRoundedIcon,
    DuplicateIcon,
    EditIcon,
    EyeInputIcon,
} from 'components/SvgIcon';
import api from 'helpers/api.helper';
import { showNormal } from 'helpers/notification.helper';
import CloneCommonSessionModal from 'pages/admin/feature/modal/CloneCommonSessionModal';
import CommonSessionDetailPreviewModal from './CommonSessionDetailPreviewModal';
import ChangeCommonSessionStateModal from 'pages/admin/feature/modal/ChangeCommonSessionStateModal';
import history from 'helpers/history.helper';
import SeminarStateEnum from 'enums/seminar_state.enum';
import { imageUrlAdapter } from 'helpers/image_url.helper';
import SettingTransmissionDateModal from 'pages/admin/feature/modal/SettingTransmissionDateModal';
import StopTransmissionModal from 'pages/admin/feature/modal/StopTransmissionModal';
import moment from 'moment';
import Routes from 'routes';
import CustomDropdown from 'components/CustomDropdown';
import DeleteCommonSessionModal from './modal/DeleteCommonSessionModal';
import CommonSessionChooseCompanyEnum from 'enums/common_session_choose_company.enum';
import { getEnumText } from 'helpers/enum.helper';
import { getMasterData } from 'pages/admin/feature/common_session.slice';
import SurveySettingModal from 'pages/admin/feature/modal/SurveySettingModal';

const mapState = state => ({
    commonSession: state.admin.commonSessionDetail.commonSession,
    loading: state.admin.commonSessionDetail.loading,
    master: state.admin.commonSession.master,
});
const mapDispatch = { getCommonSession, getMasterData };
const CommonSessionDetail = ({ commonSession, loading, getCommonSession, master, getMasterData }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const isVideoPublic = commonSession ? commonSession.is_video_public : false;
    const { id: commonSessionId } = useParams();
    const [modalVisible, setModalVisible] = useState({
        state: false,
        clone: false,
        delete: false,
        preview: false,
        transmissionDate: false,
        stopTransmission: false,
        record: null,
        survey: false,
    });

    const showChangeStateModal = () => setModalVisible({ ...modalVisible, state: true });
    const showDeleteModal = r => setModalVisible({ ...modalVisible, delete: true, record: r });
    const showCloneModal = r => setModalVisible({ ...modalVisible, clone: true, record: r });
    const showPreviewModal = () => setModalVisible({ ...modalVisible, preview: true });
    const showSettingTransmissionDateModal = () =>
        setModalVisible({ ...modalVisible, state: false, transmissionDate: true });
    const showStopTransmissionModal = () => setModalVisible({ ...modalVisible, stopTransmission: true });
    const showSurveySettingModal = () => setModalVisible({ ...modalVisible, survey: true });
    const closeModal = () =>
        setModalVisible({
            state: false,
            clone: false,
            preview: false,
            transmissionDate: false,
            stopTransmission: false,
            record: null,
            survey: false,
        });

    const deleteCommonSession = async commonSessionId => {
        const response = await api.delete(APP_URLS.ADMIN_COMPANIES_SEMINAR_DELETE.replace(':id', commonSessionId));
        if (response) {
            history.push(Routes.private.ADMIN_COMMON_SESSION.path);
            showNormal('', t('message:Deleted session'), 3);
        }
    };

    const changeSessionIsVideoPublic = async (commonSessionId, isVideoPublic) => {
        const response = await api.patch(APP_URLS.ADMIN_COMPANIES_SEMINAR_UPDATE_IS_VIDEO_PUBLIC.replace(':id', commonSessionId), {
            isVideoPublic
        });
        if (response) {
            getCommonSession(commonSessionId);
        }
    }

    const changeCommonSessionState = async (commonSessionId, transmissionDateTime = {}, closeFunc = null) => {
        closeFunc && closeFunc();
        const response = await api.patch(
            APP_URLS.ADMIN_COMMON_SESSIONS_COMMON_SESSION_UPDATE_STATUS.replace(':id', commonSessionId),
            {
                status: SeminarStateEnum.PUBLIC.value === commonSession.status ? 'private' : 'release',
                ...transmissionDateTime,
            },
        );
        if (response) {
            if (response === SeminarStateEnum.PUBLIC.value) {
                showNormal('', t('message:The session has been published'), 3);
                showNormal(
                    '',
                    t('message:Automatic notification transmission has been set'),
                    3,
                    { width: 'max-content' },
                    'custom-notification',
                    1,
                );
            } else {
                showNormal('', t('message:The session has been unpublished'), 3);
                showNormal(
                    '',
                    t('message:Automatic notification transmission has stopped'),
                    3,
                    { width: 'max-content' },
                    'custom-notification',
                    2,
                );
            }
            getCommonSession(commonSessionId);
        }
    };

    const updateSurveyUrl = async survey_url => {
        const res = await api.patch(APP_URLS.ADMIN_COMMON_SESSIONS_SURVEY.replace(':id', commonSessionId), {
            survey_url,
        });
        if (res) {
            getCommonSession(commonSessionId);
        }
    };

    useEffect(() => {
        commonSession = null;
        getCommonSession(commonSessionId);
        if (master.loading) {
            getMasterData();
        }
    }, [commonSessionId]);

    if (loading) {
        return <Loader className='loader-center' />;
    }

    if (!commonSession) {
        return <></>;
    }

    const organizerAccount = commonSession.seminar_organizer_account || {};

    // seminar.seminar_details.length === 0 ||
    const isNotComplete = !commonSession.date || !commonSession.start_time || !commonSession.end_time;
    const isRelease = commonSession && commonSession.status === SeminarStateEnum.PUBLIC.value;
    const hasTransmissionTime = commonSession && commonSession.transmission_date && commonSession.transmission_time;
    const typeIsAllCompany =
        commonSession.type === CommonSessionChooseCompanyEnum.ALL_COMPANY.value ||
        commonSession.companies.length === master.companies.length;
    const copyToClipboard = text => {
        navigator.clipboard.writeText(text);
        showNormal('', t('I copied the Zoom URL'), 3);
    };

    const hasSurveyUrl = !!commonSession.survey_url;

    const submitSettingTransmissionDate = async value => {
        const res = await api.patch(
            APP_URLS.ADMIN_COMMON_SESSIONS_COMMON_SESSION_SETTING_TRANSMISSION_DATE.replace(':id', commonSessionId),
            value,
        );
        if (res) {
            showNormal(
                '',
                hasTransmissionTime
                    ? t('message:Edited the automatic notification date and time')
                    : t('message:The date and time for automatic notification transmission has been set.'),
                3,
            );
            getCommonSession(commonSessionId);
        }
    };

    const submitStopTransmission = async () => {
        const res = await api.patch(
            APP_URLS.ADMIN_COMMON_SESSIONS_COMMON_SESSION_SETTING_TRANSMISSION_DATE.replace(':id', commonSessionId),
            { stop: true },
        );
        if (res) {
            showNormal('', t('message:Automatic notification transmission has stopped'), 3);
            getCommonSession(commonSessionId);
        }
    };

    return (
        <div className='self-wrapper px-0 pb-0'>
            <div className='px-48'>
                <Row>
                    <Col span={24}>
                        <div className='d-flex justify-content-space-between align-items-center'>
                            <BackBtn
                                label={commonSession.name ? commonSession.name : t('Not set')}
                                action={() => history.push(Routes.private.ADMIN_COMMON_SESSION.path)}
                            />
                            <div className='pt-48 change-state-container'>
                                <div className='mr-16 white-space-nowrap ml-12'>
                                    <span>{'ステータス：'}</span>
                                    <span className=' fw-700 fs-16'>
                                        {t(`enum:${getEnumText(SeminarStateEnum, commonSession.status)}`)}
                                    </span>
                                </div>

                                {commonSession && commonSession.status === 'private' ? (
                                    <Button
                                        className='fw-700 pt-12 pb-12 pl-32 pr-32 '
                                        onClick={() => {
                                            showChangeStateModal();
                                        }}
                                        disabled={isNotComplete}
                                    >
                                        {t('Common_session release')}
                                    </Button>
                                ) : (
                                    <Button
                                        className='fw-700 pink-color'
                                        onClick={() => {
                                            showChangeStateModal();
                                        }}
                                        disabled={commonSession.is_expired}
                                    >
                                        {t('Session suspension')}
                                    </Button>
                                )}

                                <CustomDropdown
                                    className=' ml-16 mt-10'
                                    onClick={e => {
                                        e.stopPropagation();
                                    }}
                                    trigger={['click']}
                                    options={[
                                        {
                                            key: 'duplicate',
                                            text: (
                                                <>
                                                    <DuplicateIcon /> <span>{'複製'}</span>
                                                </>
                                            ),
                                            className: 'primary-color fw-b',
                                        },
                                        {
                                            key: 'delete',
                                            text: (
                                                <>
                                                    <DeleteIcon /> <span>{'削除'}</span>
                                                </>
                                            ),
                                            className: 'pink-color fw-b',
                                        },
                                    ]}
                                    onSelect={opt => {
                                        opt.domEvent.stopPropagation();
                                        if (opt.key === 'delete') {
                                            showDeleteModal(commonSession);
                                        } else {
                                            showCloneModal(commonSession);
                                        }
                                    }}
                                    separator
                                >
                                    <DotsHorizontalRoundedIcon className=' primary-color c-pointer' />
                                </CustomDropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Form layout='vertical' form={form} className='mt-32 bg-gray-5 flex-1'>
                <div className='session-edit-wrapper session-detail-cards'>

                    <div className='session-detail-card'>
                        <div className='session-detail-card--header pt-6 pb-6'>
                            <div className=' d-flex justify-content-space-between align-items-center'>
                                <Typography className='fs-16 fw-700'>{t('基本情報')}</Typography>
                                <div className='fw-400 fs-16'>
                                    <Button
                                        icon={<EyeInputIcon />}
                                        type='text'
                                        className=' text-gray-color align-items-center '
                                        onClick={() => showPreviewModal()}
                                    >
                                        {t('Preview')}
                                    </Button>
                                    <Button
                                        icon={<EditIcon />}
                                        type='text'
                                        className='ml-8 text-gray-color align-items-center'
                                        onClick={() =>
                                            history.push(
                                                Routes.private.ADMIN_COMMON_SESSIONS_UPDATE.path.replace(
                                                    ':id',
                                                    commonSessionId,
                                                ),
                                            )
                                        }
                                    >
                                        {t('Edit')}
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className='session-detail-card--body'>
                            <div className='seminar-detail-content-control'>
                                <div>
                                    <Typography className='fs-14 fw-b color-blg-60'>
                                        {t('Overview explanation')}
                                    </Typography>
                                </div>
                            </div>
                            <div className='mt-8 cover-img-container admin-detail float-image-and-description'>
                                {commonSession.cover_img ? (
                                    <Image
                                        className='seminar-admin-detail-cover-image mr-24 mb-24'
                                        preview={false}
                                        src={
                                            commonSession.cover_img
                                                ? imageUrlAdapter(
                                                    `${APP_URLS.STATIC_BASE_URL}/${commonSession.cover_img}`,
                                                    480,
                                                )
                                                : 'error'
                                        }
                                    />
                                ) : (
                                    <div className='no-image-background no-cover mr-24 mb-24'>
                                        <span className='content'>{'COVER IMAGE'}</span>
                                    </div>
                                )}

                                <div className='pre-wrap description'>{commonSession.description}</div>
                            </div>
                            <div className='horizontal-line mt-24' />
                            <div className='d-flex justify-content-space-between'>
                                <div>
                                    <Typography className='fs-14 mt-24 fw-700 color-blg-60'>
                                        {t('主催者アカウント')}
                                    </Typography>
                                    <div className='mt-8 f20-30'>
                                        <span>{organizerAccount.full_name || ''}</span>
                                    </div>
                                </div>
                                <div>
                                    <Typography className='fs-14 fw-700 mt-24 color-blg-60' style={{ minWidth: 362 }}>
                                        {t('日時')}
                                    </Typography>
                                    <div className='mt-8 seminar-detail-date-time'>
                                        <span className='f20-30'>
                                            {fullDateFormatExceptHour(commonSession.date, '')}
                                        </span>
                                        <span className='f20-30'>{commonSession.start_time}</span>
                                        {commonSession.end_time && commonSession.start_time ? (
                                            <>
                                                {' '}
                                                <span>{'～'}</span>
                                                <span className='f20-30 mr-16'>
                                                    {commonSession.end_time}
                                                    {'('}
                                                    {getDuration(commonSession.end_time, commonSession.start_time)}
                                                    {'分)'}
                                                </span>
                                            </>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='mt-24'>
                                <div className='horizontal-line mt-16' />
                                <Typography className='fs-14 fw-700 mt-24 color-blg-60'>{t('提供先企業')}</Typography>
                                {!master.loading && (
                                    <div className='mt-8'>
                                        {typeIsAllCompany ? (
                                            <span className=' fs-20 body2 color-blg-100'>{t('All companies')}</span>
                                        ) : (
                                            <>
                                                {commonSession.companies.map(company => {
                                                    return (
                                                        <span key={company.id} className='hv-company-name'>
                                                            {company.name}
                                                        </span>
                                                    );
                                                })}
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {commonSession.archived_at ? (
                        <>
                            <div className='session-detail-card'>
                                <div className='session-detail-card--header pt-6 pb-6'>
                                    <div className='align-items-center pb-10 pt-10'>
                                        <Typography className='fw-b fs-16'>{'アーカイブ動画'}</Typography>
                                    </div>
                                </div>
                                <div className='session-detail-card--body'>
                                    <div className='d-flex align-items-center justify-content-space-between'>
                                        <div className='d-flex align-items-center justify-content-space-between'>
                                            <Typography className='ml-10 fw-b fs-16 mr-10'>{'非公開'}</Typography>
                                            <Switch
                                                checked={isVideoPublic}
                                                onClick={() => {
                                                    changeSessionIsVideoPublic(commonSessionId, !isVideoPublic).then(() => {});
                                                }}
                                            />
                                            <Typography className='fw-b fs-16 ml-10'>{'公開'}</Typography>
                                        </div>
                                        <Button
                                            className='fw-b pl-32 pr-32'
                                            //onClick={handleDownload}
                                            href={`${commonSession.video_src}/assets/${commonSession.video_id}/MP4/${commonSession.zoom_id}.mp4`}
                                            download={`${commonSession.zoom_id}.mp4`}
                                            target="_blank"
                                        >
                                            {'動画ダウンロード'}
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}

                    {commonSession.zoom_id ? (
                        <div className="session-detail-card">
                            <div className="session-detail-card--header pt-6 pb-6">
                                <div className=" d-flex justify-content-space-between align-items-center">
                                    <Typography className="fw-b fs-16">{t('Zoom URL')}</Typography>
                                    <Button
                                        type="text"
                                        onClick={() => copyToClipboard(commonSession.seminar_url)}
                                        className=" fw-400 text-gray-color "
                                        icon={<DuplicateIcon />}
                                    >
                                        {' '}
                                        {t('コピー')}
                                    </Button>
                                </div>
                            </div>
                            <div className="session-detail-card--body">
                                <div className="display-zoom-info">
                                    <Button
                                        type="link"
                                        href={commonSession.seminar_url}
                                        target="_blank"
                                        className="d-inline-block"
                                    >
                                        {commonSession.seminar_url}
                                    </Button>
                                    <Typography className="fs-16 mt-8">
                                        {'ウェビナーID'} <span className="ml-4 mr-8">{':'}</span>
                                        {commonSession.zoom_id}
                                    </Typography>
                                    {commonSession.password ? (
                                        <Typography className="fs-16 mt-4">
                                            {'パスコード'} <span className="ml-4 mr-8">{':'}</span>
                                            {commonSession.password}
                                        </Typography>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )}

                    <div className='session-detail-card'>
                        <div className='session-detail-card--header'>
                            <Typography className='fw-b fs-16'>{t('アンケート')}</Typography>
                        </div>
                        <div className='session-detail-card--body'>
                            <Typography className=' '>
                                {t(
                                    'セッション終了後や退出時、参加者のブラウザに表示するアンケートフォームを設定できます',
                                )}
                            </Typography>
                            {hasSurveyUrl && (
                                <Button type='link' href={commonSession.survey_url} target='_blank' className='mt-8'>
                                    {commonSession.survey_url}
                                </Button>
                            )}

                            <div>
                                <Button
                                    icon={hasSurveyUrl ? <EditIcon /> : <AddIcon />}
                                    className='fw-b mt-16'
                                    onClick={() => showSurveySettingModal()}
                                    disabled={!commonSession.zoom_id}
                                >
                                    {hasSurveyUrl ? 'アンケートを編集' : 'アンケートを追加'}
                                </Button>
                            </div>

                            <SurveySettingModal
                                onClose={() => closeModal()}
                                onUpdate={v => updateSurveyUrl(v)}
                                visible={modalVisible.survey}
                                initValue={commonSession.survey_url}
                            />
                        </div>
                    </div>

                    <div className='session-detail-card'>
                        <div className='session-detail-card--header'>
                            <Typography className='fw-b fs-16'>{t('お知らせ配信')}</Typography>
                        </div>
                        <div className='session-detail-card--body'>
                            <div className='d-flex justify-content-space-between align-items-center'>
                                <Typography>
                                    {/*<div className=" fs-14 text-gray-color">{t('セッション開催のお知らせ')}</div>*/}
                                    <div
                                        className='d-flex justify-content-space-between fs-20 seminar-detail-date-time'>
                                        {hasTransmissionTime ? (
                                            <>
                                                <div>{dateToStr(commonSession.transmission_date, 'YYYY年M月DD日')}</div>
                                                <div className='ml-16'>{commonSession.transmission_time}</div>
                                            </>
                                        ) : (
                                            <Typography className='fs-20 color-blg-30'>{'未設定'}</Typography>
                                        )}
                                    </div>
                                </Typography>
                                {hasTransmissionTime ? (
                                    <div>
                                        <Button
                                            className='fw-b pl-32 pr-32 align-items-center'
                                            disabled={!isRelease || commonSession.is_expired}
                                            onClick={() => {
                                                showSettingTransmissionDateModal();
                                            }}
                                        >
                                            {t('編集')}
                                        </Button>
                                        <Button
                                            className='fw-b pink-color ml-8 pl-32 pr-32 align-items-center'
                                            onClick={() => {
                                                showStopTransmissionModal();
                                            }}
                                        >
                                            {t('停止')}
                                        </Button>
                                    </div>
                                ) : (
                                    <Button
                                        className='fw-b seminar-detail-content-btn'
                                        disabled={!isRelease || commonSession.is_expired}
                                        onClick={() => showSettingTransmissionDateModal()}
                                    >
                                        {t('Send settings')}
                                    </Button>
                                )}
                            </div>
                            <SettingTransmissionDateModal
                                onClose={() => closeModal()}
                                onUpdate={(transmissionDateTime, withoutSetting) => {
                                    if (withoutSetting) {
                                        changeCommonSessionState(commonSessionId);
                                        return;
                                    }

                                    if (isRelease) {
                                        submitSettingTransmissionDate(transmissionDateTime);
                                    } else {
                                        changeCommonSessionState(commonSessionId, transmissionDateTime);
                                    }
                                }}
                                visible={modalVisible.transmissionDate}
                                isUpdate={hasTransmissionTime}
                                commonSession={commonSession}
                            />
                            <StopTransmissionModal
                                onClose={() => closeModal()}
                                onUpdate={submitStopTransmission}
                                visible={modalVisible.stopTransmission}
                            />
                            {/*<Typography className="fs-14 mt-32 text-gray-color">{t('アンケート')}</Typography>*/}
                            {/*<Typography className="fs-20 mt-4 color-blg-30">*/}
                            {/*    {hasSurveyUrl ? t('Possible') : t('未設定')}*/}
                            {/*</Typography>*/}
                        </div>
                    </div>
                </div>
                <ChangeCommonSessionStateModal
                    onClose={() => closeModal()}
                    onUpdate={() => {
                        if (!isRelease) {
                            if (
                                moment().isBefore(
                                    dateTimeToMoment(strToMoment(commonSession.date), '12:00').subtract(1, 'day'),
                                )
                            ) {
                                changeCommonSessionState(
                                    commonSessionId,
                                    {
                                        date: momentToStr(strToMoment(commonSession.date).subtract(1, 'day')),
                                        time: '12:00',
                                    },
                                    closeModal,
                                );
                            } else {
                                showSettingTransmissionDateModal();
                            }
                        } else {
                            changeCommonSessionState(commonSessionId);
                        }
                    }}
                    visible={modalVisible.state}
                    isPrivate={SeminarStateEnum.PRIVATE.value === commonSession.status}
                />
                <DeleteCommonSessionModal
                    onClose={() => closeModal()}
                    onUpdate={() => deleteCommonSession(modalVisible.record.id)}
                    visible={modalVisible.delete}
                />
                <CloneCommonSessionModal
                    onClose={() => {
                        closeModal();
                    }}
                    visible={modalVisible.clone}
                    commonSession={modalVisible.record}
                />
                <CommonSessionDetailPreviewModal
                    visible={modalVisible.preview}
                    onClose={() => closeModal()}
                    details={commonSession.seminar_details}
                    info={commonSession}
                />
            </Form>
        </div>
    );
};

export default connect(mapState, mapDispatch)(CommonSessionDetail);

CommonSessionDetail.propTypes = {
    commonSession: PropTypes.any,
    getCommonSession: PropTypes.func.isRequired,
};