import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import FloatBar from 'components/FloatBar';
import { DeleteIcon } from 'components/SvgIcon';
import { canPreview } from 'pages/admin/interview_sheet/interview_sheet_create.slice';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { useParams } from 'react-router-dom';
import InterviewSheetDeleteModal from 'pages/admin/interview_sheet/InterviewSheetDeleteModal';
import { showError, showNormal } from 'helpers/notification.helper';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import TabList from 'components/TabList';
import MarkContainer from './create_screen/tabs/mark/MarkContainer';
import QuestionnaireForm from './create_screen/tabs/questionnaire/QuestionnaireForm';
import InterviewSheetPreviewModal from 'pages/admin/interview_sheet/modal/InterviewSheetPreviewModal';
import ValueBeingEditedModal from 'pages/admin/interview_sheet/modal/ValueBeingEditedModal';
import RouterPrompt from 'components/RouterPrompt';

const mapState = state => ({
    canPreview: canPreview(state.admin.interviewSheetCreate),
    inUsed: state.admin.interviewSheetCreate.inUsed,
    answered: state.admin.interviewSheetCreate.answered,
    name: state.admin.interviewSheetCreate.name,
    inputCondition: state.admin.interviewSheetCreate.inputCondition,
    description: state.admin.interviewSheetCreate.description,
    byId: state.admin.interviewSheetCreate.questions.byId,
    order: state.admin.interviewSheetCreate.questions.order,
    options: state.admin.interviewSheetCreate.options.byId,
    questionOptions: state.admin.interviewSheetCreate.questionOptions,
    infoTitle: state.admin.interviewSheetCreate.infoTitle,
    infoDescription: state.admin.interviewSheetCreate.infoDescription,
    infoByScores: state.admin.interviewSheetCreate.infoByScores,
    tempProgram: state.admin.programTemp,
    isRadarType: state.admin.interviewSheetCreate.isRadarType,
    categories: state.admin.interviewSheetCreate.categories,
});

const InterviewSheetForm = ({
    isUpdate,
    canDelete,
    canPreview,
    //inUsed,
    answered,
    name,
    inputCondition,
    description,
    byId,
    order,
    options,
    questionOptions,
    infoTitle,
    infoDescription,
    infoByScores,
    tempProgram,
    // isRadarType
    categories,
}) => {
    const { t } = useTranslation();
    const { id: sheetId } = useParams();

    const routerPromptRef = useRef();
    const [isValueChange, setIsValueChange] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalVisible, setModalVisible] = useState({
        delete: false,
        preview: false,
        valueBeingEdited: false,
    });
    const showPreviewModal = () => setModalVisible({ ...modalVisible, preview: true });
    const showDeleteModal = () => setModalVisible({ ...modalVisible, delete: true });
    const showValueBeingEditedModal = () => setModalVisible({ ...modalVisible, valueBeingEdited: true });
    const closeModal = () => setModalVisible({ delete: false, preview: false, valueBeingEdited: false });

    const deleteInterviewSheet = async () => {
        const data = await api.delete(APP_URLS.ADMIN_INTERVIEW_SHEETS_DETAIL.replace(':id', sheetId));
        if (data) {
            showNormal('', t('message:Data has been deleted'));
            history.replace(Routes.private.ADMIN_INTERVIEW_SHEET.path);
        }
    };

    const submitForm = async () => {
        setIsValueChange(false);
        setLoading(true);
        let data = [];
        let questionOrder = 0;
        for (const id of order) {
            let question = { ...byId[id] };
            question.id = id;
            question.is_required = question.isRequired;
            question.order = questionOrder++;
            delete question.isRequired;
            if (questionOptions[id]) {
                question.options = [];
                let optionOrder = 0;
                for (const optId of questionOptions[id]) {
                    let option = { ...options[optId] };
                    option.id = optId;
                    option.is_range = option.isRange;
                    option.order = optionOrder++;
                    delete option.isRange;
                    question.options.push(option);
                }
            }
            data.push(question);
        }

        let infoData = [];
        let infoDisplayOrder = 0;
        const { byId: infoById, order: infoOrder } = infoByScores;
        for (const id of infoOrder) {
            if (infoById[id].rev) {
                const { score_from, score_to, title, description } = infoById[id].rev;
                infoData.push({ id, score_from, score_to, title, description, order: infoDisplayOrder++ });
            }
        }

        let isRadarQuestionnaire = 0;
        // radar chart
        const arrCategories = [];
        if (categories.order.length > 0) {
            if (categories.order.length > 6) {
                showError('', 'カテゴリの登録は6つまでにしてください。', 3);
                setLoading(false);
                return;
            }

            let start = 0,
                end = 0;
            let radarQuestionData = [];
            let questionOrder = 0;

            for (const k of categories.order) {
                const cate = categories.byId[k];
                if (cate) {
                    end += cate.children.length - 1;
                    arrCategories.push({ title: cate.title, size: cate.children.length, start, end });
                    start = end + 1;
                    end = start;

                    for (const id of cate.children) {
                        let question = { ...byId[id] };
                        question.id = id;
                        question.is_required = question.isRequired;
                        question.order = questionOrder++;
                        delete question.isRequired;
                        if (questionOptions[id]) {
                            question.options = [];
                            let optionOrder = 0;
                            for (const optId of questionOptions[id]) {
                                let option = { ...options[optId] };
                                option.id = optId;
                                option.is_range = option.isRange;
                                option.order = optionOrder++;
                                delete option.isRange;
                                question.options.push(option);
                            }
                        }
                        radarQuestionData.push(question);
                    }
                }
            }
            data = radarQuestionData;
            isRadarQuestionnaire = 1;
        }

        const requestData = {
            name,
            input_condition: inputCondition,
            description,
            questions: data,
            info_title: infoTitle,
            info_description: infoDescription,
            info_by_scores: infoData,
            json_categories: JSON.stringify(arrCategories),
            is_radar_questionnaire: isRadarQuestionnaire,
        };
        let updateResponse;
        let createResponse;
        if (sheetId) {
            updateResponse = await api.patch(
                APP_URLS.ADMIN_INTERVIEW_SHEETS_DETAIL.replace(':id', sheetId),
                requestData
            );
        } else {
            createResponse = await api.post(APP_URLS.ADMIN_INTERVIEW_SHEETS, requestData);
        }
        if (updateResponse || createResponse) {
            if (tempProgram && tempProgram.link) {
                return history.push(tempProgram.link);
            } else {
                if (updateResponse) {
                    //history.replace(Routes.private.ADMIN_INTERVIEW_SHEETS_DETAIL.path.replace(':id', sheetId));
                    history.goBack();
                } else {
                    history.replace(Routes.private.ADMIN_INTERVIEW_SHEET.path);
                }
                createResponse
                    ? showNormal('', t('message:I registered the interview sheet'), 3)
                    : showNormal('', t('message:Saved changes to the interview sheet'), 3);
            }
        }
    };

    const checkValueEditing = () => {
        const hasEditing = Object.values(infoByScores.byId).find(d => d.isEditing);
        if (hasEditing) {
            showValueBeingEditedModal();
        } else {
            submitForm().then(() => {});
        }
    };

    const tabListCreate = [
        {
            key: 'questionnaire',
            title: t('Interview sheet'),
            container: <QuestionnaireForm setIsValueChange={setIsValueChange} />,
        },
        {
            key: 'mark',
            title: '回答後画面',
            container: <MarkContainer setIsValueChange={setIsValueChange} />,
            forceRender: true,
        },
    ];

    const renderTabList = () => {
        return <TabList tabs={tabListCreate} animated={false} className="interview-sheet-tab-list bg-white" />;
    };

    return (
        <>
            <div className={`self-wrapper px-0 pb-80 bg-gray-5 ${answered ? 'itv-disable-by-readonly' : ''}`}>
                <div className="px-48 bg-white">
                    <BackBtnWithTitle
                        title={isUpdate ? t('Update a questionnaire') : t('Creating a questionnaire')}
                        onClick={() => history.goBack()}
                    />
                </div>
                {renderTabList()}
            </div>

            <RouterPrompt
                childRef={ref => (routerPromptRef.current = ref)}
                handleSave={() => {}}
                isValueChange={isValueChange}
                title={'確認'}
                body={'記入された内容は破棄されますがよろしいですか？'}
                leaveMode={true}
                discontinuationText="OK"
            />

            <InterviewSheetPreviewModal
                visible={modalVisible.preview}
                onClose={() => closeModal()}
                state={{
                    name,
                    inputCondition,
                    description,
                    questions: { order, byId },
                    options: { byId: options },
                    questionOptions,
                    infoByScores,
                    categories,
                }}
            />

            <ValueBeingEditedModal
                visible={modalVisible.valueBeingEdited}
                onClose={() => closeModal()}
                onUpdate={() => submitForm()}
            />

            <FloatBar className="ant-row-space-between">
                <div>
                    <Button className="ml-16 fw-b" disabled={!canPreview} onClick={() => showPreviewModal()}>
                        {t('Preview')}
                    </Button>

                    <Tooltip
                        placement="topRight"
                        title={
                            answered ? (
                                <span className="fs-14-21">
                                    {'回答済のデータがあるため'}
                                    <br />
                                    {'編集できません'}
                                </span>
                            ) : null
                        }
                        overlayInnerStyle={{ backgroundColor: '#37353D' }}
                        className="d-flex align-items-center"
                    >
                        <Button
                            className="ml-16 fw-b"
                            disabled={answered || !canPreview}
                            type="primary"
                            loading={loading}
                            onClick={() => checkValueEditing()}
                        >
                            {t('Save')}
                        </Button>
                    </Tooltip>

                </div>
                {canDelete ? (
                    <>
                        <Button
                            className="fw-b btn-delete"
                            icon={<DeleteIcon />}
                            onClick={() => showDeleteModal()}
                            //disabled={inUsed || answered}
                        >
                            {t('Delete the questionnaire')}
                        </Button>
                        <InterviewSheetDeleteModal
                            visible={modalVisible.delete}
                            onClose={() => closeModal()}
                            onUpdate={() => {
                                deleteInterviewSheet().then(() => {});
                            }}
                        />
                    </>
                ) : null}
            </FloatBar>
        </>
    );
};

export default connect(mapState, {})(InterviewSheetForm);

InterviewSheetForm.propTypes = {
    isUpdate: PropTypes.bool,
    canDelete: PropTypes.any,
    canPreview: PropTypes.any.isRequired,
    inUsed: PropTypes.bool,
};
