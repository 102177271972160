import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import InterviewSheetRow from 'pages/client/interview_sheet/InterviewSheetRow';
import { getReservationAnswers } from 'pages/client/interview_sheet/interview_sheet.slice';
import NoData from 'components/NoData';
import Loader from 'components/Loader';
import TdtPullable from 'components/TdtPullable';
import { filterInterviewSheets } from 'helpers/interview_sheet.helper';
import QuestionnaireRow from 'pages/client/interview_sheet/QuestionnaireRow';
import moment from 'moment';
import InterviewSheetHistory from 'pages/client/interview_sheet/InterviewSheetHistory';
import PeriodicDiagnosis from 'pages/client/interview_sheet/PeriodicDiagnosis';

const mapState = state => ({
    reservationAnswers: state.client.interviewSheet.reservationAnswers,
    questionnaires: state.client.interviewSheet.questionnaires,
    programsWithFirstInterview: state.client.interviewSheet.programsWithFirstInterview,
    noData: state.client.interviewSheet.noData,
});
const mapDispatch = { getReservationAnswers };

const InterviewSheet = ({
    reservationAnswers,
    questionnaires,
    programsWithFirstInterview,
    noData,
    getReservationAnswers,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    useEffect(async () => {
        setLoading(true);
        await getReservationAnswers();
        setLoading(false);
    }, []);

    const reservationAnswersFiltered = filterInterviewSheets(reservationAnswers);
    const questionnairesFiltered = filterInterviewSheets(questionnaires);
    const programsWithFirstInterviewFiltered = filterInterviewSheets(programsWithFirstInterview);

    const getDateByType = a => {
        if (a.reservation_id) {
            return moment(a.reservation.date);
        } else if (a.status) {
            return moment(a.created_at);
        }
    };

    const mixForSortInterviewSheet = (reservationAnswersFiltered = [], questionnairesFiltered = []) => {
        const mix = [...reservationAnswersFiltered, ...questionnairesFiltered];
        return mix.sort((a1, b1) => {
            const a = getDateByType(a1);
            const b = getDateByType(b1);
            return a.isAfter(b) ? -1 : a.isBefore ? 1 : 0;
        });
    };

    return (
        <div>
            <Row justify="space-between" align="middle">
                <Col>
                    <h1 className="page-title mb-16 mt-48">{t('Interview sheet')}</h1>
                </Col>
                {/*<Button*/}
                {/*    type="link"*/}
                {/*    className="fs-16 fw-b mt-48 mb-16 itv-history-btn"*/}
                {/*    onClick={() => {*/}
                {/*        history.push(Routes.private.CLIENT_INTERVIEW_SHEET_HISTORY.path);*/}
                {/*    }}*/}
                {/*    disabled={*/}
                {/*        reservationAnswersFiltered.length +*/}
                {/*            questionnairesFiltered.length +*/}
                {/*            programsWithFirstInterviewFiltered.length ===*/}
                {/*        reservationAnswers.length + questionnaires.length + programsWithFirstInterview.length*/}
                {/*    }*/}
                {/*>*/}
                {/*    <span className="text-decoration-none">{t('Answer history')}</span>*/}
                {/*    <ArrowRightIcon size={24} />*/}
                {/*</Button>*/}
            </Row>
            <TdtPullable
                onRefresh={() => {
                    getReservationAnswers();
                }}
            />
            {loading && (
                <Row justify="center" align="middle">
                    <Col>
                        <Loader />
                    </Col>
                </Row>
            )}
            <PeriodicDiagnosis
                reservationAnswers={reservationAnswers}
                questionnaires={questionnaires}
                programsWithFirstInterview={programsWithFirstInterview}
            />

            <div className="bg-gray-5">
                <Typography className="fs-18 fw-700" style={{ paddingLeft: 16, paddingTop: 8, paddingBottom: 8 }}>
                    {'カウンセリング用質問票'}
                </Typography>
            </div>
            <div className="mt-16 mb-32 interview-sheet-description fs-14">
                {'カウンセラーが相談時に使用します。相談前までに回答してください'}
            </div>
            <Typography className="fs-18 fw-700">{'未回答'}</Typography>

            {mixForSortInterviewSheet(reservationAnswersFiltered, questionnairesFiltered).map(itv => {
                if (itv.reservation_id) {
                    return <InterviewSheetRow key={itv.id} reservationAnswer={itv} />;
                } else if (itv.status) {
                    return <QuestionnaireRow key={itv.id} questionnaire={itv} />;
                } else {
                    return <></>;
                }
            })}

            {/*{reservationAnswersFiltered.length > 0 && (*/}
            {/*    <>*/}
            {/*        {reservationAnswersFiltered.map(reservationAnswer => (*/}
            {/*            <InterviewSheetRow key={reservationAnswer.id} reservationAnswer={reservationAnswer} />*/}
            {/*        ))}*/}
            {/*    </>*/}
            {/*)}*/}
            {/*{questionnairesFiltered.length > 0 && (*/}
            {/*    <>*/}
            {/*        {questionnairesFiltered.map(questionnaire => (*/}
            {/*            <QuestionnaireRow key={questionnaire.id} questionnaire={questionnaire} />*/}
            {/*        ))}*/}
            {/*    </>*/}
            {/*)}*/}

            {programsWithFirstInterviewFiltered.length > 0 && (
                <>
                    {programsWithFirstInterviewFiltered.map(programFirstInterview => {
                        if (!programFirstInterview.exist_reservation) {
                            return (
                                <InterviewSheetRow
                                    key={programFirstInterview.id}
                                    programId={programFirstInterview.id}
                                    reservationAnswer={programFirstInterview.first_interview_sheet}
                                    isFirstInterviewSheet={true}
                                />
                            );
                        }
                    })}
                </>
            )}
            {(noData ||
                reservationAnswersFiltered.length +
                    questionnairesFiltered.length +
                    programsWithFirstInterviewFiltered.filter(p => !p.exist_reservation).length <
                    1) && <NoData />}
            <div>
                <Typography className="mt-16 fs-18 fw-700">{'回答済み'}</Typography>
                <InterviewSheetHistory />
            </div>
        </div>
    );
};

export default connect(mapState, mapDispatch)(InterviewSheet);

InterviewSheet.propTypes = {
    reservationAnswers: PropTypes.array,
    programsWithFirstInterview: PropTypes.array,
    noData: PropTypes.bool,
    getReservationAnswers: PropTypes.func.isRequired,
};
