import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { isEmpty } from 'helpers/common.helper';

const UpdateEmailModal = ({ visible, onClose, initialValue, onUpdate }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(initialValue);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const validateMessages = {
        required: t('validation:Please enter {{field}}', { field: t('field:Email') }),
        types: {
            email: t('validation:Please check format of your ${label}'),
        },
    };

    const sendEmail = async newEmail =>
        await api.patch(APP_URLS.CLIENT_CHANGE_EMAIL_VERIFY_CODE.replace(':new_mail', newEmail), { email: newEmail });

    const checkUniqueEmail = async (rule, value) => {
        if (value) {
            try {
                const data = await api.post(APP_URLS.CHECK_UNIQUE_EMAIL, { email: value });
                if (data && data.is_existed) {
                    setLoading(false);
                    return Promise.reject(t('validation:Email has already been taken'));
                }
            } catch (e) {
                return Promise.resolve();
            }
        }
        return Promise.resolve();
    };

    const initialValues = Object.assign({
        email: '',
    });

    return (
        <Modal
            className="modal-hidden-label"
            title={'メールアドレスの編集'}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <Form
                layout="vertical"
                validateMessages={validateMessages}
                initialValues={initialValues}
                onFinish={() => {
                    sendEmail(value).then(() => {
                        onUpdate && onUpdate(value);
                    });
                }}
                requiredMark={false}
            >
                <p>
                    {
                        'ログインIDや連絡先として使用する新しいメールアドレスを入力してください。このアドレスに変更用の認証コードが送信されます。'
                    }
                </p>
                <Form.Item
                    name="email"
                    label={t('field:Email')}
                    validateTrigger={['onSubmit']}
                    rules={[
                        {
                            required: true,
                            type: 'email',
                        },
                        () => ({
                            validator(_, value) {
                                setLoading(true);
                                return checkUniqueEmail(_, value);
                            },
                        }),
                    ]}
                >
                    <Input
                        type="email"
                        value={value}
                        onChange={e => setValue(e.target.value)}
                        placeholder={'新しいメールアドレスを入力'}
                    />
                </Form.Item>

                <Row className="" justify="end">
                    <Col>
                        <Button
                            className="mr-32 btn-cancel fw-b send-button btn-bg-none"
                            disabled={loading}
                            onClick={() => {
                                onClose();
                            }}
                        >
                            {'キャンセル'}
                        </Button>
                        <Button htmlType="submit" loading={loading} className="mr-12 btn-send fw-b">
                            {'送信'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

UpdateEmailModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    onUpdate: PropTypes.func.isRequired,
};

export default UpdateEmailModal;
