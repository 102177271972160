import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import sidebar from 'layouts/sidebar';
import history from 'helpers/history.helper';
import { LogoIcon, UserAccountIcon } from 'components/SvgIcon';
import SidebarItem from 'layouts/SidebarItem';
import { setSidebarActive } from 'core.slice';
import { UserPropTypes } from 'constants/proptypes.constant';

const mapState = state => ({
    sidebarActive: state.core.sidebarActive,
    user: state.auth.user,
    count: state.auth.count,
});
const mapDispatch = { setSidebarActive };
const SidebarClient = ({ sidebarActive, user, count, setSidebarActive, isMenuResponsive }) => {
    const { t } = useTranslation();

    const badges = {
        INTERVIEW_SHEET: count.answer || 0,
        MESSAGE: count.message || 0,
    };

    const renderMailTo = () => {
        // cc, bcc, subject, body
        const email = 'cs@bynds.jp';
        const params = {
            subject: '【Team Doctors】お問い合わせ内容',
            body:
                `会社名：${user.employee_company[0]?.name || ''}\n` +
                `氏名：${user.full_name}\n` +
                '相談日時（任意）：\n' +
                'プログラム名（任意）：\n' +
                '相談内容：',
        };

        const baseUrl = 'mailto:' + email;
        // const url = new URL(baseUrl);
        // Object.keys(params).map(k => {
        //     url.searchParams.append(k, params[k]);
        // });

        //return url.href;
        return baseUrl + '?subject=' + encodeURIComponent(params.subject) + '&body=' + encodeURIComponent(params.body);
    };

    return (
        <>
            <div id={isMenuResponsive ? 'sidebar-responsive' : 'sidebar'} className="sidebar-light sidebar-user">
                {isMenuResponsive ? null : (
                    <div id="logo">
                        <LogoIcon size={32} />
                    </div>
                )}
                <div id="user-info" className="p-0">
                    <ul id="main-navigation" className="navigation">
                        <SidebarItem
                            isActive={sidebarActive === 'ACCOUNT'}
                            key={'account'}
                            icon={UserAccountIcon}
                            text={`${user.full_name}`}
                            isTranslate={false}
                            onClick={() => {
                                // if (sidebarActive === 'ACCOUNT') return;
                                setSidebarActive('ACCOUNT');
                                history.push('/client/account');
                            }}
                        />
                    </ul>
                </div>
                <ul id="main-navigation" className="navigation">
                    {sidebar.map(item => {
                        if (item.module !== 'client') return null;
                        let badge;
                        if (Object.prototype.hasOwnProperty.call(badges, item.key)) {
                            badge = badges[item.key];
                        }
                        const isActive = sidebarActive === item.key;

                        // if (item.key === 'SEMINAR' && !user.count_seminar > 0) {
                        //     return null;
                        // }

                        return (
                            <SidebarItem
                                isActive={isActive}
                                key={item.key}
                                icon={item.icon}
                                text={item.text}
                                badge={badge}
                                onClick={() => {
                                    // if (isActive) return;
                                    setSidebarActive(item.key);
                                    history.push(item.path);
                                }}
                                className="main-item"
                            />
                        );
                    })}
                </ul>
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(SidebarClient);

SidebarClient.propTypes = {
    sidebarActive: PropTypes.string,
    user: UserPropTypes,
    count: PropTypes.shape({
        answer: PropTypes.any,
        message: PropTypes.any,
    }),
    setSidebarActive: PropTypes.func.isRequired,
};
