import React, { useState } from 'react';
import { CloseIcon } from 'components/SvgIcon';
import { Modal } from 'antd';

const TdtCalendarModal = ({ title, body, visible, setVisible }) => {
    return (
        <Modal
            className="calendar-detail-modal"
            title={title}
            visible={visible}
            width={320}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => setVisible(false)}
        >
            {body}
        </Modal>
    );
};

export default TdtCalendarModal;
