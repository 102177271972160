import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, Input, Row, Select } from 'antd';
import QuestionCommonFields from 'pages/admin/interview_sheet/questions/QuestionCommonFields';
import { AddIcon } from 'components/SvgIcon';
import { randomId } from 'helpers/common.helper';
import DateTimeOptionTypeEnum from 'enums/date_time_option_type.enum';
import FloatBarQuestion from './FloatBarQuestion';
import HeaderQuestion from './HeaderQuestion';

const DateTimeQuestion = ({
    className,
    id,
    question,
    addition,
    options,
    optionsOrder,
    setQuestion,
    setAddition,
    addOption,
    updateOption,
    no,
    type,
    setType,
    isRequired,
    setIsRequired,
    setRefs,
}) => {
    const { t } = useTranslation();

    const [memoRefs, setMemoRefs] = useState({});
    const inputRef = useRef([]);

    return (
        <div className={`question-block ${className ? className : ''}`}>
            <HeaderQuestion no={no} />
            <QuestionCommonFields
                id={id}
                question={question}
                addition={addition}
                setAddition={setAddition}
                setQuestion={setQuestion}
                type={type}
                setType={setType}
            />
            {optionsOrder.length > 0 && (
                <div className="options">
                    {optionsOrder.map((optId, index) => {
                        return (
                            <div className="item" key={optId}>
                                <div className="label mb-8 fw-700 fs-14 text-gray-color">
                                    {t('field:Date/time item')}
                                </div>
                                <Row gutter={16} align="middle">
                                    <Col flex="1 0">
                                        <Input
                                            className="interview-sheet-input-create"
                                            value={options[optId].value}
                                            onChange={e => {
                                                updateOption({
                                                    optionId: optId,
                                                    data: {
                                                        ...options[optId],
                                                        value: e.target.value,
                                                    },
                                                });
                                            }}
                                            placeholder={t('Weekdays')}
                                            ref={el => {
                                                inputRef.current[id] = el;
                                                if (!memoRefs[optId]) {
                                                    setMemoRefs({
                                                        ...memoRefs,
                                                        [optId]: optId,
                                                    });
                                                    setRefs({ id: optId, el: el });
                                                }
                                            }}
                                            onPressEnter={() => {
                                                if (index === optionsOrder.length - 1) {
                                                    addOption({
                                                        questionId: id,
                                                        optionId: randomId(),
                                                        data: {
                                                            value: '',
                                                            type: DateTimeOptionTypeEnum.DATE.value,
                                                            isRange: false,
                                                        },
                                                    });
                                                }

                                                if (
                                                    index < optionsOrder.length - 1 &&
                                                    inputRef.current[optionsOrder[index + 1]]
                                                ) {
                                                    inputRef.current[optionsOrder[index + 1]].focus();
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col flex="1 0">
                                        <Select
                                            style={{ width: '100%' }}
                                            value={options[optId].type}
                                            onChange={type => {
                                                updateOption({
                                                    optionId: optId,
                                                    data: {
                                                        ...options[optId],
                                                        type: type,
                                                    },
                                                });
                                            }}
                                        >
                                            {Object.values(DateTimeOptionTypeEnum).map(item => {
                                                return (
                                                    <Select.Option key={item.value} value={item.value}>
                                                        {t(`enum:${item.text}`)}
                                                    </Select.Option>
                                                );
                                            })}
                                        </Select>
                                    </Col>
                                    <Col flex="80px">
                                        <Checkbox
                                            checked={options[optId].isRange}
                                            onChange={e => {
                                                updateOption({
                                                    optionId: optId,
                                                    data: {
                                                        ...options[optId],
                                                        isRange: e.target.checked,
                                                    },
                                                });
                                            }}
                                        >
                                            {t('Range')}
                                        </Checkbox>
                                    </Col>
                                </Row>
                            </div>
                        );
                    })}
                </div>
            )}
            <div className="item">
                <Button
                    className="fw-b"
                    icon={<AddIcon />}
                    onClick={() => {
                        addOption({
                            questionId: id,
                            optionId: randomId(),
                            data: {
                                value: '',
                                type: DateTimeOptionTypeEnum.DATE.value,
                                isRange: false,
                            },
                        });
                    }}
                >
                    {t('Item addition')}
                </Button>
            </div>
            <FloatBarQuestion id={id} isRequired={isRequired} setIsRequired={setIsRequired} />
        </div>
    );
};

export default DateTimeQuestion;

DateTimeQuestion.propTypes = {
    className: PropTypes.string,
    id: PropTypes.any.isRequired,
    question: PropTypes.string,
    addition: PropTypes.string,
    options: PropTypes.object,
    optionsOrder: PropTypes.array,
    setQuestion: PropTypes.func.isRequired,
    setAddition: PropTypes.func.isRequired,
    addOption: PropTypes.func.isRequired,
    updateOption: PropTypes.func.isRequired,
    no: PropTypes.number,
    type: PropTypes.any,
    setType: PropTypes.func,
};
