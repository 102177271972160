import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Checkbox, Input } from 'antd';
import PageElementTypeEnum from 'enums/page_element_type.enum';
import ElementActions from 'pages/admin/common/page-elements/types/ElementActions';
import AddImageButton from 'components/AddImageButton';
import TextEditor from 'components/TextEditor';

const PersonProfileElement = React.memo(({ id, pageElement, setPageElement, setRefs }) => {
    const { t } = useTranslation();
    const [fileList, setFileList] = useState(pageElement.image_media_url ? [pageElement.image_media_url] : []);

    const onFileListChange = fileList => {
        setPageElement({ id, changes: { image_media_url: fileList.length > 0 ? fileList[0] : null, media_url: null } });
    };

    return (
        <div className="page-element-block person-profile-element-thumb">
            <div className="header pb-24">
                <div className="element-common-heading">{t(`enum:${PageElementTypeEnum.PERSON_PROFILE.text}`)}</div>
                <ElementActions id={id} />
            </div>
            <div className="element-item">
                <AddImageButton
                    className="d-flex mb-24"
                    fileList={fileList}
                    setFileList={setFileList}
                    onChange={onFileListChange}
                />
                <div className="element-item-label">{'氏名、肩書き'}</div>
                <Input
                    value={pageElement.profile_name}
                    onChange={e => {
                        setPageElement({ id, changes: { profile_name: e.target.value } });
                    }}
                    //ref={el => setRefs({ id, el: el })}
                />
                <div className="element-item-label mt-24 mb-4">{'紹介文'}</div>
                <TextEditor
                    value={pageElement.content}
                    setValue={v => {
                        setPageElement({ id, changes: { content: v } });
                    }}
                    className="text-editor-for-profile"
                    id={id}
                    setRefs={setRefs}
                />
            </div>
            <div>
                <Checkbox
                    className="mt-24"
                    checked={pageElement.is_displayed}
                    onChange={e => {
                        setPageElement({ id, changes: { is_displayed: e.target.checked ? 1 : 0 }});
                    }}
                >
                    {t('Posted in the news')}
                </Checkbox>
            </div>
        </div>
    );
});

export default PersonProfileElement;

PersonProfileElement.propTypes = {
    id: PropTypes.any.isRequired,
    pageElement: PropTypes.object,
    setPageElement: PropTypes.func,
};
