import PropTypes from 'prop-types';

export const PaginationPropTypes = PropTypes.shape({
    current: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
});

export const FiltersPropTypes = PropTypes.object;

export const SorterPropTypes = PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
});

export const BaseStatePropShape = {
    loading: PropTypes.bool.isRequired,
    fetched: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    pagination: PaginationPropTypes,
    filters: FiltersPropTypes,
    sorter: SorterPropTypes,
    hideColumns: PropTypes.array.isRequired,
    selection: PropTypes.array.isRequired,
};

export const UserPropTypes = PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
});

export const CompanyPropTypes = PropTypes.shape({
    id: PropTypes.number,
    company_name: PropTypes.string,
});

export const CommonSessionPropTypes = PropTypes.shape({
    id: PropTypes.number,
});

export const ProgramPropTypes = PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
    description: PropTypes.string,
});

export const InterviewSheetPropTypes = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
});

export const DistributionListPropTypes = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
});
