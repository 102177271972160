import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper';
import { APP_URLS } from 'constants/url.constant';
import api from 'helpers/api.helper';
import moment from 'moment';
import ReservationStatus from 'enums/reservation_status.enum';
import {
    convertReservationToCalendarEvent,
    filteredBusySlots,
    filterRegistered,
    filterReservations,
} from 'helpers/calendar.helper';
import { showNormal } from 'helpers/notification.helper';

const name = 'calendar';

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.COUNSELOR_CALENDARS, ...props })
);

export const defaultFilters = {
    company_id: null,
    program_id: null,
    start_date: moment().startOf('week'),
    end_date: moment().endOf('week'),
};

const calendarSlice = createSlice({
    name,
    initialState: {
        ...baseInitialState,
        master: {
            company: {
                order: [],
                byId: {},
            },
            program: {
                order: [],
                byId: {},
            },
            companyProgram: {},
            registered: {},
        },
        filters: defaultFilters,
        sorter: {
            field: 'date',
            order: 'descend',
        },
        displayType: 'week',
        layoutType: 'calendar',
        reservations: {},
        blocked: {},
        reservedAndBlockedFrames: {},
        depends: {},
    },
    reducers: {
        ...baseReducers,
        setMasterCompany(state, action) {
            state.master.company = action.payload;
        },
        setMasterProgram(state, action) {
            state.master.program = action.payload;
        },
        setMasterCompanyProgram(state, action) {
            state.master.companyProgram = action.payload;
        },
        setMasterRegistered(state, action) {
            state.master.registered = action.payload;
        },
        setMasterReservedAndBlockedFrames(state, action) {
            state.master.reservedAndBlockedFrames = action.payload;
        },
        setMasterDepends(state, action) {
            state.master.depends = action.payload;
        },
        setStartDate(state, action) {
            state.filters.start_date = action.payload;
        },
        setEndDate(state, action) {
            state.filters.end_date = action.payload;
        },
        goPrev(state) {
            if (state.displayType === 'month') {
                state.filters.start_date = state.filters.start_date
                    .clone()
                    .add('-1', state.displayType)
                    .startOf(state.displayType);
                state.filters.end_date = state.filters.end_date
                    .clone()
                    .add('-1', state.displayType)
                    .endOf(state.displayType);
            } else {
                state.filters.start_date = state.filters.start_date.clone().add('-1', state.displayType);
                state.filters.end_date = state.filters.end_date.clone().add('-1', state.displayType);
            }
            state.pagination = { ...state.pagination, current: 1 };
        },
        goNext(state) {
            if (state.displayType === 'month') {
                state.filters.start_date = state.filters.start_date
                    .clone()
                    .add('1', state.displayType)
                    .startOf(state.displayType);
                state.filters.end_date = state.filters.end_date
                    .clone()
                    .add('1', state.displayType)
                    .endOf(state.displayType);
            } else {
                state.filters.start_date = state.filters.start_date.clone().add('1', state.displayType);
                state.filters.end_date = state.filters.end_date.clone().add('1', state.displayType);
            }
            state.pagination = { ...state.pagination, current: 1 };
        },
        goToday(state) {
            const now = moment();
            state.filters.start_date = now.clone().startOf(state.displayType);
            state.filters.end_date = now.clone().endOf(state.displayType);
            state.pagination = { ...state.pagination, current: 1 };
        },
        setDisplayType(state, action) {
            if (state.displayType !== action.payload) {
                state.displayType = action.payload;
                const now = moment();
                state.filters.start_date = now.clone().startOf(action.payload);
                state.filters.end_date = now.clone().endOf(action.payload);
            }
        },
        setLayoutType(state, action) {
            state.layoutType = action.payload;
        },
        setReservations(state, action) {
            state.reservations = action.payload;
        },
        setBlocked(state, action) {
            state.blocked = action.payload;
        },
    },
    extraReducers: baseExtraReducers(getData),
});

export const {
    setMasterCompany,
    setMasterProgram,
    setMasterCompanyProgram,
    setMasterRegistered,
    setMasterReservedAndBlockedFrames,
    setMasterDepends,
    setLoading,
    setData,
    setPagination,
    setFilters,
    setSorter,
    setHideColumns,
    setSelection,
    setStartDate,
    setEndDate,
    goPrev,
    goNext,
    goToday,
    setDisplayType,
    setLayoutType,
    setReservations,
    setBlocked,
} = calendarSlice.actions;

export default calendarSlice.reducer;

const masterSelector = state => state.master;
const registeredSelector = state => state.master.registered;
const filterSelector = state => state.filters;
const layoutTypeSelector = state => state.layoutType;
const reservationsSelector = state => state.reservations;
const reservedAndBlockedFramesSelector = state => state.master.reservedAndBlockedFrames;
const dependsSelector = state => state.master.depends;

export const programIdsSelector = createSelector(masterSelector, filterSelector, (master, filters) => {
    if (!filters.company_id) return master.program.order;
    return master.companyProgram[filters.company_id] || [];
});

export const getMasterData = () => async dispatch => {
    const data = await api.get(APP_URLS.COUNSELOR_CALENDAR_MASTER);
    if (data) {
        dispatch(setMasterCompany(data.company));
        dispatch(setMasterProgram(data.program));
        dispatch(setMasterCompanyProgram(data.companyProgram));
        dispatch(setMasterRegistered(data.registered));
        dispatch(setMasterReservedAndBlockedFrames(data.reservedAndBlockedFrames));
        dispatch(setMasterDepends(data.depends));
    }
};

export const getBlockedSlot = (startDate, endDate) => async dispatch => {
    const data = await api.get(APP_URLS.COUNSELOR_CALENDAR_RESERVATIONS_BLOCK, {
        start_date: startDate,
        end_date: endDate,
    });
    if (data) {
        dispatch(setBlocked(data));
    }
};

export const addBlockedSlot = (date, time) => async (dispatch, getState) => {
    const { start_date: startDate, end_date: endDate } = getState().counselor.calendar.filters;
    const data = await api.post(APP_URLS.COUNSELOR_CALENDAR_RESERVATIONS_BLOCK, { date, start_time: time });
    if (data) {
        dispatch(getBlockedSlot(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')));
        showNormal('', '予約枠をブロックしました', 3);
    }
};

export const removeBlockedSlot = (date, time) => async (dispatch, getState) => {
    const { start_date: startDate, end_date: endDate } = getState().counselor.calendar.filters;
    const data = await api.delete(APP_URLS.COUNSELOR_CALENDAR_RESERVATIONS_BLOCK, { date, start_time: time });
    if (data) {
        dispatch(getBlockedSlot(startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')));
    }
};

export const getReservations = (startDate, endDate) => async dispatch => {
    const data = await api.get(APP_URLS.COUNSELOR_CALENDAR_RESERVATIONS, { start_date: startDate, end_date: endDate });
    if (data) {
        let reservations = {};
        for (const date of Object.keys(data)) {
            reservations[date] = data[date].map(r => convertReservationToCalendarEvent(r));
        }
        dispatch(setReservations(reservations));
    }
};

export const filteredReservationsSelector = createSelector(
    filterSelector,
    layoutTypeSelector,
    reservationsSelector,
    filterReservations
);

export const filteredRegisteredSelector = createSelector(
    filterSelector,
    layoutTypeSelector,
    registeredSelector,
    filterRegistered
);

export const filteredBusySlotsSelector = createSelector(
    filterSelector,
    layoutTypeSelector,
    reservedAndBlockedFramesSelector,
    dependsSelector,
    filteredBusySlots
);
