import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row, Typography, Switch } from 'antd';
import {
    CloseIcon,
    HeartDisabledIcon,
    HeartIcon,
    ImageDisabledIcon,
    ImageIcon,
    PressureDisabledIcon,
    PressureIcon,
    WeightDisabledIcon,
    WeightIcon,
} from 'components/SvgIcon';

const UpdateListModal = ({ visible, onClose, initialValue, onUpdate }) => {
    const [value, setValue] = useState(initialValue);
    const [checkedValue, setCheckedValue] = useState([]);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const { t } = useTranslation();
    const switchChecked = (checked, name) => {
        if (checked) {
            setValue([...value, name]);
        } else {
            setValue(value.filter(v => v !== name));
        }
    };
    return (
        <Modal
            title={t('Data item settings')}
            visible={visible}
            width={320}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <Typography className="mb-16">入力するデータ項目を表示できます。</Typography>
            <Row className="health-data-row in-modal" justify="space-between" align="middle">
                <Col className="health-data-col-in-modal" span={8}>
                    {value.includes('weight') ? <WeightIcon size={40} /> : <WeightDisabledIcon size={40} />}
                    <Typography className="pl-8 icon-label">{t('Body weight')}</Typography>
                </Col>
                <Col className="health-data-col-switch-check" span={12}>
                    <Typography className="pr-18 icon-label">
                        {value.includes('weight') ? t('field:Display') : t('field:Hide')}
                    </Typography>
                    <Switch
                        checked={value.includes('weight')}
                        onChange={checked => {
                            switchChecked(checked, 'weight');
                            setCheckedValue(value);
                        }}
                    />
                </Col>
            </Row>
            <Row className="health-data-row in-modal" justify="space-between" align="middle">
                <Col className="health-data-col-in-modal" span={8}>
                    {value.includes('heart_beat') ? <HeartIcon size={40} /> : <HeartDisabledIcon size={40} />}
                    <Typography className="pl-8 icon-label">{t('Heart beat')}</Typography>
                </Col>
                <Col className="health-data-col-switch-check" span={12}>
                    <Typography className="pr-18 icon-label">
                        {value.includes('heart_beat') ? t('field:Display') : t('field:Hide')}
                    </Typography>
                    <Switch
                        checked={value.includes('heart_beat')}
                        onChange={checked => {
                            switchChecked(checked, 'heart_beat');
                            setCheckedValue(value);
                        }}
                    />
                </Col>
            </Row>
            <Row className="health-data-row in-modal" justify="space-between" align="middle">
                <Col className="health-data-col-in-modal" span={8}>
                    {value.includes('pressure') ? <PressureIcon size={40} /> : <PressureDisabledIcon size={40} />}
                    <Typography className="pl-8 icon-label">{t('Blood Pressure')}</Typography>
                </Col>
                <Col className="health-data-col-switch-check" span={12}>
                    <Typography className="pr-18 icon-label">
                        {value.includes('pressure') ? t('field:Display') : t('field:Hide')}
                    </Typography>
                    <Switch
                        checked={value.includes('pressure')}
                        onChange={checked => switchChecked(checked, 'pressure')}
                    />
                </Col>
            </Row>
            <Row className="health-data-row in-modal" justify="space-between" align="middle">
                <Col className="health-data-col-in-modal" span={8}>
                    {value.includes('images') ? <ImageIcon size={40} /> : <ImageDisabledIcon size={40} />}{' '}
                    <Typography className="pl-8 icon-label">{t('Photo')}</Typography>
                </Col>
                <Col className="health-data-col-switch-check" span={12}>
                    <Typography className="pr-18 icon-label">
                        {value.includes('images') ? t('field:Display') : t('field:Hide')}
                    </Typography>
                    <Switch checked={value.includes('images')} onChange={checked => switchChecked(checked, 'images')} />
                </Col>
            </Row>
            <Button
                type="primary"
                block
                className="mt-16 disable-gray-btn"
                onClick={() => {
                    onUpdate && onUpdate(value);
                    onClose();
                }}
            >
                {t('Save')}
            </Button>
        </Modal>
    );
};

UpdateListModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    initialValue: PropTypes.any,
    onUpdate: PropTypes.func.isRequired,
};

export default UpdateListModal;
