export const addToGoogleCalendar = reservation => {
    const text = reservation.program.name;
    const details = reservation.message || '';

    const date = reservation.date.replaceAll('-', '');
    const start = reservation.start_time.replace(':', '') + '00';
    const end = reservation.session_end_time.replace(':', '') + '00';
    const dates = `${date}T${start}/${date}T${end}`;

    // text, dates, ctz, details, location, add
    const baseUrl = 'https://calendar.google.com/calendar/r/eventedit';
    const params = {
        action: "TEMPLATE",
        text,
        dates,
        details,
        ctz: 'Asia/Tokyo',
    };

    const url = new URL(baseUrl);
    Object.keys(params).map(k => {
        url.searchParams.append(k, params[k]);
    });

    // window.open(url.href, '_blank');
    return url.href;
};

export const addSeminarToGoogleCalendar = seminar => {
    const text = seminar.name || '';
    const details = seminar.description || '';

    const date = seminar.date.replaceAll('-', '');
    const start = seminar.start_time.replace(':', '') + '00';
    const end = seminar.end_time.replace(':', '') + '00';
    const dates = `${date}T${start}/${date}T${end}`;

    const zoomInfo = `\n\nZoomURL：<a href="${seminar.seminar_url}">${seminar.seminar_url}</a>`;

    const baseUrl = 'https://calendar.google.com/calendar/r/eventedit';
    const params = {
        action: "TEMPLATE",
        text,
        dates,
        details: details + zoomInfo,
        ctz: 'Asia/Tokyo',
    };

    const url = new URL(baseUrl);
    Object.keys(params).map(k => {
        url.searchParams.append(k, params[k]);
    });

    return url.href;
};
