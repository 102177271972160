const QuestionTypeEnum = Object.freeze({
    FREE_INPUT: { value: 'free-input', text: 'Free input short' },
    FREE_INPUT_PARAGRAPH: { value: 'free-input-paragraph', text: 'Free input paragraph' },
    FREE_INPUT_NUMBER: { value: 'free-input-number', text: 'Number' },
    SINGLE_SELECTION: { value: 'single-selection', text: 'Single selection' },
    MULTIPLE_SELECTION: { value: 'multiple-selection', text: 'Multiple selection' },
    DATE_TIME: { value: 'date-time', text: 'Date/time' },
    DAILY_LIFE: { value: 'daily-life', text: 'Rhythm of daily life' },
    RADAR_CHART: { value: 'radar-chart', text: 'Radar chart' },
});

export default QuestionTypeEnum;
