import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Col, DatePicker, Row, Typography } from 'antd';
import { dateFormatForHealth } from 'helpers/date.helper';
import LineChart from 'components/chart/LineChart';
import DateChangeComponent from 'pages/client/health_data/tabs/date_time/DateChangeComponent';
import HealthDataSidebar from 'pages/counselor/calendar/health_data/HealthDataSidebar';
import {
    chartDataSelector,
    getWeights,
    goNext,
    goPrev,
    goToday,
    setDisplayType,
    setMonthFilter,
    weightsFilterSelector,
} from 'pages/counselor/calendar/health_data/weight.slice';
import NoData from 'components/NoData';
import TdtButtonRestrict from 'components/TdtButtonRestrict';
import { isAdminStaff } from 'helpers/role.helper';

const mapState = state => ({
    startDate: state.counselor.healthDataWeight.startDate,
    endDate: state.counselor.healthDataWeight.endDate,
    displayType: state.counselor.healthDataWeight.displayType,
    monthFilter: state.counselor.healthDataWeight.monthFilter,
    weights: weightsFilterSelector(state.counselor.healthDataWeight),
    hasData: state.counselor.healthDataWeight.weights.length > 0,
    chartData: chartDataSelector(state.counselor.healthDataWeight),
    user: state.auth.user,
});

const mapDispatch = { goPrev, goNext, goToday, setDisplayType, setMonthFilter, getWeights };

const xLabelRenderer = date => moment(date).format('MM/DD');

const WeightChart = ({
    startDate,
    endDate,
    displayType,
    monthFilter,
    weights,
    hasData,
    chartData,
    goPrev,
    goNext,
    goToday,
    setDisplayType,
    setMonthFilter,
    getWeights,
    user,
}) => {
    const { t } = useTranslation();
    const weekFormat = 'YYYY年MM月';

    const { id } = useParams();

    useEffect(() => {
        getWeights(id, startDate, endDate);
    }, [id, startDate, endDate]);

    const tooltipRenderer = useCallback(
        points => {
            return (
                <>
                    <rect
                        x={points[0].x - 50}
                        y={points[0].y + 8}
                        width={100}
                        height={52}
                        rx={4}
                        ry={4}
                        fill="#ffffff"
                        stroke="#D0D0D0"
                    />
                    <text
                        x={points[0].x - 42}
                        y={points[0].y + 32}
                        fill="#222126"
                        fontSize={12}
                        alignmentBaseline="top"
                    >
                        {chartData.datesHasAverage.includes(points[0].name) && <tspan>{t('Average')}</tspan>}
                        <tspan fontSize={16} fontWeight={500} dx={4}>
                            {points[0].value.toFixed(1)} kg
                        </tspan>
                    </text>
                    <text
                        x={points[0].x - 42}
                        y={points[0].y + 50}
                        fill="#222126"
                        fontSize={12}
                        alignmentBaseline="top"
                    >
                        {moment(points[0].name).format(displayType === 'year' ? 'M月' : 'M月D日(dd)')}
                    </text>
                </>
            );
        },
        [displayType, chartData.datesHasAverage]
    );
    return (
        <>
            <div className="counselor-aside">
                <div className="consultation-aside">
                    <div className="p-16 pb-0">
                        <div className="h7 mt-16 mb-24">{t('Health data')}</div>
                    </div>
                </div>
                <HealthDataSidebar activeKey="body_weight" />
            </div>
            <div className="counselor-main pl-24 pr-24 mt-40">
                <div className="f-24-36 fw-b">{t('Body weight')}</div>

                {!isAdminStaff(user) && (
                    <DateChangeComponent
                        startDate={startDate}
                        endDate={endDate}
                        displayType={displayType}
                        goPrev={goPrev}
                        goNext={goNext}
                        goToday={goToday}
                        setDisplayType={setDisplayType}
                    />
                )}

                {isAdminStaff(user) ? (
                    <TdtButtonRestrict withImage={true} />
                ) : (
                    <>
                        <LineChart
                            data={chartData}
                            width={1000}
                            height={320}
                            yStepSize={10}
                            yMin={!hasData ? 30 : null}
                            yCount={!hasData ? 5 : null}
                            xLabelRender={xLabelRenderer}
                            circleOnHoverOnly={displayType !== 'week'}
                            tooltipRender={tooltipRenderer}
                            isDecimal={true}
                        />

                        <Row justify="space-between" align="bottom">
                            <Col className="mt-48">
                                <Typography className="fw-b">{t('Data details')}</Typography>
                            </Col>
                            <Col>
                                {displayType === 'year' && (
                                    <DatePicker
                                        value={monthFilter}
                                        picker="month"
                                        format={weekFormat}
                                        allowClear={false}
                                        onChange={value => {
                                            setMonthFilter(value);
                                        }}
                                        inputReadOnly={true}
                                    />
                                )}
                            </Col>
                        </Row>
                        {weights.length === 0 ? (
                            <NoData />
                        ) : (
                            weights.map(w => {
                                return (
                                    <Row
                                        key={w.id}
                                        className="health-data-row data-details"
                                        justify="space-between"
                                        align="middle"
                                    >
                                        <Col className="health-data-col-center" span={4}>
                                            <Typography>{dateFormatForHealth(w.created_at)}</Typography>
                                        </Col>
                                        <Col span={4} className="flex">
                                            <Typography>{w.value === '' ? '0' : w.value}kg</Typography>
                                        </Col>

                                        <Col className="health-data-col-flex-end" span={14} />
                                    </Row>
                                );
                            })
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(WeightChart);

WeightChart.propTypes = {
    tab: PropTypes.string,
    url: PropTypes.string,
};
