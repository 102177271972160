export const defaultConfig = {
    xLabelHeight: 20,
    xPaddingRight: 40,
    xLabelMinWidth: 40,
    yLabelWidth: 40,
    yLabelMinHeight: 20,
    rectWidth: 16,
    textColor: '#222126',
    borderColor: '#D1D0D6',
};

export const getMinMax = values => {
    let min = null,
        max = null;
    for (const value of values) {
        if (value === null || typeof value === 'undefined') continue;
        for (const v of value) {
            if (v === null || typeof v === 'undefined') continue;
            if (min === null || v < min) min = v;
            if (max === null || v > max) max = v;
        }
    }
    return { min, max };
};

export const getYAxisInfo = (minValue, maxValue, yMin, yStepSize, yCount) => {
    let stepSize = yStepSize;
    if (!stepSize) {
        const step = (maxValue - minValue) / 8;
        const mag10 = Math.ceil(Math.log(step) / Math.log(10));
        stepSize = Math.pow(10, mag10);
    }
    let newMin = yMin,
        newMax;
    if (!newMin || (minValue !== null && newMin > minValue)) {
        newMin = Math.floor(minValue / stepSize) * stepSize - stepSize;
    }
    if (yCount) {
        newMax = newMin + yCount * stepSize;
    }
    if (!newMax || (maxValue !== null && newMax < maxValue)) {
        newMax = Math.ceil(maxValue / stepSize) * stepSize + stepSize;
    }
    const count = Math.round((newMax - newMin) / stepSize);
    return { count, size: stepSize, min: newMin, max: newMax };
};
