import React  from 'react';
import { Button, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { AddIcon } from 'components/SvgIcon';
import CategoryFloatBar from 'pages/admin/interview_sheet/category/CategoryFloatBar';
import RadarChartQuestion from 'pages/admin/interview_sheet/category/RadarChartQuestion';
import { randomId } from 'helpers/common.helper';
import QuestionTypeEnum from 'enums/question_type.enum';

const InterviewSheetCategory = ({
    id,
    category,
    addQuestion,
    addOption,
    updateOption,
    moveOption,
    removeOption,
    questions,
    options,
    setRefs,
    questionOptions,
    setCategoryTitle,
    setQuestion,
    setTouched,
    touched,
    categories,
}) => {
    const { t } = useTranslation();

    const hasCheckErr = () => {
        return touched.values.includes(id) && (touched.values.indexOf(id) < touched.values.length - 1);
    }
    const hasCategoryTitleError = hasCheckErr() && !category.title;
    const hasNoQuestionInCategory = hasCheckErr() && category.children.length < 1;
    const hasDuplicateCategoryTitle = hasCheckErr() && !!Object.keys(categories.byId).map(k => ({...categories.byId[k], id: k})).find(o => o.title === category.title && o.id !== id);

    return (
        <div className=" session-detail-cards " onClick={() => setTouched(id)}>
            <div className="session-detail-card border-none">
                <div className="session-detail-card-radar">
                    <div className="session-detail-card-radar--header-grey d-flex align-items-center justify-content-space-between">
                        <div className={`d-flex align-items-center ${(hasCategoryTitleError || hasDuplicateCategoryTitle) ? 'has-error-item' : ''}`}>
                            <Typography className="fw-500 fs-14 color-blg-100">{'カテゴリ名'}</Typography>
                            <Input
                                placeholder={'カテゴリ名を入力'}
                                className="ml-8 flex-1 bg-white"
                                style={{ minHeight: 41, maxWidth: 268 }}
                                value={category.title}
                                onChange={e => {
                                    setCategoryTitle({
                                        id,
                                        title: e.target.value,
                                    });
                                }}
                                maxLength={24}
                            />
                            {hasCategoryTitleError && (
                                <span className="fs-12-12 color-red-100 ml-8">{'カテゴリ名を入力してください'}</span>
                            )}
                            {!hasCategoryTitleError && hasDuplicateCategoryTitle && (
                                <span className="fs-12-12 color-red-100 ml-8">{'カテゴリ名が重複されました。もう一度入力してください。'}</span>
                            )}
                            {!hasCategoryTitleError && !hasDuplicateCategoryTitle && hasNoQuestionInCategory && (
                                <span className="fs-12-12 color-red-100 ml-8">{'こちらのカテゴリの質問を登録してください。'}</span>
                            )}
                        </div>
                        <CategoryFloatBar id={id} categoryId={id} />
                    </div>

                    {category.children.map((questionId, index) => (
                        <div
                            key={`cate_${questionId}`}
                            className={`session-detail-card-radar--body-grey ${
                                questionOptions[questionId] && questionOptions[questionId].length < 2
                                    ? 'outline-error'
                                    : ''
                            }`}
                        >
                            <RadarChartQuestion
                                id={questionId}
                                categoryId={id}
                                questionId={questionId}
                                question={questions[questionId].value}
                                addOption={addOption}
                                updateOption={updateOption}
                                moveOption={moveOption}
                                removeOption={removeOption}
                                options={options}
                                no={index + 1}
                                setRefs={setRefs}
                                optionsOrder={questionOptions[questionId] ? questionOptions[questionId] : []}
                                setQuestion={setQuestion}
                                hasCheckErr={hasCheckErr()}
                            />
                        </div>
                    ))}

                    <Button
                        className="mt-24 ml-24 mb-24 fw-b"
                        icon={<AddIcon />}
                        onClick={() => {
                            addQuestion({
                                categoryId: id,
                                id: randomId(),
                                question: {
                                    type: QuestionTypeEnum.RADAR_CHART.value,
                                },
                            });
                        }}
                    >
                        {t('Add question radar chart')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default InterviewSheetCategory;
