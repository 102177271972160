import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackBtn from 'components/BackBtn';
import { getSeminar } from 'pages/admin/company/tabs/seminar/seminar_detail.slice';
import { dateTimeToMoment, dateToStr, fullDateFormatExceptHour, momentToStr, strToMoment } from 'helpers/date.helper';
import Loader from 'components/Loader';
import { Image, Typography, Button } from 'antd';
import { APP_URLS } from 'constants/url.constant';
import { useTranslation } from 'react-i18next';
import { DeleteIcon } from 'components/SvgIcon';
import FloatBar from 'components/FloatBar';
import api from 'helpers/api.helper';
import { showNormal } from 'helpers/notification.helper';
import CompanyDetailRoutes from 'pages/admin/company/company_detail_routes';
import DeleteSeminarModal from 'pages/admin/company/tabs/seminar/modal/DeleteSeminarModal';
import history from 'helpers/history.helper';
import ChangeSeminarStateModal from 'pages/admin/company/tabs/seminar/modal/ChangeSeminarStateModal';
import { getEnumText } from 'helpers/enum.helper';
import SeminarStateEnum from 'enums/seminar_state.enum';
import CloneSeminarModal from 'pages/admin/company/tabs/seminar/modal/CloneSeminarModal';
import PreviewSeminarInDetailModal from 'pages/admin/company/tabs/seminar/modal/PreviewSeminarInDetailModal';
import { imageUrlAdapter } from 'helpers/image_url.helper';
import SettingTransmissionDateModal from 'pages/admin/company/tabs/seminar/modal/SettingTransmissionDateModal';
import StopTransmissionModal from 'pages/admin/company/tabs/seminar/modal/StopTransmissionModal';
import moment from 'moment';

const mapState = state => ({
    seminar: state.admin.companyDetailSeminarDetail.seminar,
    loading: state.admin.companyDetailSeminarDetail.loading,
    company: state.admin.companyDetail.company,
});
const mapDispatch = { getSeminar };
const SeminarDetail = ({ seminar, loading, getSeminar, baseUrl, company }) => {
    const { t } = useTranslation();
    const { id, seminarId } = useParams();
    const [modalVisible, setModalVisible] = useState({
        delete: false,
        state: false,
        clone: false,
        preview: false,
        transmissionDate: false,
        stopTransmission: false,
    });

    const [listCompanies, setListCompanies] = useState([]);

    const showDeleteModal = () => setModalVisible({ ...modalVisible, delete: true });
    const showChangeStateModal = () => setModalVisible({ ...modalVisible, state: true });
    const showCloneModal = () => setModalVisible({ ...modalVisible, clone: true });
    const showPreviewModal = () => setModalVisible({ ...modalVisible, preview: true });
    const showSettingTransmissionDateModal = () =>
        setModalVisible({ ...modalVisible, state: false, transmissionDate: true });
    const showStopTransmissionModal = () => setModalVisible({ ...modalVisible, stopTransmission: true });
    const closeModal = () =>
        setModalVisible({
            delete: false,
            state: false,
            clone: false,
            preview: false,
            transmissionDate: false,
            stopTransmission: false,
        });

    const deleteSeminar = async seminarId => {
        const response = await api.delete(APP_URLS.ADMIN_COMPANIES_SEMINAR_DELETE.replace(':id', seminarId));
        if (response) {
            history.push(`${baseUrl}${CompanyDetailRoutes.SEMINAR.path}`);
            showNormal('', t('message:Deleted session'), 3);
        }
    };

    const changeSeminarState = async (seminarId, transmissionDateTime = {}, closeFunc = null) => {
        closeFunc && closeFunc();
        const response = await api.patch(APP_URLS.ADMIN_COMPANIES_SEMINAR_UPDATE_STATUS.replace(':id', seminarId), {
            status: SeminarStateEnum.PUBLIC.value === seminar.status ? 'private' : 'release',
            ...transmissionDateTime,
        });
        if (response) {
            if (response === SeminarStateEnum.PUBLIC.value) {
                showNormal('', t('message:The session has been published'), 3);
                showNormal(
                    '',
                    t('message:Automatic notification transmission has been set'),
                    3,
                    { width: 'max-content' },
                    'custom-notification',
                    1
                );
            } else {
                showNormal('', t('message:The session has been unpublished'), 3);
                showNormal(
                    '',
                    t('message:Automatic notification transmission has stopped'),
                    3,
                    { width: 'max-content' },
                    'custom-notification',
                    2
                );
            }
            getSeminar(seminarId);
        }
    };

    const getListCompanies = async () => {
        const response = await api.get(APP_URLS.ADMIN_COMPANIES_SEMINAR_GET_LIST_COMPANIES);
        if (response) {
            setListCompanies(response);
        }
    };

    useEffect(() => {
        seminar = null;
        getSeminar(seminarId);
        getListCompanies();
    }, [id, seminarId]);

    if (loading) {
        return <Loader className="loader-center" />;
    }

    if (!seminar) {
        return <></>;
    }

    const organizerAccount = seminar.seminar_organizer_account || {};

    // seminar.seminar_details.length === 0 ||
    const isNotComplete = !seminar.date || !seminar.start_time || !seminar.end_time;
    const isRelease = seminar && seminar.status === SeminarStateEnum.PUBLIC.value;
    const hasTransmissionTime = seminar && seminar.transmission_date && seminar.transmission_time;

    const submitSettingTransmissionDate = async value => {
        const res = await api.patch(
            APP_URLS.ADMIN_COMPANIES_SEMINAR_SETTING_TRANSMISSION_DATE.replace(':id', seminarId),
            value
        );
        if (res) {
            showNormal(
                '',
                hasTransmissionTime
                    ? t('message:Edited the automatic notification date and time')
                    : t('message:The date and time for automatic notification transmission has been set.'),
                3
            );
            getSeminar(seminarId);
        }
    };

    const submitStopTransmission = async () => {
        const res = await api.patch(
            APP_URLS.ADMIN_COMPANIES_SEMINAR_SETTING_TRANSMISSION_DATE.replace(':id', seminarId),
            { stop: true }
        );
        if (res) {
            showNormal('', t('message:Automatic notification transmission has stopped'), 3);
            getSeminar(seminarId);
        }
    };

    return (
        <>
            <BackBtn
                label={seminar.name ? seminar.name : t('Not set')}
                action={() => history.push(`${baseUrl}${CompanyDetailRoutes.SEMINAR.path}`)}
            />
            {!isNotComplete && (
                <div className="pt-4 seminar-detail-date-time">
                    <span className="fs-20 mr-16">{fullDateFormatExceptHour(seminar.date)}</span>
                    <span className="fs-20">{seminar.start_time}</span>
                    <span>{'～'}</span>
                    <span className="fs-20 mr-16">{seminar.end_time}</span>
                    <span className="fs-20">{t('Organizer')}</span>
                    <span className="fs-20">{organizerAccount.full_name || ''}</span>
                </div>
            )}
            <div className="seminar-admin-detail-container">
                <div className="cover-img-container admin-detail">
                    {seminar.cover_img ? (
                        <Image
                            className="seminar-admin-detail-cover-image"
                            preview={false}
                            src={
                                seminar.cover_img
                                    ? imageUrlAdapter(`${APP_URLS.STATIC_BASE_URL}/${seminar.cover_img}`, 480)
                                    : 'error'
                            }
                        />
                    ) : (
                        <div className="no-image-background no-cover">
                            <span className="content">{'COVER IMAGE'}</span>
                        </div>
                    )}
                </div>
                <div>
                    <div className="seminar-detail-content-container">
                        <div className="seminar-detail-content-control">
                            <div>
                                <Typography className="fw-b fs-16">{t('Session content')}</Typography>
                            </div>
                            <div className="pt-12 d-flex align-items-center">
                                {isNotComplete ? (
                                    <Typography className="fs-20 color-red-100 flex-1">{t('field:Making')}</Typography>
                                ) : (
                                    <Typography className="fs-20 flex-1">{t('field:Pre-configured')}</Typography>
                                )}

                                <Button
                                    className={
                                        isNotComplete
                                            ? 'ml-52 mr-12 seminar-detail-content-btn'
                                            : 'ml-32 mr-12 seminar-detail-content-btn'
                                    }
                                    onClick={() =>
                                        history.push(
                                            `${baseUrl}${CompanyDetailRoutes.SEMINAR_UPDATE.path.replace(
                                                ':seminarId',
                                                seminarId
                                            )}`
                                        )
                                    }
                                >
                                    {t('Edit')}
                                </Button>
                                <Button className="seminar-detail-content-btn" onClick={() => showPreviewModal()}>
                                    {t('Preview')}
                                </Button>
                            </div>
                        </div>
                        <div className="seminar-detail-content-state mt-32">
                            <div>
                                <Typography className="fw-b fs-16">{t('State')}</Typography>
                            </div>
                            <div className="pt-12 change-state-container">
                                <Typography className="fs-20">
                                    {t(`enum:${getEnumText(SeminarStateEnum, seminar.status)}`)}
                                </Typography>
                                {seminar && seminar.status === 'private' ? (
                                    <Button
                                        className="fw-b seminar-detail-content-btn rls-btn"
                                        onClick={() => {
                                            showChangeStateModal();
                                        }}
                                        disabled={isNotComplete}
                                    >
                                        {t('Seminar release')}
                                    </Button>
                                ) : (
                                    <Button
                                        className="fw-b pink-color seminar-detail-content-btn un-rls-btn"
                                        onClick={() => {
                                            showChangeStateModal();
                                        }}
                                        disabled={seminar.is_expired}
                                    >
                                        {t('Seminar suspension')}
                                    </Button>
                                )}
                            </div>
                            <>
                                <div className="mt-32">
                                    <Typography className="fw-b fs-16">
                                        {t('Automatic notification transmission')}
                                    </Typography>
                                </div>
                                <div className="pt-12 d-flex align-items-center">
                                    <Typography className="fs-20 flex-1">
                                        {hasTransmissionTime ? (
                                            <>
                                                <div>{dateToStr(seminar.transmission_date, 'YYYY/MM/DD')}</div>
                                                <div>{seminar.transmission_time}</div>
                                            </>
                                        ) : (
                                            t('Off')
                                        )}
                                    </Typography>
                                    {hasTransmissionTime ? (
                                        <>
                                            <Button
                                                className="fw-b seminar-detail-content-btn mr-8 w-80"
                                                disabled={!isRelease || seminar.is_expired}
                                                onClick={() => showSettingTransmissionDateModal()}
                                            >
                                                {t('Edit')}
                                            </Button>
                                            <Button
                                                className="fw-b seminar-detail-content-btn w-80 pink-color"
                                                disabled={!isRelease || seminar.is_expired}
                                                onClick={() => showStopTransmissionModal()}
                                            >
                                                {t('Stop')}
                                            </Button>
                                        </>
                                    ) : (
                                        <Button
                                            className="fw-b seminar-detail-content-btn"
                                            disabled={!isRelease || seminar.is_expired}
                                            onClick={() => showSettingTransmissionDateModal()}
                                        >
                                            {t('Send settings')}
                                        </Button>
                                    )}
                                </div>
                                <SettingTransmissionDateModal
                                    onClose={() => closeModal()}
                                    onUpdate={(transmissionDateTime, withoutSetting) => {
                                        if (withoutSetting) {
                                            changeSeminarState(seminarId);
                                            return;
                                        }

                                        if (isRelease) {
                                            submitSettingTransmissionDate(transmissionDateTime);
                                        } else {
                                            changeSeminarState(seminarId, transmissionDateTime);
                                        }
                                    }}
                                    visible={modalVisible.transmissionDate}
                                    isUpdate={hasTransmissionTime}
                                    seminar={seminar}
                                />
                                <StopTransmissionModal
                                    onClose={() => closeModal()}
                                    onUpdate={submitStopTransmission}
                                    visible={modalVisible.stopTransmission}
                                />
                            </>
                            {seminar.zoom_id ? (
                                <>
                                    <div className="mt-32">
                                        <Typography className="fw-b fs-16">{t('Zoom URL')}</Typography>
                                    </div>
                                    <div className="display-zoom-info">
                                        <Button type="link" href={seminar.seminar_url} target="_blank">
                                            {seminar.seminar_url}
                                        </Button>
                                        <Typography className="fs-16 mt-4">
                                            {'ウェビナーID'} <span className="ml-4 mr-8">{':'}</span>
                                            {seminar.zoom_id}
                                        </Typography>
                                        {seminar.password ? (
                                            <Typography className="fs-16 mt-4">
                                                {'パスコード'} <span className="ml-4 mr-8">{':'}</span>
                                                {seminar.password}
                                            </Typography>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <DeleteSeminarModal
                onClose={() => closeModal()}
                onUpdate={() => deleteSeminar(seminarId)}
                visible={modalVisible.delete}
            />
            <ChangeSeminarStateModal
                onClose={() => closeModal()}
                onUpdate={() => {
                    if (!isRelease) {
                        if (
                            moment().isBefore(dateTimeToMoment(strToMoment(seminar.date), '12:00').subtract(1, 'day'))
                        ) {
                            changeSeminarState(
                                seminarId,
                                { date: momentToStr(strToMoment(seminar.date).subtract(1, 'day')), time: '12:00' },
                                closeModal
                            );
                        } else {
                            showSettingTransmissionDateModal();
                        }
                    } else {
                        changeSeminarState(seminarId);
                    }
                }}
                visible={modalVisible.state}
                isPrivate={SeminarStateEnum.PRIVATE.value === seminar.status}
            />
            <CloneSeminarModal
                onClose={() => {
                    closeModal();
                }}
                visible={modalVisible.clone}
                listCompanies={listCompanies}
                company={company}
                seminarId={seminarId}
            />
            <PreviewSeminarInDetailModal
                visible={modalVisible.preview}
                onClose={() => closeModal()}
                details={seminar.seminar_details}
                info={seminar}
            />
            <FloatBar className="d-flex flex-direction-unset justify-content-space-between">
                <div className="d-flex">
                    <Button
                        className="fw-b pink-color mr-16 "
                        icon={<DeleteIcon />}
                        onClick={() => {
                            showDeleteModal();
                        }}
                    >
                        {t('Delete seminar')}
                    </Button>
                    <Button
                        className="fw-b "
                        onClick={() => {
                            showCloneModal();
                        }}
                    >
                        {t('Reproduction of seminar')}
                    </Button>
                </div>
                <div>
                    {isRelease ? (
                        <Button
                            className="fw-b "
                            onClick={() => {
                                history.push(
                                    `${baseUrl}${CompanyDetailRoutes.MESSAGE_NEW_SESSION.path}?selected_id=${seminarId}`
                                );
                            }}
                            disabled={seminar.is_expired}
                        >
                            {t('Manually send notifications')}
                        </Button>
                    ) : (
                        <></>
                    )}
                </div>
            </FloatBar>
        </>
    );
};

export default connect(mapState, mapDispatch)(SeminarDetail);

SeminarDetail.propTypes = {
    seminar: PropTypes.any,
    getSeminar: PropTypes.func.isRequired,
};
