import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'antd';

const TdtTextAreaEditable = ({ value, setValue, onSave }) => {
    return (
        <div className="d-flex flex-end">
            <Input.TextArea
                className="flex-1"
                maxLength={400}
                value={value}
                onChange={v => setValue(v.target.value)}
                style={{ minHeight: 144, maxWidth: 480 }}
            />
            <Button className="fw-b ml-16 textarea-editable-send-btn" type="primary" onClick={() => onSave(value)}>
                {'保存'}
            </Button>
        </div>
    );
};

export default TdtTextAreaEditable;

TdtTextAreaEditable.propTypes = {
    value: PropTypes.any,
    setValue: PropTypes.func,
    onSave: PropTypes.func,
};
