import React from 'react';
import { Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import ActionBtnInModal from 'components/ActionBtnInModal';

const ValueBeingEditedModal = ({ visible, onClose, onUpdate }) => {
    return (
        <>
            <Modal
                title={'編集中の値'}
                visible={visible}
                width={320}
                height={197}
                footer={null}
                closeIcon={<CloseIcon />}
                onCancel={() => onClose()}
            >
                <p>{'編集中の値は保存されませんがよろしいですか？'}</p>
                <ActionBtnInModal onClose={onClose} onUpdate={onUpdate} />
            </Modal>
        </>
    );
};

export default ValueBeingEditedModal;
