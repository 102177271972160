import React, { useState } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import history from 'helpers/history.helper';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import FloatBar from 'components/FloatBar';
import { getEnum } from 'helpers/enum.helper';
import ErrorMessageEnum from 'enums/error_message.enum';
import { isEmpty } from 'helpers/common.helper';
import { showNormal } from 'helpers/notification.helper';
import TdtButtonSubmit from 'components/TdtButtonSubmit';
import { handleScrollOnFocus } from 'helpers/form_event.helper';

const UpdatePasswordAccount = () => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [errPasswordLength, setErrPasswordLength] = useState(false);

    const updatePassword = async values => {
        setLoading(false);
        try {
            const response = await api.patch(APP_URLS.CLIENT_UPDATE_PASSWORD, values, {}, {}, true);
            if (response) {
                setLoading(true);
                showNormal('', t('message:Saved password changes'), 5);
                history.goBack();
            }
        } catch (e) {
            setLoading(false);
            let data = e.response.data;
            let errorCode = data.error;
            if (!data.success && !isEmpty(errorCode)) {
                let errorEnum = getEnum(ErrorMessageEnum, errorCode);
                if (errorEnum === ErrorMessageEnum.THE_CURRENT_PASSWORD_IS_WRONG) {
                    form.setFields([
                        {
                            name: 'current_password',
                            errors: [t('validation:The current password is wrong')],
                        },
                    ]);
                }
            }
        }
    };

    const checkLengthPassword = (rule, value) => {
        setErrPasswordLength(false);
        if (value.length < 8) {
            setErrPasswordLength(true);
            return Promise.reject();
        }
        return Promise.resolve();
    };

    return (
        <>
            <BackBtnWithTitle title={t('Password editing')} onClick={() => history.goBack()} />
            <div className="">
                <Row justify="start" align="top">
                    <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                        <div id="form-wrapper">
                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={updatePassword}
                                requiredMark={false}
                                className="mb-64"
                                onFocus={handleScrollOnFocus}
                                onChange={handleScrollOnFocus}
                            >
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="current_password"
                                            label={t('field:Current password')}
                                            extra={t('Please enter the password you are currently setting')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('validation:Please enter {{field}}', {
                                                        field: t('field:New password'),
                                                    }),
                                                },
                                            ]}
                                        >
                                            <Row gutter={16}>
                                                <Col span={24}>
                                                    <Input.Password className="w-480" />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                        <Form.Item
                                            name="password"
                                            validateStatus={errPasswordLength && 'error'}
                                            extra={
                                                <div
                                                    className={`ant-form-item-explain ${
                                                        errPasswordLength ? 'ant-form-item-explain-error' : ''
                                                    }`}
                                                >
                                                    <div role="alert">{t('Alert_Input 8 characters')}</div>
                                                </div>
                                            }
                                            label={t('field:New password')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('validation:Please enter {{field}}', {
                                                        field: t('field:New password'),
                                                    }),
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        return checkLengthPassword(_, value);
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Row gutter={16}>
                                                <Col span={24}>
                                                    <Input.Password className="w-480" min={8} />
                                                </Col>
                                            </Row>
                                        </Form.Item>

                                        <Form.Item
                                            name="password_confirmation"
                                            label={t('field:New password (for confirmation)')}
                                            extra={t('field:Please re-enter your new password for confirmation')}
                                            dependencies={['password']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('validation:Please enter {{field}}', {
                                                        field: t('field:New password (for confirmation)'),
                                                    }),
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            new Error(
                                                                t(
                                                                    'validation:The two passwords that you entered do not match'
                                                                )
                                                            )
                                                        );
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password className="w-480" />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <FloatBar>
                                    <TdtButtonSubmit type="primary" htmlType="submit" className="fw-b">
                                        {t('Save')}
                                    </TdtButtonSubmit>
                                </FloatBar>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default UpdatePasswordAccount;
