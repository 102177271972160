import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import sidebar from 'layouts/sidebar';
import SidebarItem from 'layouts/SidebarItem';
import history from 'helpers/history.helper';
import { setSidebarActive, setCurrentConsultant } from 'core.slice';
import TdtButton from 'components/TdtButton';
import { CloseIcon } from 'components/SvgIcon';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import Routes from 'routes';
import Loader from 'components/Loader';
import { hasAnyRole, hasRole } from 'helpers/role.helper';
import RoleEnum from 'enums/role.enum';

const mapState = state => ({
    sidebarActive: state.core.sidebarActive,
    user: state.auth.user,
    backUrl: state.core.backUrl,
    currentConsultant: state.core.currentConsultant,
});
const mapDispatch = { setSidebarActive, setCurrentConsultant };
const SidebarCounselorCalendar = ({ sidebarActive, backUrl, user, currentConsultant, setCurrentConsultant }) => {
    const consultant = currentConsultant;
    const getConsultant = async id => {
        const response = await api.get(APP_URLS.COUNSELOR_CONSULTANT_INFO.replace(':id', id));
        if (response) {
            setCurrentConsultant(response);
        }
    };
    const defaultBackUrl = hasAnyRole(user, [RoleEnum.ADMIN.value, RoleEnum.ADMIN_STAFF.value])
        ? Routes.private.ADMIN_CALENDAR.path
        : Routes.private.COUNSELOR_CALENDAR.path;

    const { id } = useParams();
    useEffect(() => {
        getConsultant(id).then(() => {
        });
        return () => {
            setCurrentConsultant(null);
        };
    }, [id]);

    const badges = {
        COUNSELOR_DETAIL_MESSAGE: consultant ? consultant.unread_message : 0, // message unread count
    };

    const [activeNested, setActiveNested] = useState(sidebarActive);
    const pathname = window.location.pathname;
    useEffect(() => {
        if (pathname.indexOf('/consultation') > -1) {
            setActiveNested('COUNSELOR_DETAIL_CONSULTATION');
        } else if (pathname.indexOf('/health-data') > -1) {
            setActiveNested('COUNSELOR_DETAIL_HEALTH_DATA');
        } else if (pathname.indexOf('/messages') > -1) {
            setActiveNested('COUNSELOR_DETAIL_MESSAGE');
        } else if (pathname.indexOf('/questionnaire') > -1) {
            setActiveNested('COUNSELOR_DETAIL_QUESTIONNAIRE');
        } else if (pathname.indexOf('/user-information') > -1) {
            setActiveNested('COUNSELOR_DETAIL_USER_INFORMATION');
        } else {
            setActiveNested('');
        }
    }, [pathname]);

    return consultant ? (
        <div id='sidebar-calendar'>
            <div className='mt-16 ml-16'>
                <TdtButton
                    className=''
                    buttonStyle='plain'
                    icon={<CloseIcon />}
                    onClick={() => {
                        history.push(backUrl == null ? defaultBackUrl : backUrl);
                    }}
                />
            </div>
            <div className='counselor-sidebar mt-24 mb-16'>
                <div className='consultant-name-kana'>
                    {consultant ? (
                        `${consultant.first_name_kana || ''} ${consultant.last_name_kana || ''}`
                    ) : (
                        <>&nbsp;</>
                    )}
                </div>
                <div className='consultant-name mb-8'>{consultant ? `${consultant.full_name}` : <>&nbsp;</>}</div>
                <div className='consultant-corp-name'>
                    {consultant ? consultant.employee_company[0].name : <>&nbsp;</>}
                </div>
            </div>
            <ul id='main-navigation' className='navigation'>
                {sidebar.map(item => {
                    if (item.module !== 'counselor') return null;
                    if (!item.key.startsWith('COUNSELOR_DETAIL')) return null;
                    let badge;
                    if (Object.prototype.hasOwnProperty.call(badges, item.key)) {
                        badge = badges[item.key];
                    }
                    const isActive = item.key === activeNested;
                    return (
                        <SidebarItem
                            isActive={isActive}
                            key={item.key}
                            icon={item.icon}
                            text={item.text}
                            badge={badge}
                            onClick={() => {
                                // if (isActive) return;
                                setSidebarActive(item.key);
                                setActiveNested(item.key);
                                history.push(item.path.replace(':id', consultant.id));
                            }}
                        />
                    );
                })}
            </ul>
        </div>
    ) : (
        <Loader className={'mt-16 ml-16'} />
    );
};

export default connect(mapState, mapDispatch)(SidebarCounselorCalendar);

SidebarCounselorCalendar.propTypes = {
    sidebarActive: PropTypes.string,
    setSidebarActive: PropTypes.func.isRequired,
    backUrl: PropTypes.string,
    user: PropTypes.any,
};
