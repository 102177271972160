import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import history from 'helpers/history.helper';
import { dateToStr, getGraphPeriods } from 'helpers/date.helper';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import { isNullOrUndefined } from 'helpers/common.helper';
import AnswerListWithScore from 'pages/admin/interview_sheet/detail_screen/common/AnswerListWithScore';
import {
    answersToRadarChartData, getLastAnswer,
    getNextAnswer,
    getPrevAnswer,
    parseCategoriesWithQuestions,
    parseQuestionnaire,
} from 'helpers/questionnaire.helper';
import Routes from 'routes';
import { Button, Select, Typography } from 'antd';
import RadarChart, { TransitionGraphChart } from 'components/chart/radar/RadarChart';
import { ArrowLeftIcon, ArrowRightIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import TdtSelect from 'components/TdtSelect';

const mapState = state => ({ reservationAnswers: state.client.interviewSheet.reservationAnswers });

const InterviewSheetDisplay = ({ reservationAnswers }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const isFirstInterviewSheet = new URLSearchParams(location.search).get('first');
    const [reservationAnswer, setReservationAnswer] = useState(
        !reservationAnswers && reservationAnswers.find(r => r.id === Number(id))
    );

    const [displayType, setDisplayType] = useState(9999);

    // if not in redux state => get from api
    const getReservationAnswer = async id => {
        const response = await api.get(
            APP_URLS.CLIENT_INTERVIEW_SHEETS_DETAIL.replace(':id', id) +
                (isNullOrUndefined(isFirstInterviewSheet) ? '?with_others=true' : '?first=true&with_others=true')
        );
        if (response) {
            setReservationAnswer(response);
        }
    };

    useEffect(() => {
        // if (!reservationAnswer) {
        getReservationAnswer(id).then(() => {});
        // }
    }, [id]);

    if (!reservationAnswer) return <></>;

    const result = parseQuestionnaire({
        questions: reservationAnswer.interview_sheet.questions,
        listAnswers: JSON.parse(reservationAnswer.json_answers),
    });

    const matchInfoByScore = reservationAnswer.interview_sheet.info_by_scores.find(
        o => o.score_from <= result.totalScore && o.score_to >= result.totalScore
    );

    const categories = parseCategoriesWithQuestions(reservationAnswer, result.questions);

    const otherAnswerInterviewSheet = !reservationAnswer.other_answers ? [] : reservationAnswer.other_answers
        .filter(o => o.interview_sheet_id === reservationAnswer.interview_sheet_id && o.id !== reservationAnswer.id)
        .sort((a, b) => {
            return a.updated_at.localeCompare(b.updated_at);
        });

    const lastAnswer = getLastAnswer(otherAnswerInterviewSheet, reservationAnswer);

    const prev = getPrevAnswer(otherAnswerInterviewSheet, reservationAnswer);
    const goPrev = () => {
        if (prev) {
            history.push(Routes.private.CLIENT_INTERVIEW_SHEET_DISPLAY.path.replace(':id', prev.id));
        }
    };

    const next = getNextAnswer(otherAnswerInterviewSheet, reservationAnswer);
    const goNext = () => {
        if (next) {
            history.push(Routes.private.CLIENT_INTERVIEW_SHEET_DISPLAY.path.replace(':id', next.id));
        }
    };

    const combineResults = answersToRadarChartData([reservationAnswer, prev]);
    const chartData = {
        labels: combineResults[0]?.map(o => o.title),
        datasets: combineResults.map((combineResult, i) => ({
            label: `${combineResult[0].dataSetLabel} ${i === 0 ? '(今回値)' : '(前回値)'}`,
            data: combineResult.map(o => o.scorePercent),
        })),
    };

    const combineAllResults = answersToRadarChartData(
        (reservationAnswer.other_answers || []).sort((a, b) => {
            return a.updated_at.localeCompare(b.updated_at);
        }).slice(-displayType), 'YYYY/MM/DD HH:mm:ss'
    );

    const graphPeriods = getGraphPeriods(reservationAnswer.interview_sheet?.periods);

    const resultToGraph = results => {
        const labels = [];
        const datasets = {};
        for (const result of results) {
            labels.push(result[0].dataSetLabel);
            for (const category of result) {
                if (!datasets[category.title]) {
                    datasets[category.title] = {
                        title: category.title,
                        data: [],
                        dataScore: [],
                    };
                }
                datasets[category.title].data.push(category.scorePercent);
                datasets[category.title].dataScore.push(category.score);
            }
        }

        return { labels, datasets: Object.values(datasets) };
    };
    const graphData = resultToGraph(combineAllResults);
    const chartDataGraph = {
        labels: graphData.labels,
        datasets: graphData.datasets.map(dataset => ({
            label: dataset.title,
            data: dataset.data,
            dataScore: dataset.dataScore,
        })),
    };

    return (
        <>
            <div className="d-flex justify-content-space-between align-items-center">
                <BackBtnWithTitle
                    title={reservationAnswer.title}
                    onClick={() => history.push(Routes.private.CLIENT_INTERVIEW_SHEET.path)}
                />
            </div>
            <div>
                <div className="fs-14 fw-400 color-blg-60">
                    <span>{'最新回答日時：'}</span>
                    <span className="fs-14 fw-400 color-blg-60">
                        {dateToStr(lastAnswer.updated_at, 'YYYY/MM/DD HH:mm')}
                    </span>
                </div>

                {categories.length > 0 && (
                    <div className="mt-24 flex-1 text-align-center">
                        <Typography className="fw-400 fs-14 color-blg-80">{'回答日時'}</Typography>
                        <div className="d-flex justify-content-center">
                            <Button
                                type="text"
                                className={`arrow-btn mr-16 ${!prev ? 'color-blg-20' : 'color-blg-80'}`}
                                icon={<ArrowLeftIcon />}
                                onClick={() => goPrev()}
                                disabled={!prev}
                            />
                            <Typography className="mt-8 fw-700 fs-18 color-blg-100">
                                {` ${dateToStr(reservationAnswer.updated_at, 'YYYY/MM/DD HH:mm')}`}
                            </Typography>
                            <Button
                                type="text"
                                className={`arrow-btn color-blg-60 ml-32 mr-8 ${
                                    !next ? 'color-blg-20' : 'color-blg-80'
                                }`}
                                icon={<ArrowRightIcon />}
                                onClick={() => goNext()}
                                disabled={!next}
                            />
                        </div>
                    </div>
                )}

                {matchInfoByScore ? (
                    <div className="label-display mt-24 mb-48">
                        <div className="fs-24 fw-700 ">{matchInfoByScore.title}</div>
                        <div className="counselor-questionnaire-render-page-description fs-14 fw-400 color-blg-60 ">{matchInfoByScore.description}</div>
                    </div>
                ) : (
                    <div className="mt-24 mb-48 " />
                )}

                {categories.length > 0 && (
                    <>
                        <div className="mt-32">
                            <Typography className="fw-700 fs-16">{'チャート'}</Typography>
                        </div>
                        <div className="mt-32 d-flex align-items-center justify-content-space-between">
                            <RadarChart chartData={chartData} />
                        </div>
                        <div className="mt-32 d-flex align-items-center justify-content-space-between ant-select-arrow-interview">
                            <Typography className="fw-700 fs-16">{t('推移グラフ')}</Typography>
                            <TdtSelect
                                size="large"
                                className=" mt-4 fw-400 fs-16 bg-white interview-sheet-select"
                                style={{ width: 140, height: 44 }}
                                value={displayType}
                                onChange={v => setDisplayType(v)}
                            >
                                <Select.Option value={3}>{'3回分'}</Select.Option>
                                <Select.Option value={6}>{'6回分'}</Select.Option>
                                <Select.Option value={12}>{'12回分'}</Select.Option>
                                <Select.Option value={9999}>{'全部'}</Select.Option>
                            </TdtSelect>
                        </div>
                        <div className="mt-32 mb-24">
                            <TransitionGraphChart chartData={chartDataGraph} graphPeriods={graphPeriods} />
                        </div>
                    </>
                )}

                <div className="fs-16 fw-700 ">{'回答一覧'}</div>
                <div className="mt-24 white-space-pre-line">
                    <AnswerListWithScore questionnaire={reservationAnswer} hideScore={true} hideSkipped={true} />
                </div>
            </div>
        </>
    );
};

export default connect(mapState)(InterviewSheetDisplay);
