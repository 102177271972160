import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { dateToStr } from 'helpers/date.helper';
import DataTable from 'components/DataTable';
import { connect } from 'react-redux';
import PeriodFilter from 'pages/admin/interview_sheet/detail_screen/common/PeriodFilter';
import { overviewSelector } from 'pages/admin/interview_sheet/detail_screen/interview_sheet_detail.slice';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { setBackUrl } from 'core.slice';
import { emailDeletedShow } from 'helpers/string.helper';
import InputSearch from 'components/InputSearch';

const mapState = state => overviewSelector(state.admin.interviewSheetDetail);
const mapDispatch = { setBackUrl };

const IndividualContainer = ({ interviewSheet, answers, setBackUrl, setActiveKey }) => {
    const { t } = useTranslation();

    const [keyword, setKeyword] = useState('');

    const filterByKeyword = (data, keyword) => {
        if (!keyword) {
            return data;
        }

        const lowerKeyword = keyword.toLowerCase();

        return data.filter(record => {
            const name = record.user.full_name.toLowerCase();
            const email = record.user.email.toLowerCase();
            return name.includes(lowerKeyword) || email.includes(lowerKeyword);
        });
    };

    const columns = [
        {
            title: t('Company name'),
            dataIndex: 'name',
            padding: 8,
            width: 158,
            height: 40,
            render: (_, record) => record.user.employee_company[0].name,
        },
        {
            title: t('Full name'),
            dataIndex: 'user.email',
            padding: 8,
            width: 418,
            height: 40,
            render: (_, record) => {
                const { user } = record;
                return (
                    <>
                        <div className="fw-b">{`${user.full_name}`}</div>
                        <span className="text-small">{emailDeletedShow(user.email)}</span>
                    </>
                );
            },
            sorter: (a, b) => {
                return a.user.email.localeCompare(b.user.email);
            },
        },
        {
            title: '送信日時',
            dataIndex: 'created_at',
            width: 128,
            height: 40,
            render: d => dateToStr(d, 'YYYY/MM/DD HH:mm'),
        },
    ];

    return (
        <div className="bg-gray-5 flex-1">
            <div className="session-edit-wrapper session-detail-cards">
                <div className="session-detail-card">
                    <div className="session-detail-card--header">
                        <Typography className="fs-16 fw-700">{'個別集計結果'}</Typography>
                    </div>
                    <div className="session-detail-card--body pt-24 pb-24 pl-16 pr-16">
                        <div className="d-flex align-items-center ant-select-arrow-interview ">
                            <PeriodFilter setActiveKey={setActiveKey} interviewSheet={interviewSheet} />
                        </div>
                        <div>
                            <InputSearch
                                className="mt-24"
                                style={{ maxWidth: 288, height: 40 }}
                                onChange={e => setKeyword(e.target.value)}
                                placeholder={'氏名/メールアドレスで検索'}
                            />
                        </div>
                        <div className="mt-24">
                            <DataTable
                                className="collapse-table"
                                columns={columns}
                                data={filterByKeyword(answers, keyword)}
                                // data={answers}
                                onTableChange={() => {}}
                                onRow={record => ({
                                    onClick: () => {
                                        setBackUrl(
                                            Routes.private.ADMIN_INTERVIEW_SHEETS_DETAIL.path.replace(
                                                ':id',
                                                interviewSheet.id
                                            ) + '?tab=individual'
                                        );

                                        // if (record.reservation_id) {
                                        //     history.push(
                                        //         `${Routes.private.COUNSELOR_DETAIL_CONSULTATION.path.replace(
                                        //             ':id',
                                        //             record.client_id
                                        //         )}${ConsultationRoutes.DISPLAY_INTERVIEW_SHEET.path.replace(
                                        //             ':reservationId',
                                        //             record.reservation_id
                                        //         )}`
                                        //     );
                                        //     return;
                                        // }

                                        history.push(
                                            Routes.private.COUNSELOR_DETAIL_QUESTIONNAIRE.path.replace(
                                                ':id',
                                                record.user.id
                                            ) +
                                                '/' +
                                                record.id
                                        );
                                    },
                                })}
                                //rowClassName={record => (record.reservation_id ? 'row-cursor-default' : '')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(mapState, mapDispatch)(IndividualContainer);
