import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Tree } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import InputSearch from 'components/InputSearch';
import ActionBtnInDistributionModal from 'components/ActionBtnInDistributionModal';
import { connect } from 'react-redux';
import DataTable from 'components/DataTable';
import { getData } from 'pages/admin/email/distribution/distribution_list.slice';
import { randomId } from 'helpers/common.helper';

const mapState = state => ({
    state: state.admin.distributionList,
});
const mapDispatch = { getData };

const ListRegistrationModal = ({ visible, onClose, onUpdate, state, getData }) => {
    const [keyword, setKeyword] = useState('');
    const [sorterDir, setSorterDir] = useState();
    const [checkedKeys, setCheckedKeys] = useState([]);

    useEffect(() => {
        if (!state.fetched) {
            getData({
                pagination: { current: 1, pageSize: 999 },
                filters: state.filters,
                sorter: state.sorter,
            });
        }

        return () => {
            setKeyword('');
            setSorterDir(null);
            setCheckedKeys([]);
        };
    }, []);

    const stateDataToTree = useMemo(() => {
        const data = state.data;
        return data.map(d => ({
            key: `${d.id}`,
            title: (
                <span className="d-flex align-items-center">
                    {d.name}
                    <span className="label-count ml-2">{`(${d.target_number})`}</span>
                </span>
            ),
            name: d.name,
        }));
    }, [state.fetched]);

    const onCheck = checkedKeysValue => {
        setCheckedKeys(checkedKeysValue);
    };

    const filterByKeyword = (data, keyword) => {
        const hasKeyword = keyword !== '';
        return data.filter(d => (hasKeyword ? d.name.indexOf(keyword) > -1 : true))
            .sort((a, b) => {
                if (sorterDir && sorterDir === 'ascend') {
                    return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
                } else if (sorterDir && sorterDir === 'descend') {
                    return b.name > a.name ? 1 : b.name < a.name ? -1 : 0;
                }
            });
    };

    const getCheckedItems = () => {
        let result = [];
        const allData = state.data;

        for (const key of checkedKeys) {
            const item = allData.find(r => r.id === Number(key));
            if (item) {
                const targets = item.distribution_targets;
                for (const target of targets) {
                    const curCompany = target.company || {};
                    const curEmployee = target.user || {};
                    result.push({
                        ...curEmployee,
                        email: target.email,
                        company_id: curCompany.id,
                        company_name: curCompany.name,
                        user_id: curEmployee.id,
                        id: randomId(),
                    });
                }
            }
        }
        setCheckedKeys([]);
        return result;
    };

    const countSubKey = checkedKeys =>
        checkedKeys.reduce((sum = 0, next) => {
            const item = state.data.find(r => r.id === Number(next));
            return item ? sum + item.target_number : sum;
        }, 0);

    return (
        <Modal
            title={'リスト登録'}
            visible={visible}
            width={640}
            footer={
                <div className=" d-flex align-items-center justify-content-space-between pt-6 pb-6 fw-400 fs-16 space">
                    <div>
                        <span>{'選択人数：'}</span>
                        <span className="fw-700 fs-20">{countSubKey(checkedKeys)}</span>
                        <span className="ml-4">{'人'}</span>
                    </div>
                    <ActionBtnInDistributionModal onClose={onClose} onUpdate={() => onUpdate(getCheckedItems())} />
                </div>
            }
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <div className="tree-searchable tree-searchable-as-table">
                <div className="bg-gray-5 p-8 with-search-box">
                    <InputSearch
                        placeholder={'リスト名で検索'}
                        value={keyword}
                        onChange={e => setKeyword(e.target.value)}
                    />
                </div>
                <DataTable
                    className="collapse-table tree-without-thead-checkbox"
                    columns={[
                        {
                            title: 'リスト名',
                            dataIndex: 'name',
                            sorter: (a, b) => {
                                return a.name.localeCompare(b.name);
                            },
                            className: 'pl-20',
                        },
                    ]}
                    data={[]}
                    onTableChange={(pagination, filter, sorter) => {
                        setSorterDir(sorter.order);
                    }}
                    customRowSelection={{
                        selectedRowKeys: [],
                        onChange: () => {},
                        type: 'checkbox',
                    }}
                />
                <div className="mt-4 tree-list-container tree-without-thead-checkbox">
                    <Tree
                        checkable
                        treeData={filterByKeyword(stateDataToTree, keyword)}
                        checkedKeys={checkedKeys}
                        onCheck={onCheck}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default connect(mapState, mapDispatch)(ListRegistrationModal);
