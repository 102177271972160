import { createSelector, createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import QuestionTypeEnum from 'enums/question_type.enum';
import { randomId } from 'helpers/common.helper';
import { showNormal } from 'helpers/notification.helper';

const name = 'interviewSheetCreate';

const initialState = {
    name: '',
    inputCondition: '',
    description: '',
    questions: {
        byId: {},
        order: [],
    },
    options: {
        byId: {},
    },
    questionOptions: {},
    inUsed: false,
    infoTitle: '',
    infoDescription: '',
    infoByScores: {
        byId: {},
        order: [],
    },
    operationTargetId: null,
    refs: {},
    isRadarType: false,
    categories: {
        byId: {}, // id: {title: '', children: [questionId,...]}
        order: [],
    },
    touched: {
        current: {},
        values: [],
    },
};

const interviewSheetCreateSlice = createSlice({
    name,
    initialState: initialState,
    reducers: {
        reset(state, action) {
            state.questions = initialState.questions;
            state.options = initialState.options;
            state.questionOptions = initialState.questionOptions;

            if (!(action.payload && action.payload.isRadarReset)) {
                state.name = initialState.name;
                state.inputCondition = initialState.inputCondition;
                state.description = initialState.description;

                state.infoTitle = initialState.infoTitle;
                state.infoDescription = initialState.infoDescription;
                state.infoByScores = initialState.infoByScores;
            }

            state.inUsed = false;
            state.answered = false;

            state.operationTargetId = initialState.operationTargetId;
            state.refs = initialState.refs;
            state.isRadarType = initialState.isRadarType;
            state.categories = initialState.categories;
        },
        setName(state, action) {
            state.name = action.payload;
        },
        setInputCondition(state, action) {
            state.inputCondition = action.payload;
        },
        setDescription(state, action) {
            state.description = action.payload;
        },
        addQuestionRadarFirstTime(state) {
            const tmp = {
                questions: {
                    byId: {},
                    order: [],
                },
                categories: {
                    byId: {},
                    order: [],
                },
                questionOptions: {},
                options: {
                    byId: {},
                },
            };

            // rule: 3 categories are always displayed
            for (let i = 0; i < 3; i++) {
                const questionId = `q_${randomId()}_${i}`;
                tmp.questions.byId[questionId] = { type: QuestionTypeEnum.RADAR_CHART.value };
                tmp.questions.order = [...tmp.questions.order, questionId];

                for (let k = 0; k < 2; k++) {
                    const optionId = `opt_${randomId()}_${i}_${k}`;
                    if (!tmp.questionOptions[questionId]) {
                        tmp.questionOptions[questionId] = [optionId];
                    } else {
                        tmp.questionOptions[questionId] = [...tmp.questionOptions[questionId], optionId];
                    }
                    tmp.options.byId[optionId] = { value: '' };
                }

                const categoryId = `cate_${randomId()}_${i}`;
                tmp.categories.byId[categoryId] = { title: '', children: [questionId] };
                tmp.categories.order = [...tmp.categories.order, categoryId];
            }

            state.questions = tmp.questions;
            state.categories = tmp.categories;
            state.questionOptions = tmp.questionOptions;
            state.options = tmp.options;
        },
        addQuestion(state, action) {
            const { question } = action.payload;
            if (
                [QuestionTypeEnum.SINGLE_SELECTION.value, QuestionTypeEnum.MULTIPLE_SELECTION.value].includes(
                    question.type
                )
            ) {
                question.display_columns = 'set_score';
            }

            state.questions.byId[action.payload.id] = question;
            state.questions.order = [...state.questions.order, action.payload.id];

            if (question.type === QuestionTypeEnum.RADAR_CHART.value) {
                const questionId = action.payload.id;
                const tmp = {
                    questionOptions: {},
                    options: {
                        byId: {},
                    },
                };
                for (let k = 0; k < 2; k++) {
                    const optionId = `opt_${randomId()}_${questionId}_${k}`;
                    if (!tmp.questionOptions[questionId]) {
                        tmp.questionOptions[questionId] = [optionId];
                    } else {
                        tmp.questionOptions[questionId] = [...tmp.questionOptions[questionId], optionId];
                    }
                    tmp.options.byId[optionId] = { value: '' };
                }
                state.questionOptions = { ...state.questionOptions, ...tmp.questionOptions };
                state.options.byId = { ...state.options.byId, ...tmp.options.byId };
            }

            // radar case
            if (question.type === QuestionTypeEnum.RADAR_CHART.value) {
                const questionId = action.payload.id;
                let categoryId = action.payload.categoryId;

                if (!categoryId || !state.categories.byId[categoryId]) {
                    categoryId = randomId();
                    state.categories.byId[categoryId] = { title: '', children: [questionId] };
                    state.categories.order = [...state.categories.order, categoryId];
                } else {
                    state.categories.byId[categoryId].children = [
                        ...state.categories.byId[categoryId].children,
                        questionId,
                    ];
                }
            }
        },
        removeQuestion(state, action) {
            if (action.payload.isRadar) {
                const { categoryId, questionId } = action.payload;

                // delete question; otherwise delete category
                if (questionId) {
                    if (
                        !categoryId ||
                        !state.categories.byId[categoryId] ||
                        !state.categories.byId[categoryId].children.includes(questionId)
                    )
                        return;
                    state.categories.byId[categoryId].children = state.categories.byId[categoryId].children.filter(
                        tmpId => tmpId !== questionId
                    );

                    // other types : bug id not questionId in some case
                    const id = action.payload.questionId;
                    if (!id || !state.questions.byId[id]) return;
                    delete state.questions.byId[id];
                    state.questions.order = state.questions.order.filter(tmpId => tmpId !== id);
                    if (state.questionOptions[id]) {
                        for (const optId of state.questionOptions[id]) {
                            delete state.options.byId[optId];
                        }
                        delete state.questionOptions[id];
                    }

                } else {
                    // delete category
                    if (state.categories.order.length === 3) {
                        showNormal('', '3つ以上カテゴリを入力してください', 2);
                        return;
                    }

                    const tempChildren = [...state.categories.byId[categoryId].children];
                    delete state.categories.byId[categoryId];
                    state.categories.order = state.categories.order.filter(tmpId => tmpId !== categoryId);

                    const tempQuestionById = state.questions.byId;
                    tempChildren.map(k => {
                        if (tempQuestionById[k]) {
                            delete tempQuestionById[k];
                        }
                    });

                    state.questions.byId = tempQuestionById;
                    state.questions.order = [...state.questions.order.filter(tmpId => !tempChildren.includes(tmpId))];

                    // bug: delete options for check must have value
                    let tempQuestionOptions = { ...state.questionOptions };
                    let tempOptionsById = { ...state.options.byId };
                    tempChildren.map(questionId => {
                        if (tempQuestionOptions[questionId]) {
                            for (const optId of tempQuestionOptions[questionId]) {
                                delete tempOptionsById[optId];
                            }
                            delete tempQuestionOptions[questionId];
                        }
                    });

                    state.options.byId = tempOptionsById;
                    state.questionOptions = tempQuestionOptions;

                    //return;
                }
                return;
            }

            // other types
            const id = action.payload.id;
            if (!id || !state.questions.byId[id]) return;
            delete state.questions.byId[id];
            state.questions.order = state.questions.order.filter(tmpId => tmpId !== id);
            if (state.questionOptions[id]) {
                for (const optId of state.questionOptions[id]) {
                    delete state.options.byId[optId];
                }
                delete state.questionOptions[id];
            }
        },
        duplicateQuestion(state, action) {
            // radar type
            if (action.payload.isRadar) {
                const { categoryId, questionId } = action.payload;

                // duplicate question; otherwise duplicate category
                if (questionId) {
                    if (
                        !categoryId ||
                        !state.categories.byId[categoryId] ||
                        !state.categories.byId[categoryId].children.includes(questionId)
                    )
                        return;
                    const newId = `${questionId}_copy_to_${randomId()}`;
                    state.questions.byId[newId] = state.questions.byId[questionId];
                    state.questions.order.push(newId);
                    if (state.questionOptions[questionId]) {
                        state.questionOptions[newId] = [];
                        for (const optId of state.questionOptions[questionId]) {
                            const newOptId = `${optId}_copy_to_${randomId()}`;
                            state.options.byId[newOptId] = state.options.byId[optId];
                            state.questionOptions[newId].push(newOptId);
                        }
                    }
                    state.categories.byId[categoryId].children.push(newId);
                } else {
                    // duplicate category
                    if (!categoryId || !state.categories.byId[categoryId]) return;

                    const { title, children: sourceChildren } = state.categories.byId[categoryId];
                    const newCategoryId = `${categoryId}_copy_to_${randomId()}`;
                    const newCategory = { id: newCategoryId, title, children: [] };

                    const tmpQuestions = {
                        byId: {},
                        order: [],
                        questionOptions: {},
                        options: {
                            byId: {},
                        },
                    };
                    for (const sourceChildQuestionId of sourceChildren) {
                        const newId = `${sourceChildQuestionId}_copy_to_${randomId()}`;
                        tmpQuestions.byId[newId] = state.questions.byId[sourceChildQuestionId];
                        tmpQuestions.order.push(newId);
                        if (state.questionOptions[sourceChildQuestionId]) {
                            tmpQuestions.questionOptions[newId] = [];
                            for (const optId of state.questionOptions[sourceChildQuestionId]) {
                                const newOptId = `${optId}_copy_to_${randomId()}`;
                                tmpQuestions.options.byId[newOptId] = state.options.byId[optId];
                                tmpQuestions.questionOptions[newId].push(newOptId);
                            }
                        }
                    }

                    state.questions.byId = { ...state.questions.byId, ...tmpQuestions.byId };
                    state.questions.order = [...state.questions.order, ...tmpQuestions.order];
                    state.options.byId = { ...state.options.byId, ...tmpQuestions.options.byId };
                    state.questionOptions = { ...state.questionOptions, ...tmpQuestions.questionOptions };

                    newCategory.children = tmpQuestions.order;

                    state.categories.byId[newCategoryId] = newCategory;
                    state.categories.order.push(newCategoryId);
                }
                return;
            }

            // other types
            const id = action.payload.id;
            if (!id || !state.questions.byId[id]) return;
            const newId = `${id}_copy_to_${randomId()}`;
            state.questions.byId[newId] = state.questions.byId[id];
            state.questions.order.push(newId);
            if (state.questionOptions[id]) {
                state.questionOptions[newId] = [];
                for (const optId of state.questionOptions[id]) {
                    const newOptId = `${optId}_copy_to_${randomId()}`;
                    state.options.byId[newOptId] = state.options.byId[optId];
                    state.questionOptions[newId].push(newOptId);
                }
            }
        },
        moveQuestionUp(state, action) {
            if (action.payload.isRadar) {
                const { categoryId, questionId } = action.payload;

                // move question; otherwise move category
                if (questionId) {
                    if (
                        !categoryId ||
                        !state.categories.byId[categoryId] ||
                        !state.categories.byId[categoryId].children.includes(questionId)
                    )
                        return;
                    let order = state.categories.byId[categoryId].children.slice();
                    const index = order.indexOf(questionId);
                    if (index < 1) return;
                    const tmpId = order[index - 1];
                    order[index - 1] = questionId;
                    order[index] = tmpId;
                    state.categories.byId[categoryId].children = order;
                } else {
                    // move category
                    if (!categoryId || !state.categories.byId[categoryId]) return;
                    let order = state.categories.order.slice();
                    const index = order.indexOf(categoryId);
                    if (index < 1) return;
                    const tmpId = order[index - 1];
                    order[index - 1] = categoryId;
                    order[index] = tmpId;
                    state.categories.order = order;
                }
                return;
            }

            // other types
            const id = action.payload.id;
            if (!id || !state.questions.byId[id]) return;
            let order = state.questions.order.slice();
            const index = order.indexOf(id);
            if (index < 1) return;
            const tmpId = order[index - 1];
            order[index - 1] = id;
            order[index] = tmpId;
            state.questions.order = order;
        },
        moveQuestionDown(state, action) {
            if (action.payload.isRadar) {
                const { categoryId, questionId } = action.payload;

                // move question; otherwise move category
                if (questionId) {
                    if (
                        !categoryId ||
                        !state.categories.byId[categoryId] ||
                        !state.categories.byId[categoryId].children.includes(questionId)
                    )
                        return;
                    let order = state.categories.byId[categoryId].children.slice();
                    const index = order.indexOf(questionId);
                    if (index === -1 || index === order.length - 1) return;
                    const tmpId = order[index + 1];
                    order[index + 1] = questionId;
                    order[index] = tmpId;
                    state.categories.byId[categoryId].children = order;
                } else {
                    // move category
                    if (!categoryId || !state.categories.byId[categoryId]) return;
                    let order = state.categories.order.slice();
                    const index = order.indexOf(categoryId);
                    if (index === -1 || index === order.length - 1) return;
                    const tmpId = order[index + 1];
                    order[index + 1] = categoryId;
                    order[index] = tmpId;
                    state.categories.order = order;
                }
                return;
            }

            // other types
            const id = action.payload.id;
            if (!id || !state.questions.byId[id]) return;
            let order = state.questions.order.slice();
            const index = order.indexOf(id);
            if (index === -1 || index === order.length - 1) return;
            const tmpId = order[index + 1];
            order[index + 1] = id;
            order[index] = tmpId;
            state.questions.order = order;
        },
        setQuestionText(state, action) {
            if (!action.payload.id || !state.questions.byId[action.payload.id]) return;
            state.questions.byId[action.payload.id].value = action.payload.question;
        },
        setQuestionAddition(state, action) {
            if (!action.payload.id || !state.questions.byId[action.payload.id]) return;
            state.questions.byId[action.payload.id].addition = action.payload.addition;
        },
        setQuestionRequired(state, action) {
            if (!action.payload.id || !state.questions.byId[action.payload.id]) return;
            state.questions.byId[action.payload.id].isRequired = action.payload.isRequired;
        },
        setQuestionType(state, action) {
            if (!action.payload.id || !state.questions.byId[action.payload.id]) return;

            const { id, type } = action.payload;
            state.questions.byId[id].type = type;

            // update, delete option if needed
            const oldType = state.questions.byId[id].type;
            if (
                oldType === QuestionTypeEnum.DATE_TIME.value ||
                type === QuestionTypeEnum.DATE_TIME.value ||
                oldType === QuestionTypeEnum.FREE_INPUT.value ||
                type === QuestionTypeEnum.FREE_INPUT.value ||
                oldType === QuestionTypeEnum.FREE_INPUT_PARAGRAPH.value ||
                type === QuestionTypeEnum.FREE_INPUT_PARAGRAPH.value ||
                oldType === QuestionTypeEnum.FREE_INPUT_NUMBER.value ||
                type === QuestionTypeEnum.FREE_INPUT_NUMBER.value ||
                (oldType === QuestionTypeEnum.SINGLE_SELECTION.value &&
                    type !== QuestionTypeEnum.MULTIPLE_SELECTION.value) ||
                (oldType === QuestionTypeEnum.MULTIPLE_SELECTION.value &&
                    type !== QuestionTypeEnum.SINGLE_SELECTION.value)
            ) {
                // clear all options
                const optionIds = state.questionOptions[id] || [];
                for (const optionId of optionIds) {
                    delete state.options.byId[optionId];
                }
                state.questionOptions[id] = [];
                //
                if (
                    [QuestionTypeEnum.MULTIPLE_SELECTION.value, QuestionTypeEnum.SINGLE_SELECTION.value].includes(type)
                ) {
                    state.questions.byId[id].display_columns = 'set_score';
                } else {
                    state.questions.byId[id].display_columns = 'unset';
                }
            }
        },
        setQuestionDisplayColumns(state, action) {
            if (!action.payload.id || !state.questions.byId[action.payload.id]) return;
            const { id: questionId, display_columns, clearScore, clearNextQuestion } = action.payload;
            // clear display column option data
            state.questions.byId[action.payload.id].display_columns = display_columns;
            const optionIds = state.questionOptions[questionId] || [];

            for (const optionId of optionIds) {
                if (clearScore) {
                    state.options.byId[optionId] = { ...state.options.byId[optionId], score: 0 };
                }
                if (clearNextQuestion) {
                    state.options.byId[optionId] = { ...state.options.byId[optionId], next_question: null };
                }
            }
        },
        addOption(state, action) {
            const questionId = action.payload.questionId;
            const optionId = action.payload.optionId;
            if (!questionId || !state.questions.byId[questionId] || !optionId) return;
            if (!state.questionOptions[questionId]) {
                state.questionOptions[questionId] = [optionId];
            } else {
                state.questionOptions[questionId] = [...state.questionOptions[questionId], optionId];
            }
            state.options.byId[optionId] = action.payload.data;

            // auto focus
            state.operationTargetId = optionId;
        },
        updateOption(state, action) {
            const optionId = action.payload.optionId;
            if (!optionId) return;
            state.options.byId[optionId] = action.payload.data;
        },
        moveOption(state, action) {
            const { srcIndex, desIndex, questionId } = action.payload;
            if (srcIndex === desIndex) {
                return;
            }
            let newOrder = [...state.questionOptions[questionId]];
            reOrder(newOrder, srcIndex, desIndex);
            state.questionOptions[questionId] = newOrder;
        },
        removeOption(state, action) {
            const { questionId, optionId } = action.payload;
            delete state.options.byId[optionId];
            state.questionOptions[questionId] = [...state.questionOptions[questionId].filter(d => d !== optionId)];
        },
        convertDataToState(state, action) {
            const {
                name,
                input_condition: inputCondition,
                description,
                questions,
                in_used,
                answered,
                info_title: infoTitle,
                info_description: infoDescription,
                info_by_scores: infoByScores,
                json_categories: jsonCategories,
            } = action.payload;

            state.name = name;
            state.inputCondition = inputCondition;
            state.description = description;
            state.infoTitle = infoTitle;
            state.infoDescription = infoDescription;

            let stateQuestions = {
                byId: {},
                order: [],
            };
            let stateOptions = {
                byId: {},
            };
            let stateQuestionOptions = {};
            for (const question of questions) {
                let { id, type, order, value, addition, is_required, display_columns } = question;
                stateQuestions.byId[id] = {
                    id,
                    type,
                    order,
                    value,
                    addition,
                    isRequired: is_required,
                    display_columns,
                };
                stateQuestions.order.push(id);
                if (question.options && question.options.length > 0) {
                    stateQuestionOptions[id] = [];
                }
                for (const option of question.options) {
                    let { id: optId, order, value, type, is_range, score, next_question } = option;
                    stateOptions.byId[optId] = { value, type, order, isRange: is_range, score, next_question };
                    stateQuestionOptions[id].push(optId);
                }
            }
            state.questions = stateQuestions;
            state.options = stateOptions;
            state.questionOptions = stateQuestionOptions;

            state.inUsed = in_used;
            state.answered = answered;

            let stateInfoByScores = {
                byId: {},
                order: [],
            };
            for (const infoByScore of infoByScores) {
                let { id, score_from, score_to, title, description } = infoByScore;
                stateInfoByScores.byId[id] = {
                    id,
                    score_from,
                    score_to,
                    title,
                    description,
                    rev: { id, score_from, score_to, title, description },
                };
                stateInfoByScores.order.push(id);
            }
            state.infoByScores = stateInfoByScores;

            // parse categories
            if (jsonCategories) {
                const arrCategories = JSON.parse(jsonCategories);
                if (arrCategories.length > 0) {
                    const questionsOrder = stateQuestions.order;
                    let stateCategories = {
                        byId: {},
                        order: [],
                    };

                    // +1 because bug !0 is true
                    for (let i = 1; i <= arrCategories.length; i++) {
                        const { title, start, end } = arrCategories[i - 1];

                        stateCategories.byId[i + ''] = { id: i + '', title, children: questionsOrder.slice(start, end + 1) };
                        stateCategories.order.push(i + '');
                    }
                    state.categories = stateCategories;
                }
            }
        },
        addInfoByScore(state, action) {
            state.infoByScores.byId[action.payload.id] = action.payload.infoByScore;
            state.infoByScores.order = [...state.infoByScores.order, action.payload.id];
        },
        updateInfoByScore(state, action) {
            let info = state.infoByScores.byId[action.payload.id];
            state.infoByScores.byId[action.payload.id] = { ...info, ...action.payload.changes };
        },
        removeInfoByScore(state, action) {
            const id = action.payload.id;
            if (!id || !state.infoByScores.byId[id]) return;
            delete state.infoByScores.byId[id];
            state.infoByScores.order = state.infoByScores.order.filter(tmpId => tmpId !== id);
        },
        setInfoTitle(state, action) {
            state.infoTitle = action.payload;
        },
        setInfoDescription(state, action) {
            state.infoDescription = action.payload;
        },
        setRefs(state, action) {
            const { id, el } = action.payload;
            state.refs = { ...state.refs, [id]: el };
        },
        clearRefs(state) {
            state.refs = {};
        },
        setCategoryTitle(state, action) {
            if (!action.payload.id || !state.categories.byId[action.payload.id]) return;
            state.categories.byId[action.payload.id].title = action.payload.title;
        },
        setTouched(state, action) {
            const categoryId = action.payload;
            if (state.touched.current !== categoryId) {
                state.touched = {
                    current: categoryId,
                    values: [...state.touched.values, categoryId],
                }
            }
        }
    },
});

export const {
    reset,
    setName,
    setInputCondition,
    setDescription,
    addQuestionRadarFirstTime,
    addQuestion,
    removeQuestion,
    duplicateQuestion,
    moveQuestionUp,
    moveQuestionDown,
    setQuestionText,
    setQuestionAddition,
    setQuestionRequired,
    setQuestionType,
    setQuestionDisplayColumns,
    addOption,
    updateOption,
    moveOption,
    removeOption,
    convertDataToState,
    addInfoByScore,
    updateInfoByScore,
    removeInfoByScore,
    setInfoTitle,
    setInfoDescription,
    setRefs,
    clearRefs,
    setCategoryTitle,
    setTouched,
} = interviewSheetCreateSlice.actions;

export default interviewSheetCreateSlice.reducer;

export const infoSelector = state => ({
    name: state.name,
    inputCondition: state.inputCondition,
    description: state.description,
});
const questionsSelector = state => state.questions.byId;
const optionsSelector = state => state.options.byId;
const questionOptionsSelector = state => state.questionOptions;
const categoriesSelector = state => state.categories.byId;
export const canPreview = createSelector(
    infoSelector,
    questionsSelector,
    optionsSelector,
    questionOptionsSelector,
    categoriesSelector,
    (info, questions, options, questionOptions, categories) => {
        if (Object.keys(questions).length === 0) return false;
        if (!info.name) return false;
        for (const question of Object.values(questions)) {
            if (!question.value) return false;

            if (question.type === QuestionTypeEnum.RADAR_CHART.value) {
                for (const category of Object.values(categories)) {
                    if (!category.title || !category.children || category.children.length < 1) return false;
                }

                if (checkIfDuplicateExists(Object.values(categories).map(o => o.title))) {
                    return false;
                }

                if (Object.values(questionOptions).some(o => o.length < 2)) {
                    return false;
                }

                for (const option of Object.values(options)) {
                    if (!option.score && option.score !== 0) return false;
                }
            }
        }
        for (const option of Object.values(options)) {
            if (!option.value) return false;
        }

        return true;
    }
);

export const getInterviewSheet = id => async dispatch => {
    const data = await api.get(APP_URLS.ADMIN_INTERVIEW_SHEETS_DETAIL.replace(':id', id));
    if (data) {
        dispatch(convertDataToState(data));
    }
};

const reOrder = (list, srcIndex, desIndex) => {
    const [removed] = list.splice(srcIndex, 1);
    list.splice(desIndex, 0, removed);
};

const checkIfDuplicateExists = (arr) => {
    return new Set(arr).size !== arr.length
}