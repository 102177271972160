import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Select } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import ActionBtnInModal from 'components/ActionBtnInModal';
import TdtSelect from 'components/TdtSelect';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';

const CloneSeminarModal = ({ visible, onClose, listCompanies, company, seminarId }) => {
    const { t } = useTranslation();

    const [value, setValue] = useState({ company_id: company.id });

    const cloneSeminar = async seminarId => {
        const response = await api.post(APP_URLS.ADMIN_COMPANIES_SEMINAR_CLONE_SEMINAR.replace(':id', seminarId), {
            ...value,
        });
        if (response) {
            showNormal('', t('message:Duplicate session'), 3);
        }
    };

    return (
        <>
            <Modal
                title={t('field:Session duplication')}
                visible={visible}
                width={400}
                footer={null}
                closeIcon={<CloseIcon />}
                onCancel={() => onClose()}
            >
                <p>{'複製先の企業を選択してください'}</p>

                <TdtSelect
                    size="large"
                    className="fs-16 seminar-company-select select-gray"
                    defaultValue={company.id}
                    onChange={val => {
                        setValue({ ...value, company_id: val });
                    }}
                >
                    <Select.Option value={company.id}>{company.name}</Select.Option>
                    {listCompanies
                        .filter(c => c.id !== company.id)
                        .map(l => (
                            <Select.Option key={l.id} value={l.id}>
                                {l.name}
                            </Select.Option>
                        ))}
                </TdtSelect>

                <ActionBtnInModal
                    onClose={onClose}
                    onUpdate={() => {
                        cloneSeminar(seminarId);
                    }}
                    confirmText={'複製'}
                    isNotDelete={true}
                />
            </Modal>
        </>
    );
};

CloneSeminarModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default CloneSeminarModal;
