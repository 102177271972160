import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import FloatBar from 'components/FloatBar';
import MessageThreadRow from 'pages/client/message/MessageThreadRow';
import TdtVerticalResizable from 'components/TdtVerticalResizable';
import { Input } from 'antd';
import { createMessage, getMessageThread, setLoading } from 'pages/counselor/calendar/message/message_detail.slice';
import TdtButton from 'components/TdtButton';
import CustomDropdown from 'components/CustomDropdown';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import { useTranslation } from 'react-i18next';
import TdtButtonSubmit from 'components/TdtButtonSubmit';
import { scrollRef } from 'helpers/common.helper';
import NoData from 'components/NoData';
import Loader from 'components/Loader';
import { setData } from 'pages/counselor/calendar/message/message.slice';
import { setCurrentConsultant } from 'core.slice';
import { setUser } from 'pages/auth/auth.slice';
import { isAdminStaff } from 'helpers/role.helper';
import TdtButtonRestrict from 'components/TdtButtonRestrict';
import EmailType from 'enums/email_type.enum';

const mapState = state => ({
    loading: state.counselor.messageDetail.loading,
    messageThread: state.counselor.messageDetail.messageThread,
    user: state.auth.user,
    hasNewMessage: state.auth.event.hasNewMessage,
    threads: state.counselor.message.data,
    currentConsultant: state.core.currentConsultant,
});
const mapDispatch = { getMessageThread, createMessage, setLoading, setData, setCurrentConsultant, setUser };

const MessageThreadDetail = ({
    loading,
    setLoading,
    messageThread,
    getMessageThread,
    createMessage,
    firstId,
    user,
    hasNewMessage,
    getMessageThreads,
    threads,
    setData,
    currentConsultant,
    setCurrentConsultant,
    setUser,
}) => {
    const { t } = useTranslation();

    const { id, messageThreadId } = useParams();
    const ref = useRef(null);

    const [heightMessage, setHeightMessage] = useState(650);

    const selectedMessageThreadId = messageThreadId || firstId;
    useEffect(() => {
        if (selectedMessageThreadId) {
            messageThread = null;
            getMessageThread(selectedMessageThreadId);
        } else {
            setLoading(false);
        }

        return () => {
            setLoading(true);
        };
    }, [selectedMessageThreadId, hasNewMessage[selectedMessageThreadId]]);

    // useEffect(() => {
    //     if (ref.current != null) {
    //         scrollRef(ref);
    //     }
    // }, [messageThread && messageThread.messages.length]);

    const [value, setValue] = useState('');

    const submitMessage = async () => {
        if (!value || value.length < 1) {
            return false;
        }
        await createMessage(messageThread.id, value, t('message:I sent a message'));
        setValue('');
        scrollRef(ref);
    };

    // actions
    const messageThreadAction = async action => {
        const response = await api.patch(APP_URLS.MESSAGE_THREADS_ACTIONS.replace(':id', messageThread.id), {
            set_action: action,
        });
        if (response) {
            getMessageThreads(selectedMessageThreadId);
            getMessageThread(selectedMessageThreadId);
            action === 'close_thread'
                ? showNormal('', t('message:Closed message'), 3)
                : action === 'open_thread'
                ? showNormal('', t('message:Opened message '), 3)
                : action === 'no_reply'
                ? showNormal('', t('message:The message was made unable to reply'), 3)
                : showNormal('', t('message:Made it possible to reply to messages'), 3);
        }
    };

    if (loading) {
        return (
            <div className="message-detail mt-40 ml-24 mb-24">
                <Loader />
            </div>
        );
    }

    if (!messageThread || messageThread.user_id !== Number(id)) {
        return <NoData />;
    }

    const isBulkType = messageThread.company_id;
    const bulkMessageAsNormal = () => {
        return [{ ...messageThread, user: messageThread.counselor }];
    };

    const changeHeightChatBox = () => {
        // let bodyHeight = document.body.offsetHeight;
        // let backButtonHeight = document.querySelector('.message-detail')?.offsetHeight;
        // let chatBoxHeight = document.querySelector('.message-thread-float-bar')?.offsetHeight;
        // setHeightMessage(bodyHeight - backButtonHeight - chatBoxHeight - 70);
    };

    const isOwner = user.id === messageThread.counselor_id;

    const postMarkAsRead = () => {
        if (loading || Number(selectedMessageThreadId) !== messageThread.id) {
            return;
        }

        // sync
        const currentMessageThreadInList = threads.find(t => t.id === messageThread.id);
        if (!currentMessageThreadInList) {
            return;
        }
        const diffCount = currentMessageThreadInList.unread_message;
        if (currentMessageThreadInList.unread_message !== messageThread.unread_message_for_counselor) {
            const syncThreads = threads.map(t =>
                t.id === messageThread.id ? { ...t, unread_message: messageThread.unread_message_for_counselor } : t
            );
            setData(syncThreads);
            setCurrentConsultant({
                ...currentConsultant,
                unread_message:
                    currentConsultant.unread_message === '99+' ? '99+' : currentConsultant.unread_message - diffCount,
            });
            setUser({
                ...user,
                unread_message: user.unread_message === '99+' ? '99+' : user.unread_message - diffCount,
            });
        }
    };

    const isEmailType = [
        'session',
        'program',
        EmailType.HTML_EMAIL.value,
        EmailType.NEW_PROGRAM.value,
        EmailType.NEW_SESSION.value,
    ].includes(messageThread.type);

    return (
        <>
            <div className="message-detail mt-40 ml-24 mb-24">
                <div className="d-flex">
                    {!isAdminStaff(user) && <div className="fw-500 f-24-36 flex-1">{messageThread.title || 'オンライン相談フィードバック'}</div>}
                    {(isOwner && !isEmailType) && (
                        <CustomDropdown
                            trigger={['click']}
                            options={[
                                {
                                    key: messageThread.allow_reply_flag === 1 ? 'no_reply' : 'allow_reply',
                                    text: messageThread.allow_reply_flag === 1 ? '返信不可にする' : '返信可能にする',
                                },
                                {
                                    key: messageThread.close_flag === 0 ? 'close_thread' : 'open_thread',
                                    text:
                                        messageThread.close_flag === 0
                                            ? 'メッセージをクローズ'
                                            : 'メッセージをオープンする',
                                },
                            ]}
                            onSelect={opt => messageThreadAction(opt.key)}
                            separator
                        >
                            <TdtButton
                                buttonSize="big"
                                buttonStyle="outline"
                                className="fw-b mr-32"
                                style={{ minWidth: 120 }}
                            >
                                {'設定'}
                            </TdtButton>
                        </CustomDropdown>
                    )}
                </div>
            </div>

            <div className="fixed-title-msg">
                <div className={`client-message-threads pl-24 pr-24 mt-4 ${isEmailType ? 'thread-as-email-type' : ''}`} ref={ref}>
                    {isAdminStaff(user) ? (
                        <TdtButtonRestrict withImage={true} />
                    ) : (
                        <>
                            {(isBulkType ? bulkMessageAsNormal() : messageThread.messages).map((m, index) => (
                                <MessageThreadRow key={m.id} message={m} messageThread={index > 0 ? null : messageThread} currentUser={user} />
                            ))}
                        </>
                    )}
                </div>
            </div>

            {(!isOwner || isEmailType) ? (
                <></>
            ) : (
                <>
                    {messageThread.close_flag ? (
                        <FloatBar className="message-thread-end left-2side">
                            <div>{'やりとりが終了しています'}</div>
                        </FloatBar>
                    ) : (
                        <FloatBar className="message-thread-float-bar left-2side pb-42">
                            <TdtVerticalResizable onChangeHeight={changeHeightChatBox} height={74}>
                                <Input.TextArea
                                    className="flex-1 input-message"
                                    // maxLength={400}
                                    placeholder={'400文字まで入力できます'}
                                    value={value}
                                    onChange={v => setValue(v.target.value)}
                                />
                                <TdtButtonSubmit
                                    disabled={value && value.length > 400}
                                    className="fw-b ml-16 btn-message-detail csl-send-btn"
                                    type="primary"
                                    onClick={() => submitMessage()}
                                >
                                    {'送信'}
                                </TdtButtonSubmit>

                                <span className="text-area-show-count w-100p">
                                    <span className={`${value && value.length > 400 ? 'pink-color' : ''}`}>
                                        {value ? value.length : 0}
                                    </span>
                                    <span>{'/400'}</span>
                                </span>
                            </TdtVerticalResizable>
                        </FloatBar>
                    )}
                </>
            )}

            {postMarkAsRead()}
        </>
    );
};

export default connect(mapState, mapDispatch)(MessageThreadDetail);

MessageThreadDetail.propTypes = {
    messageThread: PropTypes.any,
    getMessageThread: PropTypes.func.isRequired,
    createMessage: PropTypes.func.isRequired,
    firstId: PropTypes.any,
    user: PropTypes.any,
};
