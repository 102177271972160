import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TabList from 'components/TabList';
import HealthImageEnum from 'enums/health_image.enum';
import HealthDataSidebar from 'pages/counselor/calendar/health_data/HealthDataSidebar';
import PhotoTabContainer from 'pages/counselor/calendar/health_data/PhotoTabContainer';
import { connect } from 'react-redux';
import { isAdminStaff } from 'helpers/role.helper';
import TdtButtonRestrict from 'components/TdtButtonRestrict';

const mapState = state => ({
    user: state.auth.user,
});

const PhotoTabList = ({ user }) => {
    const { t } = useTranslation();

    const [tab, setTab] = useState(`${HealthImageEnum.MEAL_RECORD.value}`);
    const tabList = [
        {
            key: `${HealthImageEnum.MEAL_RECORD.value}`, // ~path
            title: '食事履歴',
            container: (
                <>
                    <PhotoTabContainer category={HealthImageEnum.MEAL_RECORD.value} setTab={setTab} />
                </>
            ),
        },
        {
            key: `${HealthImageEnum.EXAMINATION_DATA.value}`,
            title: '検診データ',
            container: (
                <>
                    <PhotoTabContainer category={HealthImageEnum.EXAMINATION_DATA.value} setTab={setTab} />
                </>
            ),
        },
        {
            key: `${HealthImageEnum.OTHER.value}`,
            title: 'その他',
            container: (
                <>
                    <PhotoTabContainer category={HealthImageEnum.OTHER.value} setTab={setTab} />
                </>
            ),
        },
    ];

    const handlerTabChange = key => {
        setTab(key);
    };

    return (
        <>
            <div className="counselor-aside">
                <div className="consultation-aside">
                    <div className="p-16 pb-0">
                        <div className="h7 mt-16 mb-24">{t('Health data')}</div>
                    </div>
                </div>
                <HealthDataSidebar activeKey="photo" />
            </div>
            <div className="counselor-main pl-24 pr-24 mt-40">
                <div className="f-24-36 fw-b">{t('Photo')}</div>
                {!isAdminStaff(user) ? (
                    <TabList
                        tabs={tabList}
                        onChange={handlerTabChange}
                        activeKey={tab}
                        animated={false}
                        indent
                        className="mb-24"
                    />
                ) : (
                    <TdtButtonRestrict withImage={true} />
                )}
            </div>
        </>
    );
};

export default connect(mapState, null)(PhotoTabList);

PhotoTabList.propTypes = {
    tab: PropTypes.string,
};
