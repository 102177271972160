import React from 'react';

// data = [{ text }]
const AnswerQuestions = ({ data }) => {
    // const data = [
    //     {
    //         text: '理解しやすかったです。ありがとうございました',
    //     },
    //     {
    //         text: 'Zoomでの中継が面白かった',
    //     },
    //     {
    //         text: '平日の夜にも開催してほしいです。次あったらまた参加したい',
    //     },
    // ];

    return (
        <div className="session-detail-card mt-8 p-12">
            {data.map((d, index) => (
                <div key={index} className={`answer-question ${index < data.length - 1 ? 'mb-4' : ''}`}>
                    {typeof d.text === 'object' ? '' : d.text}
                </div>
            ))}
        </div>
    );
};

export default AnswerQuestions;
