import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'antd';
import { APP_URLS } from 'constants/url.constant';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { imageUrlAdapter } from 'helpers/image_url.helper';
import { useTranslation } from 'react-i18next';

const ProgramBlock = ({ name, description, programs, programsDetail, isFirstTime }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className={isFirstTime ? 'horizontal-container' : 'vertical-container'}>
                <a
                    className="seminar-block-link client-program-block"
                    onClick={() => {
                        history.push(`${Routes.private.CLIENT_PROGRAM_DETAIL.path}?companyProgram=${programs.id}`);
                    }}
                >
                    <div className="program-block">
                        {programsDetail.cover_img ? (
                            <Image
                                className={'seminar-block-image'}
                                src={imageUrlAdapter(`${APP_URLS.STATIC_BASE_URL}/${programsDetail.cover_img}`, 480)}
                                preview={false}
                                height={160}
                            />
                        ) : (
                            <div className={`no-image-background ${isFirstTime ? 'horizontal' : 'block'}`}>
                                <span className="content">{'No Image'}</span>
                            </div>
                        )}
                        <div className="content-container">
                            {programsDetail.allow_same_day_booking && (
                                <p className="msg-badge msg-badge-secondary same-day-badge">
                                    {t('Same-day reservation is possible')}
                                </p>
                            )}
                            {isFirstTime ? (
                                <>
                                    <span className="program-name first-time">{name}</span>
                                    <p className="program-description first-time">{description}</p>
                                </>
                            ) : (
                                <>
                                    <span className="program-name">{name}</span>
                                    <p className="program-description pc-display">{description}</p>
                                </>
                            )}
                        </div>
                    </div>
                </a>
            </div>
        </>
    );
};

export default ProgramBlock;

ProgramBlock.propTypes = {
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    programsDetail: PropTypes.any,
};
