import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper';
import { APP_URLS } from 'constants/url.constant';
import api from 'helpers/api.helper';

const name = 'companyDetailEmployee';

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props =>
        await baseGetData({ url: APP_URLS.ADMIN_COMPANIES_DETAIL_EMPLOYEES.replace(':id', props['id']), ...props })
);

const defaultPagination = {
    current: 1,
    pageSize: 100,
    total: 1,
};
const defaultSorter = {
    field: 'created_at',
    order: 'ascend',
};
const defaultFilters = { status: null, program_id: null };

const companyDetailEmployeeSlice = createSlice({
    name,
    initialState: {
        ...baseInitialState,
        pagination: defaultPagination,
        filters: defaultFilters,
        sorter: defaultSorter,
        metaData: {
            programs: [],
        },
    },
    reducers: {
        ...baseReducers,
        setMetaData(state, action) {
            state.metaData = action.payload;
        },
        resetData(state) {
            state.data = [];
            state.pagination = defaultPagination;
            state.sorter = defaultSorter;
        },
    },
    extraReducers: baseExtraReducers(getData),
});

export const {
    setLoading,
    setData,
    setPagination,
    setFilters,
    setSorter,
    setHideColumns,
    setSelection,
    setMetaData,
    resetData,
} = companyDetailEmployeeSlice.actions;

export default companyDetailEmployeeSlice.reducer;

export const getMetaData = id => async dispatch => {
    const metaData = await api.get(APP_URLS.ADMIN_COMPANIES_DETAIL_EMPLOYEES_META_DATA.replace(':id', id));
    if (metaData) {
        let programs = [];
        for (const program of metaData) {
            let tmpProgram = { id: program.id, name: program.name, name_mngt: program.name_mngt };
            programs.push(tmpProgram);
        }

        dispatch(setMetaData({ programs }));
    }
};
