import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Row } from 'antd';
import FloatBar from 'components/FloatBar';
import TdtButtonSubmit from 'components/TdtButtonSubmit';

const CompanyForm = ({ isEdit = false, initialValues = {}, onFinish }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    return (
        <Form
            form={form}
            layout="vertical"
            initialValues={initialValues}
            onFinish={async values => {
                await onFinish(values);
            }}
            requiredMark={false}
            className="mt-32"
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label={t('field:Company name')}
                        extra={
                            <span className="company-name-extra">
                                {/*<InfoIcon className="mr-6" />*/}
                                {
                                    '登録後、企業コードを同社社員に周知する必要があります。企業コードは各企業の詳細画面の「設定」タブで発行できます。'
                                }
                            </span>
                        }
                        validateTrigger={false}
                        rules={[
                            {
                                required: true,
                                message: t('validation:Please enter a valid {{field}}', {
                                    field: t('field:Company name'),
                                }),
                            },
                        ]}
                        style={{ maxWidth: 480 }}
                    >
                        <Input maxLength={255} />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item shouldUpdate>
                {() => (
                    <FloatBar>
                        <TdtButtonSubmit
                            type="primary"
                            disabled={!form.isFieldsTouched(true)}
                            htmlType="submit"
                            className="fw-b disable-gray-btn"
                        >
                            {isEdit ? t('Save') : t('Register')}
                        </TdtButtonSubmit>
                    </FloatBar>
                )}
            </Form.Item>
        </Form>
    );
};

export default CompanyForm;

CompanyForm.propTypes = {
    isEdit: PropTypes.bool,
    initialValues: PropTypes.object,
    onFinish: PropTypes.func,
};
