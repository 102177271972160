import React from 'react';
import CategoryFloatBar from 'pages/admin/interview_sheet/category/CategoryFloatBar';
import { Button, Input } from 'antd';
import { AddIcon } from 'components/SvgIcon';
import { randomId } from 'helpers/common.helper';
import { useTranslation } from 'react-i18next';
import DndOptionsTable from 'pages/admin/interview_sheet/questions/DndOptionsTable';
import QuestionTypeEnum from 'enums/question_type.enum';

const RadarChartQuestion = ({
    id,
    categoryId,
    question,
    addOption,
    updateOption,
    moveOption,
    removeOption,
    options,
    no,
    setRefs,
    optionsOrder,
    setQuestion,
    hasCheckErr,
}) => {
    const { t } = useTranslation();
    const { TextArea } = Input;

    const hasQuestionTitleErr = hasCheckErr && !question;

    return (
        <>
            <CategoryFloatBar className="position-top-right-float-bar" categoryId={categoryId} questionId={id} />
            <div className={`item mb-24 ${hasQuestionTitleErr ? 'has-error-item' : ''}`}>
                <div className="label mb-8 fw-700 fs-14 text-gray-color ">{t('field:質問')}</div>
                <TextArea
                    className="interview-sheet-input-create bg-white"
                    placeholder="質問を入力"
                    autoSize
                    value={question}
                    onChange={e => {
                        setQuestion({ id, question: e.target.value });
                    }}
                />
                {hasQuestionTitleErr && (
                    <span className="fs-12-12 color-red-100 option-row-check-err-name">{'質問を入力してください'}</span>
                )}
            </div>
            <DndOptionsTable
                questionId={id}
                options={options}
                optionsOrder={optionsOrder}
                type={QuestionTypeEnum.SINGLE_SELECTION.value}
                onOrderChange={(srcIndex, desIndex) => moveOption({ srcIndex, desIndex, questionId: id })}
                updateOption={updateOption}
                removeOption={removeOption}
                displayColumns={'set_score'}
                flattenQuestions={[]}
                no={no}
                setRefs={setRefs}
                addOption={addOption}
                hasCheckErr={hasCheckErr}
            />
            <div className="item ml-56">
                <Button
                    className="fw-b w-100p justify-content-center"
                    style={{
                        height: 40,
                        border: '1px dashed #D1D0D6',
                        borderRadius: 4,
                    }}
                    icon={<AddIcon />}
                    onClick={() => {
                        addOption({
                            questionId: id,
                            optionId: randomId(),
                            data: {
                                value: '',
                            },
                        });
                    }}
                >
                    {'選択肢の追加'}
                </Button>
            </div>
        </>
    );
};

export default RadarChartQuestion;
