import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Menu, Tooltip } from 'antd';
import { ArrowDropDownIcon, CloseIcon, SortIcon } from 'components/SvgIcon';
import TdtButton from 'components/TdtButton';

const TdtFilter = ({ rows = [], applyFilters }) => {
    const [visible, setVisible] = useState(false);
    const ref = useRef(null);

    const [filters, setFilters] = useState([]);
    const emptyFilter = filters.length < 1;

    const handleMenuClick = (row, i) => {
        const key = i.key;
        const target = row.options.find(o => o.key === key);
        setFilters([...filters, target]);
    };

    const renderFilterTags = () => {
        return (
            <>
                {filters.map(f => (
                    <span key={f.key} className="filter-tag">
                        <Tooltip title={f.tooltip}>{f.text}</Tooltip>
                        <CloseIcon className="ml-8" size={16} onClick={() => removeFilter(f)} />
                    </span>
                ))}
            </>
        );
    };

    const removeFilter = target => {
        setFilters([...filters].filter(f => f.key !== target.key));
    };

    const renderMenu = (row, options) => {
        return (
            <Menu onClick={item => handleMenuClick(row, item)}>
                {options.map(o => (
                    <Menu.Item key={o.key}>{o.text}</Menu.Item>
                ))}
            </Menu>
        );
    };

    const handleOutsideClick = e => {
        // ignore clicks on the component itself
        if (!ref || !ref.current || ref.current.contains(e.target)) {
            return;
        }

        // weird
        if (e.target.nodeName === 'BUTTON' || (e.target.parentNode && e.target.parentNode.nodeName === 'BUTTON')) {
            return;
        }

        setVisible(false);
    };

    const renderContainer = () => {
        return (
            <div className="filter-container" ref={ref}>
                <div className="filter-container-header">
                    <span className="fw-b">{'フィルタを追加'}</span>
                    <TdtButton buttonStyle="plain" onClick={() => setFilters([])}>
                        {'リセット'}
                    </TdtButton>
                </div>
                <div className="filter-container-rows">
                    {rows.map((r, index) => {
                        const options = r.options.filter(o => !filters.find(f => f.key === o.key));
                        return (
                            <div key={r.key || index} className="filter-container-row">
                                <div className="ml-16">{r.label}</div>
                                <Dropdown
                                    overlay={renderMenu(r, options)}
                                    trigger={['click']}
                                    disabled={options.length < 1}
                                    overlayClassName="status-arrangement"
                                >
                                    <span className="body2 btn-choose d-flex align-items-center">
                                        {'選択'}
                                        <ArrowDropDownIcon className="btn-choose-dropdown" />
                                    </span>
                                </Dropdown>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick, false);
    }, []);

    useEffect(() => {
        // build query
        applyFilters && applyFilters(filters);
    }, [filters]);

    return (
        <>
            <Dropdown overlay={renderContainer()} visible={visible}>
                <TdtButton
                    icon={<SortIcon className={emptyFilter ? 'empty-filter-icon' : ''} />}
                    buttonStyle="plain"
                    className="f-14-700"
                    onClick={() => setVisible(!visible)}
                >
                    {emptyFilter ? 'フィルタを追加' : 'フィルタを編集'}
                </TdtButton>
            </Dropdown>
            <div className="filter-render-tags mt-8">{renderFilterTags()}</div>
        </>
    );
};

export default TdtFilter;

TdtFilter.propTypes = {
    rows: PropTypes.array,
    applyFilters: PropTypes.func,
};
