import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ProgramBlock from 'pages/client/reservation/ProgramBlock';
import Routes from 'routes';
import { ProgramPropTypes } from 'constants/proptypes.constant';
import history from 'helpers/history.helper';
import ReservationItem from 'pages/client/reservation/ReservationItem';
import {
    activeReservationsSelector,
    getReservablePrograms,
    getReservations,
    pastReservationsSelector,
} from 'pages/client/reservation/reservation.slice';
import NoData from 'components/NoData';
import TdtPullable from 'components/TdtPullable';
import { Button } from 'antd';
import { ArrowRightIcon, InfoIcon } from 'components/SvgIcon';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import { isMobileActive } from 'helpers/mobile.helper';
import ProgramCategoryEnum from 'enums/program_category.enum';

const mapState = state => ({
    verified: state.auth.isVerified,
    reservations: activeReservationsSelector(state.client.reservation),
    pastReservations: pastReservationsSelector(state.client.reservation),
    programs: Object.values(state.client.reservation.programs),
});
const mapDispatch = { getReservations, getReservablePrograms };
const Reservation = ({
    verified,
    reservations,
    programs,
    getReservations,
    getReservablePrograms,
    pastReservations,
}) => {
    const { t } = useTranslation();

    const [isSendingEmail, setIsSendingEmail] = useState(false);
    useEffect(() => {
        getReservations();
        getReservablePrograms();
    }, []);

    const resendVerifiedEmail = async () => {
        setIsSendingEmail(true);
        const response = await api.get(APP_URLS.RESEND_EMAIL);
        if (response) {
            showNormal('', t('message:Resent the email'), 5);
            setIsSendingEmail(false);
        }
    };

    const sortPrograms = programs => {
        return programs.sort((a, b) =>
            a.program.display_order > b.program.display_order
                ? -1
                : a.program.display_order < b.program.display_order
                ? 1
                : 0
        );
    };

    const filterProgramsByCategory = isComprehensive => {
        const ev = ProgramCategoryEnum.COMPREHENSIVE_PROGRAM.value;
        return programs.filter(p => (isComprehensive ? p.program.category === ev : p.program.category !== ev));
    };

    const comprehensivePrograms = filterProgramsByCategory(true);
    const thematicPrograms = filterProgramsByCategory(false);

    return (
        <>
            <div className="reservation-head-page">
                <h1 className="page-title mb-24 mt-48">{t('Consultation reservation')}</h1>
                <Button
                    type="link"
                    className="fs-16 fw-b"
                    target={isMobileActive() ? '_top' : '_blank'}
                    onClick={() => {
                        history.push(Routes.private.CLIENT_PAST_RESERVATION.path);
                    }}
                    disabled={!pastReservations || pastReservations.length < 1}
                >
                    <span className="text-decoration-none">{t('Reservation history')}</span>
                    <ArrowRightIcon size={24} />
                </Button>
            </div>

            <TdtPullable
                onRefresh={() => {
                    getReservations();
                    getReservablePrograms();
                }}
            />

            {!verified && (
                <div className="verify-email-container mb-24">
                    <span className="color-blg-60 verify-email-info-icon">
                        <InfoIcon size={20} />
                    </span>
                    <div className="subtitle1 mb-8">
                        {t('message:Title_Account authentication is required before booking')}
                    </div>
                    <div className="body1 mb-8 color-blg-60">
                        {t('message:Click the link contained in the sent authentication email and authenticate.')}
                        <br />
                        {t(
                            'message:Authentication will complete the identity verification of your account and allow you to make reservations.'
                        )}
                    </div>
                    <Button type="primary" onClick={resendVerifiedEmail} loading={isSendingEmail}>
                        {t('Resend email')}
                    </Button>
                </div>
            )}

            {reservations.length > 0 && (
                <>
                    <p className="fs-20 reservation-title">{t('Latest reservation list')}</p>
                    <div className="mt-16 mb-72 reservation-item-container">
                        {reservations.map(reservation => (
                            <ReservationItem key={reservation.id} data={reservation} />
                        ))}
                    </div>
                </>
            )}

            {comprehensivePrograms.length > 0 && (
                <div>
                    <p className="fs-20 reservation-title">{t('For the first time')}</p>
                    <div className="d-flex reservation-program mb-24">
                        {sortPrograms(comprehensivePrograms).map(program => (
                            <ProgramBlock
                                key={program.id}
                                verified={verified && !program.company.is_stage_stopped_end}
                                description={program.program.description}
                                name={program.program.name}
                                programs={program}
                                programsDetail={program.program}
                                hasTooltipVerify={!verified}
                                isFirstTime={false}
                            />
                        ))}
                    </div>
                </div>
            )}

            {thematicPrograms.length > 0 && (
                <div>
                    <p className="fs-20 reservation-title">{t('enum:Thematic program')}</p>
                    <div className="d-flex reservation-program mb-24">
                        {sortPrograms(thematicPrograms).map(program => (
                            <ProgramBlock
                                key={program.id}
                                verified={verified && !program.company.is_stage_stopped_end}
                                description={program.program.description}
                                name={program.program.name}
                                programs={program}
                                programsDetail={program.program}
                                hasTooltipVerify={!verified}
                                isFirstTime={false}
                            />
                        ))}
                    </div>
                </div>
            )}

            {reservations.length === 0 && programs.length === 0 && <NoData />}
        </>
    );
};

export default connect(mapState, mapDispatch)(Reservation);

Reservation.propTypes = {
    verified: PropTypes.bool.isRequired,
    reservations: PropTypes.array,
    programs: PropTypes.arrayOf(ProgramPropTypes),
    getReservations: PropTypes.func.isRequired,
    getReservablePrograms: PropTypes.func.isRequired,
};
