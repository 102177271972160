import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Row, Col, Image, Typography, Avatar } from 'antd';
import { EditIcon, HeartIcon, ImageIcon, PressureIcon, WeightIcon } from 'components/SvgIcon';
import history from 'helpers/history.helper';
import Routes from 'routes';
import noData from 'assets/images/noData.png';
import UpdateListModal from 'pages/client/health_data/UpdateListModal';
import CreateWeightModal from 'pages/client/health_data/CreateWeightModal';
import { getData, setSorter } from 'pages/client/health_data/health_data.slice';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BaseStatePropShape, UserPropTypes } from 'constants/proptypes.constant';
import CreateHeartBeatModal from 'pages/client/health_data/CreateHeartBeatModal';
import CreatePressureModal from 'pages/client/health_data/CreatePressureModal';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import HealthTypeEnum from 'enums/health_type.enum';
import { dateFormatForHealth } from 'helpers/date.helper';
import { setUser } from 'pages/auth/auth.slice';
import CreateImageModal from 'pages/client/health_data/CreateImageModal';
import moment from 'moment';
import { showNormal } from 'helpers/notification.helper';
import Loader from 'components/Loader';
import ReservationStatus from 'enums/reservation_status.enum';

const mapState = state => ({
    healthDataState: state.client.health_data,
    user: state.auth.user,
});
const mapDispatch = { getData, setSorter, setUser };
const HealthData = ({ user, setUser }) => {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState({
        list: false,
        weight: false,
        heart_beat: false,
        pressure: false,
        image: false,
    });

    const showListModal = () =>
        setModalVisible({ list: true, weight: false, heart_beat: false, pressure: false, image: false });
    const showWeightModal = () =>
        setModalVisible({ weight: true, list: false, heart_beat: false, pressure: false, image: false });
    const showHeartBeatModal = () =>
        setModalVisible({ heart_beat: true, pressure: false, image: false, list: false, weight: false });
    const showPressureModal = () =>
        setModalVisible({ pressure: true, image: false, list: false, weight: false, heart_beat: false });
    const showImageModal = () =>
        setModalVisible({ image: true, list: false, weight: false, heart_beat: false, pressure: false });
    const closeModal = () =>
        setModalVisible({ list: false, weight: false, heart_beat: false, pressure: false, image: false });

    const healthShowKeys = (user.health_display_settings || '').split(',');
    const [value, setValue] = useState({ created_at: moment.utc() });
    const [loading, setLoading] = useState(true);
    const [weight, setWeight] = useState();
    const [heartBeat, setHeartBeat] = useState();
    const [pressure, setPressure] = useState();
    const [images, setImages] = useState();
    const isStateEnd = user.employee_company[0].state === ReservationStatus.END.value;

    const getLastImages = async () => {
        const ImagesResponse = await api.get(APP_URLS.CLIENT_HEALTH_DATA_LAST_IMAGE);
        setImages(ImagesResponse);
        setLoading(false);
    };

    useEffect(() => {
        getLastImages();
    }, []);

    const getHealthPerType = async () => {
        const weightResponse = await api.get(
            APP_URLS.CLIENT_HEALTH_DATA_BY_TYPE.replace(':typeId', HealthTypeEnum.WEIGHT.value)
        );
        const heartBeatResponse = await api.get(
            APP_URLS.CLIENT_HEALTH_DATA_BY_TYPE.replace(':typeId', HealthTypeEnum.HEART_BEAT.value)
        );
        const pressureResponse = await api.get(
            APP_URLS.CLIENT_HEALTH_DATA_BY_TYPE.replace(':typeId', HealthTypeEnum.PRESSURE.value)
        );
        setWeight(weightResponse);
        setHeartBeat(heartBeatResponse);
        setPressure(pressureResponse);
        setLoading(false);
    };

    useEffect(() => {
        getHealthPerType();
    }, []);

    const updateUserHealthSettings = async value => {
        const response = await api.patch(APP_URLS.CLIENT_UPDATE_HEALTH_DISPLAY_SETTING.replace(':id', user.id), {
            health_display_settings: value || ',',
        });
        if (response) {
            setUser({ ...user, health_display_settings: value });
            showNormal('', t('message:Data item has been saved'), 3);
        }
    };

    return (
        <>
            <Row align={'middle'} justify="space-between" className="health-data-head-row">
                <Col>
                    <h1 className="health-data-page-title mb-0">{t('Health data')}</h1>
                </Col>
                <Col>
                    <Button
                        icon={<EditIcon />}
                        className="fw-b double-btn edit-list-btn"
                        onClick={() => {
                            showListModal();
                        }}
                    >
                        {t('Data item settings')}
                    </Button>
                </Col>
                <UpdateListModal
                    visible={modalVisible.list}
                    onClose={() => closeModal()}
                    initialValue={healthShowKeys}
                    onUpdate={value => updateUserHealthSettings(value.join(','))}
                />
            </Row>
            <p className="health-data-title paragraph mb-32 fs-14">
                健康データを記録するとカウンセラーに共有され、データに基づいた質の高いアドバイスを受けられます
            </p>
            {!healthShowKeys[healthShowKeys.length - 1] ? (
                <>
                    <Row justify="center">
                        <Col>
                            <Image className="mt-98" src={noData} preview={false} />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col span={24}>
                            <Typography className="description-no-data paragraph mt-32 mb-32 ">
                                表示するデータ項目を選択して、データを入力しましょう
                            </Typography>
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Button
                            icon={<EditIcon />}
                            className="fw-b single-btn"
                            type="primary"
                            onClick={() => {
                                showListModal();
                            }}
                        >
                            {t('Data item settings')}
                        </Button>
                    </Row>
                </>
            ) : null}
            {/*list data*/}
            {loading ? (
                <>
                    <Row justify={'center'} align={'middle'}>
                        <Col>
                            <Loader />
                        </Col>
                    </Row>
                </>
            ) : (
                <>
                    {healthShowKeys.includes('weight') && (
                        <Row className="health-data-row" align="middle">
                            <Col className="health-data-col-center" xs={4} md={4}>
                                <WeightIcon size={48} />
                                <Typography className="pl-18 text-on-surface-high">{t('Body weight')}</Typography>
                            </Col>
                            <Col xs={16} md={14}>
                                <Typography className="health-data-numbers">
                                    {weight && weight.value ? Number(weight.value).toFixed(1) : '-'}
                                    {weight && weight.value ? (
                                        <span className="pl-4 fs-16 date-time-typo">kg</span>
                                    ) : (
                                        ''
                                    )}
                                </Typography>
                                <Typography className="health-data-date date-time-typo ">
                                    {dateFormatForHealth(weight && weight.created_at)}
                                </Typography>
                            </Col>
                            <Col className="health-data-col-flex-end" xs={24} md={6}>
                                {weight && weight.value ? (
                                    <Button
                                        className="mr-16 fw-b health-data-history-btn double-btn"
                                        onClick={() => history.push(Routes.private.WEIGHT_CHART.path)}
                                    >
                                        {t('History')}
                                    </Button>
                                ) : null}

                                {!isStateEnd && (
                                    <Button
                                        className={
                                            weight && weight.value
                                                ? 'fw-b health-data-input-btn double-btn mr-4'
                                                : 'fw-b health-data-input-btn double-btn'
                                        }
                                        type="primary"
                                        onClick={() => {
                                            showWeightModal();
                                        }}
                                    >
                                        {t('input')}
                                    </Button>
                                )}
                                <CreateWeightModal
                                    visible={modalVisible.weight}
                                    onClose={() => {
                                        closeModal();
                                        setValue({ created_at: moment.utc() });
                                    }}
                                    value={value}
                                    setValue={setValue}
                                    getHealthPerType={getHealthPerType}
                                    isHealthList={true}
                                />
                            </Col>
                        </Row>
                    )}
                    {healthShowKeys.includes('heart_beat') && (
                        <Row className="health-data-row" align="middle">
                            <Col className="health-data-col-center" xs={4} md={4}>
                                <HeartIcon size={48} />
                                <Typography className="pl-18 text-on-surface-high">{t('Heart beat')}</Typography>
                            </Col>
                            <Col xs={16} md={14}>
                                <Typography className="health-data-numbers">
                                    {(heartBeat && heartBeat.value) || '-'}
                                    {heartBeat && heartBeat.value ? <span>-</span> : ''}
                                    {(heartBeat && heartBeat.ext_value) || ''}
                                    {heartBeat && heartBeat.value ? (
                                        <span className="pl-4 fs-16 date-time-typo">拍/分</span>
                                    ) : (
                                        ''
                                    )}
                                </Typography>
                                <Typography className="health-data-date  date-time-typo">
                                    {dateFormatForHealth(heartBeat && heartBeat.created_at)}
                                </Typography>
                            </Col>
                            <Col className="health-data-col-flex-end" xs={24} md={6}>
                                {heartBeat && heartBeat.value ? (
                                    <Button
                                        className="mr-16 fw-b health-data-history-btn double-btn"
                                        onClick={() => history.push(Routes.private.HEART_BEAT_CHART.path)}
                                    >
                                        {t('History')}
                                    </Button>
                                ) : null}
                                {!isStateEnd && (
                                    <Button
                                        className={
                                            heartBeat && heartBeat.value
                                                ? 'fw-b health-data-input-btn double-btn mr-4'
                                                : 'fw-b health-data-input-btn double-btn'
                                        }
                                        type="primary"
                                        onClick={() => {
                                            showHeartBeatModal();
                                        }}
                                    >
                                        {t('input')}
                                    </Button>
                                )}
                                <CreateHeartBeatModal
                                    visible={modalVisible.heart_beat}
                                    onClose={() => {
                                        closeModal();
                                        setValue({ created_at: moment.utc() });
                                    }}
                                    value={value}
                                    setValue={setValue}
                                    getHealthPerType={getHealthPerType}
                                    isHealthList={true}
                                />
                            </Col>
                        </Row>
                    )}
                    {healthShowKeys.includes('pressure') && (
                        <Row className="health-data-row" align="middle">
                            <Col className="health-data-col-center" xs={4} md={4}>
                                <PressureIcon size={48} />
                                <Typography className="pl-18 text-on-surface-high">{t('Blood Pressure')}</Typography>
                            </Col>
                            <Col xs={16} md={14}>
                                <Typography className="health-data-numbers">
                                    {(pressure && pressure.value) || '-'}
                                    {pressure && pressure.value ? <span>/</span> : ''}
                                    {(pressure && pressure.ext_value) || ''}
                                    {pressure && pressure.value ? <span className="pl-4 fs-16">mmHg</span> : ''}
                                </Typography>
                                <Typography className="">
                                    {dateFormatForHealth(pressure && pressure.created_at)}
                                </Typography>
                            </Col>
                            <Col className="health-data-col-flex-end" xs={24} md={6}>
                                {pressure && pressure.value ? (
                                    <Button
                                        className="mr-16 fw-b health-data-history-btn double-btn"
                                        onClick={() => history.push(Routes.private.PRESSURE_CHART.path)}
                                    >
                                        {t('History')}
                                    </Button>
                                ) : null}
                                {!isStateEnd && (
                                    <Button
                                        className={
                                            pressure && pressure.value
                                                ? 'fw-b health-data-input-btn double-btn mr-4'
                                                : 'fw-b health-data-input-btn double-btn'
                                        }
                                        type="primary"
                                        onClick={() => {
                                            showPressureModal();
                                        }}
                                    >
                                        {t('input')}
                                    </Button>
                                )}
                                <CreatePressureModal
                                    visible={modalVisible.pressure}
                                    onClose={() => {
                                        closeModal();
                                        setValue({ created_at: moment.utc() });
                                    }}
                                    value={value}
                                    setValue={setValue}
                                    getHealthPerType={getHealthPerType}
                                    isHealthList={true}
                                />
                            </Col>
                        </Row>
                    )}
                    {healthShowKeys.includes('images') && (
                        <Row className="health-data-row" align="middle">
                            <Col className="health-data-col-center" xs={4} md={4}>
                                <ImageIcon size={48} />
                                <Typography className="pl-18 text-on-surface-high">{t('Photo')}</Typography>
                            </Col>
                            <Col xs={20} md={14}>
                                {images.map(i => {
                                    return (
                                        <>
                                            {/*<div className="list-images">*/}
                                            <Avatar
                                                className="mr-16 mt-16 health-data-list-images"
                                                shape="square"
                                                size={64}
                                                key={i.id}
                                                src={`${APP_URLS.STATIC_BASE_URL}/${i.url}`}
                                            />
                                            {/*</div>*/}
                                        </>
                                    );
                                })}
                                <Typography className="mt-8 mb-8">
                                    {dateFormatForHealth(images.length > 0 && images[0].created_at)}
                                </Typography>
                            </Col>
                            <Col className="health-data-col-flex-end" xs={24} md={6}>
                                {images.length > 0 ? (
                                    <Button
                                        className="mr-16 fw-b health-data-history-btn double-btn"
                                        onClick={() => history.push(Routes.private.PHOTO_TAB_LIST.path)}
                                    >
                                        {t('History')}
                                    </Button>
                                ) : null}
                                {!isStateEnd && (
                                    <Button
                                        className="fw-b health-data-input-btn double-btn"
                                        type="primary"
                                        onClick={() => {
                                            showImageModal();
                                            setValue({ created_at: moment.utc(), category: 0 });
                                        }}
                                    >
                                        {t('input')}
                                    </Button>
                                )}
                                <CreateImageModal
                                    visible={modalVisible.image}
                                    onClose={() => {
                                        closeModal();
                                        setValue({ created_at: moment.utc() });
                                    }}
                                    value={value}
                                    setValue={setValue}
                                    getImages={getLastImages}
                                    setLoading={setLoading}
                                />
                            </Col>
                        </Row>
                    )}
                </>
            )}
        </>
    );
};

export default connect(mapState, mapDispatch)(HealthData);

HealthData.propTypes = {
    healthDataState: PropTypes.shape(
        Object.assign({}, BaseStatePropShape, {
            data: PropTypes.arrayOf(UserPropTypes).isRequired,
        })
    ),
    getData: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
    user: PropTypes.any,
    setUser: PropTypes.func.isRequired,
};
