import React from 'react';
import { Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import ActionBtnInModal from 'components/ActionBtnInModal';
import { dateToStr } from 'helpers/date.helper';

const TransmissionAndSendModal = ({ visible, onClose, onUpdate, record, hasSetDate }) => {
    const { t } = useTranslation();

    if (!record) {
        return <></>;
    }
    return (
        <Modal
            title={t('Delete the scheduled automatic transmission date and send')}
            visible={visible}
            width={544}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            {hasSetDate ? (
                <div className="mb-16 f16-24">
                    {`配信予定のセッションのお知らせはすでに自動送信の予定があります。`}
                    <br />
                    {`設定されている自動送信を中止し、今すぐにお知らせを送信しますか？`}
                </div>
            ) : (
                <div className="mb-16 f16-24">
                    {`配信予定のセッションのお知らせはすでに自動送信の予定があります。`}
                    <br />
                    {`設定されている自動送信を中止し、新しい配信日時を予約しますか？`}
                </div>
            )}
            <div className="fw-b f-14-18 mb-8">{'設定されている自動送信予定日'}</div>
            <div className="mb-16 pt-12 pb-12 pl-16 pr-16 bg-gray-5" style={{ borderRadius: 4 }}>
                {record.transmission_date && record.transmission_time
                    ? `${dateToStr(record.transmission_date, 'YYYY/M/D')} ${record.transmission_time}`
                    : ''}
            </div>
            <ActionBtnInModal
                onClose={onClose}
                onUpdate={onUpdate}
                confirmText={hasSetDate ? t('Delete and send') : '中止して予約'}
                isNotDelete={true}
                //manualClose={true}
            />
        </Modal>
    );
};

export default TransmissionAndSendModal;
