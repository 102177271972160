import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Typography } from 'antd';
import { CheckIcon, CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/DataTable';
import ActionBtnInDistributionModal from 'components/ActionBtnInDistributionModal';

const CorrectCSVUploadModal = ({ visible, onClose, onUpdate, csvData }) => {
    const { t } = useTranslation();
    const { emails = [] } = csvData;

    const columns = [
        {
            title: 'メールアドレス',
            dataIndex: 'email',
            sorter: (a, b) => {
                return a.email.localeCompare(b.email);
            },
        },
    ];
    return (
        <>
            <Modal
                title={t('field:Target registration')}
                visible={visible}
                width={640}
                footer={
                    <div className=" d-flex align-items-center justify-content-space-between pt-6 pb-6 fw-400 fs-16 space">
                        <div>
                            <span>{'選択人数：'}</span>
                            <span className="fw-700 fs-20">{emails.length}</span>
                            <span className="ml-4">{'人'}</span>
                        </div>
                        <ActionBtnInDistributionModal onClose={onClose} onUpdate={() => onUpdate(emails)} />
                    </div>
                }
                closeIcon={<CloseIcon />}
                onCancel={() => onClose()}
            >
                <>
                    <div
                        className="mt-8 d-flex primary-color bg-aqua-spring align-items-center"
                        style={{ width: 608, height: 48, borderRadius: 4 }}
                    >
                        <CheckIcon className="ml-20 color-dlg" />
                        <Typography className="ml-10 fw-400 fs-16">{'アップロードに成功しました'}</Typography>
                    </div>
                    <DataTable
                        className="mt-16 collapse-table table-border-lighter"
                        columns={columns}
                        data={emails}
                        noDataText={' '}
                        //yScroll={{ y: 260 }}
                        onTableChange={() => {}}
                    />
                </>
            </Modal>
        </>
    );
};

CorrectCSVUploadModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default CorrectCSVUploadModal;
