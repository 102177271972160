import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'antd';
import DataTable from 'components/DataTable';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { dateToStr } from 'helpers/date.helper';
import { imageUrlAdapter } from 'helpers/image_url.helper';
import { CheckmarkCheckedIcon, CheckmarkEmptyIcon } from 'components/SvgIcon';
import NoImage from 'components/NoImage';
import CommonSessionChooseCompanyEnum from 'enums/common_session_choose_company.enum';
import { getMasterData } from 'pages/admin/feature/common_session.slice';
import { connect } from 'react-redux';
import ProvideCompaniesModal from 'pages/admin/feature/modal/ProvideCompaniesModal';

const mapState = state => ({
    master: state.admin.commonSession.master,
});
const mapDispatch = { getMasterData };

const NewSessionTable = ({ selectedRecord, setSelectedRecord, selectFromId, master, getMasterData, isRefSeminar }) => {
    const { t } = useTranslation();

    const [data, setData] = useState([]);
    const getNewPrograms = async () => {
        const res = await api.get(APP_URLS.ADMIN_EMAILS_LIST_NEW_SESSION, {
            order_by: '-date',
        });
        if (res && res.data && res.data.length > 0) {
            setData(res.data);
            // set selected from
            if (selectFromId) {
                const found = res.data.find(d => d.id === Number(selectFromId));
                setSelectedRecord(found);
            }
        }
    };

    const [modalVisible, setModalVisible] = useState({
        record: null,
        provide: false,
    });
    const closeModal = () =>
        setModalVisible({
            record: null,
            provide: false,
        });
    const showProvideModal = r => setModalVisible({ ...modalVisible, provide: true, record: r });

    const columns = [
        {
            title: t('Start date'),
            dataIndex: 'start_date',
            sorter: (r1, r2) => {
                let a = r1.date;
                let b = r2.date;
                return a > b ? 1 : a < b ? -1 : 0;
            },
            width: 200,
            render: (v, r) => (
                <span className="d-flex align-items-center">
                    {isSelectedRow(r) ? (
                        <CheckmarkCheckedIcon className="mr-10 color-blue-100" />
                    ) : (
                        <CheckmarkEmptyIcon className="mr-10 color-blg-30" />
                    )}
                    <span>
                        {r.date && r.start_time ? `${dateToStr(r.date, 'YYYY/M/D(ddd)')} ${r.start_time}-` : ''}
                    </span>
                </span>
            ),
        },
        {
            title: t('Name'),
            dataIndex: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            render: (v, r) => {
                const mediaUrl = `${APP_URLS.STATIC_BASE_URL}/${r.cover_img}`;
                return (
                    <span className="d-flex align-items-center">
                        <span className="mr-2 pr-8 pt-8 pb-8 d-flex">
                            {r.cover_img ? (
                                <Image width={46} height={46} preview={false} src={imageUrlAdapter(mediaUrl, 64)} />
                            ) : (
                                <NoImage />
                            )}
                        </span>
                        <span>{r.name}</span>
                    </span>
                );
            },
        },
        {
            title: t('Provide companies'),
            dataIndex: 'companies',
            width: 120,
            render: (companies, record) => {
                const typeIsAllCompany = record.type === CommonSessionChooseCompanyEnum.ALL_COMPANY.value;

                const total = companies.length;
                const show = 5;
                return (
                    <>
                        <div
                            className="circle-listed"
                            onClick={e => {
                                e.stopPropagation();
                                showProvideModal(typeIsAllCompany ? master : record);
                            }}
                        >
                            {typeIsAllCompany ? (
                                <span className="body2 color-blue-100">{t('All companies')}</span>
                            ) : (
                                <>
                                    {companies.map((company, index) => {
                                        if (index > show - 1) {
                                            return null;
                                        }
                                        return (
                                            <span
                                                key={`seminar${record.id}_${company.id}_${index}`}
                                                className={`circle-list-item item-${index + 1}`}
                                            >
                                                {index === show - 1 && total > show
                                                    ? `+${total - show + 1}`
                                                    : company.name.substr(0, 2)}
                                            </span>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            title: t('Scheduled transmission date'),
            dataIndex: 'transmission_date',
            width: 194,
            render: (v, r) =>
                r.sent_at
                    ? '送信済み'
                    : r.transmission_date && r.transmission_time
                    ? `${r.transmission_date} ${r.transmission_time}`
                    : '未送信',
        },
    ];

    useEffect(() => {
        getNewPrograms().then(() => {});
        if (master.loading) {
            getMasterData();
        }
    }, []);

    const isSelectedRow = record => selectedRecord && selectedRecord.id === record.id;

    return (
        <>
            <DataTable
                className="collapse-table mt-8 target-program-table"
                columns={columns}
                data={data}
                onRow={record => ({
                    onClick: () => {
                        !isRefSeminar && setSelectedRecord(isSelectedRow(record) ? null : record);
                    },
                })}
                rowClassName={record => (isSelectedRow(record) ? 'checked-row' : '')}
                onTableChange={() => {}}
            />
            <ProvideCompaniesModal
                onClose={() => closeModal()}
                visible={modalVisible.provide}
                record={modalVisible.record}
            />
        </>
    );
};

export default connect(mapState, mapDispatch)(NewSessionTable);
