import { createSlice } from '@reduxjs/toolkit';
import { APP_URLS } from 'constants/url.constant';
import { baseGetData, baseInitialState } from 'helpers/slice.helper';

const name = 'clientSeminar';

const initialState = {
    publicSeminarState: { ...baseInitialState },
    endSeminarState: { ...baseInitialState },
    loading: true,
};

const seminarSlice = createSlice({
    name,
    initialState: initialState,
    reducers: {
        setPublicSeminars(state, action) {
            state.publicSeminarState = action.payload;
        },
        setEndSeminars(state, action) {
            state.endSeminarState = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
    },
});

export const { setPublicSeminars, setEndSeminars, setLoading } = seminarSlice.actions;

export default seminarSlice.reducer;

export const getPublicSeminars =
    ({ ...props }) =>
    async dispatch => {
        dispatch(setLoading(true));
        const data = await baseGetData({
            url: APP_URLS.CLIENT_SEMINARS_LIST,
            ...props,
            sorter: {
                field: 'date',
                order: 'ascend',
            },
        });

        if (data) {
            dispatch(setPublicSeminars(data));
        }
        dispatch(setLoading(false));
    };

export const getEndSeminars =
    ({ ...props }) =>
    async dispatch => {
        dispatch(setLoading(true));
        const data = await baseGetData({
            url: APP_URLS.CLIENT_SEMINARS_LIST,
            ...props,
            sorter: {
                field: 'date',
                order: 'descend',
            },
        });

        if (data) {
            dispatch(setEndSeminars(data));
        }
        dispatch(setLoading(false));
    };
