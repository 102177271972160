import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { verifyEmail } from 'pages/auth/auth.slice';
import { useTranslation } from 'react-i18next';

const mapState = state => ({
    registerAccount: state.auth.registerAccount,
});

const mapDispatch = { verifyEmail };

const RegisterEmailVerify = ({ verifyEmail }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const queryString = location?.search;

    useEffect(() => {
        verifyEmail(queryString, t('message:Authentication is complete'));
    }, [queryString]);

    return <></>;
};

export default connect(mapState, mapDispatch)(RegisterEmailVerify);

RegisterEmailVerify.propTypes = {
    registerAccount: PropTypes.any.isRequired,
    verifyEmail: PropTypes.func.isRequired,
};
