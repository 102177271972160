import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button } from 'antd';
import { dateFormatExceptYear } from 'helpers/date.helper';
import { ArrowNextDisabledIcon, ArrowNextIcon, ArrowPreviousIcon } from 'components/SvgIcon';
import moment from 'moment';

const DateChangeInModalComponent = ({ value, setValue }) => {
    const handlePreviousDay = () => {
        const tempDate = moment(value.created_at.format()).subtract(1, 'day').utc();
        setValue({ ...value, created_at: tempDate });
    };
    const handleNextDay = () => {
        const tempDate = moment(value.created_at.format()).add(1, 'day').utc();
        setValue({ ...value, created_at: tempDate });
    };
    const toDay = moment().subtract(1, 'day').format();
    return (
        <>
            <Row justify="center">
                <Col>
                    <Button
                        className="pl-4"
                        type="link"
                        icon={<ArrowPreviousIcon size={14} />}
                        onClick={handlePreviousDay}
                    />
                    <span>{dateFormatExceptYear(value.created_at)}</span>
                    <Button
                        className="pl-14"
                        type="link"
                        icon={
                            toDay <= value.created_at.format() ? (
                                <ArrowNextDisabledIcon size={14} />
                            ) : (
                                <ArrowNextIcon size={14} />
                            )
                        }
                        disabled={toDay <= value.created_at.format()}
                        onClick={handleNextDay}
                    />
                </Col>
            </Row>
        </>
    );
};

export default DateChangeInModalComponent;

DateChangeInModalComponent.propTypes = {
    value: PropTypes.any,
    setValue: PropTypes.func,
};
