import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import QuestionCommonFields from 'pages/admin/interview_sheet/questions/QuestionCommonFields';
import { AddIcon } from 'components/SvgIcon';
import { randomId } from 'helpers/common.helper';
import FloatBarQuestion from './FloatBarQuestion';
import HeaderQuestion from './HeaderQuestion';
import DndOptionsTable from './DndOptionsTable';
import { useTranslation } from 'react-i18next';

const SelectQuestion = ({
    //isMultiple,
    className,
    id,
    question,
    addition,
    options,
    optionsOrder,
    setQuestion,
    setAddition,
    addOption,
    moveOption,
    updateOption,
    removeOption,
    no,
    type,
    setType,
    displayColumns,
    setDisplayColumns,
    isRequired,
    setIsRequired,
    flattenQuestions,
    setRefs,
}) => {
    const { t } = useTranslation();

    const hasOther = !!optionsOrder.find(key => options[key] && options[key].type === 'other');

    return (
        <div className={`question-block ${className ? className : ''}`}>
            <HeaderQuestion no={no} />
            <QuestionCommonFields
                id={id}
                question={question}
                addition={addition}
                setAddition={setAddition}
                setQuestion={setQuestion}
                type={type}
                setType={setType}
                displayColumns={displayColumns}
                setDisplayColumns={setDisplayColumns}
            />

            <DndOptionsTable
                questionId={id}
                options={options}
                optionsOrder={optionsOrder}
                type={type}
                onOrderChange={(srcIndex, desIndex) => moveOption({ srcIndex, desIndex, questionId: id })}
                updateOption={updateOption}
                removeOption={removeOption}
                displayColumns={displayColumns}
                flattenQuestions={flattenQuestions}
                no={no}
                setRefs={setRefs}
                addOption={addOption}
            />

            <div className="item mt-20">
                <Button
                    className="fw-b"
                    icon={<AddIcon />}
                    onClick={() => {
                        addOption({
                            questionId: id,
                            optionId: randomId(),
                            data: {
                                value: '',
                            },
                        });
                    }}
                >
                    {'選択肢を追加'}
                </Button>
                {!hasOther && (
                    <Button
                        className="fw-b ml-8"
                        icon={<AddIcon />}
                        onClick={() => {
                            addOption({
                                questionId: id,
                                optionId: randomId(),
                                data: {
                                    value: 'その他',
                                    type: 'other',
                                },
                            });
                        }}
                    >
                        {t('Add others')}
                    </Button>
                )}
            </div>
            <FloatBarQuestion id={id} isRequired={isRequired} setIsRequired={setIsRequired} />
        </div>
    );
};

export default SelectQuestion;

SelectQuestion.propTypes = {
    className: PropTypes.string,
    id: PropTypes.any.isRequired,
    question: PropTypes.string,
    addition: PropTypes.string,
    setQuestion: PropTypes.func.isRequired,
    setAddition: PropTypes.func.isRequired,
    addOption: PropTypes.func.isRequired,
    no: PropTypes.number,
    type: PropTypes.any,
    setType: PropTypes.func,
};
