import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Prompt, useLocation } from 'react-router-dom';
import { Modal } from 'antd';
import TdtButton from 'components/TdtButton';
import history from 'helpers/history.helper';

const RouterPrompt = ({ title, body, handleSave, isValueChange, leaveMode = false, discontinuationText = false }) => {
    const { t } = useTranslation();
    const [modalConfirm, setModalConfirm] = useState(false);
    const [lastLocation, setLastLocation] = useState();
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(isValueChange);
    const [navigated, setNavigated] = useState(false);
    const location = useLocation();

    const handleBlockedNavigation = nextLocation => {
        setLastLocation(nextLocation);
        setModalConfirm(true);
        return !isValueChange;
    };

    const handleDoNotSave = () => {
        setModalConfirm(false);
        setShouldBlockNavigation(false);
        setNavigated(true);
    };

    useEffect(() => {
        setShouldBlockNavigation(isValueChange);
    }, [isValueChange]);

    useEffect(() => {
        if (navigated) {
            history.push(lastLocation.pathname + location.search);
        }
    }, [navigated]);

    return (
        <>
            <Prompt when={shouldBlockNavigation} message={location => handleBlockedNavigation(location)} />
            <Modal
                title={title || '保存されていない変更があります'}
                visible={modalConfirm}
                width={400}
                onCancel={() => setModalConfirm(false)}
                className="router-prompt"
                footer={
                    !leaveMode
                        ? [
                              <TdtButton
                                  key="do_not_save"
                                  buttonSize="big"
                                  buttonStyle="plain"
                                  className="fw-b pink-color text-decoration-none"
                                  onClick={() => handleDoNotSave()}
                              >
                                  {t('Discard changes')}
                              </TdtButton>,
                              <TdtButton
                                  key="save"
                                  buttonSize="big"
                                  buttonStyle="plain"
                                  className="fw-b primary-color text-decoration-none"
                                  onClick={() => {
                                      handleSave(lastLocation.pathname).then(rs => {
                                          if (rs !== 'field_error') {
                                              setShouldBlockNavigation(false);
                                              setNavigated(true);
                                          } else {
                                              setModalConfirm(false);
                                          }
                                      });

                                      // setShouldBlockNavigation(false);
                                      // setNavigated(true);
                                      // handleSave(lastLocation.pathname);
                                  }}
                              >
                                  {t('Save')}
                              </TdtButton>,
                          ]
                        : [
                              <TdtButton
                                  key="stay"
                                  buttonSize="big"
                                  buttonStyle="plain"
                                  className="fw-b disable-color text-decoration-none"
                                  onClick={() => setModalConfirm(false)}
                              >
                                  {t('Cancel')}
                              </TdtButton>,
                              <TdtButton
                                  key="leave_without_save"
                                  buttonSize="big"
                                  buttonStyle="plain"
                                  className="fw-b pink-color text-decoration-none"
                                  onClick={() => handleDoNotSave()}
                              >
                                  {discontinuationText ? discontinuationText : t('Discontinuation')}
                              </TdtButton>,
                          ]
                }
            >
                <p className="mb-0">{body || '変更部分を保存してから閉じますか？'}</p>
            </Modal>
        </>
    );
};

export default RouterPrompt;

RouterPrompt.propTypes = {
    title: PropTypes.any,
    body: PropTypes.any,
    handleSave: PropTypes.func,
    isValueChange: PropTypes.bool,
    leaveMode: PropTypes.bool,
};
