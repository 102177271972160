import React from 'react';
import { Typography } from 'antd';

// data = [{text, percent, count}]
const HorizontalBarChart = ({ data }) => {
    // const data = [
    //     {
    //         text: '運動についていけなそう',
    //         percent: 62.5,
    //         count: 32,
    //     },
    //     {
    //         text: '家ではできなそう',
    //         percent: 31.25,
    //         count: 16,
    //     },
    //     {
    //         text: '効果が出るまで長そう',
    //         percent: 31.25,
    //         count: 16,
    //     },
    //     {
    //         text: 'ジムが近所にない',
    //         percent: 12,
    //         count: 8,
    //     },
    //     {
    //         text: '自分には堪え性がないと思う',
    //         percent: 4,
    //         count: 8,
    //     },
    // ];

    // const maxPercent = Math.max(...data.map(d => d.percent)) || 1;
    return (
        <div className="horizontal-bar-chart position-relative">
            <span className="vertical-line" data-percent="0%" style={{ left: 'calc( 124px )' }} />

            <span
                className="vertical-line"
                data-percent="25%"
                style={{ left: 'calc( 124px + ( 100% - 124px - 48px )/4 )' }}
            />
            <span
                className="vertical-line"
                data-percent="50%"
                style={{ left: 'calc( 124px + ( 100% - 124px - 48px) /4 * 2 )' }}
            />
            <span
                className="vertical-line"
                data-percent="75%"
                style={{ left: 'calc( 124px + ( 100% - 124px - 48px) /4 * 3 )' }}
            />
            <span
                className="vertical-line "
                data-percent="100%"
                style={{ left: 'calc( 124px + ( 100% - 124px - 48px) / 4 * 4 )' }}
            />

            {data.map((d, index) => (
                <div key={index} className="d-flex mb-16 align-items-center">
                    <div
                        className="mr-16 fs-12-17 color-blg-80 text-align-right"
                        style={{ width: 108, height: 34, overflow: 'hidden' }}
                    >
                        {d.text}
                    </div>
                    <div className="d-flex flex-1">
                        <span
                            className={`horizontal-bar-chart-percent position-relative ${
                                d.percent < 2 ? 'percent-as-zero' : ''
                            } ${d.isOther ? 'percent-of-other' : ''}`}
                            style={{ width: `calc( ( 100% - 48px ) * ( ${d.percent / 100} )` }}
                        />
                        <span className={'ml-2 mt-4 percent-number align'}>{`${d.percent}% (${d.count})`}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default HorizontalBarChart;
