import moment from 'moment';

moment.locale('ja');

export const dateToStr = (str, format) => (str ? moment(str).format(format || 'M月DD日(ddd) HH:mm') : '');

export const dateFormatForHealth = str => (str ? moment.utc(str).local().format('M月DD日(ddd) HH:mm') : '-');

export const dateFormatExceptHour = str => (str ? moment(str).format('M月DD日(ddd)') : '-');

export const fullDateFormatExceptHour = (str, empty = '-') => (str ? moment(str).format('YYYY年M月DD日(ddd)') : empty);

export const fullDateFormat = str => (str ? moment.utc(str).local().format('YYYY年M月DD日(ddd) HH:mm') : '-');

export const monthFormatExceptDay = str => (str ? moment(str).format('YYYY年M月') : '-');

export const dateFormatExceptYear = str => (str ? moment(str).format('M月DD日(ddd)') : '-');

export const momentToStr = (mm, format = 'YYYY-MM-DD') => mm.format(format);

export const strToMoment = (str, format = 'YYYY-MM-DD') => moment(str, format);

export const dateFormatCreatedAt = str => (str ? moment(str).format('YYYY/MM/DD') : '-');

export const timeFormatCreatedAt = str => (str ? moment(str).format('HH:mm:ss') : '-');

export const dateWithForwardSlash = str => (str ? moment(str).format('YYYY/MM/DD(ddd)') : '-');

export const enWeekDayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const dateTimeToMoment = (date, time) =>
    date ? strToMoment(`${date.format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mm') : null;

export const getDuration = (start, end, format = 'HH:mm', unit = 'minutes') => {
    if (!start || !end) {
        return '';
    }
    return moment(start, format).diff(moment(end, format), unit);
};

export const strPeriodFormat = (date, hour, minute) => {
    hour = hour.toString().padStart(2, '0');
    minute = minute.toString().padStart(2, '0');
    return `${date} ${hour}:${minute}`;
    // return `${date} ${hour > 10 ? hour : '0' + hour}:${minute > 10 ? minute : '0' + minute}`;
};

export const periodToDateTime = (startDate, startHour, startMinute) => {
    if (!startDate.format) {
        startDate = strToMoment(startDate);
    }

    const strDateTime = `${momentToStr(startDate)} ${startHour}:${startMinute}`;

    return strToMoment(strDateTime, 'YYYY-MM-DD HH:mm');
};

export const periodRangeFormatToString = (period, format = 'YYYY年MM月DD日 HH:mm') => {
    const { start_date, start_hour, start_minute, end_date, end_hour, end_minute } = period;
    const start = periodToDateTime(start_date, start_hour, start_minute);
    const end = periodToDateTime(end_date, end_hour, end_minute);
    return `${start.format(format)} ~ ${end.format(format)}`;
};

export const periodFullStartEnd = period => {
    const { start_date, end_date } = period;
    const ends = end_date.split('-');

    if (ends.length < 3) {
        return;
    }

    return `${start_date.replaceAll('-', '/')} - ${start_date.indexOf(ends[0]) < 0 ? ends[0] + '/' : ''}${ends[1]}/${
        ends[2]
    }`;
};

export const checkNowMatchPeriod = (periods, matchOnly = false) => {
    const now = moment();

    const matchPeriod = periods.find(period => {
        const periodStart = periodToDateTime(period.start_date, period.start_hour, period.start_minute);
        const periodEnd = periodToDateTime(period.end_date, period.end_hour, period.end_minute);
        return now.isBetween(periodStart, periodEnd);
    });

    if (matchOnly) {
        return matchPeriod;
    }

    if (!matchPeriod) {
        // find closest after : QUICK FIX: closest before
        let closestAfter;
        for (let i = 0; i < periods.length; i++) {
            const period = periods[i];

            const periodStart = periodToDateTime(period.start_date, period.start_hour, period.start_minute);
            if (now.isAfter(periodStart)) {
                closestAfter = period;
                break;
            }
        }

        if (!closestAfter) {
            return { latestPeriod: periods[periods.length - 1] };
        }

        return { closestAfter };
    }

    return { matchPeriod };
};

export const isLastAnswerInPeriod = (lastAnswer, period) => {
    if (!lastAnswer || !period) {
        return false;
    }

    const periodStart = periodToDateTime(period.start_date, period.start_hour, period.start_minute);
    const periodEnd = periodToDateTime(period.end_date, period.end_hour, period.end_minute);
    const lastAnswerTime = moment(lastAnswer.updated_at);

    return lastAnswerTime.isBetween(periodStart, periodEnd);
}

export const getGraphPeriods = (periods = []) => {
    return periods.map(period => ({
        ...period,
        periodStart: periodToDateTime(period.start_date, period.start_hour, period.start_minute),
        periodEnd: periodToDateTime(period.end_date, period.end_hour, period.end_minute),
        displayPeriodStart: strPeriodFormat(period.start_date, period.start_hour, period.start_minute).replaceAll("-", "/"),
        displayPeriodEnd: strPeriodFormat(period.end_date, period.end_hour, period.end_minute).replaceAll("-", "/"),
    }));
}

export const getMatchGraphPeriod = (graphPeriods = [], timeStr) => {
    const mm = moment(timeStr);

    return graphPeriods.find(graphPeriod => {
        return mm.isBetween(graphPeriod.periodStart, graphPeriod.periodEnd);
    });
}

export const dateToGraphAxis = dateStr => {
    try {
        const arr = dateStr.split('/');
        const year = arr[0];
        let month = arr[1];
        month = month.indexOf('0') === 0 ? month.substr(1) : month;

        const currentYear = moment().get("year");

        if (Number(year) === Number(currentYear)) {
            return `${month}月`;
        }

        return [year.substr(2), `${month}月`];

    } catch (e) {
        return "";
    }
}

export const formatPointValue = str => {
    str = str + '';
    if (str.indexOf) {
        if (str.indexOf(",") > -1) {
            str = str.substr(0, str.indexOf(",") + 2);
        } else if (str.indexOf(".") > -1) {
            str = str.substr(0, str.indexOf(".") + 2);
        }
    }
    return str;
}
