import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Radio } from 'antd';
import RoleEnum from 'enums/role.enum';
import { CloseIcon } from 'components/SvgIcon';

const UpdateRoleModal = ({ visible, onClose, initialValue, onUpdate, counselor }) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const { t } = useTranslation();
    return (
        <Modal
            title={t('Edit role')}
            visible={visible}
            width={320}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <p className="text-gray-color fs-12 mt--16">相談予約がある場合には変更できません</p>
            <Radio.Group
                className="vertical-group"
                value={value}
                onChange={e => {
                    setValue(e.target.value);
                }}
            >
                <Radio value={RoleEnum.DOCTOR.value}>{t(`enum:${RoleEnum.DOCTOR.text}`)}</Radio>
                <Radio
                    className="mb-0"
                    value={RoleEnum.ADMIN_STAFF.value}
                    disabled={counselor && counselor.exist_reservations}
                >
                    {t(`enum:${RoleEnum.ADMIN_STAFF.text}`)}
                </Radio>
                {counselor && counselor.exist_reservations ? (
                    <span className="fs-12 pl-28">予約が入っている間は選択できません</span>
                ) : null}
            </Radio.Group>

            <Button
                type="primary"
                block
                className="mt-16"
                onClick={() => {
                    onUpdate && onUpdate(value);
                    onClose();
                }}
            >
                {t('Save')}
            </Button>
        </Modal>
    );
};

UpdateRoleModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    onUpdate: PropTypes.func.isRequired,
};

export default UpdateRoleModal;
