import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { UserPropTypes } from 'constants/proptypes.constant';
import BackBtn from 'components/BackBtn';
import { showNormal } from 'helpers/notification.helper';
import Loader from 'components/Loader';
import { Button, Checkbox, Col, Form, Row, Select } from 'antd';
import TdtSelect from 'components/TdtSelect';
import FloatBar from 'components/FloatBar';
import { getCounselor, setFilter, updateEmail, updateRole } from 'pages/admin/counselor/counselor_detail.slice';
import {
    getAllCompanies,
    getAllProgramsByCompany,
} from 'pages/admin/counselor/company_program/counselor_company_program.slice';
import { InfoIcon } from 'components/SvgIcon';
import ProgramBookingOption from 'pages/admin/common/program/ProgramBookingOption';
import ReservationFrameList from 'pages/admin/counselor/company_program/ReservationFrameList';
import {
    resetToDefault,
    convertDataToState,
} from 'pages/admin/counselor/company_program/reservable_frame_programs.slice';
import CantCreateModal from 'pages/admin/counselor/company_program/CantCreateModal';
import api from 'helpers/api.helper';
import history from 'helpers/history.helper';
import { APP_URLS } from 'constants/url.constant';
import Routes from 'routes';
import DuplicateReservationSlotsModal from 'pages/admin/counselor/company_program/DuplicateReservationSlotsModal';
import { processOverlap } from 'helpers/common.helper';

const mapState = state => ({
    counselor: state.admin.counselorDetail.counselor,
    counselorCompanyProgram: state.admin.counselorCompanyProgram,
    detailsOrder: state.admin.reservableFramePrograms.detailsOrder,
    details: state.admin.reservableFramePrograms.details,
    cloneDataId: state.admin.reservableFramePrograms.cloneDataId,
    orgRegistered: state.admin.counselorDetail.orgRegistered,
});
const mapDispatch = {
    getAllCompanies,
    getAllProgramsByCompany,
    getCounselor,
    updateRole,
    updateEmail,
    setFilter,
    resetToDefault,
    convertDataToState,
};
const CounselorAddIndividualProgramCompany = ({
    counselor,
    getCounselor,
    getAllCompanies,
    getAllProgramsByCompany,
    counselorCompanyProgram,
    resetToDefault,
    detailsOrder,
    details,
    cloneDataId,
    orgRegistered,
    convertDataToState,
}) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [companySelectedValue, setCompanySelectedValue] = useState(-1);
    const { allCompanies, companyProgramsLoading, companyPrograms } = counselorCompanyProgram;
    const [selectedProgramId, setSelectedProgramId] = useState(null);
    const isValid = detailsOrder.length > 0 && selectedProgramId && selectedProgramId !== -1 && !!companySelectedValue;
    const [modalVisible, setModalVisible] = useState({
        isOverlap: false,
        overlapData: {},
    });
    const showIsOverlapModal = data => setModalVisible({ isOverlap: true, overlapData: data });
    const closeModal = () => setModalVisible({ isOverlap: false, overlapData: {} });
    const [isLoading, setIsLoading] = useState(false);

    const handleCompanySelected = companyId => {
        setCompanySelectedValue(companyId);
        if (companyId > 0) {
            getAllProgramsByCompany(companyId, counselor.id);
        }
        form.setFieldsValue({ company_id: companyId, program_id: null });
        setSelectedProgramId(null);
    };

    useEffect(() => {
        if (!cloneDataId || !orgRegistered || Object.keys(orgRegistered).length < 1) {
            history.push(Routes.private.ADMIN_COUNSELORS_DETAIL.path.replace(':id', id));
        } else {
            const cloneTarget = orgRegistered[cloneDataId];
            const company = cloneTarget[0].company_program.company;
            const companyId = company.id;
            convertDataToState(cloneTarget);
            setCompanySelectedValue(companyId);
            form.setFieldsValue({ company_id: companyId });
            getAllProgramsByCompany(companyId, counselor.id);
            setSelectedProgramId(null);
        }
    }, []);

    useEffect(() => {
        counselor = null;
        getCounselor(id);
        getAllCompanies();

        return () => {
            setIsLoading(false);
            resetToDefault();
        };
    }, [id]);

    useEffect(() => {
        if (counselor && counselor.is_disabled) {
            showNormal('', t('Has been deleted'), 3);
        }
    }, [counselor && counselor.is_disabled]);

    if (!counselor) {
        return <Loader className="loader-center" />;
    }

    const renderCompanyProgram = () => {
        return (
            <TdtSelect
                style={{ width: 320 }}
                size="large"
                className="fs-14 select-gray"
                onChange={v => {
                    setSelectedProgramId(v);
                    form.setFieldsValue({ program_id: v });
                }}
            >
                <Select.Option key="-1" value="-1">
                    {' '}
                </Select.Option>
                {companyPrograms.map(c => (
                    <Select.Option key={c.id} value={c.id}>{`${c.name_mngt}`}</Select.Option>
                ))}
            </TdtSelect>
        );
    };

    const renderBookingOption = () => {
        const selectedProgram = companyPrograms.find(o => o.id === selectedProgramId);
        if (selectedProgram) {
            return <ProgramBookingOption program={selectedProgram} className="mb-24" />;
        }
        return <div className="mb-24" />;
    };

    const submit = async values => {
        values.counselor_id = id;

        const companyProgramId = findCompanyProgramId();
        const response = await api.post(
            APP_URLS.ADMIN_COMPANIES_DETAIL_ADD_COUNSELOR.replace(':id', companySelectedValue).replace(
                ':programId',
                companyProgramId
            ),
            values
        );

        if (response) {
            history.push(Routes.private.ADMIN_COUNSELORS_DETAIL.path.replace(':id', id));
            showNormal('', t('message:Registered company / program'), 3);
        }
    };

    const findCompanyProgramId = () => {
        const curProgram = companyPrograms.find(cp => cp.id === Number(selectedProgramId));
        return curProgram.program_companies[0].id;
    }

    const processOnSubmit = (values, skip) => {
        // map details (reservable_frames)
        let newDetails = [];
        detailsOrder.map(orderKey => newDetails.push(details[orderKey]));

        // find overlap
        const companyProgramId = findCompanyProgramId();
        const existDetails = orgRegistered[companyProgramId] || [];

        const overlapData = processOverlap(newDetails, existDetails);

        if (!skip && overlapData.overlapInfo.length > 0) {
            showIsOverlapModal(overlapData);
        } else {
            setIsLoading(true);
            values.details = overlapData.result;
            submit(values).then(() => {});
        }
    };

    const onSave = () => {
        processOnSubmit(form.getFieldsValue(), true);
    };

    return (
        <div className="self-wrapper d-block px-48">
            <BackBtn label={`${t('Responsible company / program duplication')}`} />

            <Form
                form={form}
                layout="vertical"
                initialValues={{}}
                requiredMark={false}
                className="mt-32"
                onFinish={v => processOnSubmit(v)}
            >
                <Row gutter={16}>
                    <Col span={24} className="mb-8">
                        <Form.Item name="company_id" label={t('Company in charge')}>
                            <TdtSelect
                                style={{ width: 320 }}
                                size="large"
                                className="fs-14 select-gray"
                                onChange={handleCompanySelected}
                                value={companySelectedValue}
                            >
                                <Select.Option key="-1" value="-1">
                                    {' '}
                                </Select.Option>
                                {allCompanies.map(c => (
                                    <Select.Option key={c.id} value={c.id}>{`${c.name}`}</Select.Option>
                                ))}
                            </TdtSelect>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} className="mb-8">
                        <Form.Item name="program_id" label={t('Program in charge')} className="mb-8">
                            {renderCompanyProgram()}
                        </Form.Item>
                        {renderBookingOption()}
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24} className="mb-8">
                        <Form.Item label={t('Reservable frame')} validateTrigger={false}>
                            <ReservationFrameList sessionTime={60} />
                        </Form.Item>
                    </Col>
                </Row>
                <FloatBar>
                    <Button type="primary" htmlType="submit" className="fw-b" disabled={!isValid} loading={isLoading}>
                        {'追加'}
                    </Button>
                </FloatBar>
            </Form>
            <DuplicateReservationSlotsModal
                visible={modalVisible.isOverlap}
                overlapData={modalVisible.overlapData}
                onClose={() => {
                    closeModal();
                    setIsLoading(false);
                }}
                onSave={onSave}
            />
        </div>
    );
};

export default connect(mapState, mapDispatch)(CounselorAddIndividualProgramCompany);

CounselorAddIndividualProgramCompany.propTypes = {
    counselor: UserPropTypes,
    counselorCompanyProgram: PropTypes.any,
};
