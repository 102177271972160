import MessageThreadDetail from 'pages/counselor/calendar/message/MessageThreadDetail';
import MessageThreadCreate from 'pages/counselor/calendar/message/MessageThreadCreate';

const MessageRoutes = {
    INDEX: { path: '/', component: MessageThreadDetail },
    CREATE: { path: '/create-message', component: MessageThreadCreate },
    DETAIL: { path: '/:messageThreadId', component: MessageThreadDetail }, // id ref
};

export default MessageRoutes;
