import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmailTabList from 'pages/admin/email/EmailTabList';
import { Button } from 'antd';
import { AddIcon, DeleteIcon, DotsHorizontalRoundedIcon, DuplicateIcon } from 'components/SvgIcon';
import history from 'helpers/history.helper';
import Routes from 'routes';
import FloatBar from 'components/FloatBar';
import DataTable from 'components/DataTable';
import CustomDropdown from '../../../../components/CustomDropdown';
import { getData } from 'pages/admin/email/distribution/distribution_list.slice';
import { connect } from 'react-redux';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import DeleteDistributionListModal from 'pages/admin/email/distribution/modal/DeleteDistributionListModal';
import CloneDistributionListModal from 'pages/admin/email/distribution/modal/CloneDistributionListModal';

const mapState = state => ({
    state: state.admin.distributionList,
});
const mapDispatch = { getData };

const DistributionList = ({ state, getData }) => {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState({
        clone: false,
        delete: false,
        record: null,
    });

    const closeModal = () =>
        setModalVisible({
            clone: false,
            delete: false,
            record: null,
        });

    const showDeleteModal = r => setModalVisible({ ...modalVisible, delete: true, record: r });
    const showCloneModal = r => setModalVisible({ ...modalVisible, clone: true, record: r });

    const deleteDistributionList = async distributionListId => {
        const response = await api.delete(APP_URLS.ADMIN_EMAIL_DISTRIBUTIONS_DELETE.replace(':id', distributionListId));
        if (response) {
            history.push(Routes.private.ADMIN_EMAIL_DISTRIBUTION.path);
            showNormal('', t('message:Data has been deleted'), 3);
            getDistributionLists();
        }
    };
    const columns = [
        {
            title: '配信リスト名',
            dataIndex: 'name',
            sorter: true,
            width: 600,
        },
        {
            title: '対象者数',
            dataIndex: 'target_number',
            width: 88,
        },
        {
            title: '',
            dataIndex: 'dots-select',
            render: (_, r) => {
                return (
                    <CustomDropdown
                        ovlClassName={'dots-dropdown z-index-above-modal'}
                        getPopupContainer={node => node}
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        trigger={['click']}
                        options={[
                            {
                                key: 'duplicate',
                                text: (
                                    <>
                                        <DuplicateIcon /> <span>{'複製'}</span>
                                    </>
                                ),
                                className: 'primary-color fw-b',
                            },
                            {
                                key: 'delete',
                                text: (
                                    <>
                                        <DeleteIcon /> <span>{'削除'}</span>
                                    </>
                                ),
                                className: 'pink-color fw-b',
                            },
                        ]}
                        onSelect={opt => {
                            opt.domEvent.stopPropagation();
                            if (opt.key === 'delete') {
                                showDeleteModal(r);
                            } else {
                                showCloneModal(r);
                            }
                        }}
                        separator
                    >
                        <DotsHorizontalRoundedIcon className=" dots-icon c-pointer" />
                    </CustomDropdown>
                );
            },
            width: 40,
        },
    ];

    const getDistributionLists = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        getData({ pagination, filters, sorter });
    };

    useEffect(() => {
        getDistributionLists();
    }, []);

    return (
        <div className="self-wrapper px-0 pb-0">
            <h1 className="page-title mb-16 ml-24">{t('Email')}</h1>
            <EmailTabList tab="distribution" />

            <div className="p-24">
                <DataTable
                    className="collapse-table"
                    columns={columns}
                    data={state.data}
                    loading={state.loading}
                    pagination={state.pagination}
                    filters={state.filters}
                    sorter={state.sorter}
                    onTableChange={(pagination, filter, sorter) => {
                        getDistributionLists(pagination, state.filters, sorter);
                    }}
                    onRow={record => {
                        return {
                            onClick: () => {
                                history.push(
                                    Routes.private.ADMIN_EMAIL_DISTRIBUTION_UPDATE.path.replace(':id', record.id)
                                );
                            },
                        };
                    }}
                    noDataText={t('Distribution list is empty.')}
                />
            </div>
            <DeleteDistributionListModal
                onClose={() => closeModal()}
                onUpdate={() => deleteDistributionList(modalVisible.record.id)}
                visible={modalVisible.delete}
                record={modalVisible.record}
            />
            <CloneDistributionListModal
                onClose={() => closeModal()}
                visible={modalVisible.clone}
                record={modalVisible.record}
            />
            <FloatBar>
                <Button
                    icon={<AddIcon />}
                    className="fw-b"
                    onClick={() => history.push(Routes.private.ADMIN_EMAIL_DISTRIBUTION_CREATE.path)}
                >
                    {'配信リスト作成'}
                </Button>
            </FloatBar>
        </div>
    );
};

export default connect(mapState, mapDispatch)(DistributionList);
