import React, { useEffect } from 'react';
import { Button, Typography } from 'antd';
import DataTable from 'components/DataTable';
import { connect } from 'react-redux';
import PeriodFilter from 'pages/admin/interview_sheet/detail_screen/common/PeriodFilter';
import {
    overviewSelector,
    setDisplayType,
} from 'pages/admin/interview_sheet/detail_screen/interview_sheet_detail.slice';
import { dateToStr } from 'helpers/date.helper';
import NumberOfResponseChart from 'pages/admin/interview_sheet/detail_screen/tabs/overview/NumberOfResponseChart';
import CustomDropdown from 'components/CustomDropdown';
import { useTranslation } from 'react-i18next';
import { ArrowDropDownIcon } from 'components/SvgIcon';
import { parseQuestionnaire } from 'helpers/questionnaire.helper';
import { numberToFixed } from 'helpers/number_format.helper';

const mapState = state => ({
    ...overviewSelector(state.admin.interviewSheetDetail),
    displayType: state.admin.interviewSheetDetail.displayType,
});
const mapDispatch = { setDisplayType };

const OverviewContainer = ({
    interviewSheet,
    totalAnswers,
    lastAnswer,
    answers,
    questions,
    setDisplayType,
    displayType,
    setActiveKey,
}) => {
    const { t } = useTranslation();
    const columns = [
        {
            title: 'スコア',
            dataIndex: 'title',
            width: 449,
        },
        {
            title: '人数',
            dataIndex: 'count',
            width: 120,
            render: count => count || 0,
        },
        {
            title: '割合(%)',
            dataIndex: 'ratio',
            width: 120,
            render: ratio => ratio || 0,
        },
    ];

    const summaryByScore = () => {
        let data = [];

        let results = [];
        for (const answer of answers) {
            results.push(
                parseQuestionnaire({
                    questions: questions,
                    listAnswers: JSON.parse(answer.json_answers),
                })
            );
        }

        const { info_by_scores } = interviewSheet;
        for (const info of info_by_scores) {
            const matchCount = results.filter(
                r => info.score_from <= r.totalScore && r.totalScore <= info.score_to
            ).length;
            data.push({
                ...info,
                count: matchCount,
                ratio: matchCount / results.length ? numberToFixed((matchCount / results.length) * 100) : 0,
            });
        }
        return data;
    };

    useEffect(() => {
        return () => {
            setDisplayType('week');
        };
    }, []);

    return (
        <div className="bg-gray-5 flex-1">
            <div className="session-edit-wrapper session-detail-cards">
                <div className="session-detail-card">
                    <div className="session-detail-card--header">
                        <Typography className="fs-16 fw-700">{'サマリー'}</Typography>
                    </div>
                    <div className="session-detail-card--body">
                        <div className=" d-flex align-items-center ant-select-arrow-interview">
                            <PeriodFilter setActiveKey={setActiveKey} interviewSheet={interviewSheet} />
                        </div>

                        <div className="d-flex align-items-center mt-24 ">
                            <div>
                                <Typography className="fs-14 fw-700 color-blg-60">{'回答数'}</Typography>
                                <div className="d-flex justify-content-space-between align-items-center mt-8 ">
                                    <Typography className="ml-16 fw-100 health-data-numbers color-blg-60">
                                        {totalAnswers}
                                    </Typography>
                                    {!!lastAnswer && (
                                        <div>
                                            <Typography className="ml-16 fs-14-20 color-blg-60">
                                                {'最後に受け付けた回答'}
                                            </Typography>
                                            <span className="ml-16 fs-14-20 color-blg-60">
                                                {dateToStr(lastAnswer.updated_at, 'YYYY/MM/DD HH:mm')}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <Typography className="fs-14 fw-700 mt-32 color-blg-60">{'スコア別サマリー'}</Typography>
                        <DataTable
                            className={`mt-8 collapse-table no-clickable-row-table`}
                            columns={columns}
                            data={summaryByScore()}
                        />

                        <div className="d-flex justify-content-space-between align-items-center mt-24">
                            <Typography className="fs-14 fw-700 color-blg-60">{'回答数の推移'}</Typography>
                            <CustomDropdown
                                ovlClassName={'dots-dropdown'}
                                trigger={['click']}
                                options={[
                                    {
                                        key: 'week',
                                        text: t('Week display'),
                                    },
                                    {
                                        key: 'month',
                                        text: t('Month display'),
                                    },
                                    {
                                        key: 'year',
                                        text: t('Year display'),
                                    },
                                ]}
                                onSelect={opt => setDisplayType(opt.key)}
                                separator
                            >
                                <Button className="fw-b type-dropdown ant-pagination-item drop-down-arrow">
                                    {displayType === 'year'
                                        ? t('Year')
                                        : displayType === 'month'
                                        ? t('Month')
                                        : t('Week')}
                                    <ArrowDropDownIcon className="ml-11" />
                                </Button>
                            </CustomDropdown>
                        </div>
                        <div className="align-items-center">
                            <NumberOfResponseChart />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default connect(mapState, mapDispatch)(OverviewContainer);
