import { createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { processCounselorDetails } from 'helpers/counselor.helper';

const name = 'counselorAccount';

const counselorAccountSlice = createSlice({
    name,
    initialState: {
        companies: [],
        loading: true,
        programs: {},
        registered: [],
    },
    reducers: {
        setCompanies(state, action) {
            state.companies = action.payload;
        },

        setLoading(state, action) {
            state.loading = action.payload;
        },
        setPrograms(state, action) {
            state.programs = action.payload;
        },
        setRegistered(state, action) {
            state.registered = action.payload;
        },
    },
});

export const { setCompanies, setLoading, setPrograms, setRegistered } = counselorAccountSlice.actions;

export const getCompanies = id => async dispatch => {
    dispatch(setLoading(true));

    const companies = await api.get(APP_URLS.COUNSELOR_GET_COMPANIES.replace(':id', id));
    if (companies) {
        dispatch(setCompanies(companies));
    }

    dispatch(setLoading(false));
};

export const getPrograms = id => async dispatch => {
    dispatch(setLoading(true));
    const response = await api.get(APP_URLS.ADMIN_COUNSELORS_DETAIL_REGISTERED.replace(':id', id));
    const data = processCounselorDetails(response);
    if (data) {
        dispatch(setPrograms(data.programs));
        dispatch(setRegistered(data.registered));
    }
    dispatch(setLoading(false));
};

export default counselorAccountSlice.reducer;
