import React from 'react';
import AnswerQuestions from 'pages/admin/interview_sheet/detail_screen/common/aggregate_results/AnswerQuestions';

const AggregateFreeInput = ({ question, answersDataList }) => {
    const parseData = () => {
        let values = [];
        for (const r of answersDataList) {
            const cur = r.questionById[question.id];
            if (cur && cur.answers.length > 0) {
                values = [...values, ...cur.answers.map(o => ({ text: o.value }))];
            }
        }
        return values;
    };

    return (
        <>
            <AnswerQuestions data={parseData()} />
        </>
    );
};

export default AggregateFreeInput;
