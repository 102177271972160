import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Select } from 'antd';
import TdtSelect from 'components/TdtSelect';

const SelectPagination = ({ current, pageSize, onChange, pageSizeOptions = [100, 200, 300] }) => {
    const updatePagination = option => {
        onChange(Object.assign({ current, pageSize }, option));
    };

    return (
        <div className="select-pagination-wrapper">
            <Row align="middle">
                <Col className="">
                    <span className="select-pagination-title pr-8">表示件数</span>
                </Col>
                <Col className="">
                    <TdtSelect
                        size="large"
                        className="fs-14"
                        value={pageSize}
                        defaultValue={100}
                        onSelect={pageSize => updatePagination({ pageSize, current: 1 })}
                    >
                        {pageSizeOptions.map(opt => (
                            <Select.Option key={opt} value={opt}>
                                {<span>{opt}件</span>}
                            </Select.Option>
                        ))}
                    </TdtSelect>
                </Col>
            </Row>
        </div>
    );
};

export default SelectPagination;

SelectPagination.propTypes = {
    current: PropTypes.number,
    onChange: PropTypes.func,
    pageSize: PropTypes.number,
    pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
};
