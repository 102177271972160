import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import GA4React from 'ga-4-react';
import { GA } from 'constants/ga.constant';

let ga4;

const GaTracker = () => {
    const location = useLocation();
    const isInitialized = GA4React.isInitialized();

    useEffect(() => {
        if (ga4 && isInitialized) {
            logPageView(location);
        } else {
            ga4 = new GA4React(GA.MEASUREMENT_ID);
            ga4.initialize()
                .then(() => logPageView(location))
                .catch(err => console.error(err));
        }
    }, [isInitialized, location]);

    return <></>;
};

export default GaTracker;

export const logPageView = location => {
    ga4.pageview(location.pathname + location.search);
};
