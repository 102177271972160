const InquiryItemEnum = Object.freeze({
    ABOUT_CONSULTATION_CONTENT_AND_SCOPE: { value: 0, text: '相談内容・範囲について' },
    ABOUT_THE_CONSULTATION_AVAILABLE_DATE_AND_TIME: { value: 1, text: '相談可能日時について' },
    ABOUT_COUNSELORS: { value: 2, text: 'カウンセラーについて' },
    ABOUT_HOW_TO_USE: { value: 3, text: '使い方について' },
    ABOUT_DEFECTS: { value: 4, text: '不具合について' },
    OTHER_INQUIRIES: { value: 5, text: 'その他、問い合わせ' },
    OTHER_OPINIONS_AND_REQUESTS: { value: 6, text: 'その他、ご意見・ご要望' },
});

export default InquiryItemEnum;
