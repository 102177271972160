import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Dropdown, Form, Menu, Typography } from 'antd';
import { AddIcon, DeleteIcon } from 'components/SvgIcon';
import QuestionTypeEnum from 'enums/question_type.enum';
import InterviewSheetInfoCreate from 'pages/admin/interview_sheet/InterviewSheetInfoCreate';
import {
    addOption,
    addQuestion,
    setQuestionAddition,
    setQuestionRequired,
    setQuestionText,
    setQuestionType,
    updateOption,
    moveOption,
    removeOption,
    setQuestionDisplayColumns,
    setRefs,
    clearRefs,
    setCategoryTitle,
    reset,
    addQuestionRadarFirstTime,
    setTouched,
} from 'pages/admin/interview_sheet/interview_sheet_create.slice';
import { randomId } from 'helpers/common.helper';
import FreeInputQuestion from 'pages/admin/interview_sheet/questions/FreeInputQuestion';
import SingleSelectQuestion from 'pages/admin/interview_sheet/questions/SingleSelectQuestion';
import MultipleSelectQuestion from 'pages/admin/interview_sheet/questions/MultipleSelectQuestion';
import DateTimeQuestion from 'pages/admin/interview_sheet/questions/DateTimeQuestion';
import DailyLifeQuestion from 'pages/admin/interview_sheet/questions/DailyLifeQuestion';
import InterviewSheetCategory from 'pages/admin/interview_sheet/category/InterviewSheetCategory';

const mapState = state => ({
    byId: state.admin.interviewSheetCreate.questions.byId,
    order: state.admin.interviewSheetCreate.questions.order,
    options: state.admin.interviewSheetCreate.options.byId,
    questionOptions: state.admin.interviewSheetCreate.questionOptions,
    operationTargetId: state.admin.interviewSheetCreate.operationTargetId,
    refs: state.admin.interviewSheetCreate.refs,
    isRadarType: state.admin.interviewSheetCreate.isRadarType,
    categories: state.admin.interviewSheetCreate.categories,
    touched: state.admin.interviewSheetCreate.touched,
});
const mapDispatch = {
    addQuestionRadarFirstTime,
    addQuestion,
    setQuestionText,
    setQuestionAddition,
    setQuestionRequired,
    setQuestionType,
    setQuestionDisplayColumns,
    addOption,
    updateOption,
    moveOption,
    removeOption,
    setRefs,
    clearRefs,
    setCategoryTitle,
    reset,
    setTouched,
};

const QuestionnaireForm = ({
    byId,
    order,
    options,
    questionOptions,
    addQuestionRadarFirstTime,
    addQuestion,
    setQuestionText,
    setQuestionAddition,
    setQuestionRequired,
    setQuestionType,
    setQuestionDisplayColumns,
    addOption,
    updateOption,
    moveOption,
    removeOption,
    operationTargetId,
    refs,
    setRefs,
    clearRefs,
    setIsValueChange,
    // isRadarType
    categories,
    setCategoryTitle,
    reset,
    setTouched,
    touched,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const flattenQuestions = order.map(id => {
        const question = byId[id];
        return { id, question: question.value };
    });

    const targetToDOM = target => {
        if (target.input) {
            return target.input;
        } else if (target.editingArea) {
            return target.editingArea;
        } else if (target.resizableTextArea) {
            return target.resizableTextArea.textArea;
        }
    };

    useEffect(() => {
        if (operationTargetId && refs[operationTargetId]) {
            // focus
            const target = refs[operationTargetId];
            const el = targetToDOM(target);
            if (el) {
                setTimeout(() => {
                    target.focus();
                });
            }
        }

        return () => {
            clearRefs();
        };
    }, [Object.keys(refs).length]);

    useEffect(() => {
        return () => {
            reset();
        };
    }, []);

    const isRadarMode = order.length > 0 && byId[order[0]].type === QuestionTypeEnum.RADAR_CHART.value;

    const menu = (
        <Menu
            onClick={key => {
                if (QuestionTypeEnum[key.key].value === QuestionTypeEnum.RADAR_CHART.value) {
                    addQuestionRadarFirstTime();
                    return;
                }
                addQuestion({
                    id: randomId(),
                    question: {
                        type: QuestionTypeEnum[key.key].value,
                    },
                });
            }}
        >
            {Object.keys(QuestionTypeEnum).filter(type => (order.length > 0 && !isRadarMode) ? QuestionTypeEnum[type].value !== QuestionTypeEnum.RADAR_CHART.value : true).map(type => {
                return <Menu.Item key={type}>{t(`enum:${QuestionTypeEnum[type].text}`)}</Menu.Item>;
            })}
        </Menu>
    );

    return (
        <Form
            layout="vertical"
            form={form}
            className="bg-gray-5 flex-1"
            component="div"
            onChange={() => setIsValueChange(true)}
        >
            <InterviewSheetInfoCreate />

            {isRadarMode && (
                <div className="session-edit-wrapper pt-0">
                    <div className="session-detail-card mb-0 mt-24">
                        <Typography className="pt-20 fw-700 pl-16 fs-16 color-blg-100 d-flex align-items-center">
                            {'設問内容'}
                            <span className="fs-14-21 color-blg-60 ml-24 fw-400">{'カテゴリを３つ以上登録してください。'}</span>
                        </Typography>

                        {categories.order.map(categoryId => {
                            return (
                                <InterviewSheetCategory
                                    key={`category_${categoryId}`}
                                    id={categoryId}
                                    category={categories.byId[categoryId]}
                                    addQuestion={addQuestion}
                                    addOption={addOption}
                                    updateOption={updateOption}
                                    moveOption={moveOption}
                                    removeOption={removeOption}
                                    questions={byId}
                                    options={options}
                                    setRefs={setRefs}
                                    questionOptions={questionOptions}
                                    setCategoryTitle={setCategoryTitle}
                                    setQuestion={setQuestionText}
                                    setTouched={setTouched}
                                    touched={touched}
                                    categories={categories}
                                />
                            );
                        })}

                        {categories.order.length < 6 && (
                            <div className="item m-16">
                                <Button
                                    className="fw-b w-100p justify-content-center"
                                    style={{
                                        height: 48,
                                        border: '2px dashed #D1D0D6',
                                        borderRadius: 4,
                                    }}
                                    icon={<AddIcon />}
                                    onClick={() => {
                                        addQuestion({
                                            id: randomId(),
                                            question: {
                                                type: QuestionTypeEnum.RADAR_CHART.value,
                                            },
                                        });
                                    }}
                                >
                                    {'カテゴリ追加'}
                                </Button>
                            </div>
                        )}

                        <div className="hor-line-slim mt-24" />
                        <div className="position-right-button">
                            <Button
                                type="text"
                                className="mt-12 mr-24 mb-12 fw-b pink-color position-right-button"
                                icon={<DeleteIcon />}
                                onClick={() => {
                                    reset({ isRadarReset: true });
                                }}
                            >
                                {t('Cancellation of radar chart creation')}
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            {order.length > 0 && !isRadarMode && (
                <div className="session-edit-wrapper pt-0">
                    {order.map((id, index) => {
                        let Component = null;
                        switch (byId[id].type) {
                            case QuestionTypeEnum.FREE_INPUT.value:
                            case QuestionTypeEnum.FREE_INPUT_PARAGRAPH.value:
                            case QuestionTypeEnum.FREE_INPUT_NUMBER.value:
                                Component = FreeInputQuestion;
                                break;
                            case QuestionTypeEnum.SINGLE_SELECTION.value:
                                Component = SingleSelectQuestion;
                                break;
                            case QuestionTypeEnum.MULTIPLE_SELECTION.value:
                                Component = MultipleSelectQuestion;
                                break;
                            case QuestionTypeEnum.DATE_TIME.value:
                                Component = DateTimeQuestion;
                                break;
                            case QuestionTypeEnum.DAILY_LIFE.value:
                                Component = DailyLifeQuestion;
                                break;
                            // case QuestionTypeEnum.RADAR_CHART.value:
                            //     Component = InterviewSheetCategory;
                            //     break;
                        }

                        if (Component) {
                            return (
                                <div className="session-detail-card mb-0 mt-24" key={id}>
                                    <Component
                                        id={id}
                                        question={byId[id].value}
                                        addition={byId[id].addition}
                                        options={options}
                                        optionsOrder={questionOptions[id] ? questionOptions[id] : []}
                                        isRequired={byId[id].isRequired}
                                        setQuestion={setQuestionText}
                                        setAddition={setQuestionAddition}
                                        setIsRequired={setQuestionRequired}
                                        addOption={addOption}
                                        updateOption={updateOption}
                                        moveOption={moveOption}
                                        removeOption={removeOption}
                                        no={index + 1}
                                        type={byId[id].type}
                                        setType={setQuestionType}
                                        displayColumns={byId[id].display_columns || 'unset'}
                                        setDisplayColumns={setQuestionDisplayColumns}
                                        flattenQuestions={flattenQuestions}
                                        setRefs={setRefs}
                                    />
                                </div>
                            );
                        }

                        return (
                            <div className="session-detail-card" key={id}>
                                <div className="question-block">
                                    {id} - {byId[id].type}
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
            <Dropdown overlay={menu} trigger={['click']} overlayClassName="z-index-10000">
                <Button className={`mb-64 ml-24 fw-b ${isRadarMode ? 'd-none' : ''}`} icon={<AddIcon />}>
                    {t('Add question')}
                </Button>
            </Dropdown>
        </Form>
    );
};

export default connect(mapState, mapDispatch)(QuestionnaireForm);

QuestionnaireForm.propTypes = {
    byId: PropTypes.object,
    order: PropTypes.array,
    options: PropTypes.object,
    questionOptions: PropTypes.object,
    addQuestion: PropTypes.func.isRequired,
    setQuestionText: PropTypes.func.isRequired,
    setQuestionAddition: PropTypes.func.isRequired,
    setQuestionRequired: PropTypes.func.isRequired,
    addOption: PropTypes.func.isRequired,
    updateOption: PropTypes.func.isRequired,
};
