import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Button, Col, Form, Input, Row } from 'antd';
import PropTypes from 'prop-types';
import SidebarTopClient from 'layouts/SidebarTopClient';
import Routes from 'routes';
import { useTranslation } from 'react-i18next';
import api from 'helpers/api.helper';
import { showNormal } from 'helpers/notification.helper';
import history from 'helpers/history.helper';
import { APP_URLS } from 'constants/url.constant';

const PasswordResetChange = ({ isCounselor = false }) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);
    const [errorSubmit, setErrorSubmit] = useState(true);
    const [errPasswordLength, setErrPasswordLength] = useState(false);
    const location = useLocation();
    const queryString = location?.search;

    useEffect(async () => {
        setPageLoading(false);
        try {
            await api.get(APP_URLS.PASSWORD_RESET_VERIFY + queryString, null, {}, true);
            setPageLoading(true);
        } catch (e) {
            setPageLoading(false);
            showNormal('', t('message:Session has expired'), 5);
            history.push(Routes.public.LOGIN.path);
        }
    }, [queryString]);

    const formSubmit = async values => {
        setLoading(true);
        try {
            await api.post(
                APP_URLS.PASSWORD_RESET_VERIFY + queryString,
                {
                    password: values.password,
                },
                {},
                {},
                true
            );
            setLoading(false);
            showNormal('', t('message:Saved password changes'), 5);
            history.push(Routes.public.LOGIN.path);
        } catch (e) {
            setLoading(false);
            showNormal('', t('message:Session has expired'), 5);
        }
    };

    const checkLengthPassword = (rule, value) => {
        setErrPasswordLength(false);
        if (value.length < 8) {
            setErrPasswordLength(true);
            return Promise.reject();
        }
        return Promise.resolve();
    };

    return (
        pageLoading && (
            <div id="forgot-password">
                <SidebarTopClient backUrl={isCounselor ? '' : Routes.public.PASSWORD_RESET.path} />

                <div className="register-header mt-96">
                    <h2 className="td-header">
                        {isCounselor ? t('First password registration') : t('Password reissue')}
                    </h2>
                </div>
                <Row justify="center" align="middle">
                    <Col xs={22} sm={24} md={12} lg={8} xl={6}>
                        <div id="form-wrapper">
                            <Form layout="vertical" onFinish={formSubmit}>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item
                                            name="password"
                                            validateStatus={errPasswordLength && 'error'}
                                            label={t('field:New password')}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('validation:Please enter {{field}}', {
                                                        field: t('field:New password'),
                                                    }),
                                                    validator: checkLengthPassword,
                                                },
                                            ]}
                                        >
                                            <Row gutter={16}>
                                                <Col span={24}>
                                                    <Input.Password min={8} />
                                                    <div
                                                        className={`ant-form-item-extra ${
                                                            errPasswordLength && 'ant-form-item-extra-error'
                                                        }`}
                                                    >
                                                        <div role="alert">{t('Alert_Input 8 characters')}</div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form.Item>

                                        <Form.Item
                                            name="confirm"
                                            label={t('field:New password (for confirmation)')}
                                            extra={t('field:Please re-enter your new password for confirmation')}
                                            dependencies={['password']}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: t('validation:Please enter {{field}}', {
                                                        field: t('field:New password (for confirmation)'),
                                                    }),
                                                },
                                                ({ getFieldValue }) => ({
                                                    validator(_, value) {
                                                        if (!value || getFieldValue('password') === value) {
                                                            setErrorSubmit(false);
                                                            return Promise.resolve();
                                                        }
                                                        setErrorSubmit(true);
                                                        return Promise.reject(
                                                            new Error(
                                                                t(
                                                                    'validation:The two passwords that you entered do not match'
                                                                )
                                                            )
                                                        );
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input.Password />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row justify="center" align="middle" className="mt-32">
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            disabled={errorSubmit}
                                            htmlType="submit"
                                            loading={loading}
                                            className="td-btn disable-gray-btn"
                                        >
                                            {t('Register')}
                                        </Button>
                                    </Form.Item>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    );
};

export default connect()(PasswordResetChange);

PasswordResetChange.propTypes = {
    isCounselor: PropTypes.bool,
};
