import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Modal, Row, Select, Upload, Typography } from 'antd';
import { CloseIcon, ImageIcon } from 'components/SvgIcon';
import HealthImageEnum from 'enums/health_image.enum';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import HealthTypeEnum from 'enums/health_type.enum';
import DateChangeInModalComponent from 'pages/client/health_data/tabs/date_time/DateChangeInModalComponent';
import moment from 'moment';
import { showNormal } from 'helpers/notification.helper';
import TdtSelect from 'components/TdtSelect';

const CreateImageModal = ({
    value = {},
    setValue,
    visible,
    onClose,
    getImages,
    isTabList,
    getListPhoto,
    setTab,
    setLoading,
    tab,
}) => {
    const { t } = useTranslation();
    const [fileList, setFileList] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [category, setCategory] = useState(!isTabList ? 0 : tab);
    // const [createAt, setCreateAt] = useState();
    const handleUpload = () => {
        const formData = new FormData();
        fileList.forEach(image_file => {
            formData.append('images[]', image_file);
        });
        formData.append('type', HealthTypeEnum.IMAGE.value);
        formData.append('category', category);
        formData.append('created_at', moment.utc(value.created_at));

        setUploading(true);
        uploadImages(formData);
    };
    useEffect(() => {
        setValue({ ...value, category: 0 });
    }, []);
    const uploadImages = async values => {
        const response = await api.post(APP_URLS.CLIENT_HEALTH_DATA_CREATE, values);
        if (response && !isTabList) {
            showNormal('', t('message:Data has been saved'), 3);
            getImages();
        } else {
            showNormal('', t('message:Data has been saved'), 3);
            getListPhoto(category);
            setTab(`${category}`);
        }
        setUploading(!response);
    };

    const props = {
        onRemove: file => {
            setFileList(fileList.filter(f => f !== file));
        },
        beforeUpload: (file, fileLists) => {
            file.url = URL.createObjectURL(file);
            setFileList([...fileList, ...fileLists]);
            return false;
        },
        fileList,
    };
    return (
        <Modal
            title={
                <>
                    <Row align={'middle'}>
                        <ImageIcon size={39} className="pr-8" />
                        {t('Photo')}
                    </Row>
                </>
            }
            visible={visible}
            width={320}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <Row justify="center">
                <Col>
                    <DateChangeInModalComponent value={value} setValue={setValue} />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Typography className="image-modal-label mt-20 mb-4">{t('Category')}</Typography>
                </Col>
                <Col span={24}>
                    <TdtSelect
                        size="large"
                        className="fs-16 image-modal-select select-gray"
                        defaultValue={!isTabList ? 0 : tab === '0' ? 0 : tab === '1' ? 1 : 2}
                        onChange={val => {
                            setValue({ ...value, category: val });
                            setCategory(val);
                        }}
                    >
                        {Object.values(HealthImageEnum).map(i => (
                            <Select.Option key={i.value} value={i.value}>
                                {i.text}
                            </Select.Option>
                        ))}
                    </TdtSelect>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Typography className="image-modal-label mt-20 mb-4">{t('Photo')}</Typography>
                </Col>
                <Col span={24}>
                    <Upload
                        {...props}
                        accept={['.jpg', '.webp', '.png', '.heic']}
                        listType="picture-card"
                        fileList={[...fileList]}
                        multiple={true}
                    >
                        {fileList.length >= 10 ? null : (
                            <Button className="fw-b add-file-btn" block>
                                {t('Add photo')}
                            </Button>
                        )}
                    </Upload>
                </Col>
            </Row>
            <p className="fs-14 mb-32 mt-8 text-gray-color ">{t('File format jpg, png')}</p>
            <Button
                type="primary"
                block
                className="mt-16 disable-gray-btn"
                onClick={() => {
                    handleUpload();
                    setLoading(true);
                    onClose();
                    // isTabList ? getListPhoto(category) : null;
                    setFileList([]);
                }}
                disabled={fileList.length === 0 || value.category === undefined}
                loading={uploading}
            >
                {t('Save')}
            </Button>
        </Modal>
    );
};

CreateImageModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    getImages: PropTypes.func,
    isTabList: PropTypes.func,
    getListPhoto: PropTypes.func,
    value: PropTypes.any,
    setValue: PropTypes.func,
    setTab: PropTypes.func,
};

export default CreateImageModal;
