import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row, Image } from 'antd';
import noData from 'assets/images/noData.png';
import MessageRow from 'pages/client/message/MessageRow';
import { getData } from 'pages/client/message/message.slice';
import TdtPullable from 'components/TdtPullable';

const mapState = state => ({
    state: state.client.message,
    userId: state.auth.user.id,
    hasNewMessage: state.auth.event.hasNewMessage,
});
const mapDispatch = { getData };

const Message = ({ state, getData, userId, hasNewMessage }) => {
    const { t } = useTranslation();

    const getMessages = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        getData({ pagination, filters: { ...filters, module: 'client' }, sorter });
    };

    useEffect(() => {
        getMessages();
    }, [hasNewMessage]);

    const localSorter = (messageThreads = []) => {
        return [...messageThreads].sort((a, b) => {
            const aCreatedAt = a.company_id ? a.created_at : a.recent_message.created_at;
            const bCreatedAt = b.company_id ? b.created_at : b.recent_message.created_at;
            return aCreatedAt > bCreatedAt ? -1 : aCreatedAt < bCreatedAt ? 1 : 0;
        });
    };

    return (
        <>
            <Row justify="space-between" align="middle">
                <Col>
                    <h1 className="page-title mb-16 mt-48">{t('Message')}</h1>
                </Col>
            </Row>
            <div className="mb-32 fs-14">{'カウンセラーやサービスからの連絡事項が届きます。'}</div>

            <TdtPullable
                onRefresh={() => {
                    getMessages();
                }}
            />

            {state.data.length > 0 ? (
                <>
                    {localSorter(state.data).map(m => (
                        <MessageRow key={m.id} messageThreadItem={m} userId={userId} />
                    ))}
                </>
            ) : (
                <>
                    <Row justify="center">
                        <Col>
                            <Image className="mt-40" src={noData} preview={false} />
                        </Col>
                    </Row>
                    <Row justify="center">
                        <Col>
                            <div className="mt-32">{'メッセージが来たら、やりとりを進めましょう'}</div>
                        </Col>
                    </Row>
                </>
            )}
        </>
    );
};

export default connect(mapState, mapDispatch)(Message);

Message.propTypes = {
    state: PropTypes.any,
    getData: PropTypes.func.isRequired,
};
