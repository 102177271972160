import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BackBtn from 'components/BackBtn';
import ReservationForm from 'pages/counselor/calendar/consultation/ReservationForm';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import history from 'helpers/history.helper';
import ConsultationRoutes from 'pages/counselor/calendar/consultation/consultation_routes';
import Routes from 'routes';

const ReservationCreate = () => {
    const { t } = useTranslation();

    const { id } = useParams();
    const [consultant, setConsultant] = useState();

    const getConsultant = async id => {
        const response = await api.get(APP_URLS.COUNSELOR_CONSULTANT_INFO.replace(':id', id));
        if (response) {
            setConsultant(response);
        }
    };

    const createReservation = async values => {
        const data = await api.post(APP_URLS.COUNSELOR_RESERVATIONS_SUBMIT.replace(':id', id), values);
        if (data) {
            history.push(
                `${Routes.private.COUNSELOR_DETAIL_CONSULTATION.path.replace(
                    ':id',
                    id
                )}${ConsultationRoutes.DETAIL.path.replace(':reservationId', data.id)}`
            );
        }
    };

    useEffect(() => {
        getConsultant(id).then(() => {});
    }, [id]);

    if (!consultant) {
        return <></>;
    }

    return (
        <div className="pl-44 mb-32">
            <BackBtn className="back-btn-counselor" label={t('Consultation reservation creation')} />
            <ReservationForm consultant={consultant} onSubmit={values => createReservation(values)} />
        </div>
    );
};

export default ReservationCreate;
