import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackBtn from 'components/BackBtn';
import { getSeminar } from 'pages/client/seminar/seminar_detail.slice';
import Loader from 'components/Loader';
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import RenderPageElements from 'pages/admin/common/page-elements/RenderPageElements';
import { dateWithForwardSlash } from 'helpers/date.helper';
import { isMobileActive } from 'helpers/mobile.helper';
import Routes from 'routes';
import history from 'helpers/history.helper';
import { addSeminarToGoogleCalendar } from 'helpers/google_calendar.helper';
import { DateRangeIcon } from '../../../components/SvgIcon';

const mapState = state => ({
    seminar: state.client.seminarDetail.seminar,
    loading: state.client.seminarDetail.loading,
});
const mapDispatch = { getSeminar };
const ClientSeminarDetail = ({ seminar, loading, getSeminar }) => {
    const { t } = useTranslation();
    const { id } = useParams();

    const [isSticky, setIsSticky] = useState(false);
    const ref = useRef(null);

    const check = () => {
        if (ref.current) {
            const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
            const refRectTop = ref.current.getBoundingClientRect().top;
            setIsSticky(refRectTop + ref.current.offsetHeight > viewportHeight);
        }
    };

    const handleScroll = () => {
        debounce(check, 10);
    };

    useEffect(() => {
        check();
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', () => handleScroll);
        };
    }, []);

    useEffect(() => {
        seminar = null;
        getSeminar(id);
    }, [id]);

    if (loading) {
        return <Loader className='loader-center' />;
    }

    if (!seminar) {
        return <></>;
    }

    return (
        <>
            <BackBtn label={seminar.name} noBackText={true} />
            <div className='client-render-page-container'>
                <RenderPageElements info={seminar} details={seminar.seminar_details} isClient={true}
                                    withArchive={seminar.archived_at && seminar.is_video_public} />
                <Row ref={ref} className='seminar-detail-action-panel' justify={'space-between'} align='middle'>
                    <Col xs={24} md={12} className='date-time-panel d-flex align-items-center'>
                        {/*<span className="fs-14 fw-b">{t('field:Date and time')}</span>*/}
                        {/*<span className="pc-display">{'：'}</span>*/}
                        <span className='circle-icon-with-bg mr-16'><DateRangeIcon /></span>
                        <div>
                            <div className='white-space-nowrap'>
                                <span className='date-time-text in-page'>{dateWithForwardSlash(seminar.date)}</span>
                                &nbsp;
                                <span className='date-time-text in-page'>{seminar.start_time}</span>
                                <span className='date-time-text in-page'>{'-'}</span>
                                <span className='date-time-text in-page'>{seminar.end_time}</span>
                            </div>
                            <Button
                                type='link'
                                className='fs-16 fw-400'
                                href={addSeminarToGoogleCalendar(seminar)}
                                target={isMobileActive() ? '_top' : '_blank'}
                                disabled={seminar.is_expired}
                            >
                                {t('Register with Google Calendar')}
                            </Button>
                        </div>
                    </Col>
                    <Col xs={24} md={8} className='action-panel'>
                        {seminar.is_expired ? (
                            <Button
                                className='admission-btn'
                                type='primary'
                                disabled
                            >
                                {t('開催済み')}
                            </Button>
                        ) : seminar.is_running ? (
                            <Button
                                className='admission-btn'
                                type='primary'
                                target='_blank'
                                href={seminar.seminar_url}
                            >
                                {t('開催中')}
                            </Button>
                        ) : seminar.is_upcoming ? (
                            <Button
                                className='admission-btn'
                                type='primary'
                                disabled
                            >
                                {t('開催予定')}
                            </Button>
                        ) : (
                            <Button
                                className='admission-btn'
                                type='primary'
                                target='_blank'
                                href={seminar.seminar_url}
                            >
                                {t('Admission')}
                            </Button>
                        )}
                    </Col>
                </Row>
                {/*floatbar-sticky*/}
                <div className={`sticky-container stick ${!isSticky && 'd-none'}`}>
                    <Row className='seminar-detail-action-panel' justify={'space-between'} align='middle'>
                        <div className='floatbar-sticky-container'>
                            <Col xs={14} md={14} className='date-time-panel'>
                                <span className='fs-16 fw-b date-time-title'>{t('field:Date and time')}</span>
                                <span className='date-time-title'>{'：'}</span>
                                <div className='white-space-nowrap date-time-text-ctn'>
                                    <span className='date-time-text'>{dateWithForwardSlash(seminar.date)}</span>
                                    &nbsp;
                                    <span className='date-time-text'>{seminar.start_time}</span>
                                    <span className='date-time-text'>{'-'}</span>
                                    <span className='date-time-text'>{seminar.end_time}</span>
                                </div>
                            </Col>
                            <Col xs={8} md={10} className='action-panel'>
                                <Button
                                    type='link'
                                    className='fs-16 google-calendar-btn'
                                    href={addSeminarToGoogleCalendar(seminar)}
                                    target={isMobileActive() ? '_top' : '_blank'}
                                    disabled={seminar.is_expired}
                                >
                                    {t('Register with Google Calendar')}
                                </Button>
                                {seminar.is_expired ? (
                                    <Button
                                        className='admission-floatbar-btn'
                                        type='primary'
                                        disabled
                                    >
                                        {t('開催済み')}
                                    </Button>
                                ) : seminar.is_running ? (
                                    <Button
                                        className='admission-floatbar-btn'
                                        type='primary'
                                        target='_blank'
                                        href={seminar.seminar_url}
                                    >
                                        {t('開催中')}
                                    </Button>
                                ) : seminar.is_upcoming ? (
                                    <Button
                                        className='admission-floatbar-btn'
                                        type='primary'
                                        disabled
                                    >
                                        {t('開催予定')}
                                    </Button>
                                ) : (
                                    <Button
                                        className='admission-floatbar-btn'
                                        type='primary'
                                        target='_blank'
                                        href={seminar.seminar_url}
                                    >
                                        {t('Admission')}
                                    </Button>
                                )}
                            </Col>
                        </div>
                    </Row>
                </div>
                {/*floatbar-sticky*/}
                <Button
                    type='link'
                    className='back-to-list-btn'
                    onClick={() => history.push(Routes.private.CLIENT_SEMINAR.path)}
                >
                    {t('Return to seminar list')}
                </Button>
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(ClientSeminarDetail);

ClientSeminarDetail.propTypes = {
    seminar: PropTypes.any,
    getSeminar: PropTypes.func.isRequired,
};

function debounce(method, delay) {
    clearTimeout(method._tId);
    method._tId = setTimeout(function() {
        method();
    }, delay);
}
