import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';

const LogOutModal = ({ visible, onClose, onUpdate }) => {
    return (
        <Modal
            title={'ログアウト'}
            visible={visible}
            width={380}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <p>{'ログアウトします。よろしいですか？'}</p>
            <div className="text-align-right">
                <Button
                    type=""
                    className=" delete-button fw-b"
                    onClick={() => {
                        onUpdate && onUpdate();
                        onClose();
                    }}
                >
                    <span className={'title-button'}>{'はい'}</span>
                </Button>
                <Button
                    type=""
                    className="cancel-button fw-b"
                    onClick={() => {
                        onClose();
                    }}
                >
                    <span className={'title-button'}>{'キャンセル'}</span>
                </Button>
            </div>
        </Modal>
    );
};

LogOutModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default LogOutModal;
