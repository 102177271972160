import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Row } from 'antd';
import { LineIcon, MailIcon } from 'components/SvgIcon';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { dateToStr } from 'helpers/date.helper';
import { truncateStr } from 'helpers/string.helper';
import { hasAnyRole } from 'helpers/role.helper';
import RoleEnum from 'enums/role.enum';

const MessageRow = ({ messageThreadItem, userId }) => {
    const recentMessage = messageThreadItem.recent_message;
    const recentMessageOwner = messageThreadItem.recent_message_owner;
    const isBulkType = messageThreadItem.company_id;

    return (
        <Row className="client-message-row" align="middle">
            <Col className="mr-16" xs={4} md={2} xl={2}>
                <div>
                    <span
                        className={`message-icon-box ${
                            (!isBulkType &&
                                recentMessage &&
                                (recentMessage.read_flag || recentMessage.user_id === userId)) ||
                            (isBulkType && recentMessageOwner && recentMessageOwner.read_flag)
                                ? 'seen'
                                : ''
                        }`}
                    >
                        <MailIcon />
                        <span className="line-icon-box custom-zoom-icon">
                            <LineIcon />
                        </span>
                    </span>
                </div>
            </Col>
            <Col xs={14} md={17} xl={19}>
                <div className="client-message-item">
                    <div className="mb-8 client-message">
                        <span className="mr-16 client-message-time">
                            {dateToStr(
                                isBulkType ? messageThreadItem.created_at : recentMessage.created_at,
                                'M月DD日(ddd) HH:mm'
                            )}
                        </span>
                        {isBulkType || hasAnyRole(messageThreadItem.counselor, [RoleEnum.ADMIN.value, RoleEnum.ADMIN_STAFF.value]) ? (
                            <>
                                <span className="mr-8 message-msg-badge">
                                    <span className="msg-badge msg-badge-disable">{'Team Doctors'}</span>
                                </span>
                            </>
                        ) : (
                            <>
                                <span className="mr-8">
                                    <span className="msg-badge msg-badge-secondary">{'カウンセラー'}</span>
                                </span>
                                <span>{'Team Doctors'}</span>
                            </>
                        )}
                    </div>
                    <div className="message-item-title mb-8">{truncateStr(messageThreadItem.title, 28)}</div>
                    <div className="message-item-content">
                        {isBulkType
                            ? truncateStr(messageThreadItem.content, 82)
                            : recentMessage && truncateStr(recentMessage.content, 82)}
                    </div>
                </div>
            </Col>
            <Col className="btn-outline send-button" xs={24} md={2} xl={2}>
                <div className="btn-outline-message">
                    <Button
                        className="fw-b btn-outline-detail"
                        type="outline"
                        onClick={() =>
                            history.push(Routes.private.CLIENT_MESSAGE_DETAIL.path.replace(':id', messageThreadItem.id))
                        }
                    >
                        {'詳細'}
                    </Button>
                </div>
            </Col>
        </Row>
    );
};

export default MessageRow;

MessageRow.propTypes = {
    messageThreadItem: PropTypes.any,
};
