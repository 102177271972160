import React, { useState } from 'react';
import useOutsideClick from 'components/OutsideClick';

const TdtPureSelect = ({
    value: selectedValue,
    onSelect: setSelectedValue,
    renderSelected,
    options,
    maxHeight,
    maxWidth,
}) => {
    const [visible, setVisible] = useState(false);

    const handleClickOutside = () => {
        setVisible(false);
    };

    const ref = useOutsideClick(handleClickOutside);

    const handleOptionClick = option => {
        setVisible(false);
        setSelectedValue(option.value);
    };

    return (
        <div
            ref={ref}
            className={`parent-ref ${maxHeight ? 'parent-ref-has-max-height' : ''} ${
                maxWidth ? 'parent-ref-has-max-width-' + maxWidth : ''
            }`}
        >
            <div className="parent-ref-plus">
                <div
                    className="tdt-pure-select ant-select ant-select-lg tdt-select tdt-select-default tdt-select-medium fw-400 fs-16 bg-gray-5 interview-sheet-select label-no-after ant-select-single ant-select-show-arrow"
                    style={{ minWidth: '115px', height: '40px', width: 'unset' }}
                >
                    <div
                        className="ant-select-selector"
                        onClick={() => {
                            setVisible(!visible);
                        }}
                    >
                        <span className="ant-select-selection-search" />

                        {selectedValue ? (
                            <>
                                <span className="ant-select-selection-item">
                                    <span className="d-flex align-items-center">
                                        {renderSelected ? renderSelected(selectedValue) : selectedValue}
                                    </span>
                                </span>
                            </>
                        ) : (
                            <span className="ant-select-selection-placeholder" />
                        )}
                    </div>

                    <span
                        className="ant-select-arrow"
                        unselectable="on"
                        aria-hidden="true"
                        style={{ userSelect: 'none' }}
                    >
                        <span role="img" aria-label="home" className="anticon svg-icon ">
                            <svg
                                width="24px"
                                height="24px"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M7 10L12 15L17 10H7Z" fill="currentColor" />
                            </svg>
                        </span>
                    </span>
                </div>
            </div>

            <div
                className={`${
                    visible ? '' : 'ant-select-dropdown-hidden'
                } tdt-pure-select-dropdown ant-select-dropdown tdt-dropdown tdt-dropdown-select-default dle-as-modal-dropdown-popup dropdown-popup-width dropdown-popup-height mobile-option-padding ovr-dropdown-width-auto  ant-select-dropdown-placement-topLeft`}
            >
                <div className="rc-virtual-list">
                    <div
                        className={`rc-virtual-list-holder pure-select-max-height-${maxHeight}`}
                        style={{
                            overflowY: 'auto',
                            overflowAnchor: 'none',
                        }}
                    >
                        <div
                            className="rc-virtual-list-holder-inner"
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            {options.map(option => (
                                <div
                                    key={option.value}
                                    className={`ant-select-item ant-select-item-option ${
                                        option.value === selectedValue ? 'ant-select-item-option-selected' : ''
                                    }`}
                                    onClick={() => handleOptionClick(option)}
                                >
                                    <span className="d-flex align-items-center">{option.label}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TdtPureSelect;
