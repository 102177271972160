import React from 'react';
import { Button, DatePicker, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { dateToStr, strPeriodFormat } from 'helpers/date.helper';
import DataTable from 'components/DataTable';
import { connect } from 'react-redux';
import { overviewSelector } from 'pages/admin/interview_sheet/detail_screen/interview_sheet_detail.slice';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { setBackUrl } from 'core.slice';
import TdtButton from 'components/TdtButton';
import { AddIcon, EditIcon } from 'components/SvgIcon';

const mapState = state => overviewSelector(state.admin.interviewSheetDetail);
const mapDispatch = { setBackUrl };

const ResponsePeriodContainer = ({ interviewSheet }) => {
    const { t } = useTranslation();
    const columns = [
        {
            title: t('Response period'),
            dataIndex: 'response_period',
            render: (value, record) => {
                const { start_date, start_hour, start_minute, end_date, end_hour, end_minute } = record;
                return `${strPeriodFormat(start_date, start_hour, start_minute)} ~ ${strPeriodFormat(
                    end_date,
                    end_hour,
                    end_minute
                )}`;
            },
            sorter: (a, b) => {
                const a1 = `${a.start_date} ${a.start_hour}:${a.start_minute} ~ ${a.end_date} ${a.end_hour}:${a.end_minute}`;
                const b1 = `${b.start_date} ${b.start_hour}:${b.start_minute} ~ ${b.end_date} ${b.end_hour}:${b.end_minute}`;
                return a1.localeCompare(b1);
            },
        },
        {
            title: t('Number of respondents'),
            dataIndex: 'answered_count',
            width: 80,
            className: 'text-right',
            render: total => total || 0,
        },
        {
            title: t('Edit'),
            dataIndex: 'EDIT',
            className: 'text-align-center',
            render: (_, r) => {
                return (
                    <TdtButton
                        buttonStyle="plain"
                        icon={<EditIcon />}
                        onClick={event => {
                            event.stopPropagation();
                            history.push(
                                Routes.private.ADMIN_INTERVIEW_SHEETS_RESPONSE_PERIOD_UPDATE.path
                                    .replace(':id', interviewSheet.id)
                                    .replace(':periodId', r.id)
                            );
                        }}
                    />
                );
            },
            width: 120,
        },
    ];

    return (
        <div className="bg-gray-5 flex-1">
            <div className="session-edit-wrapper session-detail-cards">
                <div className="session-detail-card">
                    <div className="session-detail-card--header d-flex align-items-center justify-content-space-between">
                        <Typography className="fs-16 fw-700">{'回答期間'}</Typography>
                        <Button
                            type="text"
                            disabled={interviewSheet.programs_count > 0}
                            icon={<AddIcon />}
                            className="fw-b bg-transparent"
                            onClick={() =>
                                history.push(
                                    Routes.private.ADMIN_INTERVIEW_SHEETS_RESPONSE_PERIOD_CREATE.path.replace(
                                        ':id',
                                        interviewSheet.id
                                    )
                                )
                            }
                            style={{
                                height: 24,
                                padding: '0 8px 0 0',
                                color: interviewSheet.programs_count > 0 ? '#BAB9C2' : '#4c18ed',
                            }}
                        >
                            {t('Register new')}
                        </Button>
                    </div>
                    <div className="session-detail-card--body pt-24 pb-24 pl-16 pr-16">
                        {interviewSheet.programs_count > 0 ? (
                            <span className="fs-14-21 color-blg-100">
                                {'プログラムの予約に紐づいているので変更できません。'}
                            </span>
                        ) : (
                            <DataTable
                                className="collapse-table"
                                columns={columns}
                                data={interviewSheet.periods}
                                onTableChange={() => {}}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(mapState, mapDispatch)(ResponsePeriodContainer);
