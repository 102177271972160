import React, { useState } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const ActionBtnInDistributionModal = ({
    onClose,
    onUpdate,
    confirmText = '',
    isNotDelete,
    manualClose = false,
    // okButtonDisabled = false,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="delete-action-panel">
                <Button
                    type="text"
                    className="cancel-button fw-b disable-color"
                    onClick={() => {
                        onClose();
                    }}
                >
                    <span className={'title-button'}>{'キャンセル'}</span>
                </Button>
                <Button
                    type="primary"
                    className={isNotDelete ? 'delete-button fw-b color-blue-100' : 'delete-button fw-b'}
                    style={{ width: 108, paddingLeft: 38 }}
                    onClick={() => {
                        onUpdate && onUpdate();
                        !manualClose && onClose();
                    }}
                    // disabled={okButtonDisabled}
                >
                    <span className={'title-button'}>{confirmText ? confirmText : t('登録')}</span>
                </Button>
            </div>
        </>
    );
};

export default ActionBtnInDistributionModal;
