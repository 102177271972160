import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Row, Typography, Button } from 'antd';
import { getPublicSeminars, getEndSeminars } from 'pages/client/seminar/seminar.slice';
import SeminarBlock from 'pages/client/seminar/SeminarBlock';
import Loader from 'components/Loader';
import NoData from 'components/NoData';
import seminarNodata from 'assets/images/seminarNodata.png';
import { isMobileActive } from 'helpers/mobile.helper';
import { ExternalLinkIcon } from 'components/SvgIcon';
import TdtPullable from 'components/TdtPullable';

const mapState = state => ({
    publicSeminarState: state.client.seminar.publicSeminarState,
    endSeminarState: state.client.seminar.endSeminarState,
    loading: state.client.seminar.loading,
});
const mapDispatch = { getPublicSeminars, getEndSeminars };

const Seminar = ({ publicSeminarState, endSeminarState, getPublicSeminars, getEndSeminars }) => {
    const { t } = useTranslation();

    useEffect(() => {
        getPublicSeminarList();
        getEndSeminarList();
    }, []);

    const getPublicSeminarList = (
        pagination = publicSeminarState.pagination,
        filters = publicSeminarState.filters,
        sorter = publicSeminarState.sorter
    ) => {
        getPublicSeminars({ pagination, filters: { ...filters }, sorter });
    };

    const getEndSeminarList = (
        pagination = endSeminarState.pagination,
        filters = endSeminarState.filters,
        sorter = endSeminarState.sorter
    ) => {
        getEndSeminars({
            pagination: { ...pagination, pageSize: 5 },
            filters: { ...filters, anchor_time: 'before' },
            sorter,
        });
    };

    return (
        <>
            <div>
                <Row justify="space-between" align="middle">
                    <Col>
                        <h1 className="page-title mb-16 mt-48">{t('Session')}</h1>
                    </Col>
                </Row>

                <TdtPullable
                    onRefresh={() => {
                        getPublicSeminarList();
                        getEndSeminarList();
                    }}
                />

                <Typography className="seminar-category-title scheduled">{t('Scheduled session')}</Typography>
                {publicSeminarState.data.length === 0 ? (
                    <>
                        <NoData
                            messageNoData="開催予定のセッションはありません。"
                            srcImage={seminarNodata}
                            className={'seminar-nodata-image'}
                            textClassName={'nodata-text'}
                        />
                        <div className="session-link mb-44">
                            <Button
                                type="link"
                                className="fs-16 fw-b"
                                href={'https://teamdoctors.jp/#session'}
                                target={isMobileActive() ? '_top' : '_blank'}
                            >
                                {t('Td Sessionとは')}
                                <ExternalLinkIcon size={16} />
                            </Button>
                        </div>
                    </>
                ) : (
                    <SeminarBlock seminarState={publicSeminarState} type="releaseType" />
                )}
                {endSeminarState.data.length !== 0 && (
                    <Typography className="seminar-category-title past">{t('Past sessions')}</Typography>
                )}

                <SeminarBlock
                    seminarState={endSeminarState}
                    showPagination={true}
                    fetchData={getEndSeminarList}
                    type="endType"
                />
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(Seminar);

Seminar.propTypes = {};
