import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd';
import BackBtn from 'components/BackBtn';
import api from 'helpers/api.helper';
import history from 'helpers/history.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import TdtSelect from 'components/TdtSelect';
import { DateRangeIcon } from 'components/SvgIcon';
import moment from 'moment';
import FloatBar from 'components/FloatBar';
import TdtButtonSubmit from 'components/TdtButtonSubmit';
import PageElements from 'pages/admin/common/page-elements/PageElements';
import { convertDataToState } from 'pages/admin/common/page-elements/page_elements.slice';
import AddImageButton from 'components/AddImageButton';
import CommonSessionDetailPreviewModal from 'pages/admin/feature/CommonSessionDetailPreviewModal';
import { momentToStr } from 'helpers/date.helper';
import { isNullOrUndefined } from 'helpers/common.helper';
import TdtSelectTime from 'components/TdtSelectTime';
import CommonSessionChooseCompanyEnum from 'enums/common_session_choose_company.enum';
import CompaniesTable from 'pages/admin/feature/form/CompaniesTable';
import Routes from 'routes';

const mapState = state => ({
    byId: state.admin.pageElements.elements.byId,
    order: state.admin.pageElements.elements.order,
});
const mapDispatch = { convertDataToState };

const CommonSessionForm = ({ isUpdate, initialValues, byId, order, convertDataToState }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    initialValues = Object.assign(
        {
            type: CommonSessionChooseCompanyEnum.ALL_COMPANY.value,
            companies: [],
        },
        initialValues
    );

    if (initialValues.date) {
        initialValues.date = moment.utc(initialValues.date, 'YYYY年M月D日(dd)');
    }

    const [fileList, setFileList] = useState(
        initialValues.cover_img ? [{ url: `${APP_URLS.STATIC_BASE_URL}/${initialValues.cover_img}` }] : []
    );
    const [startTime, setStartTime] = useState(initialValues.start_time);
    const [listOrganizerAccount, setListOrganizerAccount] = useState([]);

    const organizerAccount = initialValues.seminar_organizer_account;

    const [typeIsAllCompany, setTypeIsAllCompany] = useState(
        initialValues.type === CommonSessionChooseCompanyEnum.ALL_COMPANY.value
    );
    const [selectedCompanyIds, setSelectedCompanyIds] = useState(
        initialValues.companies.length > 0 ? initialValues.companies.map(c => c.id) : []
    );

    const [modalVisible, setModalVisible] = useState({
        preview: false,
    });
    const showPreviewModal = () => setModalVisible({ preview: true });
    const closeModal = () => setModalVisible({ preview: false });

    const submitCommonSession = async values => {
        const formData = new FormData();
        if (fileList.length > 0) {
            formData.append('image_cover_img', fileList[0]);
        }

        // set details
        values.details = [];
        order.map(key => values.details.push(byId[key]));

        Object.keys(values).map(k => {
            if (values[k]) {
                if (Array.isArray(values[k])) {
                    const details = values[k];
                    details.map((detail, i) => {
                        Object.keys(detail).map(d => {
                            !isNullOrUndefined(detail[d]) && formData.append(`${k}[${i}][${d}]`, detail[d]);
                        });
                    });
                } else if (k === 'date') {
                    !!values[k] && formData.append(k, momentToStr(values[k]));
                } else {
                    formData.append(k, values[k]);
                }
            }
        });

        // append company_ids
        if (values.type === CommonSessionChooseCompanyEnum.SPECIFIC_COMPANIES.value) {
            selectedCompanyIds.map((id, index) => formData.append(`company_ids[${index}]`, id));
            //formData.append('company_ids', selectedCompanyIds);
        }

        const res = await (!isUpdate
            ? api.post(APP_URLS.ADMIN_COMPANIES_SEMINAR_CREATE, formData)
            : api.post(APP_URLS.ADMIN_COMPANIES_SEMINAR_UPDATE.replace(':id', initialValues.id), formData, {
                  _method: 'PUT',
              }));
        if (res) {
            showNormal('', !isUpdate ? t('message:Created a seminar') : t('message:Edited the seminar'), 3);
            history.push(Routes.private.ADMIN_COMMON_SESSIONS_DETAIL.path.replace(':id', res.id));
        }
    };

    const getListOrganizerAccount = async () => {
        const response = await api.get(APP_URLS.ADMIN_COMPANIES_SEMINAR_GET_LIST_ORGANIZER_ACCOUNTS);
        if (response) {
            setListOrganizerAccount(response);
        }
    };

    useEffect(() => {
        if (!listOrganizerAccount || listOrganizerAccount.length < 1) {
            getListOrganizerAccount().then(() => {});
        }
        if (isUpdate) {
            convertDataToState({ elements: initialValues.seminar_details });
        }
    }, []);

    useEffect(() => {
        if (fileList.length > 0) {
            form.setFieldsValue({ cover_img: fileList[0].url });
        }
    }, [fileList]);

    return (
        <div className="self-wrapper d-block px-48">
            <BackBtn label={!isUpdate ? t('Creating a session') : t('Edit session')} />

            <Form
                layout="vertical"
                form={form}
                initialValues={initialValues}
                onFinish={async values => {
                    await submitCommonSession(values);
                }}
                className="mt-32"
                requiredMark={false}
            >
                <Row gutter={16}>
                    <Col span={24} className="mb-8">
                        <Form.Item
                            name="type"
                            label={t('field:提供先企業')}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            className={typeIsAllCompany ? `mb-16` : 'mb-32'}
                        >
                            <Radio.Group
                                className="d-flex"
                                onChange={e => {
                                    setTypeIsAllCompany(
                                        e.target.value === CommonSessionChooseCompanyEnum.ALL_COMPANY.value
                                    );
                                }}
                            >
                                <Radio value={CommonSessionChooseCompanyEnum.ALL_COMPANY.value} className="mr-16">
                                    {t(`enum:${CommonSessionChooseCompanyEnum.ALL_COMPANY.text}`)}
                                </Radio>
                                <Radio value={CommonSessionChooseCompanyEnum.SPECIFIC_COMPANIES.value}>
                                    {t(`enum:${CommonSessionChooseCompanyEnum.SPECIFIC_COMPANIES.text}`)}
                                </Radio>
                            </Radio.Group>
                        </Form.Item>

                        {!typeIsAllCompany && (
                            <CompaniesTable
                                selectedCompanyIds={selectedCompanyIds}
                                setSelectedCompanyIds={setSelectedCompanyIds}
                            />
                        )}
                    </Col>
                    <Col span={24} className="mb-8">
                        <Form.Item
                            name="name"
                            label={
                                <>
                                    <span>{'名称'}</span>
                                </>
                            }
                            rules={[
                                {
                                    required: isUpdate && initialValues.name,
                                    message: t('validation:No title has been entered'),
                                },
                            ]}
                        >
                            <Input maxLength={255} style={{ maxWidth: 320 }} />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mb-8">
                        <Form.Item name="description" label={'概要説明'}>
                            <Input.TextArea style={{ maxWidth: 480, height: 172 }} />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mb-8">
                        <Form.Item name="seminar_organizer_account_id" label={'主催者アカウント'}>
                            <TdtSelect
                                size="large"
                                className="fs-14 select-gray"
                                placeholder={'アカウントを選択'}
                                style={{ maxWidth: 320 }}
                            >
                                {!!organizerAccount && (
                                    <Select.Option value={organizerAccount.id}>
                                        {organizerAccount.full_name}
                                    </Select.Option>
                                )}
                                {listOrganizerAccount
                                    .filter(o => !organizerAccount || o.id !== organizerAccount.id)
                                    .map(o => (
                                        <Select.Option key={o.id} value={o.id}>
                                            {o.full_name}
                                        </Select.Option>
                                    ))}
                            </TdtSelect>
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mb-8">
                        <Form.Item label={'日時'}>
                            <Form.Item name="date" noStyle>
                                <DatePicker
                                    placeholder={'日時を選択'}
                                    className="mr-16 bg-gray tdt-datepicker reservation-date-picker"
                                    dropdownClassName="tdt-datepicker-panel z-index-above-modal"
                                    disabledDate={date => {
                                        if (!date.isAfter(moment().startOf('day'))) {
                                            return true;
                                        }
                                    }}
                                    style={{ maxWidth: 256 }}
                                    suffixIcon={<DateRangeIcon />}
                                    format="YYYY年M月D日(dd)"
                                    value={form.getFieldValue('date')}
                                    allowClear={false}
                                    showToday={false}
                                    onChange={date => {
                                        form.setFieldsValue({ date: date });
                                    }}
                                    inputReadOnly={true}
                                />
                            </Form.Item>
                            <Form.Item name="start_time" noStyle>
                                <TdtSelectTime
                                    style={{ width: 168 }}
                                    className="mr-16"
                                    placeholder={'開始時刻を選択'}
                                    onChange={v => {
                                        setStartTime(v);
                                        if (v >= form.getFieldValue('end_time')) {
                                            form.setFieldsValue({ end_time: null });
                                        }
                                    }}
                                />
                            </Form.Item>
                            <Form.Item name="end_time" noStyle>
                                <TdtSelectTime
                                    style={{ width: 168 }}
                                    className="mr-16"
                                    placeholder={'終了時刻を選択'}
                                    minTime={startTime}
                                />
                            </Form.Item>
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mb-8">
                        <Form.Item label={'カバー画像'}>
                            <Form.Item name="cover_img" noStyle>
                                <Input hidden />
                            </Form.Item>
                            <AddImageButton className="d-flex mb-8" fileList={fileList} setFileList={setFileList} />
                            {!fileList.length && (
                                <div className="body2 color-blg-60">{'推奨サイズ: 1024px × 512px'}</div>
                            )}
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <div className="mb-40">
                            <PageElements />
                        </div>
                    </Col>
                </Row>

                <CommonSessionDetailPreviewModal
                    visible={modalVisible.preview}
                    onClose={() => closeModal()}
                    info={form.getFieldsValue()}
                    details={order.map(key => byId[key])}

                />

                <FloatBar>
                    <TdtButtonSubmit type="primary" className="fw-b ml-16" htmlType="submit">
                        {t('Save')}
                    </TdtButtonSubmit>
                    <Button className="fw-b" onClick={() => showPreviewModal()}>
                        {t('Preview')}
                    </Button>
                </FloatBar>
            </Form>
        </div>
    );
};

export default connect(mapState, mapDispatch)(CommonSessionForm);

CommonSessionForm.propTypes = {
    isUpdate: PropTypes.bool,
    initialValues: PropTypes.object,
    byId: PropTypes.object,
    order: PropTypes.array,
};
