import React, { useEffect, useState } from 'react';
import DistributionForm from 'pages/admin/email/distribution/form/DistributionForm';
import { useParams } from 'react-router-dom';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';

const DistributionUpdate = () => {
    const { id } = useParams();
    const [distributionList, setDistributionList] = useState({});
    const [loading, setLoading] = useState(true);

    const getDistributionList = async id => {
        const response = await api.get(APP_URLS.ADMIN_EMAIL_DISTRIBUTIONS_DETAIL.replace(':id', id));
        if (response) {
            setDistributionList(response);
            setLoading(false);
        }
    };

    useEffect(() => {
        getDistributionList(id).then(() => {});

        return () => {
            setDistributionList({});
            setLoading(true);
        };
    }, [id]);

    if (loading) return <></>;

    return <DistributionForm isUpdate={true} initialValues={distributionList} />;
};

export default DistributionUpdate;
