import React from 'react';
import PropTypes from 'prop-types';
import SeminarForm from 'pages/admin/company/tabs/seminar/form/SeminarForm';

const SeminarCreate = ({ company, baseUrl }) => {
    return <SeminarForm company={company} baseUrl={baseUrl} />;
};

export default SeminarCreate;

SeminarCreate.propTypes = {
    company: PropTypes.object,
    baseUrl: PropTypes.string,
};
