import React from 'react';

const NoImage = () => {
    return (
        <span className="no-image-as-image">
            <span className="pd-4">{'No Image'}</span>
        </span>
    );
};

export default NoImage;
