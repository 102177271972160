import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, Select, Typography } from 'antd';
import Routes from 'routes';
import { BaseStatePropShape, InterviewSheetPropTypes } from 'constants/proptypes.constant';
import history from 'helpers/history.helper';
import DataTable from 'components/DataTable';
import { AddIcon, DeleteIcon, DotsHorizontalRoundedIcon, DuplicateIcon, EditIcon } from 'components/SvgIcon';
import {
    getData,
    setFilters,
    setSorter,
    setPagination,
} from 'pages/admin/interview_sheet/interview_sheet.slice';
import FloatBar from 'components/FloatBar';
import TdtButton from 'components/TdtButton';
import CustomDropdown from 'components/CustomDropdown';
import DeleteInterviewSheetModal from 'pages/admin/interview_sheet/modal/DeleteInterviewSheetModal';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import CloneInterviewSheetModal from 'pages/admin/interview_sheet/modal/CloneInterviewSheetModal';
import InputSearch from '../../../components/InputSearch';
import HandleFilterQuestionnaireEnum from '../../../enums/handle_filter_questionnaire.enum';
import { getEnumText } from '../../../helpers/enum.helper';
import TdtSelect from '../../../components/TdtSelect';

const mapState = state => ({ interviewSheetState: state.admin.interviewSheet, user: state.auth.user });
const mapDispatch = { getData, setSorter, setFilters};
const InterviewSheet = ({ interviewSheetState, setFilters, setSorter, getData }) => {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState({
        clone: false,
        delete: false,
    });

    const closeModal = () =>
        setModalVisible({
            clone: false,
            delete: false,
        });
    const showCloneModal = r => setModalVisible({ ...modalVisible, clone: true, record: r });
    const showDeleteModal = r => setModalVisible({ ...modalVisible, delete: true, record: r });

    const deleteInterviewSheet = async interviewSheetId => {
        const response = await api.delete(APP_URLS.ADMIN_INTERVIEW_SHEETS_DETAIL.replace(':id', interviewSheetId));
        if (response) {
            showNormal('', t('message:Data has been deleted'));
            history.push(Routes.private.ADMIN_INTERVIEW_SHEET.path);
            getInterviewSheets();
        }
    };

    const columns = [
        {
            title: t('Name'),
            dataIndex: 'name',
            sorter: true,
            // render: (_, r) => <span className={'word-break-all'}>{r && r.name ? `${r.name}` : t('Not set')}</span>,
            render: (name, r) => {
                return (
                    <div className='d-flex align-items-center'>
                        {name}
                        {r.programs_count > 0 && (
                            <div className='small-note-interview-sheet ml-10 bg-white'>
                                <Typography className='text-small-note-interview-sheet '>
                                    {t('Set in the program')}
                                </Typography>
                            </div>
                        )}
                    </div>
                );
            },
        },
        {
            title: '回答数',
            dataIndex: 'number_of_answers',
            className: 'text-right',
            sorter: (a, b) => {
                const countA = a.number_of_answers;
                const countB = b.number_of_answers;
                return countA > countB ? 1 : countA < countB ? -1 : 0;
            },
            width: 88,
        },
        {
            title: t('Edit'),
            dataIndex: 'EDIT',
            className: 'white-space-nowrap text-align-center',
            render: (_, r) => {
                return (
                    <TdtButton
                        buttonStyle='plain'
                        icon={<EditIcon />}
                        onClick={e => {
                            e.stopPropagation();
                            history.push(
                                Routes.private.ADMIN_INTERVIEW_SHEETS_UPDATE.path.replace(':id', r.id),
                            );
                        }}
                    />
                );
            },
            width: 80,
        },
        {
            title: '',
            dataIndex: 'dots-select',
            render: (_, r) => {
                return (
                    <CustomDropdown
                        ovlClassName={'dots-dropdown z-index-above-modal'}
                        getPopupContainer={node => node}
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        trigger={['click']}
                        options={[
                            {
                                key: 'duplicate',
                                text: (
                                    <>
                                        <DuplicateIcon /> <span>{'複製'}</span>
                                    </>
                                ),
                                className: 'primary-color fw-b',
                            },
                            {
                                key: 'delete',
                                text: (
                                    <>
                                        <DeleteIcon /> <span>{'削除'}</span>
                                    </>
                                ),
                                className: 'pink-color fw-b',
                            },
                        ]}
                        onSelect={opt => {
                            opt.domEvent.stopPropagation();
                            if (opt.key === 'delete') {
                                showDeleteModal(r);
                            } else {
                                showCloneModal(r);
                            }
                        }}
                        separator
                    >
                        <DotsHorizontalRoundedIcon className=' dots-icon c-pointer' />
                    </CustomDropdown>
                );
            },
            width: 80,
        },
    ];

    const getInterviewSheets = (
        pagination = interviewSheetState.pagination,
        filters = interviewSheetState.filters,
        sorter = interviewSheetState.sorter,
    ) => {
        if (!sorter || !sorter.field) {
            sorter = interviewSheetState.sorter;
        }
        setSorter(sorter);
        getData({ pagination, filters, sorter });
    };

    useEffect(() => {
        getInterviewSheets(interviewSheetState.pagination, interviewSheetState.filters, interviewSheetState.sorter);
    }, []);

    const keywordChangeHandler = value => {
        const newFilters = Object.assign({}, interviewSheetState.filters, {
            keyword: value,
        });
        setFilters(newFilters);
        getInterviewSheets(interviewSheetState.pagination, newFilters, interviewSheetState.sorter);
    };

    return (
        <div className='self-wrapper d-block'>
            <h1 className='page-title mb-24'>{t('Interview sheet')}</h1>
            <div className='p-0'>
                <Row gutter={16}>
                    <Col span={24} className='d-flex'>
                        <InputSearch
                            style={{ maxWidth: 240 }}
                            onSearch={value => keywordChangeHandler(value)}
                            placeholder={'名称で検索'}
                        />
                        <TdtSelect
                            size="large"
                            className="fs-14 ml-8"
                            style={{ width: 240 }}
                            defaultValue="all"
                            value={interviewSheetState.filters.rule || 'all'}
                            onChange={value => {
                                const newFilters = Object.assign({}, interviewSheetState.filters, { rule: value });
                                const newPagination = { ...interviewSheetState.pagination, current: 1 };
                                setFilters(newFilters);
                                setPagination(newPagination);
                                getInterviewSheets(newPagination, newFilters, interviewSheetState.sorter);
                            }}
                        >
                            <Select.Option value="all">{'すべて'}</Select.Option>
                            {Object.values(HandleFilterQuestionnaireEnum).map(o => (
                                <Select.Option key={o.value} value={o.value}>
                                    {t(`enum:${getEnumText(HandleFilterQuestionnaireEnum, o.value)}`)}
                                </Select.Option>
                            ))}
                        </TdtSelect>
                    </Col>
                </Row>
            </div>
            <DataTable
                className={`mt-24 collapse-table `}
                columns={columns}
                noDataText={'登録されている質問票はありません'}
                hideColumns={interviewSheetState.hideColumns}
                data={interviewSheetState.data}
                loading={interviewSheetState.loading}
                pagination={interviewSheetState.pagination}
                filters={interviewSheetState.filters}
                sorter={interviewSheetState.sorter}
                onTableChange={(pagination, filter, sorter) => {
                    getInterviewSheets(pagination, interviewSheetState.filters, sorter);
                }}
                onRow={record => {
                    return {
                        onClick: () => {
                            history.push(Routes.private.ADMIN_INTERVIEW_SHEETS_DETAIL.path.replace(':id', record.id));
                        },
                    };
                }}
            />
            <DeleteInterviewSheetModal
                onClose={() => closeModal()}
                onUpdate={() => deleteInterviewSheet(modalVisible.record.id)}
                visible={modalVisible.delete}
                interviewSheet={modalVisible.record}
            />
            {modalVisible.record && (
                <CloneInterviewSheetModal
                    onClose={() => {
                        closeModal();
                    }}
                    visible={modalVisible.clone}
                    interviewSheet={modalVisible.record}
                />
            )}
            <FloatBar>
                <Button
                    type='primary'
                    icon={<AddIcon />}
                    className='fw-b'
                    onClick={() => history.push(`${Routes.private.ADMIN_INTERVIEW_SHEETS_CREATE.path}?new=1`)}
                >
                    {t('Create new')}
                </Button>
            </FloatBar>
        </div>
    );
};

export default connect(mapState, mapDispatch)(InterviewSheet);

InterviewSheet.propTypes = {
    interviewSheetState: PropTypes.shape(
        Object.assign({}, BaseStatePropShape, {
            data: PropTypes.arrayOf(InterviewSheetPropTypes).isRequired,
        }),
    ),
    getData: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
};
