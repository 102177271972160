import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import DeleteBtnInModal from 'components/DeleteBtnInModal';
import { useTranslation } from 'react-i18next';

const DeleteUserModal = ({ visible, onClose, onUpdate, existCreatedReservation = false }) => {
    const { t } = useTranslation();
    const titleContent = existCreatedReservation ? (
        <span className={'not-delete-title'}>この相談者は削除できません</span>
    ) : (
        <>{t('Deletion of consultant')}</>
    );

    return (
        <Modal
            title={titleContent}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <span>
                {existCreatedReservation
                    ? t('There is a consultation reservation that has not been implemented yet so it cannot be deleted')
                    : t('Delete the counselor. This operation can not be rejected. Is it OK')}
            </span>
            {existCreatedReservation ? (
                <div className="delete-action-panel">
                    <Button
                        type=""
                        className="cancel-button fw-b"
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <span className={'title-button'}>{'OK'}</span>
                    </Button>
                </div>
            ) : (
                <DeleteBtnInModal onClose={onClose} onUpdate={onUpdate} />
            )}
        </Modal>
    );
};

DeleteUserModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default DeleteUserModal;
