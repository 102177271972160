const ActivityLogEnum = Object.freeze({
    USER: { value: 'user', text: 'ユーザー' },
    STAFF: { value: 'staff', text: 'スタッフ' },
    DOCTOR: { value: 'doctor', text: 'カウンセラー' },
    ADMIN: { value: 'admin', text: '管理者' },
    ADMIN_STAFF: { value: 'admin_staff', text: '管理スタッフ' },
    CREATE: { value: 'created', text: '作成' },
    UPDATE: { value: 'updated', text: '更新' },
    DELETE: { value: 'deleted', text: '削除' },
    SEND: { value: 'send', text: '送信' },
    CREATE_COMPANY_CODE: { value: 'create_company_code', text: '企業コード新規発行' },
    RESET_COMPANY_CODE: { value: 'reset_company_code', text: '企業コード再発行' },
    ADD_COUNSELOR_PROGRAM: { value: 'add_counselor_program', text: 'カウンセラー追加' },
    CREATE_INTERVIEW_SHEET: { value: 'create_interview_sheet', text: '' },
    UPDATE_INTERVIEW_SHEET: { value: 'update_interview_sheet', text: '' },
    DELETE_INTERVIEW_SHEET: { value: 'delete_interview_sheet', text: '' },
    CREATE_PROGRAM: { value: 'create_program', text: '' },
    UPDATE_PROGRAM: { value: 'update_program', text: '' },
    DELETE_PROGRAM: { value: 'delete_program', text: '' },
    CREATE_STAFF: { value: 'staff_create', text: '' },
    DELETE_STAFF: { value: 'staff_delete', text: '' },
    DELETE_COUNSELOR: { value: 'delete_counselor', text: '' },
    UPDATE_COUNSELOR_PROGRAM_RESERVATION_FRAME: { value: 'update_counselor_program_reservation_frame', text: '' },
    REGISTER_ACCOUNT: { value: 'account_register', text: 'アカウント登録' },
    EDIT_ACCOUNT: { value: 'account_edit', text: 'アカウント編集' },
    UPDATE_PASSWORD: { value: 'password_update', text: 'パスワード更新' },
    RESET_PASSWORD: { value: 'password_reset', text: 'パスワード再発行' },
    ACCOUNT_VERIFICATION: { value: 'account_verification', text: 'アカウント認証' },
    CHANGE_STATE_COMPANY: { value: 'change_state_company', text: '更新' },
    SESSION_TIME_EDIT: { value: 'session_time_edit', text: '予約枠編集' },
    ROLE_DOCTOR_STAFF: { value: 'staff,doctor', text: 'カウンセラーアクセスログ' },
    ROLE_USER: { value: 'user', text: '相談者アクセスログ' },
    ROLE_ADMIN: { value: 'admin', text: '管理者アクセスログ' },
    ROLE_ADMIN_STAFF: { value: 'admin_staff', text: '管理スタッフアクセスログ' },
    DUPLICATE: { value: 'duplicate', text: '複製' },

    // new log enums
    Account_EditName: { value: 'Account_EditName', text: 'アカウント (名前編集 )'},
    Account_EditEmailAddress: { value: 'Account_EditEmailAddress', text: 'アカウント (メールアドレス編集 )'},
    Account_ChangePassword: { value: 'Account_ChangePassword', text: 'アカウント (パスワード変更)'},

    Company_NewRegistration_CompanyName: { value: 'Company_NewRegistration_CompanyName', text: '企業 ( 新規登録 )'},
    Company_Edit_CompanyName: { value: 'Company_Edit_CompanyName', text: '企業名編集'},

    Company_Employee_CounselorInformation_Edit_Program: { value: 'Company_Employee_CounselorInformation_Edit_Program', text: '社員 ( 相談者情報 編集 )'},
    Company_Employee_CounselorInformation_Edit_DeleteEmployee: { value: 'Company_Employee_CounselorInformation_Edit_DeleteEmployee', text: '社員 ( 相談者の削除 )'},

    Company_Employee_ConsultationDate_CreateNewReservation: { value: 'Company_Employee_ConsultationDate_CreateNewReservation', text: '相談 ( 新規予約作成 )'},
    Company_Employee_ConsultationDate_MedicalRecordCreation: { value: 'Company_Employee_ConsultationDate_MedicalRecordCreation', text: '相談 ( カルテ作成 )'},
    Company_Employee_ConsultationDate_FBSending: { value: 'Company_Employee_ConsultationDate_FBSending', text: '相談 ( フィードバック 送信 )'},
    Company_Employee_ConsultationDate_DeleteReservation: { value: 'Company_Employee_ConsultationDate_DeleteReservation', text: '相談 ( 予約削除 )'},
    Company_Employee_ConsultationDate_EditReservation: { value: 'Company_Employee_ConsultationDate_EditReservation', text: '相談 ( 予約編集 )'},
    Company_Employee_ConsultationDate_CancellationOfConsultation: { value: 'Company_Employee_ConsultationDate_CancellationOfConsultation', text: '相談 ( 相談の中止 )'},

    Company_Employee_Message_Create: { value: 'Company_Employee_Message_Create', text: '新規メッセージ作成'},
    Company_Employee_Message_CloseMessageExchange: { value: 'Company_Employee_Message_CloseMessageExchange', text: '社員( メッセージやり取りをクローズする )'},
    Company_Employee_Message_CannotReply: { value: 'Company_Employee_Message_CannotReply', text: '社員( メッセージ 返信不可 )'},
    Company_Employee_Message_Send: { value: 'Company_Employee_Message_Send', text: '社員( メッセージ送信 )'},

    Company_Program_AddProgram: { value: 'Company_Program_AddProgram', text: 'プログラム追加'},
    Company_Program_DeleteProgram: { value: 'Company_Program_DeleteProgram', text: 'プログラム削除'},
    Company_Program_AddedProgramCounselor: { value: 'Company_Program_AddedProgramCounselor', text: 'プログラムカウンセラー追加'},
    Company_Program_EditReservationSlot: { value: 'Company_Program_EditReservationSlot', text: '予約枠編集'},

    Company_Notice_ManualNotification_FreeText: { value: 'Company_Notice_ManualNotification_FreeText', text: 'お知らせ ( フリーテキスト )'},
    Company_Notice_ManualNotification_NewPrograms: { value: 'Company_Notice_ManualNotification_NewPrograms', text: 'お知らせ ( 新着プログラム )'},
    Company_Notice_ManualNotification_Session: { value: 'Company_Notice_ManualNotification_Session', text: 'お知らせ ( セッション )'},

    Company_Settings_CompanyCodeReissue: { value: 'Company_Settings_CompanyCodeReissue', text: '設定 ( 企業コード再発行 )'},
    Company_Settings_StageChange: { value: 'Company_Settings_StageChange', text: '設定 ( ステージ変更 )'},
    Company_Settings_DeleteCompany: { value: 'Company_Settings_DeleteCompany', text: '設定 ( 企業の削除 )'},

    Member_NewMemberRegistration: { value: 'Member_NewMemberRegistration', text: 'メンバー ( 新規登録 )'},
    Member_MemberDetails_RoleChange: { value: 'Member_MemberDetails_RoleChange', text: 'メンバー ( ロール変更 )'},
    Member_MemberDetails_EmailAddressChange: { value: 'Member_MemberDetails_EmailAddressChange', text: 'メンバー ( メールアドレス変更 )'},
    Member_MemberDetails_DeleteMember: { value: 'Member_MemberDetails_DeleteMember', text: 'メンバー ( メンバーの削除 )'},
    Member_MemberDetails_AddedCompaniesAndPrograms: { value: 'Member_MemberDetails_AddedCompaniesAndPrograms', text: 'メンバー ( 担当企業・・プログラム追加 )'},
    Member_MemberDetails_ResponsibleCompanyProgramEditing: { value: 'Member_MemberDetails_ResponsibleCompanyProgramEditing', text: 'メンバー ( 担当企業・・プログラム編集 )'},
    Member_MemberDetails_ResponsibleCompanyProgramDeletion: { value: 'Member_MemberDetails_ResponsibleCompanyProgramDeletion', text: 'メンバー ( 担当企業・・プログラム削除 )'},
    Member_MemberDetails_ResponsibleCompanyProgramDuplication: { value: 'Member_MemberDetails_ResponsibleCompanyProgramDuplication', text: 'メンバー ( 担当企業・・プログラム複製 )'},

    Program_ProgramRegistration: { value: 'Program_ProgramRegistration', text: 'プログラム登録'},
    Program_DeleteProgram: { value: 'Program_DeleteProgram', text: 'プログラム削除'},
    Program_ProgramReplication: { value: 'Program_ProgramReplication', text: 'プログラム複製'},
    Program_DisplayOrderSettings: { value: 'Program_DisplayOrderSettings', text: '表示順設定'},

    Session_SignUp: { value: 'Session_SignUp', text: 'セッション ( 新規登録 )'},
    Session_Detail_Release: { value: 'Session_Detail_Release', text: 'セッション ( 公開)'},
    Session_Detail_DiscontinuedPublication: { value: 'Session_Detail_DiscontinuedPublication', text: 'セッション ( 公開停止)'},
    Session_Detail_Edit: { value: 'Session_Detail_Edit', text: 'セッション ( 編集)'},
    Session_Detail_AddedSurvey: { value: 'Session_Detail_AddedSurvey', text: 'セッション (アンケート追加)'},
    Session_Detail_ArchiveDownload: { value: 'Session_Detail_ArchiveDownload', text: 'セッション ( アーカイブダウンロード)'},
    Session_Detail_ArchivePublicPrivate: { value: 'Session_Detail_ArchivePublicPrivate', text: 'セッション ( アーカイブ公開・非公開)'},
    Session_Detail_NotificationDistributionEditing: { value: 'Session_Detail_NotificationDistributionEditing', text: 'セッション ( お知らせ配信編集)'},
    Session_Detail_StopNotificationDelivery: { value: 'Session_Detail_StopNotificationDelivery', text: 'セッション ( お知らせ配信停止)'},

    Email_Distribution_CreateNew_SaveDraft: { value: 'Email_Distribution_CreateNew_SaveDraft', text: 'メール ( 下書き保存 )'},
    Email_Distribution_CreateNew_Send: { value: 'Email_Distribution_CreateNew_Send', text: 'メール（送信予約）'},
    Email_Distribution_Duplicate: { value: 'Email_Distribution_Duplicate', text: 'メール（複製）'},
    Email_Distribution_Edit: { value: 'Email_Distribution_Edit', text: 'メール ( 編集 )'},
    Email_Distribution_Delete: { value: 'Email_Distribution_Delete', text: 'メール ( 削除 )'},

    Email_DistributionList_CreateNew: { value: 'Email_DistributionList_CreateNew', text: '配信リスト ( 新規作成 )'},
    Email_DistributionList_Edit: { value: 'Email_DistributionList_Edit', text: '配信リスト ( 編集 )'},
    Email_DistributionList_Duplicate: { value: 'Email_DistributionList_Duplicate', text: '配信リスト ( 複製 )'},
    Email_DistributionList_Delete: { value: 'Email_DistributionList_Delete', text: '配信リスト ( 削除 )'},

    Questionnaire_CreateNew: { value: 'Questionnaire_CreateNew', text: '質問票 ( 新規作成)'},
    Questionnaire_Detail_Delete: { value: 'Questionnaire_Detail_Delete', text: '質問票 ( 削除 )'},
    Questionnaire_Detail_Edit: { value: 'Questionnaire_Detail_Edit', text: '質問票 (  編集)'},
    Questionnaire_Detail_Duplicate: { value: 'Questionnaire_Detail_Duplicate', text: '質問票 ( 複製 )'},
    Questionnaire_Detail_NewRegistrationForResponsePeriod: { value: 'Questionnaire_Detail_NewRegistrationForResponsePeriod', text: '質問票 ( 回答期間新規登録 )'},
    Questionnaire_Detail_EditResponsePeriod: { value: 'Questionnaire_Detail_EditResponsePeriod', text: '質問票 ( 回答期間編集 )'},
    Questionnaire_Detail_DeleteResponsePeriod: { value: 'Questionnaire_Detail_DeleteResponsePeriod', text: '質問票 ( 回答期間削除 )'},
    Questionnaire_Detail_CSVDownload: { value: 'Questionnaire_Detail_CSVDownload', text: '質問票 ( CSVダウンロード)'},

    Log_CSVDownload: { value: 'Log_CSVDownload', text: 'ログ ( CSVダウンロード )'},

    // counselor
    CounselorAccountInformation_EditName: { value: 'CounselorAccountInformation_EditName', text: 'アカウント( 名前編集 )'},
    CounselorAccountInformation_EmailAddressChange: { value: 'CounselorAccountInformation_EmailAddressChange', text: 'アカウント (メールアドレス編集 )'},
    CounselorAccountInformation_ChangePassword: { value: 'CounselorAccountInformation_ChangePassword', text: 'アカウント (パスワード変更)'},

    Calendar_BlockReservationSlots: { value: 'Calendar_BlockReservationSlots', text: 'カレンダー ( 予約枠をブロック )'},
    Calendar_CreateNewReservation: { value: 'Calendar_CreateNewReservation', text: 'カレンダー ( 新規予約作成 )'},

    Consultation_Details_EditReservation: { value: 'Consultation_Details_EditReservation', text: '相談 ( 予約編集 )'},
    Consultation_Details_DeleteReservation: { value: 'Consultation_Details_DeleteReservation', text: '相談 ( 予約削除 )'},
    Consultation_Details_StartConsultation: { value: 'Consultation_Details_StartConsultation', text: '相談 ( 相談開始 )'},
    Consultation_Details_MedicalRecordCreation: { value: 'Consultation_Details_MedicalRecordCreation', text: '相談 ( カルテ作成 )'},
    Consultation_Details_MedicalRecordEditing: { value: 'Consultation_Details_MedicalRecordEditing', text: '相談 ( カルテ編集 )'},
    Consultation_Details_Feedback: { value: 'Consultation_Details_Feedback', text: '相談 ( フィードバック )'},

    Message_CreateNew: { value: 'Message_CreateNew', text: 'メッセージ ( 新規作成 )'},

    // user
    AccountInformation_EditName: { value: 'AccountInformation_EditName', text: 'アカウント( 名前編集 )'},
    AccountInformation_EmailAddressChange: { value: 'AccountInformation_EmailAddressChange', text: 'アカウント (メールアドレス編集 )'},
    AccountInformation_ChangePassword: { value: 'AccountInformation_ChangePassword', text: 'アカウント (パスワード変更)'},

    Consultation_CreateNew: { value: 'Consultation_CreateNew', text: '相談 ( 新規作成 )'},
    Consultation_Edit: { value: 'Consultation_Edit', text: '相談 ( 編集 )'},
    Consultation_Delete: { value: 'Consultation_Delete', text: '相談 ( 削除 )'},

    Message_Send: { value: 'Message_Send', text: 'メッセージ ( 送信 )'},

    Questionnaire_FillSend: { value: 'Questionnaire_FillSend', text: '質問票 ( 送信 )'},

    HealthData_ItemInput: { value: 'HealthData_ItemInput', text: '健康データ ( 項目入力 )'},
});

export default ActivityLogEnum;
