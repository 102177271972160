import React from 'react';
import PropTypes from 'prop-types';
import TdtButton from 'components/TdtButton';
import { ArrowDownIcon, ArrowUpIcon, CopyIcon, DeleteIcon } from 'components/SvgIcon';
import { Switch, Typography } from 'antd';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { duplicateQuestion, moveQuestionDown, moveQuestionUp, removeQuestion } from '../interview_sheet_create.slice';

const mapDispatch = { removeQuestion, duplicateQuestion, moveQuestionUp, moveQuestionDown };
const FloatBarQuestion = ({
    id,
    removeQuestion,
    duplicateQuestion,
    moveQuestionUp,
    moveQuestionDown,
    isRequired,
    setIsRequired,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="hor-line-slim mt-24 ml--24 mr--24"></div>
            <div className="d-flex align-items-center justify-content-flex-end pt-12 pb-12 mb--24">
                <TdtButton
                    key="up"
                    buttonStyle="plain"
                    icon={<ArrowUpIcon />}
                    className="text-gray-color"
                    onClick={() => moveQuestionUp({ id })}
                />
                <TdtButton
                    key="down"
                    buttonStyle="plain"
                    icon={<ArrowDownIcon />}
                    className="ml-32 text-gray-color"
                    onClick={() => moveQuestionDown({ id })}
                />
                <TdtButton
                    key="duplicate"
                    buttonStyle="plain"
                    icon={<CopyIcon />}
                    className="ml-32 text-gray-color"
                    onClick={() => duplicateQuestion({ id })}
                />
                <TdtButton
                    key="delete"
                    buttonStyle="plain"
                    icon={<DeleteIcon />}
                    className="ml-32 text-gray-color"
                    onClick={() => removeQuestion({ id })}
                />
                <div className="ver-line-slim ml-26" />
                <Typography className="ml-16 fs-14 fw-400 color-blg-100 ">{t('必須')}</Typography>
                <div className="ml-8 d-flex align-items-center">
                    <Switch
                        checked={isRequired}
                        onClick={checked =>
                            setIsRequired({
                                id,
                                isRequired: checked,
                            })
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default connect(null, mapDispatch)(FloatBarQuestion);

FloatBarQuestion.propTypes = {
    id: PropTypes.any.isRequired,
    removeQuestion: PropTypes.func.isRequired,
    duplicateQuestion: PropTypes.func.isRequired,
    moveQuestionUp: PropTypes.func.isRequired,
    moveQuestionDown: PropTypes.func.isRequired,
};
