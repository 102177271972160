import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { connect } from 'react-redux';
import { overviewSelector } from 'pages/admin/interview_sheet/detail_screen/interview_sheet_detail.slice';
import { dateToStr } from 'helpers/date.helper';
import { emailDeletedShow } from 'helpers/string.helper';
import { parseQuestionnaire } from 'helpers/questionnaire.helper';
import moment from 'moment';
import QuestionTypeEnum from 'enums/question_type.enum';
import { getEnumText } from 'helpers/enum.helper';
import { useTranslation } from 'react-i18next';
import DailyLifeEventEnum from 'enums/daily_life_event.enum';

const mapState = state => overviewSelector(state.admin.interviewSheetDetail);
const InterviewSheetCsvDownload = forwardRef(({ answers: data, questions, interviewSheet }, ref) => {
    const { t } = useTranslation();
    const [dataInterviewSheet, setDataInterviewSheet] = useState([]);
    const [fileName, setFileName] = useState('');
    const csvLink = useRef();

    useImperativeHandle(ref, () => ({
        runExport() {
            convertDatToCSV();
        },
    }));

    useEffect(() => {
        setFileName(`INTERVIEW_SHEET_${moment().unix()}.csv`);
    }, []);

    const convertDatToCSV = () => {
        let csvData = [];
        if (data) {
            let arrQuestionStrings = [];
            questions.map((question, index) => {
                if (question.type === QuestionTypeEnum.DAILY_LIFE.value) {
                    Object.values(DailyLifeEventEnum).map(o => {
                        arrQuestionStrings.push(
                            `Q${index + 1}: ${question.value}\n${t(`enum:${getEnumText(DailyLifeEventEnum, o.value)}`)}`
                        );
                    });
                } else {
                    arrQuestionStrings.push(`Q${index + 1}: ${question.value}`);
                }
            });

            csvData.push(['企業名', '氏名', '送信日時', '点', 'スコア', ...arrQuestionStrings]);
            data.forEach(record => {
                const result = parseQuestionnaire({
                    questions: questions,
                    listAnswers: JSON.parse(record.json_answers),
                });
                const matchInfoByScore = interviewSheet.info_by_scores.find(
                    o => o.score_from <= result.totalScore && o.score_to >= result.totalScore
                );

                //
                let arrAnswerStrings = [];
                result.questions.map(question => {
                    const { answers } = question;
                    let answerResult = '';

                    if (question.type === QuestionTypeEnum.DAILY_LIFE.value) {
                        const values = answers[0] ? answers[0].value || [] : [];
                        Object.values(DailyLifeEventEnum).map(o => {
                            arrAnswerStrings.push(
                                values
                                    .filter(v => v.type === o.value)
                                    .map(v => `${v.start}-${v.end}`)
                                    .join(', ')
                            );
                        });
                        return;
                    }

                    answers.map((answer, index) => {
                        const val = answer.otherValue ? `${answer.value}: ${answer.otherValue}` : answer.value;
                        answerResult += val;
                        if (index < answers.length - 1) {
                            answerResult += ', ';
                        }
                    });
                    arrAnswerStrings.push(answerResult);
                });

                csvData.push([
                    record.user.employee_company[0].name,
                    record.user.full_name,
                    // emailDeletedShow(record.user.email),
                    dateToStr(record.created_at, 'YYYY/MM/DD HH:mm'),
                    result.totalScore,
                    matchInfoByScore ? matchInfoByScore.title : '',
                    ...arrAnswerStrings,
                ]);
            });
            setDataInterviewSheet(csvData);
            setTimeout(() => {
                csvLink.current.link.click();
            }, 100);
        }
    };
    return (
        <div>
            <CSVLink data={dataInterviewSheet} filename={fileName} target="_blank" ref={csvLink} />
        </div>
    );
});

export default connect(mapState, null, null, { forwardRef: true })(InterviewSheetCsvDownload);
