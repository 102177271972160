import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Routes from 'routes';
import NotFound from 'pages/error/NotFound';
import SidebarCounselorCalendar from 'layouts/SidebarCounselorCalendar';

const LayoutCounselorCalendar = () => {
    return (
        <div id="main-layout" className="self-layout">
            <SidebarCounselorCalendar />
            <div id="container-none-user" className="pl-0">
                <Switch>
                    {Object.keys(Routes.private).map(key => {
                        const r = Routes.private[key];
                        let component = r.component;
                        return <Route key={key} path={r.path} exact={r.exact !== false} component={component} />;
                    })}
                    <Route path="*">
                        <NotFound />
                    </Route>
                </Switch>
            </div>
        </div>
    );
};

export default LayoutCounselorCalendar;
