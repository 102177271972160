import axios from 'axios';
import moment from 'moment';
import { APP_URLS } from 'constants/url.constant';
import { showError } from 'helpers/notification.helper';
import { isEmpty } from 'helpers/common.helper';
import { ResponseCode } from 'constants/response_code.constant';

const instance = axios.create({
    baseURL: `${APP_URLS.BASE_URL}/api`,
    responseType: 'json',
});

const api = {
    accessToken: null,
    message: {
        error: 'エラー',
        serverError: 'サーバーエラー',
        applicationError: 'アプリケーションエラー',
        message: '不明なエラーが発生しました。もう一度お試しください',
    },
    getHeaders: customHeaders => {
        if (!api.accessToken) return customHeaders;
        return { ...customHeaders, Authorization: `Bearer ${api.accessToken}` };
    },
    handleError: e => {
        if (e.response && e.response.data) {
            const { error, message } = e.response.data;
            if (error === ResponseCode.E_INVALID_TOKEN) {
                // Token has been revoked or expired
                localStorage.removeItem('access_token');
                window.location.href = '/login?invalidToken=true';
                return;
            }
            if (message) {
                if (error) {
                    showError(api.message.error, `不明なエラーが発生しました。もう一度お試しください [code:${error}] `);
                } else {
                    showError('エラー', '不明なエラーが発生しました。もう一度お試しください ');
                }
            } else {
                showError('サーバーエラー', api.message.message);
            }
        } else {
            showError('アプリケーションエラー', '不明なエラーが発生しました。もう一度お試しください');
        }
    },
    get: async (uri, params, headers, throwError = false) => {
        try {
            const response = await instance.get(uri, { params, headers: api.getHeaders(headers) });
            return response.data.data;
        } catch (e) {
            if (throwError) {
                throw e;
            }
            api.handleError(e);
        }
    },
    post: async (uri, data, params, headers, throwError = false) => {
        try {
            const response = await instance.post(uri, data, { params, headers: api.getHeaders(headers) });
            return response.data.data;
        } catch (e) {
            if (throwError) {
                throw e;
            }
            api.handleError(e);
        }
    },
    put: async (uri, data, params, headers, throwError = false) => {
        try {
            const response = await instance.put(uri, data, { params, headers: api.getHeaders(headers) });
            return response.data.data;
        } catch (e) {
            if (throwError) {
                throw e;
            }
            api.handleError(e);
        }
    },
    patch: async (uri, data, params, headers, throwError = false) => {
        try {
            const response = await instance.patch(uri, data, { params, headers: api.getHeaders(headers) });
            return response.data.data;
        } catch (e) {
            if (throwError) {
                throw e;
            }
            api.handleError(e);
        }
    },
    delete: async (uri, params, headers, throwError = false) => {
        try {
            const response = await instance.delete(uri, { params, headers: api.getHeaders(headers) });
            return response.data.data;
        } catch (e) {
            if (throwError) {
                throw e;
            }
            api.handleError(e);
        }
    },
};

export default api;

export const getCustomFiltersKey = columns => {
    let customFilterKeys = {};
    for (const c of columns) {
        if (!c.searchable) continue;
        const searchKey = c.searchKey ? c.searchKey : c.dataIndex;
        switch (c.searchType) {
            case 'like':
                customFilterKeys[c.dataIndex] = [`${searchKey}:like`];
                break;
            case 'range':
            case 'dateRange':
                customFilterKeys[c.dataIndex] = [`${searchKey}:gte`, `${searchKey}:lte`];
                break;
            default:
                customFilterKeys[c.dataIndex] = [searchKey];
                break;
        }
    }
    return customFilterKeys;
};

export const createQuery = (pagination, filters, sorter) => {
    let params = {
        page: pagination.current,
        page_size: pagination.pageSize,
    };
    if (filters) {
        for (const f of Object.keys(filters)) {
            if (isEmpty(filters[f])) continue;
            if (moment.isMoment(filters[f])) {
                params[f] = filters[f].format('YYYY-MM-DD');
            } else if (typeof filters[f] !== 'object') {
                params[f] = filters[f];
            }
        }
    }
    if (sorter && sorter.field && sorter.order) {
        params.order_by = `${sorter.order === 'ascend' ? '' : '-'}${sorter.field}`;
    }
    return params;
};
