import { createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import React from 'react';

const name = 'counselorCompanyProgram';

const counselorCompanyProgramSlice = createSlice({
    name,
    initialState: {
        counselor: null,
        allCompanyLoading: false,
        allCompanies: [],
        companyProgramsLoading: false,
        companyPrograms: [],
    },
    reducers: {
        setCounselor(state, action) {
            state.counselor = action.payload;
        },
        setAllCompanies(state, action) {
            state.allCompanies = action.payload;
        },
        setAllCompanyLoading(state, action) {
            state.allCompanyLoading = action.payload;
        },
        setCompanyProgramsLoading(state, action) {
            state.companyProgramsLoading = action.payload;
        },
        setCompanyPrograms(state, action) {
            state.companyPrograms = action.payload;
        },
    },
});

export const { setCounselor, setAllCompanies, setAllCompanyLoading, setCompanyPrograms, setCompanyProgramsLoading } =
    counselorCompanyProgramSlice.actions;

export const getAllCompanies = () => async dispatch => {
    dispatch(setAllCompanyLoading(true));
    const companies = await api.get(APP_URLS.ADMIN_ALL_COMPANIES);
    if (companies) {
        dispatch(setAllCompanies(companies));
    }
    dispatch(setAllCompanyLoading(false));
};

export const getAllProgramsByCompany = (companyId, counselorId) => async dispatch => {
    dispatch(setCompanyProgramsLoading(true));
    const companyPrograms = await api.get(
        APP_URLS.ADMIN_COMPANIES_DETAIL_ALL_PROGRAMS.replace(':id', companyId).replace(':counselorId', counselorId)
    );
    if (companyPrograms) {
        dispatch(setCompanyPrograms(companyPrograms));
    }
    dispatch(setCompanyProgramsLoading(false));
};

export default counselorCompanyProgramSlice.reducer;
