import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { Col, Row } from 'antd';
import { ArrowRightIcon, DateRangeIcon, InfoIcon } from 'components/SvgIcon';
import TdtCalendar from 'components/calendar/TdtCalendar';
import PropTypes from 'prop-types';
import { strToMoment } from 'helpers/date.helper';
import { isEmpty } from 'helpers/common.helper';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { getReservations } from 'pages/counselor/dashboard/dashboard.slice';
import TdtCalendarDefaultDetailModal from 'components/calendar/TdtCalendarDefaultDetailModal';
import { setBackUrl } from 'core.slice';
import NoData from 'components/NoData';

const mapState = state => ({
    state: state.counselor.dashboard,
    reservations: state.counselor.dashboard.reservations,
});

const mapDispatch = { getReservations, setBackUrl };

const Dashboard = ({ reservations, getReservations, setBackUrl }) => {
    const { t } = useTranslation();

    const today = moment();
    const todayStr = today.format('YYYY-MM-DD');
    const yesterday = moment().add(-1, 'day');
    const yesterdayStr = yesterday.format('YYYY-MM-DD');
    const timeStart = '08:00';

    // to notice

    useEffect(() => {
        getReservations(yesterdayStr, todayStr);
    }, []);

    const handleReservationClick = reservation => {
        setBackUrl(Routes.private.COUNSELOR_DASHBOARD.path);
        history.push(
            Routes.private.COUNSELOR_DETAIL_CONSULTATION.path.replace(':id', reservation.client_id) +
                '/' +
                reservation.id
        );
    };

    const filterTodayReservations = reservations => {
        return { [todayStr]: reservations[todayStr] };
    };

    const renderNotice = () => {
        const flat = [
            ...(reservations[yesterdayStr] || []).filter(r => r.start >= timeStart),
            ...(reservations[todayStr] || []).filter(r => r.end < timeStart),
        ].filter(r => r.isStatusEnd && (isEmpty(r.feedback) || r.message_threads_count < 1));
        return flat.length === 0 ? (
            <NoData />
        ) : (
            <div style={{ maxWidth: 500 }}>{flat.map(reservation => renderNoticeWithCond(reservation))}</div>
        );
    };

    const renderNoticeWithCond = reservation => {
        return (
            <React.Fragment key={reservation.id}>
                {isEmpty(reservation.feedback) && noticeNoMessageThread(reservation)}
                {reservation.message_threads_count < 1 && noticeNoFeedback(reservation)}
            </React.Fragment>
        );
    };

    const noticeNoFeedback = reservation => {
        return (
            <div
                key={`no_feedback__${reservation.id}`}
                className="notice-row pt-16 pb-16 ml-16 mr-16 pr-24"
                onClick={() => handleReservationClick(reservation)}
            >
                <div className="subtitle2 mb-4">
                    {`${strToMoment(reservation.date).format('M/DD')} ${
                        reservation.start
                    }〜の相談フィードバックがまだ送られていません`}
                </div>
                <div className="caption">{'できるだけ早く相談者へフィードバックしてください。'}</div>
                <ArrowRightIcon className="arrow-right" />
            </div>
        );
    };

    const noticeNoMessageThread = reservation => {
        return (
            <div
                key={`no_msg_${reservation.id}`}
                className="notice-row pt-16 pb-16 ml-16 mr-16 pr-24"
                onClick={() => handleReservationClick(reservation)}
            >
                <div className="subtitle2 mb-4">{`${strToMoment(reservation.date).format('M/DD')} ${
                    reservation.start
                }〜の相談カルテがまだ作成されていません`}</div>
                <div className="caption">{'できるだけ早くカルテを作成してください。'}</div>
                <ArrowRightIcon className="arrow-right" />
            </div>
        );
    };

    const eventModalRenderer = useCallback(event => {
        return (
            <TdtCalendarDefaultDetailModal
                event={event}
                setBackUrl={() => setBackUrl(Routes.private.COUNSELOR_DASHBOARD.path)}
            />
        );
    }, []);

    return (
        <div className="dashboard self-wrapper d-block">
            <h1 className="page-title mb-24 mt-48">{today.format('YYYY年M月DD 日（ddd）')}</h1>
            <Row>
                <Col flex="259px" className="mr-32 mb-32">
                    <div>
                        <div className="h7 d-flex align-items-center">
                            <span className="icon-circle sub-color-100 mr-8">
                                <DateRangeIcon size={14} />
                            </span>
                            {'本日の相談予定'}
                        </div>
                        <div className="mt-16">
                            <TdtCalendar
                                type="day"
                                startDate={today}
                                events={filterTodayReservations(reservations)}
                                eventModalRenderer={eventModalRenderer}
                            />
                        </div>
                    </div>
                </Col>
                <Col flex="auto">
                    <div>
                        <div className="h7 d-flex align-items-center">
                            <span className="icon-circle gray-info mr-8">
                                <InfoIcon size={20} />
                            </span>
                            {'お知らせ'}
                        </div>
                        <div className="mt-16">{renderNotice()}</div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default connect(mapState, mapDispatch)(Dashboard);

Dashboard.propTypes = {
    reservations: PropTypes.any,
    getReservations: PropTypes.func.isRequired,
};
