export const APP_URLS = {
    // eslint-disable-next-line no-undef
    BASE_URL: process.env.REACT_APP_BASE_URL,
    STATIC_BASE_URL: process.env.REACT_APP_STATIC_BASE_URL,

    // Auth + Register + Password Reset
    LOGIN: '/login',
    LOGOUT: '/logout',
    AUTH_CHECK: '/auth/check',
    REGISTER: '/register',
    CHECK_UNIQUE_EMAIL: '/check-unique-email',
    VERIFY_EMAIL: '/email/verify',
    RESEND_EMAIL: '/email/resend',
    REQUEST_PASSWORD_RESET: '/password-reset',
    PASSWORD_RESET_VERIFY: '/password-reset-verify',
    COUNSELOR_SETTING_PASSWORD: '/counselors/setting-password',

    ADMIN_COMMON_SESSIONS: '/admin/seminars',
    ADMIN_COMMON_SESSIONS_MASTER: '/admin/seminars-master',
    ADMIN_COMMON_SESSIONS_CREATE: '/admin/common-sessions/create',
    ADMIN_COMMON_SESSIONS_CREATE_EDIT: '/admin/common-sessions/create/edit',
    ADMIN_COMMON_SESSIONS_DETAIL: '/admin/seminars/:id',
    ADMIN_COMMON_SESSIONS_SURVEY: '/admin/seminars/:id/survey',
    ADMIN_COMMON_SESSIONS_COMMON_SESSION_SETTING_TRANSMISSION_DATE: '/admin/seminars/:id/setting-transmission-date',
    ADMIN_COMMON_SESSIONS_COMMON_SESSION_UPDATE_STATUS: '/admin/seminars/:id/status',

    ADMIN_EMAILS: '/admin/emails',
    ADMIN_EMAILS_DETAIL: '/admin/emails/:id',
    ADMIN_EMAILS_DELETE: '/admin/emails/:id',
    ADMIN_EMAILS_CLONE: '/admin/emails/:id/copy',
    ADMIN_EMAIL_DISTRIBUTIONS: '/admin/email/distributions',
    ADMIN_EMAIL_DISTRIBUTIONS_DETAIL: '/admin/email/distributions/:id',
    ADMIN_EMAIL_DISTRIBUTIONS_DELETE: '/admin/email/distributions/:id',
    ADMIN_EMAIL_DISTRIBUTIONS_CLONE_DISTRIBUTION: '/admin/email/distributions/:id/copy',

    ADMIN_EMAILS_LIST_NEW_PROGRAM: '/admin/emails/list-new-program',
    ADMIN_EMAILS_LIST_NEW_SESSION: '/admin/emails/list-new-session',

    ADMIN_MASTER_COMPANY_EMPLOYEES: '/admin/master/company-employees',

    ADMIN_ALL_COMPANIES: '/admin/companies/all',
    ADMIN_COMPANIES: '/admin/companies',
    ADMIN_COMPANIES_CREATE: '/admin/companies/create',
    ADMIN_COMPANIES_UPDATE: '/admin/companies/update/:id',
    ADMIN_COMPANIES_DETAIL: '/admin/companies/:id',
    ADMIN_COMPANIES_DETAIL_EMPLOYEES: '/admin/companies/:id/employees',
    ADMIN_COMPANIES_DETAIL_EMPLOYEES_META_DATA: '/admin/companies/:id/employees/meta-data',
    ADMIN_COMPANIES_DETAIL_PROGRAMS: '/admin/companies/:id/programs',
    ADMIN_COMPANIES_DETAIL_ALL_PROGRAMS: '/admin/companies/:id/programs/all/:counselorId',
    ADMIN_COMPANIES_DETAIL_PROGRAMS_DETAIL: '/admin/companies/:id/programs/:programCompanyId',
    ADMIN_COMPANIES_DETAIL_PROGRAMS_CREATE: '/admin/companies/:id/programs/create',
    ADMIN_COMPANIES_DETAIL_PROGRAMS_UPDATE: '/admin/companies/:id/programs/:programCompanyId',
    ADMIN_COMPANIES_DETAIL_PROGRAMS_DELETE: '/admin/companies/:id/programs/:programCompanyId',
    ADMIN_COMPANIES_DETAIL_ADD_COUNSELOR: '/admin/companies/:id/programs/:programId/add-counselor',
    ADMIN_COMPANIES_DETAIL_COUNSELORS_OF_PROGRAM: '/admin/companies/:id/programs/:programId/counselors',
    ADMIN_COMPANIES_DETAIL_STAFFS: '/admin/companies/:id/staffs',
    ADMIN_COMPANIES_DETAIL_STAFFS_COUNSELORS: '/admin/companies/:id/staffs/counselors',
    ADMIN_COMPANIES_DETAIL_STAFFS_ADD_COUNSELOR: '/admin/companies/:id/staffs/add-counselor',
    ADMIN_COMPANIES_DETAIL_STAFFS_DELETE: '/admin/companies/:id/staffs/:staffsId',
    ADMIN_COMPANIES_DETAIL_COMPANY_CODE: '/admin/companies/:id/company_code',
    ADMIN_COMPANIES_DETAIL_COMPANY_STATE: '/admin/companies/:id/state',
    ADMIN_COMPANIES_DELETE: '/admin/companies/:id',
    ADMIN_COMPANIES_SEMINAR: '/admin/seminars',
    ADMIN_COMPANIES_SEMINAR_CREATE: '/admin/seminars/create',
    ADMIN_COMPANIES_SEMINAR_DETAIL: '/admin/seminars/:id',
    ADMIN_COMPANIES_SEMINAR_DELETE: '/admin/seminars/:id',
    ADMIN_COMPANIES_SEMINAR_UPDATE: '/admin/seminars/:id/update',
    ADMIN_COMPANIES_SEMINAR_UPDATE_STATUS: '/admin/seminars/:id/status',
    ADMIN_COMPANIES_SEMINAR_UPDATE_IS_VIDEO_PUBLIC: '/admin/seminars/:id/is-video-public',
    ADMIN_COMPANIES_SEMINAR_GET_LIST_COMPANIES: '/admin/seminars/list-companies',
    ADMIN_COMPANIES_SEMINAR_CLONE_SEMINAR: '/admin/seminars/:id/copy',
    ADMIN_COMPANIES_SEMINAR_GET_LIST_ORGANIZER_ACCOUNTS: '/admin/seminars/list-organizer-accounts',
    ADMIN_COMPANIES_SEMINAR_SETTING_TRANSMISSION_DATE: '/admin/seminars/:id/setting-transmission-date',
    ADMIN_COMPANIES_SEMINAR_LIST_FOR_MESSAGE_NEW: '/admin/seminars/list-for-message-new',
    ADMIN_COMPANIES_DETAIL_PROGRAMS_LIST_FOR_MESSAGE_NEW: '/admin/companies/:id/programs/list-for-message-new',
    ADMIN_COMPANIES_SEMINAR_SEND_MESSAGE_NEW: '/admin/seminars/:id/send-seminar-message-new',
    ADMIN_COMPANIES_PROGRAM_SEND_MESSAGE_NEW: '/admin/companies/:id/programs/send-program-message-new/:programId',

    ADMIN_COUNSELORS: '/admin/counselors',
    ADMIN_COUNSELORS_CREATE: '/admin/counselors/create',
    ADMIN_COUNSELORS_DETAIL: '/admin/counselors/:id',
    ADMIN_COUNSELORS_DETAIL_ROLE: '/admin/counselors/:id/role',
    ADMIN_COUNSELORS_DETAIL_EMAIL: '/admin/counselors/:id/email',
    ADMIN_COUNSELORS_DETAIL_REGISTERED: '/counselors/:id/registered',
    ADMIN_COUNSELORS_DETAIL_ADD_COMPANY_PROGRAM: '/admin/counselors/:counselorId/add-company-program',
    ADMIN_COUNSELORS_PROGRAM_DELETE: '/admin/counselors/:id/program/:programCompanyId',
    ADMIN_COUNSELORS_COMPANY_DELETE: '/admin/counselors/:id/company/:companyId',
    ADMIN_COUNSELORS_DELETE: '/admin/counselors/:id',
    ADMIN_COUNSELORS_DELETE_USER: '/admin/counselors/user/:id',

    ADMIN_PROGRAM: '/admin/programs',
    ADMIN_PROGRAM_DETAIL: '/admin/programs/:id',
    ADMIN_PROGRAM_CHECK_DELETE: '/admin/programs/:id/check-delete',
    ADMIN_PROGRAM_UPDATE: '/admin/programs/:id/update',
    ADMIN_PROGRAM_CREATE: '/admin/programs/create',
    ADMIN_PROGRAM_COPY: '/admin/programs/:id/copy',
    ADMIN_PROGRAM_DISPLAY_ORDER_SETTING: '/admin/programs/display-order-setting',

    INTERVIEW_SHEET: 'admin/interview_sheets',

    ADMIN_INTERVIEW_SHEETS: '/admin/interview-sheets',
    ADMIN_INTERVIEW_SHEETS_DETAIL: '/admin/interview-sheets/:id',
    ADMIN_INTERVIEW_SHEETS_DELETE: '/admin/interview-sheets/:id',
    ADMIN_INTERVIEW_SHEETS_CLONE: '/admin/interview-sheets/:id/copy',
    ADMIN_INTERVIEW_SHEETS_DETAIL_PERIOD: '/admin/interview-sheets/:id/period',
    ADMIN_INTERVIEW_SHEETS_DETAIL_PERIOD_UPDATE: '/admin/interview-sheets/:id/period/:periodId',
    ADMIN_INTERVIEW_SHEETS_DETAIL_PERIOD_DELETE: '/admin/interview-sheets/:id/period/:periodId',

    ADMIN_CALENDAR_MASTER: '/admin/calendar/master',
    ADMIN_CALENDAR_RESERVATIONS: '/admin/calendar/reservations',
    ADMIN_CALENDARS: '/admin/calendars',

    ADMIN_LOG: '/admin/activity_logs',

    // Client url
    CLIENT_HEALTH_DATA: '/client/healths',
    CLIENT_HEALTH_DATA_CREATE: '/client/healths/create',
    CLIENT_HEALTH_DATA_UPDATE: '/client/healths/:id/update',
    CLIENT_HEALTH_DATA_DELETE: '/client/healths/:id',
    CLIENT_HEALTH_DATA_BY_TYPE: '/client/healths/type/:typeId',

    CLIENT_PROGRAMS: '/client/programs',
    CLIENT_PROGRAMS_RESERVABLE: '/client/programs/reservable',

    CLIENT_RESERVATIONS: '/client/reservations',
    CLIENT_RESERVATIONS_DETAIL: '/client/reservations/:id',
    CLIENT_RESERVATIONS_SCHEDULES: '/client/reservations/schedules',
    CLIENT_PAST_RESERVATION: '/client/reservations/get-list-past',

    CLIENT_INTERVIEW_SHEETS: '/client/reservation-answers',
    CLIENT_INTERVIEW_SHEETS_DETAIL: '/client/reservation-answers/:id',
    CLIENT_INTERVIEW_SHEETS_SUBMIT_ANSWERS: '/client/reservation-answers/:id',

    CLIENT_UPDATE_ACCOUNT: '/client/account/name',
    CLIENT_UPDATE_PASSWORD: '/client/account/password',
    CLIENT_UPDATE_EMAIL: '/client/account/email',
    CLIENT_SEND_EMAIL: '/client/account/verify-code-email',
    CLIENT_DELETE_ACCOUNT: '/client/account/delete',
    CLIENT_UPDATE_HEALTH_DISPLAY_SETTING: '/client/patients/:id/health-display-settings',
    CLIENT_HEALTH_DATA_BY_DAY: '/client/healths/type/:typeId/by-day',
    CLIENT_HEALTH_DATA_IMAGE: '/client/health_images',
    CLIENT_HEALTH_DATA_IMAGE_DELETE: '/client/health_images/:id',
    CLIENT_HEALTH_DATA_LAST_IMAGE: '/client/health_images/last_images',
    CLIENT_HEALTH_DATA_IMAGE_BY_CATEGORY: '/client/health_images/:category',

    CLIENT_CHANGE_EMAIL_VERIFY_CODE: '/client/account/verify-code-email?email=:new_mail',

    CLIENT_SEMINARS_LIST: 'client/seminars',
    CLIENT_SEMINARS_DETAIL: 'client/seminars/:id',
    CLIENT_PROGRAMS_DETAIL: 'client/programs/:id',

    CLIENT_INQUIRY_CREATE: '/client/inquiries',

    // Counselor url
    COUNSELOR_UPDATE_NAME_ACCOUNT: '/counselor/account/update-name',
    ADMIN_UPDATE_NAME_ACCOUNT: '/counselor/account/update-name',
    COUNSELOR_UPDATE_PASSWORD: '/counselor/account/update-password',
    COUNSELOR_UPDATE_EMAIL: '/counselor/account/update-email',
    COUNSELOR_SEND_EMAIL: '/counselor/account/verify-code-email',
    COUNSELOR_GET_COMPANIES: '/counselor/account/companies',

    COUNSELOR_CALENDARS: '/counselor/calendars',
    COUNSELOR_CALENDAR_MASTER: '/counselor/calendar/master',
    COUNSELOR_CALENDAR_RESERVATIONS_BLOCK: '/counselor/calendar/reservations/block',
    COUNSELOR_CALENDAR_RESERVATIONS: '/counselor/calendar/reservations',
    COUNSELOR_CALENDARS_META_DATA: '/counselor/calendars/meta-data',
    COUNSELOR_CONSULTANT_INFO: '/counselor/consultants/:id',
    COUNSELOR_CONSULTATIONS: '/counselor/consultations/:id',
    COUNSELOR_CONSULTATION_DETAIL: '/counselor/consultations/:id/:reservationId/detail',
    COUNSELOR_META_DATA_CONSULTATIONS: '/counselor/consultations/meta-data/:id',
    COUNSELOR_CHANGE_EMAIL_VERIFY_CODE: '/counselor/account/verify-code-email?email=:new_mail',

    COUNSELOR_CONSULTATIONS_RESERVATION_ANSWERS: '/counselor/consultations/:id/:reservationId/reservation-answers',
    COUNSELOR_CONSULTATIONS_FEEDBACK: '/counselor/consultations/:id/:reservationId/feedback',
    COUNSELOR_CONSULTATIONS_CANCELLATION: '/counselor/consultations/:id/:reservationId/cancellation',
    COUNSELOR_CONSULTATIONS_RESUME: '/counselor/consultations/:id/:reservationId/resume',
    COUNSELOR_CONSULTATIONS_DELETE: '/counselor/consultations/:id/:reservationId/delete',
    COUNSELOR_CONSULTATIONS_START: '/counselor/consultations/:id/:reservationId/start',

    COUNSELOR_QUESTIONNAIRE: '/counselor/consultations/:id/questionnaires',
    COUNSELOR_QUESTIONNAIRE_DETAIL: '/counselor/consultations/:id/questionnaires/:questionnaireId',

    COUNSELOR_HEALTH_DATA_BY_DAY: '/counselor/healths/type/:typeId/by-day',
    COUNSELOR_HEALTH_DATA_IMAGE_BY_CATEGORY: '/counselor/health_images/:category',

    COUNSELOR_CONSULTANTS: '/counselor/consultants',
    COUNSELOR_CONSULTANTS_META_DATA: '/counselor/consultants/meta-data',

    COUNSELOR_PROGRAMS_RESERVABLE: '/counselor/consultant/:id/programs/reservable',
    COUNSELOR_RESERVATIONS_SCHEDULES: '/counselor/consultant/:id/reservations/schedules',
    COUNSELOR_RESERVATIONS_SUBMIT: '/counselor/consultant/:id/create-reservation',
    COUNSELOR_RESERVATIONS_UPDATE: '/counselor/consultant/:id/update-reservation/:reservationId',
    COUNSELOR_RESERVATIONS_DETAIL: '/counselor/reservations/:id',
    COUNSELOR_RESERVATIONS_SEARCH_CLIENTS: '/counselor/reservations/search-clients',

    COUNSELOR_EMPLOYEE_PROGRAM: '/counselor/employee-program/:id',
    COUNSELOR_EMPLOYEE_PROGRAM_UPDATE: '/counselor/employee-program/:id',

    MESSAGE_THREADS: '/message-threads',
    MESSAGE_THREADS_CREATE: '/message-threads/create',
    MESSAGE_THREADS_DETAIL: '/message-threads/:id',
    MESSAGE_THREADS_ACTIONS: '/message-threads/:id',
    MESSAGE_TEMPLATES: '/message-templates',
    MESSAGE_THREADS_META_DATA: '/message-threads/meta-data/:id',

    // public
    QUESTIONNAIRE_DETAIL: '/questionnaire/:uuid',

    SSE_CLIENT: 'http://127.0.0.1:8000/api/sse/client/getEventStream',
};
