export const handleScrollOnFocus = e => {
    if (e.target.tagName === 'BUTTON') return;

    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
    if (viewportWidth > 1024) {
        return;
    }

    const bounding = e.target.getBoundingClientRect();
    const toBottomPx = viewportHeight - bounding.bottom;
    const requireAtLeast = 80; // float bar
    const count = bounding.height + window.scrollY + (requireAtLeast - toBottomPx);
    if (toBottomPx < requireAtLeast) {
        setTimeout(function () {
            window.scrollTo({
                top: count,
                left: 0,
                behavior: 'smooth',
            });
        }, 0);
    } else if (bounding.top < 54) {
        setTimeout(function () {
            window.scrollTo({
                top: window.scrollY - 54 - 33 + bounding.top, // input + label
                left: 0,
                behavior: 'smooth',
            });
        }, 0);
    }
};
