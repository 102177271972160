import React from 'react';

export const HMImage = ({ detail }) => {
    const wrapper_style = 'padding: 0 16px 24px 16px;';
    const table_style = 'font-size: 14px; text-align: left;';
    const td_style = 'line-height: 1; font-size: 0';
    const img_style = 'border-radius: 4px 4px 0 0; width: 100%; height: auto; background: #FFFFFF;';
    const img_src = detail['media_url'];

    const raw = `<tr>
    <td style="${wrapper_style}">
        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="${table_style}">
            <tr>
                <td style="${td_style}">
                    <img src="${img_src}" height="auto" border="0" alt="alt_text" style="${img_style}" class="center-on-narrow">
                </td>
            </tr>
        </table>
    </td>
</tr>
`;

    return <tr dangerouslySetInnerHTML={{ __html: raw }} />;
};
