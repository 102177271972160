import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import BackBtn from 'components/BackBtn';
import { getMessage, setMessage } from 'pages/admin/company/tabs/message/message_detail.slice';
import { dateToStr } from 'helpers/date.helper';
import Loader from 'components/Loader';
import RenderHTMLMail from 'pages/admin/common/page-elements/RenderHTMLMail';
import RenderHTMLMailFreeText from 'pages/admin/common/page-elements/RenderHTMLMailFreeText';

const mapState = state => ({
    message: state.admin.companyDetailMessageDetail.message,
});
const mapDispatch = { getMessage, setMessage };
const MessageDetail = ({ message, getMessage, setMessage }) => {
    const { id, messageId } = useParams();

    useEffect(() => {
        message = null;
        getMessage(messageId);

        return () => {
            setMessage(null);
        };
    }, [id, messageId]);

    if (!message) {
        return <Loader className="loader-center" />;
    }

    const previewData = message.json_preview ? JSON.parse(message.json_preview) : null;

    return (
        <div className="px-24">
            <BackBtn className={`mb-32 ${history && history.length < 2 ? 'opacity-0 pointer-events-none cursor-default' : ''}`} label={message.title} />

            <div className="pr-32 mt-24">
                {message.counselor ? <div className="fw-b">{`${message.counselor.full_name}`}</div> : <></>}

                <div className="text-small mb-24">{dateToStr(message.created_at, 'YYYY年M月DD日(ddd) HH:mm')}</div>

                {message.type !== 'program' && message.type !== 'session' ? (
                    <>
                        {/*<div className="white-space-pre-line">{message.content}</div>*/}
                        <div className="html-mail_wrapper">
                            <RenderHTMLMailFreeText message={message} />
                        </div>
                    </>
                ) : (
                    <>
                        {!previewData ? (
                            <>
                                <div className="white-space-pre-line">{message.content}</div>
                            </>
                        ) : (
                            <>
                                <div className="html-mail_wrapper">
                                    {previewData.note ? (
                                        <div className="preview-html-note-wrapper">
                                            <div className="preview-html-note">{previewData.note}</div>
                                        </div>
                                    ) : (
                                        <></>
                                    )}
                                    <RenderHTMLMail
                                        info={previewData}
                                        details={previewData.details}
                                        isProgram={message.type === 'program'}
                                    />
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default connect(mapState, mapDispatch)(MessageDetail);

MessageDetail.propTypes = {
    message: PropTypes.any,
    getMessage: PropTypes.func.isRequired,
};
