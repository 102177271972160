import { createSlice } from '@reduxjs/toolkit';
import { baseInitialState, baseReducers } from 'helpers/slice.helper';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import ReservationStatus from 'enums/reservation_status.enum';
import { convertReservationToCalendarEvent } from 'helpers/calendar.helper';

const name = 'dashboard';

const dashboardSlice = createSlice({
    name,
    initialState: {
        ...baseInitialState,
        reservations: {},
    },
    reducers: {
        ...baseReducers,
        setReservations(state, action) {
            state.reservations = action.payload;
        },
    },
});

export const { setReservations } = dashboardSlice.actions;

export default dashboardSlice.reducer;

export const getReservations = (startDate, endDate) => async dispatch => {
    const data = await api.get(APP_URLS.COUNSELOR_CALENDAR_RESERVATIONS, {
        start_date: startDate,
        end_date: endDate,
        count_message_threads: true,
    });
    if (data) {
        let reservations = {};
        for (const date of Object.keys(data)) {
            reservations[date] = data[date].map(r => ({
                ...convertReservationToCalendarEvent(r),
                date: r.date,
                feedback: r.feedback,
                message_threads_count: r.message_threads_count,
                client_id: r.client_id,
                isStatusEnd: r.status === ReservationStatus.END.value,
            }));
        }
        dispatch(setReservations(reservations));
    }
};
