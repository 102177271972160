import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FormIcon } from 'components/SvgIcon';

let isResizing = null;

const TdtVerticalResizable = ({ children, onChangeHeight, ...props }) => {
    const [drawerHeight, setDrawerHeight] = useState(undefined);

    const cbHandleMouseMove = React.useCallback(handleMousemove, []);
    const cbHandleMouseUp = React.useCallback(handleMouseup, []);

    useEffect(() => {
        setDrawerHeight(props.height);
    }, [props.visible]);

    function handleMouseup() {
        if (!isResizing) {
            return;
        }
        isResizing = false;
        document.removeEventListener('mousemove', cbHandleMouseMove);
        document.removeEventListener('mouseup', cbHandleMouseUp);
    }

    function handleMousedown(e) {
        e.stopPropagation();
        e.preventDefault();
        // we will only add listeners when needed, and remove them afterward
        document.addEventListener('mousemove', cbHandleMouseMove);
        document.addEventListener('mouseup', cbHandleMouseUp);
        isResizing = true;
    }

    function handleMousemove(e) {
        // let offsetBottom = document.body.offsetHeight - (e.clientY - document.body.offsetTop);
        let offsetBottom = document.body.offsetHeight - e.clientY - 48; // -48px padding
        let minHeight = 74;
        let maxHeight = 299;
        if (offsetBottom > minHeight && offsetBottom < maxHeight) {
            setDrawerHeight(offsetBottom);
            onChangeHeight();
        }
    }

    return (
        <div className="vertical-resizable-wrap" {...props} style={{ height: drawerHeight }}>
            <span className="resizable-button-container">
                <span className="resizable-button" onMouseDown={handleMousedown}>
                    <FormIcon />
                </span>
            </span>
            {children}
        </div>
    );
};

export default TdtVerticalResizable;

TdtVerticalResizable.propTypes = {
    children: PropTypes.any,
    height: PropTypes.any,
    visible: PropTypes.bool,
};
