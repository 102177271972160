import { createSelector, createSlice, current } from '@reduxjs/toolkit';
import { randomId } from 'helpers/common.helper';

const name = 'distributionCreate';
const initialState = {
    name: null,
    data: [],
};

const distributionCreateSlice = createSlice({
    name,
    initialState: initialState,
    reducers: {
        reset(state) {
            state.name = initialState.name;
            state.data = initialState.data;
        },
        addByEmailInput(state, action) {
            const { email, companyEmployeesData } = action.payload;
            if (!current(state.data).find(r => r.email === email)) {
                state.data = [...state.data, { ...autoFillEmailInfo(email, companyEmployeesData), id: randomId() }];
            }
        },
        removeById(state, action) {
            const id = action.payload;
            state.data = state.data.filter(d => d.id !== id);
        },
        setName(state, action) {
            state.name = action.payload;
        },
        convertDataToState(state, action) {
            const { name, distribution_targets } = action.payload;
            state.name = name;
            state.data = distribution_targets.map(d => ({
                id: d.id,
                email: d.email,
                user_id: d.user_id,
                user: d.user,
                company_id: d.company_id,
                full_name: d.user ? d.user.full_name : '-',
                company_name: d.company ? d.company.name : '-',
            }));
        },
        addByEmailList(state, action) {
            const { emails: items, companyEmployeesData } = action.payload;
            const oldItems = current(state.data);
            const mergedItems = oldItems.map(item => {
                 const newItem = items.find(r => r.email === item.email);
                 return (newItem && newItem.user_id) ? newItem : item;
            });

            state.data = [...mergedItems, ...items.filter(item => !mergedItems.find(r => r.email === item.email)).map(o => o.user_id ? o : ({...o, ...autoFillEmailInfo(o.email, companyEmployeesData)}))];
        },
    },
});

export const { reset, addByEmailInput, removeById, setName, convertDataToState, addByEmailList } =
    distributionCreateSlice.actions;
export default distributionCreateSlice.reducer;

const dataSelector = state => state.data;
export const checkDataSelector = createSelector(dataSelector, data => {
    const tmp = {};
    for (const obj of data) {
        !tmp[obj.email] && (tmp[obj.email] = true);
    }
    let targetAudience = Object.keys(tmp).length;
    let numberOfRegistrants = data.length;
    let duplicateEmailAddress = numberOfRegistrants - targetAudience;
    return { targetAudience, numberOfRegistrants, duplicateEmailAddress };
});

const autoFillEmailInfo = (email, companyEmployeesData = []) => {
    for (const company of companyEmployeesData.data) {
        for (const employee of company.employees) {
            if (employee.email === email) {
                return {
                    email,
                    company_id: company.id,
                    company_name: company.name,
                    user_id: employee.id,
                    full_name: employee.full_name,
                }
            }
        }
    }
    return  {
        email
    }
};