import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Radio, Row } from 'antd';
import { APP_URLS } from 'constants/url.constant';
import RoleEnum from 'enums/role.enum';
import api from 'helpers/api.helper';
import history from 'helpers/history.helper';
import FloatBar from 'components/FloatBar';
import { showNormal } from 'helpers/notification.helper';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import { connect } from 'react-redux';
import { isAdminStaff } from 'helpers/role.helper';

const mapState = state => ({ user: state.auth.user });

const CounselorCreate = ({ user }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const createCounselor = async values => {
        setLoading(true);
        const response = await api.post(APP_URLS.ADMIN_COUNSELORS_CREATE, values);
        if (response) {
            setLoading(false);
            showNormal(
                '',
                values.role === RoleEnum.ADMIN_STAFF.value
                    ? t('message:I registered the management staff')
                    : t('message:Counselor has been registered'),
                5
            );
            history.goBack();
        }
    };

    const checkUniqueEmail = async (rule, value) => {
        if (value) {
            try {
                const data = await api.post(APP_URLS.CHECK_UNIQUE_EMAIL, { email: value });
                if (data && data.is_existed) {
                    return Promise.reject(t('validation:Email has already been taken'));
                }
            } catch (e) {
                return Promise.resolve();
            }
        }

        return Promise.resolve();
    };

    const validateMessages = {
        required: t('validation:Please enter ${label}'),
        types: {
            email: t('validation:Please check format of your ${label}'),
        },
    };

    return (
        <div className="self-wrapper d-block px-48">
            <h1 className="mb-32">
                <BackBtnWithTitle title={t('Add member')} />
            </h1>

            <Form
                layout="vertical"
                initialValues={{ role: RoleEnum.DOCTOR.value }}
                validateMessages={validateMessages}
                onFinish={async values => {
                    await createCounselor(values);
                }}
                requiredMark={false}
                style={{ maxWidth: 480 }}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="first_name"
                            label={t('field:first name')}
                            validateTrigger={['onBlur']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="last_name"
                            label={t('field:Last name')}
                            validateTrigger={['onBlur']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name="email"
                            label={t('field:Email')}
                            extra={t('Please enter the address that you will use as your login ID')}
                            validateTrigger={['onBlur']}
                            rules={[
                                {
                                    required: true,
                                    type: 'email',
                                },
                                () => ({
                                    validator(_, value) {
                                        return checkUniqueEmail(_, value);
                                    },
                                }),
                            ]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name="role" label={t('field:Role')}>
                            <Radio.Group className="vertical-group">
                                <Radio value={RoleEnum.DOCTOR.value}>{t(`enum:${RoleEnum.DOCTOR.text}`)}</Radio>
                                {isAdminStaff(user) ? null : (
                                    <Radio value={RoleEnum.ADMIN_STAFF.value}>
                                        {t(`enum:${RoleEnum.ADMIN_STAFF.text}`)}
                                    </Radio>
                                )}
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
                <FloatBar>
                    <Button type="primary" loading={loading} htmlType="submit" className="fw-b">
                        {t('Register')}
                    </Button>
                </FloatBar>
            </Form>
        </div>
    );
};

export default connect(mapState, null)(CounselorCreate);
