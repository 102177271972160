import React from 'react';
import PropTypes from 'prop-types';
import { Button, Tooltip } from 'antd';
import history from 'helpers/history.helper';
import { ArrowBackIcon } from 'components/SvgIcon';

const BackBtnWithTitle = ({ title, onClick, className = '', titleClassName = '' }) => {
    return (
        <>
            <div className="back-button">
                <Tooltip placement="right" title="前に戻る" color="#666666">
                    <Button
                        type="link"
                        className={`back-btn with-title ${className}`}
                        icon={<ArrowBackIcon />}
                        onClick={() => {
                            if (onClick) {
                                onClick();
                            } else {
                                history.goBack();
                            }
                        }}
                    />
                </Tooltip>
                <span className={`custom-text back-text  ${titleClassName}`}>{title}</span>
            </div>
        </>
    );
};

export default BackBtnWithTitle;

BackBtnWithTitle.propTypes = {
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    className: PropTypes.string,
    titleClassName: PropTypes.string,
};
