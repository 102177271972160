import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { APP_URLS } from 'constants/url.constant';
import api from 'helpers/api.helper';
import BackBtn from 'components/BackBtn';
import FloatBar from 'components/FloatBar';
import { AddIcon, DeleteIcon, EditIcon } from 'components/SvgIcon';
import TdtSelect from 'components/TdtSelect';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { setDefaultData, setLink, setProgramTempData } from 'pages/admin/program/program_temp.slice';
import { connect } from 'react-redux';
import { useParams, useRouteMatch } from 'react-router-dom';
import { showNormal } from 'helpers/notification.helper';
import ProgramDeleteModal from 'pages/admin/program/ProgramDeleteModal';
import ProgramCantDeleteModal from 'pages/admin/program/ProgramCantDeleteModal';
import TdtButtonSubmit from 'components/TdtButtonSubmit';
import Loader from 'components/Loader';
import AddImageButton from 'components/AddImageButton';
import PageElements from 'pages/admin/common/page-elements/PageElements';
import ProgramDetailPreviewModal from 'pages/admin/program/ProgramDetailPreviewModal';
import { isNullOrUndefined } from 'helpers/common.helper';
import { convertDataToState, restoreFromTemp, reset } from 'pages/admin/common/page-elements/page_elements.slice';
import ProgramCategoryEnum from 'enums/program_category.enum';

const mapState = state => ({
    tempState: state.admin.programTemp,
    byId: state.admin.pageElements.elements.byId,
    order: state.admin.pageElements.elements.order,
});
const mapDispatch = { setLink, setProgramTempData, setDefaultData, convertDataToState, restoreFromTemp, reset };
const ProgramForm = ({
                         initialValues = {},
                         isUpdate = false,
                         onFinish,
                         tempState,
                         setLink,
                         setProgramTempData,
                         setDefaultData,
                         byId,
                         mode,
                         order,
                         convertDataToState,
                         restoreFromTemp,
                         reset,
                     }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [interviews, setInterviews] = useState([]);
    const [changeValue, setChangeValue] = useState([]);
    const [loadingInterviewSheet, setLoadingInterviewSheet] = useState(false);
    const [modalVisible, setModalVisible] = useState({
        delete: false,
        cantDelete: false,
        id: null,
        previewDetail: false,
    });
    const showDeleteModal = id => setModalVisible({ delete: true, cantDelete: false, id, previewDetail: false });
    const showCantDeleteModal = () => setModalVisible({ cantDelete: true, id, delete: false, previewDetail: false });
    const showPreviewDetailModal = () => setModalVisible({ previewDetail: true, delete: false, cantDelete: false, id });
    const closeModal = () => setModalVisible({ delete: false, cantDelete: false, id: null, previewDetail: false });

    const title = mode === 'create' ? t('Register {{entity}}', { entity: t('entity:program') }): 'プログラム編集';// Define titles for "Create" and "Edit" modes

    const [isCopying, setIsCopying] = useState(false);

    if (isUpdate && !initialValues.category) {
        initialValues.category = ProgramCategoryEnum.THEMATIC_PROGRAM.value;
    }

    const [fileList, setFileList] = useState(
        initialValues.cover_img ? [{ url: `${APP_URLS.STATIC_BASE_URL}/${initialValues.cover_img}` }] : [],
    );

    // valid has: name_mngt, cover image, at least 1 detail / order.length > 0
    //const isValidDetail = form.getFieldValue('name_mngt') && fileList.length > 0;
    const [isValidDetail, setIsValidDetail] = useState(false);
    const checkIsValidDetail = () => {
        setIsValidDetail(
            form.getFieldValue('name_mngt') && form.getFieldValue('name') && form.getFieldValue('description'),
        );
    };

    let { url } = useRouteMatch();
    const getInterviews = async () => {
        setLoadingInterviewSheet(false);
        const data = await api.get(APP_URLS.INTERVIEW_SHEET + '?page_size=999&no_period=true');
        if (data && data.data && data.data.length > 0) {
            setInterviews(data.data);
        }
        setLoadingInterviewSheet(true);
    };
    const { id } = useParams();
    const showModalToDelete = async () => {
        const data = await api.get(APP_URLS.ADMIN_PROGRAM_CHECK_DELETE.replace(':id', id));
        if (!data) {
            showCantDeleteModal();
        } else {
            showDeleteModal();
        }
    };
    const deleteProgram = async () => {
        const data = await api.delete(APP_URLS.ADMIN_PROGRAM_DETAIL.replace(':id', id));
        if (data) {
            showNormal('', t('message:Program has been deleted'), 3);
            history.replace(Routes.private.ADMIN_PROGRAM.path);
        }
    };

    const submitForm = async () => {
        //await form.submit();
        await onFinish(valuesToFormData(form.getFieldsValue()));
        setDefaultData();
        reset();
    };

    const labelFirstInterviewSheet = (
        <>
            <div className='ant-col ant-form-item-label'>
                <label
                    htmlFor='description'
                    className='ant-form-item-required'
                    title={t('field:Questionnaire to use first')}
                >
                    {t('field:Questionnaire to use first')}
                </label>
            </div>
        </>
    );
    useEffect(() => {
        getInterviews().then(() => {});
        if (isUpdate) {
            convertDataToState({ elements: initialValues.program_details });
        }
    }, []);

    useEffect(() => {
        if (tempState.data && url === tempState.link) {
            initialValues = Object.assign(initialValues, { ...tempState.data });
            // restore tmp data
            const { fileList, byId, order } = tempState.data;
            setFileList(fileList || []);
            restoreFromTemp({ byId, order });
        }
        form.setFieldsValue(initialValues);
        checkIsValidDetail();
    }, [tempState.data]);

    useEffect(() => {
        form.setFieldsValue({ cover_img: fileList.length > 0 ? fileList[0].url : null });
        checkIsValidDetail();
    }, [fileList]);

    const valuesToFormData = values => {
        const formData = new FormData();
        if (fileList.length > 0) {
            formData.append('image_cover_img', fileList[0]);
        }

        // set details
        values.details = [];
        order.map(key => values.details.push(byId[key]));

        Object.keys(values).map(k => {
            if (values[k]) {
                if (Array.isArray(values[k])) {
                    const details = values[k];
                    details.map((detail, i) => {
                        Object.keys(detail).map(d => {
                            !isNullOrUndefined(detail[d]) && formData.append(`${k}[${i}][${d}]`, detail[d]);
                        });
                    });
                } else {
                    formData.append(k, values[k]);
                }
            }
        });
        return formData;
    };

    const cloneProgram = async programId => {
        setIsCopying(true);
        const response = await api.post(APP_URLS.ADMIN_PROGRAM_COPY.replace(':id', programId));
        if (response) {
            showNormal('', t('message:I duplicated the program'), 5);
            history.push(Routes.private.ADMIN_PROGRAM.path);
        }
        setIsCopying(false);
    };

    return (
        <div className='self-wrapper d-block px-48'>
            <BackBtn className='' label={title} />
            <Form
                className='mt-32'
                layout='vertical'
                form={form}
                initialValues={initialValues}
                onFinish={() => {
                    //onFinish(valuesToFormData(value));
                    //setDefaultData();
                    //reset();
                }}
                requiredMark={false}
                onValuesChange={() => {
                    //setProgramTempData(form.getFieldsValue());
                    checkIsValidDetail();
                }}
            >
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item name='category' label={t('Category')}>
                            <TdtSelect
                                size='large'
                                className='fs-14 tdt-select-40 program-input'
                                placeholder={t('Select a program category')}
                            >
                                {Object.values(ProgramCategoryEnum).map(e => (
                                    <Select.Option key={e.value} value={e.value}>
                                        {t(`enum:${e.text}`)}
                                    </Select.Option>
                                ))}
                            </TdtSelect>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name='name_mngt'
                            label={t('field:Name (for management)')}
                            validateTrigger={false}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input className={'program-input'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name='name'
                            label={t('field:Name (for consultants)')}
                            validateTrigger={false}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input className={'program-input'} />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            name='description'
                            label={t('field:Outline explanation (for consultants)')}
                            validateTrigger={false}
                            // value={description}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input.TextArea className='outline-explanation-input' />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={16}>
                    <Col span={24}>
                        <Form.Item
                            label={t('Booking options')}
                            name='allow_same_day_booking'
                            className='mt-8 mb-8'
                            valuePropName='checked'
                        >
                            <Checkbox className='body2 fs-14'>{t('Same-day reservation is possible')}</Checkbox>
                        </Form.Item>
                        <Form.Item name='auto_allocate_counselor' className='mt-8 mb-32' valuePropName='checked'>
                            <Checkbox className='body2 fs-14'>{t('Automatically allocate counselors')}</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>

                {loadingInterviewSheet ? (
                    <>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    className='mb-4'
                                    name='first_interview_sheet_id'
                                    initialValue=''
                                    label={labelFirstInterviewSheet}
                                >
                                    <TdtSelect size='large' className='fs-14 tdt-select-40 program-input'>
                                        <Select.Option key='0' value=''>
                                            {t('field:Choose questionnaire')}
                                        </Select.Option>
                                        {interviews.map(itv => (
                                            <Select.Option key={itv.id} value={itv.id}>
                                                {itv.name}
                                            </Select.Option>
                                        ))}
                                    </TdtSelect>
                                </Form.Item>
                                <p className='fs-14 mb-32 text-gray-color'>
                                    {t(
                                        'You can set a questionnaire to be used only when making an initial reservation',
                                    )}
                                </p>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item
                                    name='interview_sheet_id'
                                    initialValue=''
                                    label={t('field:Questionnaire to use')}
                                >
                                    <TdtSelect
                                        size='large'
                                        className='fs-14 tdt-select-40 program-input select-last-child-relative'
                                        getPopupContainer={trigger => trigger.parentNode}
                                        dropdownClassName='relative-parent-select'
                                    >
                                        <Select.Option key='0' value=''>
                                            {t('field:Choose questionnaire')}
                                        </Select.Option>
                                        {interviews.map(itv => (
                                            <Select.Option key={itv.id} value={itv.id}>
                                                {itv.name}
                                            </Select.Option>
                                        ))}
                                    </TdtSelect>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Button
                                    icon={<AddIcon />}
                                    className='fw-b program-btn-create'
                                    onClick={() => {
                                        setLink(url);
                                        // set tmp data
                                        setProgramTempData({ ...form.getFieldsValue(), fileList, byId, order });
                                        history.push(`${Routes.private.ADMIN_INTERVIEW_SHEETS_CREATE.path}?new=1`);
                                    }}
                                >
                                    {t('Questionnaire creation')}
                                </Button>
                            </Col>
                        </Row>
                    </>
                ) : (
                    <Loader className='mb-16' />
                )}

                <Col span={24} className='mb-8 mt-12'>
                    <Form.Item label={'カバー画像'}>
                        <Form.Item name='cover_img' noStyle>
                            <Input hidden />
                        </Form.Item>
                        <AddImageButton className='d-flex mb-8' fileList={fileList} setFileList={setFileList} />
                        {!fileList.length && <div className='body2 color-blg-60'>{'推奨サイズ: 1024px × 512px'}</div>}
                    </Form.Item>
                </Col>

                <Row gutter={16}>
                    <Col span={24}>
                        <div className='mb-40'>
                            <div className='fw-b mb-8'>{t('Page element')}</div>
                            <PageElements hasExtends={true} />
                        </div>
                    </Col>
                </Row>

                <ProgramDetailPreviewModal
                    visible={modalVisible.previewDetail}
                    onClose={() => closeModal()}
                    info={{ ...form.getFieldsValue(), name: form.getFieldValue('name_mngt') }}
                    byId={byId}
                    order={order}
                />

                <FloatBar className='ant-row-space-between'>
                    <div>
                        <Button className='fw-b mr-16' onClick={() => showPreviewDetailModal()}>
                            {t('Preview')}
                        </Button>
                        <TdtButtonSubmit type='primary' onClick={submitForm} className='fw-b' disabled={!isValidDetail}>
                            {isUpdate ? t('Save') : t('Register')}
                        </TdtButtonSubmit>
                    </div>

                    {isUpdate && (
                        <div className='d-flex'>
                            <Button
                                className='fw-b pink-color'
                                icon={<DeleteIcon />}
                                onClick={() => {
                                    showModalToDelete();
                                }}
                                style={{ marginRight: 'auto' }}
                            >
                                {t('Cancellation of program')}
                            </Button>
                            <Button
                                loading={isCopying}
                                onClick={() => cloneProgram(id)}
                                className='fw-b ml-16'
                                icon={<EditIcon />}
                            >
                                {t('Program duplication')}
                            </Button>
                        </div>
                    )}
                    <ProgramDeleteModal
                        visible={modalVisible.delete}
                        onClose={() => closeModal()}
                        onUpdate={() => deleteProgram(modalVisible.id)}
                    />
                    <ProgramCantDeleteModal
                        visible={modalVisible.cantDelete}
                        onClose={() => closeModal()}
                        initialValue={{}}
                        onUpdate={() => closeModal()}
                    />
                </FloatBar>
            </Form>
        </div>
    );
};

export default connect(mapState, mapDispatch)(ProgramForm);

ProgramForm.propTypes = {
    isUpdate: PropTypes.bool,
    initialValues: PropTypes.object,
    tempState: PropTypes.any,
    setLink: PropTypes.func,
    setProgramTempData: PropTypes.func,
    setDefaultData: PropTypes.func,
};
