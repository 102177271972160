import React, { useCallback } from 'react';
import ColumnChart from 'components/chart/ColumnChart';
import { chartDataSelector } from 'pages/admin/interview_sheet/detail_screen/interview_sheet_detail.slice';
import { connect } from 'react-redux';
import moment from 'moment';

const mapState = state => ({
    displayType: state.admin.interviewSheetDetail.displayType,
    chartData: chartDataSelector(state.admin.interviewSheetDetail),
});
const mapDispatch = {};

const NumberOfResponseChart = ({ displayType, chartData }) => {
    const xLabelRenderer = date => moment(date).format(displayType === 'year' ? 'YYYY/MM' : 'MM/DD');

    const tooltipRenderer = useCallback(
        point => {
            return (
                <>
                    <rect
                        x={point.x - 25}
                        y={point.y - 18}
                        width={50}
                        height={32}
                        rx={4}
                        ry={4}
                        fill="#ffffff"
                        stroke="#D0D0D0"
                    />
                    <text
                        x={point.x}
                        y={point.y}
                        fill="#222126"
                        fontSize={12}
                        alignmentBaseline="middle"
                        textAnchor="middle"
                    >
                        {point.value[0]}
                    </text>
                </>
            );
        },
        [displayType]
    );

    const hasData = chartData && chartData.values.find(x => x);

    return (
        <>
            <ColumnChart
                data={chartData}
                width={1000}
                height={320}
                yStepSize={5}
                yCount={!hasData ? 4 : null}
                xLabelRender={xLabelRenderer}
                tooltipRender={tooltipRenderer}
            />
        </>
    );
};

export default connect(mapState, mapDispatch)(NumberOfResponseChart);
