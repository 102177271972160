import React from 'react';
import PieWithTableChart from 'pages/admin/interview_sheet/detail_screen/common/aggregate_results/PieWithTableChart';
import { COLORS } from 'helpers/color.helper';
import AnswerQuestionsWithCount from 'pages/admin/interview_sheet/detail_screen/common/aggregate_results/AnswerQuestionsWithCount';

const AggregateSingleSelect = ({ question, answersDataList }) => {
    const parseData = () => {
        let data = [];
        let dataOthers = [];

        const { options } = question;

        let colorIndex = 0;
        for (const opt of options) {
            const matchCount = answersDataList.filter(r => {
                const byId = r.questionById[opt.interview_question_id];
                const answer = byId && byId.answers.find(d => d.id === opt.id);
                if (answer && answer.type === 'other') {
                    dataOthers.push(answer);
                }
                return answer;
            }).length;

            //const percent = numberToFixed((matchCount / answersDataList.length) * 100);
            data.push({
                id: opt.id,
                text: opt.value,
                count: matchCount,
                color: COLORS[colorIndex++],
            });

            if (colorIndex >= COLORS.length) {
                colorIndex = 0;
            }
        }

        return { data, dataOthers: parseOtherValues(dataOthers) };
    };

    const parseOtherValues = dataOthers => {
        let group = {};
        for (const dataOther of dataOthers) {
            const v = dataOther['otherValue'];
            if (!group[v]) {
                group[v] = { text: v, count: 0 };
            }
            group[v].count += 1;
        }

        return Object.values(group);
    }

    const parsedData = parseData();

    return (
        <>
            <PieWithTableChart data={parsedData.data} />
            {parsedData.dataOthers.length > 0 && (
                <>
                    <div className="fw-b mt-32 color-blg-80">{'その他の回答'}</div>
                    <AnswerQuestionsWithCount data={parsedData.dataOthers} />
                </>
            )}
        </>
    );
};

export default AggregateSingleSelect;
