import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import Routes from 'routes';
import sidebar from 'layouts/sidebar';
import LayoutClient from 'layouts/LayoutClient';
import LayoutAdmin from 'layouts/LayoutAdmin';
import LayoutCounselor from 'layouts/LayoutCounselor';
import LayoutCounselorCalendar from 'layouts/LayoutCounselorCalendar';
import NotFound from 'pages/error/NotFound';
import RootPage from 'pages/RootPage';
import { setSidebarActive } from 'core.slice';
import { allowedAdminPage, allowedClientPage, allowedCounselorPage } from 'helpers/role.helper';
import PusherHandler from 'layouts/PusherHandler';

const mapState = state => ({
    verified: state.auth.isVerified,
    authenticated: state.auth.isAuth,
    user: state.auth.user,
});
const mapDispatch = { setSidebarActive };
const MainLayout = ({ authenticated, setSidebarActive, user, verified }) => {
    if (!authenticated) {
        return <Redirect to={Routes.public.LOGIN.path} />;
    }

    const location = useLocation();
    const pathname = location.pathname;
    useEffect(() => {
        for (const item of sidebar) {
            if (window.location.pathname.indexOf(item.path) > -1) {
                setSidebarActive(item.key);
                return;
            }
        }
    }, [pathname]);

    const goto404 = <Redirect to={'/404'} />;

    return (
        <>
            <PusherHandler />
            <Switch>
                <Route path="/" exact>
                    <RootPage user={user} />
                </Route>
                <Route path="/client">{allowedClientPage(user) ? <LayoutClient verified={verified} /> : goto404}</Route>
                <Route path="/admin">{allowedAdminPage(user) ? <LayoutAdmin /> : goto404}</Route>
                <Route path="/counselor/calendar/create-reservation">
                    {allowedCounselorPage(user) ? <LayoutCounselor /> : goto404}
                </Route>
                <Route path="/counselor/calendar/:id">
                    {allowedCounselorPage(user) ? <LayoutCounselorCalendar /> : goto404}
                </Route>
                <Route path="/counselor">{allowedCounselorPage(user) ? <LayoutCounselor /> : goto404}</Route>
                <Route path="*">
                    <NotFound />
                </Route>
            </Switch>
        </>
    );
};
export default connect(mapState, mapDispatch)(MainLayout);

MainLayout.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    setSidebarActive: PropTypes.func.isRequired,
    user: PropTypes.any,
    verified: PropTypes.bool.isRequired,
};
