import React from 'react';
import { CloseIcon } from 'components/SvgIcon';
import { Button } from 'antd';
import { getEnumText } from 'helpers/enum.helper';
import { useTranslation } from 'react-i18next';
import DailyLifeEventEnum from 'enums/daily_life_event.enum';
import { TdtPureSelectTimeWithBlock, timeToMinute } from 'components/TdtSelectTime';
import TdtPureSelect from 'components/TdtPureSelect';

const AddEvent = ({ record, setRecord, visible, onClose, onOk, onDelete, slots }) => {
    const { t } = useTranslation();

    const sortedStartSlots = [...slots].sort((a1, b1) => {
        const a = timeToMinute(a1.start);
        const b = timeToMinute(b1.start);
        return a > b ? 1 : a < b ? -1 : 0;
    });

    const checkNotAvailableEndIfHasStart = end => {
        if (slots.length < 1) {
            return false;
        }

        const closestGreaterSlot = sortedStartSlots.find(
            startSlot => timeToMinute(startSlot.start) >= timeToMinute(record.start) && startSlot.id !== record.id
        );

        return closestGreaterSlot && timeToMinute(end) > closestGreaterSlot;
    };

    const checkOverlap = () => {
        return slots
            .filter(slot => !record.id || slot.id !== record.id)
            .some(slot => {
                if (!slot.start || !slot.end) {
                    return false;
                }
                const slotStartMinute = timeToMinute(slot.start);
                const slotEndMinute = timeToMinute(slot.end);
                const recordStartMinute = timeToMinute(record.start);
                const recordEndMinute = timeToMinute(record.end);
                return !(recordEndMinute <= slotStartMinute || recordStartMinute >= slotEndMinute);
            });
    };

    return (
        <div className={`dle-as-modal p-16 ${visible ? '' : 'd-none'}`}>
            <div>
                <div className="dle-as-modal-header d-flex align-items-center justify-content-space-between mb-24">
                    <div>{'予定の編集'}</div>
                    <Button type="" className="dle-as-modal-close-icon" onClick={() => onClose()}>
                        <CloseIcon size={24} className="color-blg-60" />
                    </Button>
                </div>
                <div className="dle-as-modal-body mt-16">
                    <div className="fw-700 fs-16">
                        <label className="color-blg-60">{'予定'}</label>
                        <div className="mt-4 ant-select-arrow-interview position-relative">
                            <TdtPureSelect
                                value={record.type}
                                onSelect={v => setRecord({ ...record, type: v })}
                                options={Object.values(DailyLifeEventEnum).map(o => ({
                                    value: o.value,
                                    label: (
                                        <>
                                            <span className={`mr-8 dle-color dle-color-${o.value}`} />
                                            {t(`enum:${getEnumText(DailyLifeEventEnum, o.value)}`)}
                                        </>
                                    ),
                                }))}
                                renderSelected={v => (
                                    <>
                                        <span className={`mr-8 dle-color dle-color-${v}`} />
                                        {t(`enum:${getEnumText(DailyLifeEventEnum, v)}`)}
                                    </>
                                )}
                            />
                        </div>
                    </div>
                    <div className="fw-700 fs-16 mt-16 d-flex dle-time-two-columns">
                        <div>
                            <label className="color-blg-60">{'開始'}</label>
                            <div className="mt-4 ant-select-arrow-interview">
                                <TdtPureSelectTimeWithBlock
                                    value={record.start}
                                    onSelect={v => {
                                        setRecord({
                                            ...record,
                                            start: v,
                                            end:
                                                timeToMinute(v) >= timeToMinute(record.end)
                                                    ? null
                                                    : checkNotAvailableEndIfHasStart(record.end)
                                                    ? null
                                                    : record.end,
                                        });
                                    }}
                                    checkNotAvailable={v => {
                                        return slots.some(
                                            slot =>
                                                timeToMinute(slot.start) <= timeToMinute(v) &&
                                                timeToMinute(v) < timeToMinute(slot.end) &&
                                                slot.id !== record.id
                                        );
                                    }}
                                    maxHeight={256}
                                    maxWidth={115}
                                />
                            </div>
                        </div>
                        <div className="ml-12">
                            <label className="color-blg-60">{t('End')}</label>
                            <div className="mt-4 ant-select-arrow-interview">
                                <TdtPureSelectTimeWithBlock
                                    value={record.end}
                                    minTime={record.start}
                                    onSelect={v => {
                                        setRecord({ ...record, end: v });
                                    }}
                                    hasEndOfDay={true}
                                    checkNotAvailable={v => {
                                        if (!record.start) {
                                            return slots.some(
                                                slot =>
                                                    timeToMinute(slot.start) < timeToMinute(v) &&
                                                    timeToMinute(v) <= timeToMinute(slot.end) &&
                                                    slot.id !== record.id
                                            );
                                        } else {
                                            if (slots.length < 1) {
                                                return false;
                                            }
                                            const sortedStartSlots = slots.sort((a1, b1) => {
                                                const a = timeToMinute(a1.start);
                                                const b = timeToMinute(b1.start);
                                                return a > b ? 1 : a < b ? -1 : 0;
                                            });

                                            const closestGreaterSlot = sortedStartSlots.find(
                                                startSlot =>
                                                    timeToMinute(startSlot.start) > timeToMinute(record.start) &&
                                                    startSlot.id !== record.id
                                            );

                                            return (
                                                closestGreaterSlot &&
                                                timeToMinute(v) > timeToMinute(closestGreaterSlot.start)
                                            );
                                        }
                                    }}
                                    maxHeight={256}
                                    maxWidth={115}
                                />
                            </div>
                        </div>
                    </div>
                    {checkOverlap() && (
                        <div className="fs-14-21 color-red-100 mt-16">
                            {'既に入力済みの予定と時刻が重なっています。時刻を変更してください。'}
                        </div>
                    )}
                </div>
                <div className="dle-as-modal-footer mt-16">
                    <div className="delete-action-panel">
                        <Button
                            type=""
                            className="fw-b color-blg-40 dle-cancel-btn"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            <span>{'キャンセル'}</span>
                        </Button>

                        {!!record.id && (
                            <Button
                                type=""
                                className="fw-b color-blg-40 dle-delete-btn"
                                onClick={() => {
                                    onDelete && onDelete(record);
                                    onClose();
                                }}
                            >
                                <span>{t('Delete')}</span>
                            </Button>
                        )}

                        <Button
                            type=""
                            className="fw-b color-blue-100 ml-10 dle-save-btn"
                            onClick={() => {
                                onOk(record);
                                onClose();
                            }}
                            disabled={!(record && record.type && record.start && record.end) || checkOverlap()}
                        >
                            <span>{'保存'}</span>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddEvent;
