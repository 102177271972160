import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, Radio, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { AddIcon, DateRangeIcon, DeleteIcon } from 'components/SvgIcon';
import TdtSelectTime from 'components/TdtSelectTime';
import { addByEmailInput, addByEmailList, removeById, setInfo } from 'pages/admin/email/email_create.slice';
import { connect } from 'react-redux';
import TdtButton from 'components/TdtButton';
import CustomDropdown from 'components/CustomDropdown';
import DataTable from 'components/DataTable';
import { csvDataToListEmail, isValidEmail } from 'helpers/email.helper';
import ProvideCompanyEmployeeModal from 'pages/admin/email/distribution/modal/company-employee/ProvideCompanyEmployeeModal';
import SelectCSVModal from 'pages/admin/email/distribution/modal/csv/SelectCSVModal';
import CorrectCSVUploadModal from 'pages/admin/email/distribution/modal/csv/CorrectCSVUploadModal';
import ErrorCSVUploadModal from 'pages/admin/email/distribution/modal/csv/ErrorCSVUploadModal';
import ListRegistrationModal from 'pages/admin/email/form/delivery_settings/modal/ListRegistrationModal';
import EmailType from 'enums/email_type.enum';
import CommonSessionChooseCompanyEnum from 'enums/common_session_choose_company.enum';

const mapState = state => ({
    info: state.admin.emailCreate.info,
    companyEmployeesData: state.admin.masterData.companyEmployeesData,
});
const mapDispatch = { setInfo, addByEmailInput, removeById, addByEmailList };

const DeliverySettings = ({
    initialValues = {},
    info,
    setInfo,
    selectedRecord,
    addByEmailInput,
    removeById,
    addByEmailList,
    isRefSeminar,
    companyEmployeesData,
}) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    initialValues = Object.assign(
        {
            delivery_type: 'send_now',
        },
        initialValues
    );
    if (initialValues.delivery_date) {
        initialValues.delivery_date = moment.utc(initialValues.delivery_date, 'YYYY/M/D');
    }

    const [deliveryType, setDeliveryType] = useState(initialValues.delivery_type);
    const hasSetDate = deliveryType !== 'send_now';
    const [emailInput, setEmailInput] = useState('');

    const [modalVisible, setModalVisible] = useState({
        listRegistration: false,
        record: null,
        provide: false,
        selectCsv: false,
        correctCsv: false,
        errorCsv: false,
        csvData: {},
    });
    const closeModal = () =>
        setModalVisible({
            listRegistration: false,
            record: null,
            provide: false,
            selectCsv: false,
            correctCsv: false,
            errorCsv: false,
            csvData: {},
        });
    const showListRegistrationModal = () => setModalVisible({ ...modalVisible, listRegistration: true });
    const showProvideCompanyStaffModal = r => setModalVisible({ ...modalVisible, provide: true, record: r });
    const showSelectCSVModal = r => setModalVisible({ ...modalVisible, selectCsv: true, record: r });
    const showCorrectCsvModal = data =>
        setModalVisible({ ...modalVisible, correctCsv: true, selectCsv: false, csvData: data });
    const showErrorCsvModal = data =>
        setModalVisible({ ...modalVisible, errorCsv: true, selectCsv: false, csvData: data });

    const callbackCsvData = csvData => {
        const { data } = csvData;
        const { error, emails, hasError } = csvDataToListEmail(data);
        if (hasError) {
            showErrorCsvModal({ error, emails });
        } else {
            showCorrectCsvModal({ emails });
        }
    };

    const addEmailsFromCsvModal = emails => {
        addByEmailList({ emails, companyEmployeesData });
    };

    const columns = [
        {
            title: 'メールアドレス',
            dataIndex: 'email',
            width: 292,
            sorter: (a, b) => {
                return a.email.localeCompare(b.email);
            },
        },
        {
            title: t('Company name'),
            dataIndex: 'company_name',
            width: 180,
            render: name => (name ? name : '-'),
            sorter: (a, b) => {
                const a1 = a.company_name ? a.company_name : '-';
                const b1 = b.company_name ? b.company_name : '-';
                return a1.localeCompare(b1);
            },
        },
        {
            title: '氏名',
            dataIndex: 'full_name',
            width: 160,
            render: full_name => (full_name ? full_name : '-'),
            sorter: (a, b) => {
                const a1 = a.full_name ? a.full_name : '-';
                const b1 = b.full_name ? b.full_name : '-';
                return a1.localeCompare(b1);
            },
        },
        {
            title: '',
            dataIndex: 'DELETE',
            width: 40,
            render: (_, r) => {
                return (
                    <TdtButton
                        buttonStyle="plain"
                        icon={<DeleteIcon className="color-red-100" />}
                        onClick={() => removeById(r.id)}
                        disabled={isRefSeminar}
                        className={`${isRefSeminar ? 'd-none' : ''}`}
                    />
                );
            },
        },
    ];

    const isSessionLimited =
        info.type === EmailType.NEW_SESSION.value &&
        selectedRecord &&
        selectedRecord.type === CommonSessionChooseCompanyEnum.SPECIFIC_COMPANIES.value;
    const isLimited = 1 > 0 && isSessionLimited; // for program limit

    useEffect(() => {
        if (selectedRecord && isLimited && !isRefSeminar) {
            setInfo({ target_audiences: [] });
        }
    }, [selectedRecord && isRefSeminar]);

    const minDeliveryTime = () => {
        return info.delivery_date && moment().isSame(moment(info.delivery_date), 'day')
            ? moment().format('HH:mm')
            : null;
    };

    return (
        <div className="px-48 pt-24">
            <Form layout="vertical" form={form} initialValues={initialValues} requiredMark={false}>
                <Row gutter={16}>
                    <Col span={24} className="mb-8">
                        <Form.Item name="delivery_type" label={'配信日時'} className="mb-8">
                            <Radio.Group
                                onChange={e => {
                                    const value = e.target.value;
                                    setDeliveryType(value);
                                    setInfo({ delivery_type: value });
                                }}
                            >
                                <Radio value={'send_now'} className="d-block">
                                    {'今すぐに送信'}
                                </Radio>
                                <Radio value={'reservation_of_delivery_date_and_time'} className="d-block">
                                    {'配信日時の予約'}
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <div className="d-flex align-items-center mb-16">
                            <Form.Item name="delivery_date" noStyle>
                                <DatePicker
                                    placeholder={'日時を選択'}
                                    className="mr-8 tdt-datepicker reservation-date-picker"
                                    dropdownClassName="tdt-datepicker-panel z-index-above-modal"
                                    disabledDate={date => {
                                        if (date.isBefore(moment().startOf('day'))) {
                                            return true;
                                        }
                                    }}
                                    style={{ width: 182 }}
                                    suffixIcon={<DateRangeIcon />}
                                    format="YYYY/M/D"
                                    allowClear={false}
                                    showToday={false}
                                    onChange={date => {
                                        if (
                                            moment().isSame(moment(date), 'day') &&
                                            info.delivery_time &&
                                            moment().format('HH:mm') > info.delivery_time
                                        ) {
                                            setInfo({ delivery_date: date, delivery_time: null });
                                        } else {
                                            setInfo({ delivery_date: date });
                                        }
                                    }}
                                    inputReadOnly={true}
                                    disabled={!hasSetDate}
                                />
                            </Form.Item>
                            <Form.Item noStyle>
                                <TdtSelectTime
                                    style={{ width: 182 }}
                                    onChange={v => setInfo({ delivery_time: v })}
                                    disabled={!hasSetDate}
                                    className="white-override"
                                    minTime={minDeliveryTime()}
                                    value={info.delivery_time}
                                />
                            </Form.Item>
                        </div>
                    </Col>

                    <Col span={24} className="mb-8">
                        <div>
                            <label className="fw-700 fs-16 color-blg-100">{'配信対象者'}</label>
                            <div className="mt-8 d-flex justify-content-space-between align-items-center">
                                <div className="d-flex">
                                    <Input
                                        className="w-100p bg-white"
                                        style={{ minWidth: 320, height: 40 }}
                                        placeholder="メールアドレスで追加"
                                        value={emailInput}
                                        onChange={e => setEmailInput(e.target.value)}
                                        disabled={isLimited || isRefSeminar}
                                    />
                                    <TdtButton
                                        buttonStyle="outline"
                                        buttonSize="big"
                                        className={`${isLimited ? '' : 'bg-blue-purple-100'} btn-radius-4 fw-b ml-8 `}
                                        onClick={() => {
                                            if (isValidEmail(emailInput)) {
                                                !!emailInput && addByEmailInput({ email: emailInput, companyEmployeesData });
                                                setEmailInput('');
                                            }
                                        }}
                                        style={{ width: 56, height: 39 }}
                                        disabled={isLimited || isRefSeminar}
                                    >
                                    <span
                                        className={`fw-400 fs-16 white-space-nowrap ${
                                            isLimited ? '' : 'color-white'
                                        }`}
                                    >
                                        {'追加'}
                                    </span>
                                    </TdtButton>
                                </div>
                                <CustomDropdown
                                    className={'distribution-dropdown '}
                                    ovlClassName={'menu-less-py'}
                                    trigger={['click']}
                                    options={[
                                        {
                                            key: 'list-registration',
                                            text: (
                                                <>
                                                    <span>{'リスト登録'}</span>
                                                </>
                                            ),
                                            disabled: isLimited || isRefSeminar,
                                        },
                                        {
                                            key: 'company-employee-batch-registration',
                                            text: (
                                                <>
                                                    <span>{'会社・社員一括登録'}</span>
                                                </>
                                            ),
                                            disabled: isRefSeminar,
                                        },
                                        {
                                            key: 'csv-upload',
                                            text: (
                                                <>
                                                    <span>{'CSVアップロード'}</span>
                                                </>
                                            ),
                                            disabled: isLimited || isRefSeminar,
                                        },
                                    ]}
                                    onSelect={opt => {
                                        opt.domEvent.stopPropagation();
                                        if (opt.key === 'list-registration') {
                                            showListRegistrationModal();
                                        } else if (opt.key === 'company-employee-batch-registration') {
                                            showProvideCompanyStaffModal();
                                        } else if (opt.key === 'csv-upload') {
                                            showSelectCSVModal();
                                        }
                                    }}
                                    separator
                                >
                                    <Button className="fw-700" icon={<AddIcon />} disabled={isRefSeminar}>
                                        {'一括登録'}
                                    </Button>
                                </CustomDropdown>
                            </div>

                            <ListRegistrationModal
                                onClose={() => closeModal()}
                                visible={modalVisible.listRegistration}
                                onUpdate={emails => {
                                    addByEmailList({ emails, companyEmployeesData });
                                }}
                            />
                            <ProvideCompanyEmployeeModal
                                onClose={() => closeModal()}
                                visible={modalVisible.provide}
                                onUpdate={emails => {
                                    addByEmailList({ emails, companyEmployeesData });
                                }}
                                selectedRecord={selectedRecord}
                            />
                            <SelectCSVModal
                                onClose={() => closeModal()}
                                visible={modalVisible.selectCsv}
                                callbackData={callbackCsvData}
                            />
                            <CorrectCSVUploadModal
                                onClose={() => closeModal()}
                                visible={modalVisible.correctCsv}
                                csvData={modalVisible.csvData}
                                onUpdate={addEmailsFromCsvModal}
                            />
                            <ErrorCSVUploadModal
                                onClose={() => closeModal()}
                                visible={modalVisible.errorCsv}
                                csvData={modalVisible.csvData}
                                onUpdate={addEmailsFromCsvModal}
                            />

                            <div className="mt-14">
                                <DataTable
                                    className="mt-16 fw-400 collapse-table no-clickable-row-table"
                                    columns={columns}
                                    data={info.target_audiences || []}
                                    noDataText={t('Audience is not registered')}
                                    onTableChange={() => {}}
                                />
                            </div>
                        </div>
                    </Col>

                </Row>
            </Form>
        </div>
    );
};

export default connect(mapState, mapDispatch)(DeliverySettings);
