import React from 'react';
import PropTypes from 'prop-types';

const FloatBarAccount = ({ children }) => {
    return <div className="float-bar-account">{children}</div>;
};

export default FloatBarAccount;

FloatBarAccount.propTypes = {
    children: PropTypes.any,
};
