import React from 'react';
import { Col, Row } from 'antd';
import { LoopIcon } from 'components/SvgIcon';
import { checkNowMatchPeriod, dateToStr, isLastAnswerInPeriod, periodRangeFormatToString } from 'helpers/date.helper';
import TdtButton from 'components/TdtButton';
import ReservationAnswerStatus from 'enums/reservation_answer_status.enum';
import history from 'helpers/history.helper';
import Routes from 'routes';

const PeriodicDiagnosisRow = ({ diagnosisRow, className = '' }) => {
    const latestAnswered = diagnosisRow.rows.length > 0 ? diagnosisRow.rows[0] : {};
    const { matchPeriod, closestAfter, latestPeriod } = checkNowMatchPeriod(diagnosisRow.periods);
    const isActive = !!matchPeriod && !isLastAnswerInPeriod(latestAnswered, matchPeriod);

    const getDateForInterviewSheet = () => {
        const showPeriod = matchPeriod || closestAfter || latestPeriod;
        return `回答期間 : ${periodRangeFormatToString(showPeriod)}`;
    };

    const isOneDraftAnswer =
        diagnosisRow.rows.length === 1 && diagnosisRow.rows[0].status === ReservationAnswerStatus.BLANK.value;
    const isOtherActiveWay = !!matchPeriod && isOneDraftAnswer;

    return (
        <Row className={`f-primary pt-24 pb-24 mb-12 ${className}`} align="middle">
            <Col className="mr-16 interview-icon">
                <div>
                    <span className={`circle-icon-with-bg ${(isActive || isOtherActiveWay) ? 'periodic-diagnosis' : 'with-check'}`}>
                        <LoopIcon />
                    </span>
                </div>
            </Col>
            <div className="boxes-col">
                <Col className="mr-8">
                    <div className={`time-badge ${(isActive || isOtherActiveWay) ? '' : 'periodic-diagnosis-disabled'}`}>
                        <div className="p4-8 fw-500 f16-24 interview-datetime itv-date-time">
                            {getDateForInterviewSheet()}
                        </div>
                    </div>
                    <div className="flex-1 f20-30 itv-reservation-title">{diagnosisRow.name}</div>
                    <div className="d-flex color-blg-60 white-space-nowrap mobile-flex-wrap">
                        <div className=" fw-400 fs-14 itv-reservation-title mr-16">
                            {`最終回答日: ${dateToStr(latestAnswered.updated_at, 'YYYY/MM/DD')}`}
                        </div>
                        <div className="fw-400 fs-14 itv-reservation-title">{`回答済み : ${
                            isOneDraftAnswer ? 0 : diagnosisRow.rows.length
                        }回`}</div>
                    </div>
                </Col>
            </div>

            <div className="ml-24 interview-btn">
                <div className="itv-btn">
                    {(isActive || isOtherActiveWay) && (
                        <TdtButton
                            className="fw-b mb-8 btn-w120 single-btn"
                            buttonStyle={'primary'}
                            onClick={() => {
                                let referId = latestAnswered.id;
                                history.push(
                                    Routes.private.CLIENT_INTERVIEW_SHEET_DETAIL.path.replace(':id', referId) +
                                        (isOneDraftAnswer ? '' : '?period')
                                );
                            }}
                        >
                            {'記入'}
                        </TdtButton>
                    )}
                    {isOneDraftAnswer ? (
                        <></>
                    ) : (
                        <>
                            <TdtButton
                                className="fw-b btn-w120 single-btn"
                                buttonStyle={'outline'}
                                onClick={() => {
                                    let referId = latestAnswered.id;
                                    latestAnswered.status === ReservationAnswerStatus.BLANK.value
                                        ? history.push(
                                              Routes.private.CLIENT_INTERVIEW_SHEET_DETAIL.path.replace(':id', referId)
                                          )
                                        : history.push(
                                              Routes.private.CLIENT_INTERVIEW_SHEET_DISPLAY.path.replace(':id', referId)
                                          );
                                }}
                            >
                                {'履歴'}
                            </TdtButton>
                        </>
                    )}
                </div>
            </div>
        </Row>
    );
};

export default PeriodicDiagnosisRow;
