import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import BackBtn from 'components/BackBtn';
import FloatBar from 'components/FloatBar';
import history from 'helpers/history.helper';
import Routes from 'routes';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { getData, setPagination, setSorter } from 'pages/admin/program/program.slice';
import DragAndDropGroup from 'components/dnd/DragAndDropGroup';
import ProgramRow from 'pages/admin/program/dnd/ProgramRow';
import ProgramCategoryEnum from 'enums/program_category.enum';
import { getEnumText } from 'helpers/enum.helper';

const mapState = state => ({ state: state.admin.program });
const mapDispatch = { getData, setPagination, setSorter };

const ProgramDisplayOrderSetting = ({ state, getData, setPagination, setSorter }) => {
    const { t } = useTranslation();

    const [dndState, setDndState] = useState({
        groupOrder: [],
        data: {},
        order: {},
    });

    const reOrder = (list, srcIndex, desIndex) => {
        const [removed] = list.splice(srcIndex, 1);
        list.splice(desIndex, 0, removed);
    };

    const onOrderChange = (srcIndex, desIndex, srcId, desId) => {
        if (srcId === desId && srcIndex === desIndex) {
            return;
        }
        if (srcId === desId) {
            if (srcIndex === desIndex) return;
            let tmpOrder = [...dndState.order[srcId]];
            reOrder(tmpOrder, srcIndex, desIndex);
            setDndState({
                ...dndState,
                order: {
                    ...dndState.order,
                    [srcId]: tmpOrder,
                },
            });
        } else {
            let srcOrder = [...dndState.order[srcId]];
            let desOrder = [...dndState.order[desId]];
            const [detailId] = srcOrder.splice(srcIndex, 1);
            desOrder.splice(desIndex, 0, detailId);
            setDndState({
                ...dndState,
                order: {
                    ...dndState.order,
                    [srcId]: srcOrder,
                    [desId]: desOrder,
                },
            });
        }
    };

    const isOrderChanged = () => {
        const { groupOrder, order } = parseDataToDndState(state.data);
        let initOrder = '';
        groupOrder.map(cate => {
            if (order[cate]) {
                initOrder += order[cate].join(',');
            }
        });

        let newOrder = '';
        groupOrder.map(cate => {
            if (dndState.order[cate]) {
                newOrder += dndState.order[cate].join(',');
            }
        });

        return initOrder !== newOrder;
    };

    const submitDisplayOrderSetting = async () => {
        const { groupOrder, data, order } = dndState;
        let orderNum = Object.keys(data).length;

        let orders = [];
        groupOrder.map(cate => {
            order[cate].map(k => {
                const item = data[k];
                if (item.display_order !== orderNum || item.category !== cate) {
                    orders.push({ id: item.id, display_order: orderNum, category: cate });
                }
                orderNum--;
            });
        });

        const res = await api.put(APP_URLS.ADMIN_PROGRAM_DISPLAY_ORDER_SETTING, { orders });
        if (res) {
            history.push(Routes.private.ADMIN_PROGRAM.path);
        }
    };

    const parseDataToDndState = (programs = []) => {
        let groupOrder = [ProgramCategoryEnum.COMPREHENSIVE_PROGRAM.value, ProgramCategoryEnum.THEMATIC_PROGRAM.value];
        let data = {};
        let order = {};
        programs.map(program => {
            if (!data[program.id + '']) {
                data[program.id + ''] = program;
            }

            const category = program.category || ProgramCategoryEnum.THEMATIC_PROGRAM.value;
            if (!order[category]) {
                order[category] = [];
            }
            order[category].push(program.id + '');
        });
        return { groupOrder, data, order };
    };

    useEffect(() => {
        getData({
            pagination: { current: 1, pageSize: 999 },
            filters: state.filters,
            sorter: { order: 'descend', field: 'display_order' },
        }).then(d => {
            setPagination({ current: 1, pageSize: 10, total: 1 });
            setSorter({ order: 'descend', field: 'display_order' });
            setDndState(parseDataToDndState(d.payload.data));
        });

        return () => {
            setDndState({
                groupOrder: [],
                data: {},
                order: {},
            });
        };
    }, []);

    return (
        <div className="self-wrapper d-block px-48">
            <BackBtn className="" label={t('Display order setting')} />
            <div className="fs-12-15 mt-16">
                {'プログラムの表示順を設定できます。（ユーザーが閲覧する画面に反映されます。）'}
            </div>

            <div className="mb-32" />

            <DragAndDropGroup
                data={dndState.data}
                order={dndState.order}
                groupOrder={dndState.groupOrder}
                onOrderChange={onOrderChange}
                headerRenderer={category => (
                    <div className="fw-b f16-24 mb-8 mt-32">
                        {t(`enum:${getEnumText(ProgramCategoryEnum, category)}`)}
                    </div>
                )}
                rowComponent={ProgramRow}
            />

            <FloatBar>
                <Button
                    type="primary"
                    className="fw-b"
                    onClick={() => submitDisplayOrderSetting()}
                    disabled={!isOrderChanged()}
                >
                    {t('Save')}
                </Button>
            </FloatBar>
        </div>
    );
};

export default connect(mapState, mapDispatch)(ProgramDisplayOrderSetting);
