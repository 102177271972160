import { createSelector, createSlice } from '@reduxjs/toolkit';
import { APP_URLS } from 'constants/url.constant';
import HealthTypeEnum from 'enums/health_type.enum';
import api from 'helpers/api.helper';
import { baseChartInitState, baseChartReducer } from 'helpers/slice.helper';

const name = 'healthDataWeight';

const LINE_COLOR = '#4C18ED';

const healthDataWeightSlice = createSlice({
    name,
    initialState: {
        ...baseChartInitState,
        weights: [],
    },
    reducers: {
        ...baseChartReducer,
        setWeights(state, action) {
            state.weights = action.payload;
        },
    },
});

export const { setStartDate, setEndDate, goPrev, goNext, goToday, setDisplayType, setMonthFilter, setWeights } =
    healthDataWeightSlice.actions;

export const getWeights = (startDate, endDate) => async (dispatch, getState) => {
    if (!startDate || !endDate) {
        const state = getState().client.healthDataWeight;
        startDate = state.startDate;
        endDate = state.endDate;
    }
    const data = await api.get(APP_URLS.CLIENT_HEALTH_DATA_BY_DAY.replace(':typeId', HealthTypeEnum.WEIGHT.value), {
        'created_at:gte': startDate.format('YYYY-MM-DD'),
        'created_at:lte': endDate.format('YYYY-MM-DD'),
    });
    dispatch(setWeights(data));
};

const startDateSelector = state => state.startDate;
const endDateSelector = state => state.endDate;
const displayTypeSelector = state => state.displayType;
const monthFilterSelector = state => state.monthFilter;
const weightsSelector = state => state.weights;

export const weightsFilterSelector = createSelector(
    displayTypeSelector,
    monthFilterSelector,
    weightsSelector,
    (displayType, monthFilter, weights) => {
        if (displayType !== 'year') return weights;
        const dateStr = monthFilter.format('YYYY-MM');
        return weights.filter(weight => weight.created_at.substr(0, 7) === dateStr);
    }
);

export const chartDataSelector = createSelector(
    startDateSelector,
    endDateSelector,
    displayTypeSelector,
    weightsSelector,
    (startDate, endDate, displayType, weights) => {
        let diff,
            duration,
            names = [],
            tmpObj = {},
            avgObj = {};

        if (displayType === 'year') {
            duration = 'months';
        } else {
            duration = 'days';
        }
        diff = endDate.diff(startDate, duration);
        for (let i = 0; i <= diff; i++) {
            const tmpDate = startDate.clone().add(i, duration).format('YYYY-MM-DD');
            names.push(tmpDate);
            tmpObj[tmpDate] = null;
        }

        let datesHasAverage = [];

        for (const weight of weights) {
            let date;
            const value = parseFloat(weight.value);

            displayType === 'year'
                ? (date = `${weight.created_at.substr(0, 7)}-01`)
                : (date = weight.created_at.substr(0, 10));

            if (!avgObj[date]) {
                avgObj[date] = { total: value, count: 1 };
            } else {
                avgObj[date].total += value;
                avgObj[date].count++;
            }
        }
        for (const date of Object.keys(avgObj)) {
            tmpObj[date] = [avgObj[date].total / avgObj[date].count];
            if (avgObj[date].count > 1) {
                datesHasAverage.push(date);
            }
        }

        return { names, values: Object.values(tmpObj), colors: [LINE_COLOR], datesHasAverage };
    }
);

export default healthDataWeightSlice.reducer;
