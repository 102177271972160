import React from 'react';
import { APP_URLS } from 'constants/url.constant';
import { isAndroid, isIOS } from 'helpers/common.helper';

export const HMFooter = () => {
    const style_label =
        'font-family: Noto Sans JP;font-size:12px;font-weight:700;line-height:150%;text-align:center;color:#000000;';
    const style_link =
        'font-family: Noto Sans JP;font-size:12px;font-weight:400;line-height:150%;text-align:center;text-decoration:underline;color:#E63E55;';
    const style_text =
        'font-family: Noto Sans JP;font-size:14px;font-weight:700;line-height:150%;text-align:center;color:#000000;';
    const style_text_smaller =
        'font-family: Noto Sans JP;font-size:12px;font-weight:400;line-height:150%;text-align:center;color:#000000;';
    const style_text_smaller_link =
        'font-family: Noto Sans JP;font-size:12px;font-weight:400;line-height:150%;text-align:center;text-decoration:underline;color:#E63E55;';

    const storeIOS = !isAndroid ? `<span style="font-size: 0px; padding: 0 8px 16px 8px;word-break: break-word;">
                                        <a href="https://apps.apple.com/jp/app/team-doctors/id1576314578?l=jp" target="_blank">
                                          <img height="45" src="${APP_URLS.BASE_URL}/images/app_store_badge.png" style="border:0;outline:none;text-decoration:none;height:45px;font-size:0;" width="121" />
                                        </a>
                                      </span>` : ``;
    const storeAndroid = !isIOS ? `<span style="font-size: 0px; padding: 0 8px 16px 8px;word-break: break-word;">
                                        <a href="https://play.google.com/store/apps/details?id=jp.bynds.teamdoctors" target="_blank">
                                          <img height="45" src="${APP_URLS.BASE_URL}/images/google_play_badge.png" style="border:0;outline:none;text-decoration:none;height:45px;font-size:0;" width="150" />
                                        </a>
                                      </span>` : ``;

    const raw = `<table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="background-color: #F0F0F0;">
    <tr>
        <td style="direction:ltr;font-size:0px;padding:16px;text-align:center;">
            <div align="center" style="max-width: 600px; margin: auto;" class="email-container">
                <!--[if mso]>
                <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="600" align="center">
                    <tr>
                        <td>
                <![endif]-->
                <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%">
                    <tr>
                        <td align="center" style="font-size:0px;padding:24px 16px 4px;word-break:break-word;">
                            <div style="${style_label}">Team doctors</div>
                        </td>
                    </tr>
                    <tr>
                        <td align="center" style="font-size:0px;padding:0 16px 16px;word-break:break-word;">
                            <div style="${style_link}">
                                <a href="https://teamdoctors.jp" target="_blank" style="${style_link}">https://teamdoctors.jp</a>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td align="center" style="font-size:0px;padding:0 16px 8px;word-break:break-word;">
                            <div style="${style_text}">アプリでどこからでもご覧いただけます。</div>
                        </td>
                    </tr>
                    <tr>
                        <td align="center" style="font-size:0px;padding:0 16px 16px;word-break:break-word;">
                            <div style="font-size:13px;line-height:1;text-align:center;color:#000000;">
                                <div style="width:100%;display:inline-block;font-size:0;">
                                      ` + storeIOS + storeAndroid + `
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td align="center" style="font-size:0px;padding:0 16px 4px;word-break:break-word;">
                            <div style="${style_text_smaller}">© Team doctors All rights reserved.</div>
                        </td>
                    </tr>
                    <tr>
                        <td align="center" style="font-size:0px;padding:0 16px 40px;word-break:break-word;">
                            <div style="${style_text_smaller}">運営会社： <a href="https://bynds.jp/" target="_blank" style="${style_text_smaller_link}"> 株式会社Bynds </a></div>
                        </td>
                    </tr>
                </table>
                <!--[if mso]>
                </td>
                </tr>
                </table>
                <![endif]-->
            </div>
        </td>
    </tr>
</table>
`;

    return <div dangerouslySetInnerHTML={{ __html: raw }} />;
};
