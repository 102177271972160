import React, { useState } from 'react';
import { Col, Form, Input, Radio, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import EmailType from 'enums/email_type.enum';
import PageElements from 'pages/admin/common/page-elements/PageElements';
import { connect } from 'react-redux';
import { setInfo } from 'pages/admin/email/email_create.slice';
import NewProgramTable from 'pages/admin/email/form/delivery_content/type/NewProgramTable';
import NewSessionTable from 'pages/admin/email/form/delivery_content/type/NewSessionTable';

const mapState = state => ({
    info: state.admin.emailCreate.info,
});
const mapDispatch = { setInfo };

const DeliveryContent = ({ initialValues = {}, info, setInfo, selectedRecord, setSelectedRecord, isRefSeminar }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    initialValues = Object.assign(
        {
            type: EmailType.HTML_EMAIL.value,
        },
        initialValues
    );

    const [type, setType] = useState(initialValues.type);

    return (
        <div className="px-48 pt-24">
            <Form layout="vertical" className="mb-16" form={form} initialValues={initialValues} requiredMark={false}>
                <Row gutter={16}>
                    <Col span={24} className="mb-8">
                        <Form.Item
                            name="title"
                            label={'件名'}
                            validateTrigger={false}
                            rules={[
                                {
                                    required: true,
                                    message: t('validation:Please enter a valid {{field}}', {
                                        field: t('field:Title'),
                                    }),
                                },
                            ]}
                            style={{ maxWidth: 480 }}
                        >
                            <Input
                                maxLength={255}
                                value={info.title}
                                onChange={e => setInfo({ title: e.target.value })}
                                disabled={isRefSeminar}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mb-8">
                        <Form.Item name="additional_notes" label={t('Additional notes')} style={{ maxWidth: 480 }}>
                            <Input.TextArea
                                autoSize
                                style={{ minHeight: 58 }}
                                value={info.additional_notes}
                                onChange={e => setInfo({ additional_notes: e.target.value })}
                                disabled={isRefSeminar}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24} className="mb-8">
                        <Form.Item name="type" label={'メールタイプ'}>
                            <Radio.Group
                                className="d-flex"
                                onChange={e => {
                                    setType(e.target.value);
                                    setInfo({ type: e.target.value });
                                    //setInfo({ type: e.target.value, target_audiences: [] });
                                }}
                                disabled={isRefSeminar}
                            >
                                {Object.values(EmailType).map(o => (
                                    <Radio key={o.value} value={o.value} className="mr-16">
                                        {o.text}
                                    </Radio>
                                ))}
                            </Radio.Group>
                        </Form.Item>
                    </Col>

                    {type === EmailType.HTML_EMAIL.value && (
                        <Col span={24} className="mb-8 hide-posted-in-announcement">
                            <PageElements hasExtends={true} />
                        </Col>
                    )}

                    {type === EmailType.NEW_PROGRAM.value && (
                        <Col span={24} className="mb-8">
                            <Form.Item label={t('Target program')} validateTrigger={false}>
                                <p className="body2 mb-4">{'プログラムを１つ選択してください(必須)'}</p>
                                <NewProgramTable
                                    selectedRecord={selectedRecord}
                                    setSelectedRecord={setSelectedRecord}
                                    selectFromId={initialValues.program_id}
                                />
                            </Form.Item>
                        </Col>
                    )}

                    {type === EmailType.NEW_SESSION.value && (
                        <Col span={24} className="mb-8">
                            <Form.Item label={t('Target session')} validateTrigger={false}>
                                <p className="body2 mb-4">{'セッションを１つ選択してください(必須)'}</p>
                                <NewSessionTable
                                    selectedRecord={selectedRecord}
                                    setSelectedRecord={setSelectedRecord}
                                    selectFromId={initialValues.seminar_id}
                                    isRefSeminar={isRefSeminar}
                                />
                            </Form.Item>
                        </Col>
                    )}
                </Row>
            </Form>
        </div>
    );
};

export default connect(mapState, mapDispatch)(DeliveryContent);
