import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';

const SurveySettingModal = ({ visible, onClose, onUpdate, initValue = '' }) => {
    const { t } = useTranslation();
    const titleContent = <span>{'アンケート'}</span>;

    const [value, setValue] = useState(initValue);

    return (
        <Modal
            title={titleContent}
            visible={visible}
            width={450}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <div className="mb-8">{'URL'}</div>
            <div className="mb-16">
                <Input value={value} onChange={e => setValue(e.target.value)} />
            </div>
            {
                <>
                    <div className="delete-action-panel">
                        <Button
                            type=""
                            className="cancel-button fw-b disable-color"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            <span className={'title-button'}>{t('Cancel')}</span>
                        </Button>
                        <Button
                            type=""
                            className={`delete-button fw-b bg-transparent color-blue-100`}
                            onClick={() => {
                                onUpdate && onUpdate(value);
                                onClose();
                            }}
                        >
                            <span className={'title-button'}>{t('Save')}</span>
                        </Button>
                    </div>
                </>
            }
        </Modal>
    );
};

SurveySettingModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default SurveySettingModal;
