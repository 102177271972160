export const getEnum = (enumObject, value) => {
    for (const k in enumObject) {
        if (!Object.prototype.hasOwnProperty.call(enumObject, k)) continue;
        if (enumObject[k].value === value) {
            return enumObject[k];
        }
    }
    return null;
};

export const getEnumText = (enumObject, value) => {
    const enumItem = getEnum(enumObject, value);
    return enumItem ? enumItem.text : '';
};
