import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import { useMediaQuery } from 'react-responsive';
import { Table } from 'antd';
import { FiltersPropTypes, PaginationPropTypes, SorterPropTypes } from 'constants/proptypes.constant';
import Loader from 'components/Loader';
import { ArrowLeftIcon, ArrowRightIcon } from 'components/SvgIcon';

const { Column } = Table;

const DataTable = ({
    loading,
    columns,
    hideColumns,
    data,
    pagination,
    sorter,
    actionColumn,
    onTableChange,
    selection,
    onSelectionChange,
    responsiveWidth,
    renderNoData,
    noDataText,
    yScroll,
    customRowSelection,
    noPagination = false,
    rowKey = r => r.id,
    ...rest
}) => {
    const isSm = useMediaQuery({ query: '(max-width: 991px)' });

    let rowSelection = null;
    if (onSelectionChange) {
        rowSelection = {
            selectedRowKeys: selection,
            onChange: keys => {
                onSelectionChange(keys);
            },
        };
    }

    if (customRowSelection && !rowSelection) {
        rowSelection = customRowSelection;
    }

    const paginationOptions = noPagination
        ? false
        : Object.assign(
              {
                  showLessItems: true,
                  //showQuickJumper: true,
                  //showTotal: (total, range) => `${range[0]} - ${range[1]} / ${total}`,
                  //pageSizeOptions: [10, 15, 20, 25, 30],
                  hideOnSinglePage: true,
                  showSizeChanger: false,
                  position: ['bottomCenter'],
                  itemRender: (page, type, org) => {
                      if (type === 'next') {
                          return <ArrowRightIcon className="ml-16" />;
                      } else if (type === 'prev') {
                          return <ArrowLeftIcon className="mr-16" />;
                      }

                      return org;
                  },
              },
              pagination
          );

    const getColumnSorter = field => {
        if (!sorter || sorter.field !== field) return null;
        return sorter.order;
    };

    let children = [];
    for (const c of columns) {
        if (hideColumns && hideColumns.indexOf(c.dataIndex) > -1) continue;
        children.push(
            <Column
                className={c.className}
                key={c.dataIndex}
                title={c.title}
                dataIndex={c.dataIndex}
                filters={c.filters}
                sorter={c.sorter}
                render={c.render}
                defaultSortOrder={getColumnSorter(c.dataIndex)}
                width={c.width}
            />
        );
    }
    if (actionColumn) {
        children.push(
            <Column
                className={`text-right ${actionColumn.className || ''}`}
                key="actionColumn"
                title={actionColumn.title || ''}
                width={actionColumn.width}
                render={r => actionColumn.render(r)}
            />
        );
    }

    return (
        <Table
            {...rest}
            dataSource={data}
            loading={loading ? { indicator: <Loader /> } : false}
            rowKey={rowKey}
            rowSelection={rowSelection}
            pagination={paginationOptions}
            onChange={(pagination, filters, sorter) => {
                const { order, field } = sorter;
                onTableChange(pagination, filters, { order, field });
            }}
            size="middle"
            scroll={responsiveWidth && isSm ? { x: responsiveWidth } : yScroll ? yScroll : null}
            locale={{
                emptyText: renderNoData || <div className="pt-72 pb-72">{noDataText || 'データがありません'}</div>,
            }}
        >
            {children}
        </Table>
    );
};

export default DataTable;

DataTable.propTypes = {
    loading: PropTypes.bool,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            dataIndex: PropTypes.any.isRequired,
            filters: PropTypes.arrayOf(
                PropTypes.shape({
                    text: PropTypes.string.isRequired,
                    value: PropTypes.string.isRequired,
                })
            ),
            sorter: PropTypes.any,
            render: PropTypes.func,
            searchKey: PropTypes.string,
        })
    ).isRequired,
    hideColumns: PropTypes.array,
    data: PropTypes.array.isRequired,
    pagination: PaginationPropTypes,
    filters: FiltersPropTypes,
    sorter: SorterPropTypes,
    actionColumn: PropTypes.shape({
        width: PropTypes.any,
        render: PropTypes.func.isRequired,
    }),
    onTableChange: PropTypes.func,
    selection: PropTypes.array,
    onSelectionChange: PropTypes.func,
    responsiveWidth: PropTypes.number,
    renderNoData: PropTypes.any,
    noDataText: PropTypes.string,
    yScroll: PropTypes.any,
    customRowSelection: PropTypes.any,
};
