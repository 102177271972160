import React, { useEffect } from 'react';
import { Col, Form, Input, Row } from 'antd';
import BackBtn from 'components/BackBtn';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FloatBar from 'components/FloatBar';
import { useTranslation } from 'react-i18next';
import api from 'helpers/api.helper';
import history from 'helpers/history.helper';
import { APP_URLS } from 'constants/url.constant';
import { setUser } from 'pages/auth/auth.slice';
import TdtButtonSubmit from 'components/TdtButtonSubmit';

const mapState = state => ({
    account: state.auth.user,
});
const mapDispatch = { setUser };
const UpdateNameAccount = ({ account, setUser }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const updateName = async values => {
        const response = await api.patch(APP_URLS.COUNSELOR_UPDATE_NAME_ACCOUNT, values);
        if (response) {
            setUser(response);
            history.goBack();
        }
    };

    useEffect(() => {}, []);

    let initialValues = Object.assign({}, { ...account });

    return (
        <div className="px-48">
            <BackBtn label={'アカウント編集'} />

            <Form
                className="mt-32"
                layout="vertical"
                form={form}
                initialValues={initialValues}
                onFinish={async values => {
                    await updateName(values);
                }}
                requiredMark={false}
            >
                <Row className="mb-24" align="middle">
                    <Col className="mr-16">
                        <Form.Item
                            name="first_name"
                            label={t('field:first name')}
                            validateTrigger={['onBlur']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{ maxWidth: 232 }} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="last_name"
                            label={t('field:last name')}
                            validateTrigger={['onBlur']}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input style={{ maxWidth: 232 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row className="mb-24" align="middle">
                    <Col className="mr-16">
                        <Form.Item
                            name="first_name_kana"
                            label={t('field:First name kana')}
                            validateTrigger={['onBlur']}
                            rules={[
                                {
                                    required: true,
                                },
                                () => ({
                                    validator(_, value) {
                                        if (/^[ぁ-ん]+$/.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(t('validation:Please enter in hiragana')));
                                    },
                                }),
                            ]}
                        >
                            <Input style={{ maxWidth: 232 }} />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="last_name_kana"
                            label={t('field:Last name kana')}
                            validateTrigger={['onBlur']}
                            rules={[
                                {
                                    required: true,
                                },
                                () => ({
                                    validator(_, value) {
                                        if (!value || /^[ぁ-ん]+$/.test(value)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(t('validation:Please enter in hiragana')));
                                    },
                                }),
                            ]}
                        >
                            <Input style={{ maxWidth: 232 }} />
                        </Form.Item>
                    </Col>
                </Row>
                <FloatBar>
                    <TdtButtonSubmit type="primary" htmlType="submit" className="fw-b btn-float-bar">
                        {t('Save')}
                    </TdtButtonSubmit>
                </FloatBar>
            </Form>
        </div>
    );
};

export default connect(mapState, mapDispatch)(UpdateNameAccount);

UpdateNameAccount.propTypes = {
    account: PropTypes.any,
    setUser: PropTypes.func,
};
