import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const ActionBtnInModal = ({ onClose, onUpdate, confirmText = '', isNotDelete, manualClose = false }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="delete-action-panel">
                <Button
                    type=""
                    className="cancel-button fw-b disable-color"
                    onClick={() => {
                        onClose();
                    }}
                >
                    <span className={'title-button'}>{t('Cancel')}</span>
                </Button>
                <Button
                    type=""
                    className={isNotDelete ? 'delete-button fw-b color-blue-100' : 'delete-button fw-b'}
                    onClick={() => {
                        onUpdate && onUpdate();
                        (!manualClose) && onClose();
                    }}
                >
                    <span className={'title-button'}>{confirmText ? confirmText : t('Delete')}</span>
                </Button>
            </div>
        </>
    );
};

export default ActionBtnInModal;
