import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Routes from 'routes';
import NotFound from 'pages/error/NotFound';
import SidebarAdmin from 'layouts/SidebarAdmin';

const LayoutClient = () => {
    return (
        <div id="main-layout" className="self-layout">
            <SidebarAdmin />
            <div id="container-none-user">
                <div className="tdt-content">
                    <Switch>
                        {Object.keys(Routes.private).map(key => {
                            const r = Routes.private[key];
                            let component = r.component;
                            return <Route key={key} path={r.path} exact={r.exact !== false} component={component} />;
                        })}
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default LayoutClient;
