import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import { getReservationAnswers } from 'pages/client/interview_sheet/interview_sheet.slice';
import { connect } from 'react-redux';
import { filterInterviewSheets } from 'helpers/interview_sheet.helper';
import TdtPullable from 'components/TdtPullable';
import Loader from 'components/Loader';
import InterviewSheetRow from 'pages/client/interview_sheet/InterviewSheetRow';
import NoData from 'components/NoData';
import QuestionnaireRow from 'pages/client/interview_sheet/QuestionnaireRow';
import moment from 'moment';

const mapState = state => ({
    reservationAnswers: state.client.interviewSheet.reservationAnswers,
    questionnaires: state.client.interviewSheet.questionnaires,
    programsWithFirstInterview: state.client.interviewSheet.programsWithFirstInterview,
    noData: state.client.interviewSheet.noData,
});
const mapDispatch = { getReservationAnswers };

const InterviewSheetHistory = ({
    reservationAnswers,
    questionnaires,
    programsWithFirstInterview,
    noData,
    getReservationAnswers,
}) => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    useEffect(async () => {
        setLoading(true);
        await getReservationAnswers();
        setLoading(false);

        return () => {
            setLoading(true);
        };
    }, []);

    const reservationAnswersFiltered = filterInterviewSheets(reservationAnswers, true);
    const questionnairesFiltered = filterInterviewSheets(questionnaires, true);
    const programsWithFirstInterviewFiltered = filterInterviewSheets(programsWithFirstInterview, true);

    const getDateByType = a => {
        if (a.reservation_id) {
            return moment(a.reservation.date);
        } else if (a.status) {
            return moment(a.created_at);
        }
    };

    const mixForSortInterviewSheet = (reservationAnswersFiltered = [], questionnairesFiltered = []) => {
        const mix = [...reservationAnswersFiltered, ...questionnairesFiltered];
        return mix.sort((a1, b1) => {
            const a = getDateByType(a1);
            const b = getDateByType(b1);
            return a.isAfter(b) ? -1 : a.isBefore ? 1 : 0;
        });
    };

    return (
        <>
            {/*<BackBtnWithTitle title={t('Answer history')} titleClassName={'past-reservation-back-title'} />*/}
            <TdtPullable
                onRefresh={() => {
                    getReservationAnswers();
                }}
            />
            {loading ? (
                <Loader className="loader-center" />
            ) : (
                <div>
                    {mixForSortInterviewSheet(reservationAnswersFiltered, questionnairesFiltered).map(itv => {
                        if (itv.reservation_id) {
                            return <InterviewSheetRow key={itv.id} reservationAnswer={itv} />;
                        } else if (itv.status) {
                            return <QuestionnaireRow key={itv.id} questionnaire={itv} />;
                        } else {
                            return <></>;
                        }
                    })}

                    {/*{reservationAnswersFiltered.length > 0 && (*/}
                    {/*    <>*/}
                    {/*        {reservationAnswersFiltered.map(reservationAnswer => (*/}
                    {/*            <InterviewSheetRow key={reservationAnswer.id} reservationAnswer={reservationAnswer} />*/}
                    {/*        ))}*/}
                    {/*    </>*/}
                    {/*)}*/}
                    {/*{questionnairesFiltered.length > 0 && (*/}
                    {/*    <>*/}
                    {/*        {questionnairesFiltered.map(questionnaire => (*/}
                    {/*            <QuestionnaireRow key={questionnaire.id} questionnaire={questionnaire} />*/}
                    {/*        ))}*/}
                    {/*    </>*/}
                    {/*)}*/}

                    {programsWithFirstInterviewFiltered.length > 0 && (
                        <>
                            {programsWithFirstInterviewFiltered.map(programFirstInterview => {
                                if (!programFirstInterview.exist_reservation) {
                                    return (
                                        <InterviewSheetRow
                                            key={programFirstInterview.id}
                                            programId={programFirstInterview.id}
                                            reservationAnswer={programFirstInterview.first_interview_sheet}
                                            isFirstInterviewSheet={true}
                                        />
                                    );
                                }
                            })}
                        </>
                    )}
                    {(noData ||
                        reservationAnswersFiltered.length +
                            questionnairesFiltered.length +
                            programsWithFirstInterviewFiltered.filter(p => !p.exist_reservation).length <
                            1) && <NoData />}
                </div>
            )}
        </>
    );
};

export default connect(mapState, mapDispatch)(InterviewSheetHistory);

InterviewSheetHistory.propTypes = {
    reservationAnswers: PropTypes.array,
    programsWithFirstInterview: PropTypes.array,
    noData: PropTypes.bool,
    getReservationAnswers: PropTypes.func.isRequired,
};
