import React from 'react';

export const HMCoverImage = ({ cover_img }) => {
    const raw = `<tr>
    <td style="padding: 0 0 32px 0;">
        <table role="presentation" cellspacing="0" cellpadding="0" border="0" width="100%" style="font-size: 14px; text-align: left;">
            <tr>
                <td style="line-height: 1; font-size: 0">
                    <img src="${cover_img}" height="auto" border="0" alt="alt_text" style="width: 100%; height: auto; background: #FFFFFF;" class="center-on-narrow">
                </td>
            </tr>
        </table>
    </td>
</tr>
`;

    return <tr dangerouslySetInnerHTML={{ __html: raw }} />;
};
