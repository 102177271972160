import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { dateToStr } from 'helpers/date.helper';
import { Typography } from 'antd';
import { connect } from 'react-redux';
import { getData } from 'pages/counselor/calendar/questionnaire/questionnaire.slice';
import DataTable from 'components/DataTable';
import QuestionnaireDetail from 'pages/counselor/calendar/questionnaire/QuestionnaireDetail';
import history from 'helpers/history.helper';
import { setQuestionnaire } from 'pages/counselor/calendar/questionnaire/questionnaire_detail.slice';

const mapState = state => ({
    state: state.counselor.questionnaire,
    questionnaire: state.counselor.questionnaireDetail.questionnaire,
});
const mapDispatch = { getData, setQuestionnaire };

const Questionnaire = ({ state, getData, questionnaire, setQuestionnaire }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    let { path, url } = useRouteMatch();
    const columns = [
        {
            title: '送信日時',
            dataIndex: 'created_at',
            sorter: true,
            render: (_, record) => {
                return (
                    <>
                        {record.unread_message > 0 && <span className="dot-unread-message abs-left" />}
                        <div className="mb-4 fw-400 fs-12">{`${dateToStr(record.created_at, 'YYYY/MM/DD HH:mm')}`}</div>
                        <div>
                            <Typography className="fw-400 fs-14 color-blg-100">{record.title}</Typography>
                        </div>
                    </>
                );
            },
        },
    ];

    const getQuestionnaires = (
        pagination = state.pagination,
        filters = state.filters,
        sorter = state.sorter,
        callback
    ) => {
        getData({ id, pagination, filters, sorter }).then(o => {
            const data = o.payload ? o.payload.data : [];
            return callback && callback(data);
        });
    };

    const getListCallback = data => {
        const questionnaireId = questionnaire && questionnaire.id;
        if (!questionnaireId) {
            return;
        }

        if (data.length > 0) {
            if (data.find(d => d.id === questionnaireId)) {
                return;
            }
            history.push(`${url}/${data[0].id}`);
        } else {
            history.push(`${url}`);
            setQuestionnaire(null);
        }
    };

    useEffect(() => {
        getQuestionnaires(state.pagination, state.filters, state.sorter, getListCallback);
    }, [id]);

    return (
        <>
            <div className="counselor-aside">
                <div className="consultation-aside ant-layout h-100p">
                    <div className="pb-0">
                        <div className="h7 mt-32 mb-16 ml-16 fw-b">{t('Interview sheet')}</div>
                    </div>
                    <div className="ant-layout">
                        <DataTable
                            className="collapse-table aside-table overflow-y h-100p counselor-for-message-table"
                            columns={columns}
                            data={state.data}
                            loading={state.loading}
                            pagination={state.pagination}
                            filters={state.filters}
                            sorter={state.sorter}
                            renderNoData={<div className="pt-24 pb-24">データがありません</div>}
                            onTableChange={() => {}}
                            onRow={record => ({
                                onClick: () => {
                                    history.push(`${url}/${record.id}`);
                                },
                            })}
                            rowClassName={record => (record.id === questionnaire?.id ? 'tdt-row-selected' : '')}
                        />
                    </div>
                </div>
            </div>
            <div className="counselor-main">
                <Switch>
                    <Route
                        path={`${path}/`}
                        exact
                        render={props => (
                            <QuestionnaireDetail
                                {...props}
                                baseUrl={url}
                                firstId={state.data.length > 0 ? state.data[0].id : undefined}
                                allAnswers={state.data}
                            />
                        )}
                    />
                    <Route
                        path={`${path}/:questionnaireId`}
                        exact
                        render={props => (
                            <QuestionnaireDetail
                                {...props}
                                baseUrl={url}
                                firstId={state.data.length > 0 ? state.data[0].id : undefined}
                                allAnswers={state.data}
                            />
                        )}
                    />
                </Switch>
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(Questionnaire);
