import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataTable from 'components/DataTable';
import { getData, setFilters, setSorter } from 'pages/counselor/message/message.slice';
import { dateToStr } from 'helpers/date.helper';
import { truncateStr } from 'helpers/string.helper';
import Routes from 'routes';
import history from 'helpers/history.helper';
import { setBackUrl } from 'core.slice';

const mapState = state => ({
    state: state.counselor.bulkMessage,
});
const mapDispatch = { getData, setSorter, setFilters, setBackUrl };

const MessageContainer = ({ state, getData, setBackUrl }) => {
    const columns = [
        {
            title: '日付',
            dataIndex: 'created_at',
            width: 120,
            sorter: (a, b) => {
                return a.created_at.localeCompare(b.created_at);
            },
            render: d => <span className="text-small high-color">{dateToStr(d)}</span>,
        },
        {
            title: '送信先',
            dataIndex: 'destination',
            width: 133,
            render: (_, r) => (
                <>
                    <div>{r.user ? `${r.user.full_name}` : ''}</div>
                    <div className="fs-12 text-gray-color">{r.user ? r.user.employee_company[0].name : ''}</div>
                </>
            ),
        },
        {
            title: 'メッセージ',
            dataIndex: 'content',
            width: 658,
            render: (_, r) => {
                const recentMessage = r.recent_message || {};
                return (
                    <>
                        <div className="fw-b high-color">{r.title}</div>
                        <div className="text-gray-color fs-12">{truncateStr(recentMessage.content, 70)}</div>
                    </>
                );
            },
        },
    ];

    const getMessages = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        setSorter(sorter);
        getData({ pagination, filters: { ...filters, module: 'counselor' }, sorter });
    };

    const handleRowClick = row => {
        setBackUrl(Routes.private.COUNSELOR_MESSAGE.path);
        if (row) {
            history.push(Routes.private.COUNSELOR_DETAIL_MESSAGE.path.replace(':id', row.user_id) + '/' + row.id);
        }
    };

    useEffect(() => {
        getMessages();
    }, []);

    return (
        <div className="self-wrapper d-block">
            <h1 className="page-title mb-32 h5">{'メッセージ'}</h1>

            <div className="p-0">
                <div className="mt-16">
                    <DataTable
                        className="collapse-table"
                        columns={columns}
                        hideColumns={state.hideColumns}
                        data={state.data}
                        loading={state.loading}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                        onTableChange={(pagination, filter, sorter) => {
                            getMessages(pagination, state.filters, sorter);
                        }}
                        noDataText={'登録されているメッセージはいません'}
                        onRow={record => ({
                            onClick: () => {
                                record.user && handleRowClick(record);
                            },
                        })}
                    />
                </div>
            </div>
        </div>
    );
};

export default connect(mapState, mapDispatch)(MessageContainer);

MessageContainer.propTypes = {
    state: PropTypes.any,
    getData: PropTypes.func.isRequired,
    setBackUrl: PropTypes.func,
};
