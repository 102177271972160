import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import DataTable from 'components/DataTable';
import { AddIcon, DeleteIcon, EditIcon } from 'components/SvgIcon';
import CustomDropdown from 'components/CustomDropdown';
import BackBtn from 'components/BackBtn';
import history from 'helpers/history.helper';
import CompanyDetailRoutes from 'pages/admin/company/company_detail_routes';
import { getData, setSorter } from 'pages/admin/company/tabs/program/program.slice';
import FloatBar from 'components/FloatBar';
import DeleteProgramModal from 'pages/admin/company/tabs/program/DeleteProgramModal';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { realSessionTime } from 'helpers/session_time_calculation.helper';
import TdtButton from 'components/TdtButton';
import Routes from 'routes';
import { showNormal } from 'helpers/notification.helper';
import AddCounselorContainer from './AddCounselorContainer';

const mapState = state => ({
    state: state.admin.companyDetailProgram,
});
const mapDispatch = { getData, setSorter };

const ProgramContainer = ({ company, renderTabList, baseUrl, state, getData, setSorter }) => {
    const { t } = useTranslation();

    const [modalVisible, setModalVisible] = useState({
        delete: false,
        id: null,
        existReservation: false,
    });
    const showDeleteModal = (id, existReservation) =>
        setModalVisible({ delete: true, id: id, existReservation: existReservation });
    const closeModal = () => setModalVisible({ delete: false, id: null, existReservation: false });

    const deleteProgram = async programCompanyId => {
        const res = await api.delete(
            APP_URLS.ADMIN_COMPANIES_DETAIL_PROGRAMS_DELETE.replace(':id', company.id).replace(
                ':programCompanyId',
                programCompanyId
            )
        );
        if (res) {
            getPrograms();
            showNormal('', t('message:Program has been deleted'), 3);
        }
    };

    const detailsToDoctors = (details = []) => {
        let result = {};
        details.map(detail => {
            if (detail.doctor_id && !result[detail.doctor_id] && detail.doctor) {
                result[detail.doctor_id] = detail.doctor;
            }
        });
        return Object.values(result);
    };

    const columns = [
        {
            title: t('Name'),
            dataIndex: 'name_mngt',
            width: 288,
            sorter: (a, b) => {
                return a.name_mngt.localeCompare(b.name_mngt);
            },
        },
        {
            title: '予約枠',
            dataIndex: 'session_length',
            className: 'text-align-right',
            width: 128,
            render: (_, r) => {
                const pc = r.program_companies[0];
                return `${realSessionTime(pc, pc.buffer_time)}分`;
            },
        },
        {
            title: '担当カウンセラー',
            dataIndex: 'counselor',
            width: 220,
            render: (_, r) => {
                const pc = r.program_companies[0];
                if (!pc.details || pc.details.length < 1) {
                    return 'アサインされていません';
                }

                const doctors = detailsToDoctors(pc.details);
                return doctors.map((doctor, index) => (
                    <>
                        <span className="" key={doctor.id}>
                            <TdtButton
                                buttonStyle="plain"
                                onClick={() =>
                                    history.push(Routes.private.ADMIN_COUNSELORS_DETAIL.path.replace(':id', doctor.id))
                                }
                            >{`${doctor.full_name}`}</TdtButton>
                        </span>
                        {index !== doctors.length - 1 && <span className="primary-color">{'，'}</span>}
                    </>
                ));
            },
        },
        {
            title: t('Edit'),
            dataIndex: 'EDIT',
            render: (_, r) => {
                return (
                    <CustomDropdown
                        trigger={['click']}
                        options={[
                            {
                                key: 'add',
                                text: (
                                    <>
                                        <AddIcon /> <span>{'カウンセラー追加'}</span>
                                    </>
                                ),
                                className: 'primary-color fw-b',
                            },
                            {
                                key: 'edit',
                                text: (
                                    <>
                                        <EditIcon /> <span>{'予約枠編集'}</span>
                                    </>
                                ),
                                className: 'primary-color fw-b',
                            },
                            {
                                key: 'delete',
                                text: (
                                    <>
                                        <DeleteIcon /> <span>{'プログラム削除'}</span>
                                    </>
                                ),
                                className: 'pink-color fw-b',
                            },
                        ]}
                        onSelect={opt => {
                            const pc = r.program_companies[0];
                            if (opt.key === 'delete') {
                                // programCompany_id
                                showDeleteModal(pc.id, r.reservations_count > 0 ? true : false);
                            } else if (opt.key === 'add') {
                                let mode="create"
                                // const isEditScreen = true;
                                // Check company program
                                history.push(
                                    `${baseUrl}${CompanyDetailRoutes.PROGRAM_ADD_COUNSELOR.path.replace(
                                        ':programId',
                                        pc.id
                                    )}`
                                );
                                <AddCounselorContainer mode={mode} />
                            } else if (opt.key === 'edit') {
                                history.push(
                                    `${baseUrl}${CompanyDetailRoutes.PROGRAM_EDIT.path.replace(':programId', pc.id)}`
                                );
                            }
                        }}
                        separator
                    >
                        <EditIcon className="primary-color custom-icon c-pointer" />
                    </CustomDropdown>
                );
            },
            width: 60,
        },
    ];

    const getPrograms = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        setSorter(sorter);
        getData({ id: company.id, pagination, filters, sorter });
    };

    useEffect(() => {
        getPrograms();
    }, [company.id]);

    return (
        <>
            <div className="px-24 pb-12">
                <BackBtn label={company.name} action={() => history.push(Routes.private.ADMIN_COMPANY.path)} />
            </div>
            {renderTabList('program')}

            <div className="p-0">
                <div className="mt-16">
                    <DataTable
                        className="collapse-table"
                        columns={columns}
                        hideColumns={state.hideColumns}
                        data={state.data}
                        loading={state.loading}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                        onTableChange={(pagination, filter, sorter) => {
                            getPrograms(pagination, state.filters, sorter);
                        }}
                        noDataText={'登録されているプログラムはいません'}
                    />

                    <DeleteProgramModal
                        visible={modalVisible.delete}
                        onClose={() => closeModal()}
                        initialValue={{}}
                        onUpdate={() => deleteProgram(modalVisible.id)}
                        existCreatedReservation={modalVisible.existReservation}
                    />

                    <FloatBar>
                        <Button
                            icon={<AddIcon />}
                            className="fw-b"
                            onClick={() => history.push(`${baseUrl}${CompanyDetailRoutes.PROGRAM_CREATE.path}`)}
                        >
                            {'プログラムの追加'}
                        </Button>
                    </FloatBar>
                </div>
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(ProgramContainer);

ProgramContainer.propTypes = {
    company: PropTypes.any,
    renderTabList: PropTypes.func,
    baseUrl: PropTypes.string,
    state: PropTypes.any,
    getData: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
};
