import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { InterviewSheetPropTypes } from 'constants/proptypes.constant';
import {
    getInterviewSheet,
    setInterviewSheet,
    resetFilter,
} from 'pages/admin/interview_sheet/detail_screen/interview_sheet_detail.slice';
import Loader from 'components/Loader';
import TabList from 'components/TabList';
import OverviewContainer from './tabs/overview/OverviewContainer';
import TotalContainer from './tabs/total/TotalContainer';
import IndividualContainer from './tabs/individual/IndividualContainer';
import { Button, Col, Row, Tooltip } from 'antd';
import history from 'helpers/history.helper';
import {
    DeleteIcon,
    DotsHorizontalRoundedIcon,
    DownloadIcon,
    DuplicateIcon,
    EditIcon,
    EditorLinkIcon,
} from 'components/SvgIcon';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import DeleteInterviewSheetModal from 'pages/admin/interview_sheet/modal/DeleteInterviewSheetModal';
import CustomDropdown from 'components/CustomDropdown';
import api from 'helpers/api.helper';
import Routes from 'routes';
import BackBtn from 'components/BackBtn';
import { copyToClipboard } from 'helpers/common.helper';
import InterviewSheetCSVDownload from 'pages/admin/interview_sheet/detail_screen/InterviewSheetCSVDownload';
import InterviewSheetPreviewModal from 'pages/admin/interview_sheet/detail_screen/InterviewSheetPreviewModal';
import ResponsePeriodContainer from 'pages/admin/interview_sheet/detail_screen/tabs/response_period/ResponsePeriodContainer';
import CloneInterviewSheetModal from 'pages/admin/interview_sheet/modal/CloneInterviewSheetModal';
import { callLogApi } from 'pages/admin/log/log.slice';

const mapState = state => ({
    interviewSheet: state.admin.interviewSheetDetail.interviewSheet,
});
const mapDispatch = { getInterviewSheet, setInterviewSheet, resetFilter };

const InterviewSheetDetail = ({ interviewSheet, getInterviewSheet, setInterviewSheet, resetFilter }) => {
    const { t } = useTranslation();
    const { id: interviewSheetId } = useParams();
    const tab = new URLSearchParams(useLocation().search).get('tab');
    const exportRef = React.createRef();

    const [activeKey, setActiveKey] = useState('overview');
    const [modalVisible, setModalVisible] = useState({
        clone: false,
        delete: false,
        preview: false,
    });

    const showCloneModal = r => setModalVisible({ ...modalVisible, clone: true, record: r });
    const showDeleteModal = r => setModalVisible({ ...modalVisible, delete: true, record: r });
    const showPreviewModal = () => setModalVisible({ ...modalVisible, preview: true });
    const closeModal = () =>
        setModalVisible({
            clone: false,
            delete: false,
            preview: false,
        });

    const deleteInterviewSheet = async interviewSheetId => {
        const response = await api.delete(APP_URLS.ADMIN_INTERVIEW_SHEETS_DETAIL.replace(':id', interviewSheetId));
        if (response) {
            showNormal('', t('message:Data has been deleted'));
            history.push(Routes.private.ADMIN_INTERVIEW_SHEET.path);
        }
    };

    useEffect(() => {
        getInterviewSheet(interviewSheetId);

        if (tab) {
            setActiveKey(tab);
        }

        return () => {
            setInterviewSheet(null);
            resetFilter();
        };
    }, [interviewSheetId]);

    if (!interviewSheet) {
        return <Loader className="loader-center" />;
    }

    const tabList = [
        {
            key: 'overview',
            title: '概要',
            container: <OverviewContainer interviewSheet={interviewSheet} setActiveKey={setActiveKey} />,
        },
        {
            key: 'total',
            title: '集計',
            container: <TotalContainer interviewSheet={interviewSheet} setActiveKey={setActiveKey} />,
        },
        {
            key: 'individual',
            title: '個別',
            container: <IndividualContainer interviewSheet={interviewSheet} setActiveKey={setActiveKey} />,
        },
        {
            key: 'response_period',
            title: '回答期間',
            container: <ResponsePeriodContainer interviewSheet={interviewSheet} />,
            hidden: !interviewSheet.is_radar_questionnaire,
        },
    ];

    const renderTabList = () => {
        return (
            <TabList
                activeKey={activeKey}
                onChange={setActiveKey}
                tabs={tabList.filter(t => !t.hidden)}
                animated={false}
                className="interview-sheet-tab-list bg-white"
            />
        );
    };

    return (
        <div className="self-wrapper px-0 pb-0 bg-gray-5">
            <div className="px-48 bg-white">
                <Row>
                    <Col span={24}>
                        <div className="d-flex justify-content-space-between align-items-center">
                            <BackBtn
                                label={interviewSheet.name}
                                action={() => history.push(Routes.private.ADMIN_INTERVIEW_SHEET.path)}
                            />

                            <div className="pt-48 change-state-container">
                                {interviewSheet.programs_count > 0 ? (
                                    <Tooltip
                                        placement="top"
                                        title={
                                            <span className="fs-14-21">
                                                {'プログラムに紐づいているため、'}
                                                <br />
                                                {'共有リンク発行ができません'}
                                            </span>
                                        }
                                        overlayInnerStyle={{ backgroundColor: 'rgba(33, 33, 33, 0.9)' }}
                                    >
                                        <Button
                                            className="fw-700"
                                            icon={<EditorLinkIcon />}
                                            onClick={() => {}}
                                            disabled={true}
                                        >
                                            <span className="fw-700">{'共有リンク'}</span>
                                        </Button>
                                    </Tooltip>
                                ) : interviewSheet.is_radar_questionnaire && interviewSheet.periods.length < 1 ? (
                                    <Tooltip
                                        placement="top"
                                        title={
                                            <span className="fs-14-21">
                                                {'回答期間が未設定のため'}
                                                <br />
                                                {'共有リンク発行ができません'}
                                            </span>
                                        }
                                        overlayInnerStyle={{ backgroundColor: 'rgba(33, 33, 33, 0.9)' }}
                                    >
                                        <Button
                                            className="fw-700"
                                            icon={<EditorLinkIcon />}
                                            onClick={() => {
                                                copyToClipboard(`${interviewSheet.short_link}`);
                                                // copyToClipboard(`${location.origin}/questionnaire/${interviewSheet.uuid}`);
                                                showNormal('', '質問票 URLをコピーしました', 3);
                                            }}
                                            disabled={true}
                                        >
                                            <span className="fw-700">{'共有リンク'}</span>
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Button
                                        className="fw-700"
                                        icon={<EditorLinkIcon />}
                                        onClick={() => {
                                            copyToClipboard(`${interviewSheet.short_link}`);
                                            // copyToClipboard(`${location.origin}/questionnaire/${interviewSheet.uuid}`);
                                            showNormal('', '質問票 URLをコピーしました', 3);
                                        }}
                                    >
                                        <span className="fw-700">{'共有リンク'}</span>
                                    </Button>
                                )}

                                <Button className="fw-700 ml-8" onClick={() => showPreviewModal()}>
                                    {'プレビュー'}
                                </Button>

                                <CustomDropdown
                                    ovlClassName={'top-dots-dropdown'}
                                    className=" ml-24 mt-10"
                                    onClick={e => {
                                        e.stopPropagation();
                                    }}
                                    trigger={['click']}
                                    options={[
                                        {
                                            key: 'csv-download',
                                            text: (
                                                <>
                                                    <DownloadIcon /> <span>{'CSVファイルダウンロード'}</span>
                                                </>
                                            ),
                                            className: 'primary-color fw-b',
                                        },
                                        {
                                            key: 'edit',
                                            text: (
                                                <>
                                                    <EditIcon />
                                                    <span>
                                                        {'編集'}
                                                    </span>
                                                </>
                                            ),
                                            className: 'primary-color fw-b',
                                        },
                                        {
                                            key: 'duplicate',
                                            text: (
                                                <>
                                                    <DuplicateIcon size={24} /> <span>{t('Duplicate')}</span>
                                                </>
                                            ),
                                            className: 'primary-color fw-b',
                                        },
                                        {
                                            key: 'delete',
                                            text: (
                                                <>
                                                    <DeleteIcon /> <span>{'削除'}</span>
                                                </>
                                            ),
                                            className: 'pink-color fw-b',
                                        },
                                    ]}
                                    onSelect={opt => {
                                        opt.domEvent.stopPropagation();
                                        if (opt.key === 'delete') {
                                            showDeleteModal(interviewSheet);
                                        } else if (opt.key === 'csv-download') {
                                            exportRef.current.runExport();
                                            callLogApi('csv_itv_download', interviewSheet.id);
                                        } else if (opt.key === 'duplicate') {
                                            showCloneModal(interviewSheet);
                                        } else {
                                            history.push(
                                                Routes.private.ADMIN_INTERVIEW_SHEETS_UPDATE.path.replace(
                                                    ':id',
                                                    interviewSheet.id
                                                )
                                            );
                                        }
                                    }}
                                    separator
                                >
                                    <DotsHorizontalRoundedIcon className=" primary-color c-pointer" />
                                </CustomDropdown>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>

            {renderTabList()}

            <DeleteInterviewSheetModal
                onClose={() => closeModal()}
                onUpdate={() => deleteInterviewSheet(modalVisible.record.id)}
                visible={modalVisible.delete}
                interviewSheet={interviewSheet}
            />
            {interviewSheet && (
                <CloneInterviewSheetModal
                    onClose={() => {
                        closeModal();
                    }}
                    visible={modalVisible.clone}
                    interviewSheet={interviewSheet}
                />
            )}
            <InterviewSheetPreviewModal
                visible={modalVisible.preview}
                onClose={() => closeModal()}
                data={interviewSheet}
            />

            <InterviewSheetCSVDownload interviewSheet={interviewSheet} ref={exportRef} />
        </div>
    );
};

export default connect(mapState, mapDispatch)(InterviewSheetDetail);

InterviewSheetDetail.propTypes = {
    // interviewSheet: InterviewSheetPropTypes,
    interviewSheet: PropTypes.any,
    getInterviewSheet: PropTypes.func.isRequired,
    setInterviewSheet: PropTypes.func,
};
