import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { addDetail } from 'pages/admin/company/tabs/program/reservation_detail.slice';
import ReservationFrame from 'pages/admin/company/tabs/program/ReservationFrame';
import { AddIcon } from 'components/SvgIcon';
import Loader from 'components/Loader';

const mapState = state => ({
    detailsOrder: state.admin.companyDetailProgramReservation.detailsOrder,
    loading: state.admin.companyDetailProgramReservation.loading,
});
const mapDispatch = { addDetail };

const ReservationFrameList = React.memo(({ detailsOrder, addDetail, sessionTime, loading }) => {
    return (
        <>
            <div className="fw-b mb-32">{'予約枠'}</div>
            {loading ? (
                <Loader></Loader>
            ) : (
                detailsOrder.map(id => <ReservationFrame key={id} id={id} sessionTime={sessionTime} />)
            )}
            <div className="mt-24">
                <Button icon={<AddIcon />} className="fw-b" onClick={() => addDetail(sessionTime)}>
                    {'予約枠追加'}
                </Button>
            </div>
        </>
    );
});

export default connect(mapState, mapDispatch)(ReservationFrameList);

ReservationFrameList.propTypes = {
    detailsOrder: PropTypes.array.isRequired,
    addDetail: PropTypes.func,
    sessionTime: PropTypes.number,
    loading: PropTypes.bool,
};
