import React from 'react';
import PropTypes from 'prop-types';

const QuestionCommon = ({ title, addition, type, isRequired }) => {
    return (
        <>
            <h3 className="title mb-2">
                {title}
                {isRequired && <span className="required-mark">*</span>}
            </h3>
            <div className="f16-24 color-blg-100 mb-8">{addition || ''}</div>
            <div className="type">{type}</div>
        </>
    );
};

export default QuestionCommon;

QuestionCommon.propTypes = {
    title: PropTypes.string,
    addition: PropTypes.string,
    type: PropTypes.string,
    isRequired: PropTypes.bool,
};
