import React from 'react';
import PropTypes from 'prop-types';
import { dateToStr } from 'helpers/date.helper';
import RenderHTMLMail from 'pages/admin/common/page-elements/RenderHTMLMail';
import EmailType from 'enums/email_type.enum';

const MessageThreadRow = ({ message, messageThread, currentUser = null }) => {
    const user = message.user;

    const previewData = messageThread?.json_preview ? JSON.parse(messageThread.json_preview) : null;

    return (
        <div className="client-message-thread-row">
            <div className="message-thread-item">
                <div className="message-thread-item-author fw-b">
                    {currentUser != null && currentUser.id === user.id
                        ? `${user.full_name}`
                        : messageThread && messageThread.counselor
                        ? 'Team Doctors'
                        : `${user.full_name}`}
                </div>
                <div className="message-thread-item-date mb-24">
                    {dateToStr(message.created_at, 'YYYY年M月DD日(ddd) HH:mm')}
                </div>

                {!previewData ? (
                    <div className="message-thread-item-content">{message.content}</div>
                ) : (
                    <>
                        <div className="html-mail_wrapper msg-user-side">
                            {previewData.note ? (
                                <div className="preview-html-note-wrapper">
                                    <div className="preview-html-note">{previewData.note}</div>
                                </div>
                            ) : (
                                <></>
                            )}
                            <RenderHTMLMail
                                info={{ ...previewData, manual: messageThread.type === EmailType.HTML_EMAIL.value }}
                                details={previewData.details}
                                isProgram={
                                    messageThread.type !== EmailType.NEW_SESSION.value &&
                                    messageThread.type !== 'session'
                                }
                            />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default MessageThreadRow;

MessageThreadRow.propTypes = {
    message: PropTypes.any,
};
