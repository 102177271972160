import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { InfoIcon } from 'components/SvgIcon';

const ProgramBookingOption = ({ program, className = '' }) => {
    const { t } = useTranslation();

    const { allow_same_day_booking, auto_allocate_counselor } = program;
    return allow_same_day_booking || auto_allocate_counselor ? (
        <div className={`program-booking-option body2 ${className}`}>
            <InfoIcon size={16} className="color-red-100 info-icon" />
            <div>{allow_same_day_booking && t('This program allows you to make same-day reservations')}</div>
            <div>{auto_allocate_counselor && t('Counselor assignment is automatic in this program')}</div>
        </div>
    ) : (
        <></>
    );
};

export default ProgramBookingOption;

ProgramBookingOption.propTypes = {
    program: PropTypes.object,
};
