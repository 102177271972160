import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import DeleteBtnInModal from 'components/DeleteBtnInModal';

const CounselorDeleteModal = ({ visible, onClose, onUpdate, isAdminStaff }) => {
    return (
        <Modal
            title={isAdminStaff ? '管理スタッフ削除' : 'カウンセラー削除'}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <p>{`${isAdminStaff ? '管理スタッフ' : 'カウンセラー'}を削除します。この操作はやり直しできません。よろしいですか？`}</p>
            <DeleteBtnInModal onClose={onClose} onUpdate={onUpdate} />
        </Modal>
    );
};

CounselorDeleteModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    isAdminStaff: PropTypes.bool,
};

export default CounselorDeleteModal;
