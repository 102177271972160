import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Select } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import TdtSelect from 'components/TdtSelect';
import { useTranslation } from 'react-i18next';
import InputSearch from 'components/InputSearch';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';

const SearchClientModal = ({ visible, onClose, onSelect }) => {
    const { t } = useTranslation();

    const [keyword, setKeyword] = useState('');

    const [filterCompanyId, setFilterCompanyId] = useState(-1);

    const [clients, setClients] = useState([]);
    const [selectedId, setSelectedId] = useState();
    const getClients = async () => {
        const data = await api.get(APP_URLS.COUNSELOR_RESERVATIONS_SEARCH_CLIENTS);
        if (data) {
            setClients(data);
        }
    };

    useEffect(() => {
        getClients().then(() => {});
    }, []);

    const handleSelect = client => {
        setSelectedId(client.id);
        onSelect(client);
        onClose();
    };

    const renderClients = () => {
        const filteredClients = clients
            .filter(c => {
                if (keyword) {
                    const keywordLower = keyword.toLowerCase();
                    return (
                        c.first_name.toLowerCase().indexOf(keywordLower) > -1 ||
                        c.last_name.toLowerCase().indexOf(keywordLower) > -1 ||
                        c.email.toLowerCase().indexOf(keywordLower) > -1
                    );
                }
                return true;
            })
            .filter(c => {
                if (filterCompanyId !== -1) {
                    return c.employee_company[0].id === filterCompanyId;
                }
                return true;
            });

        return (
            <>
                {filteredClients.map(c => (
                    <div
                        key={c.id}
                        className={`modal-search-result-row ${selectedId === c.id && 'selected'}`}
                        onClick={() => handleSelect(c)}
                    >
                        <div className="text-small color-blg-100">{c.employee_company[0].name}</div>
                        <div className="fw-b">{`${c.full_name}`}</div>
                        <span className="text-small">{c.email}</span>
                    </div>
                ))}
            </>
        );
    };

    const getCompanies = () => {
        let tmpObj = {};
        clients.map(c => {
            const company = c.employee_company[0];
            if (!tmpObj[company.id]) {
                tmpObj[company.id] = company;
            }
        });
        return Object.values(tmpObj);
    };

    return (
        <Modal
            title={'相談者の選択'}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <div className="modal-search-filters">
                <TdtSelect
                    value={filterCompanyId}
                    size="large"
                    className="fs-14 mr-8 mb-8"
                    onChange={v => setFilterCompanyId(v)}
                >
                    <Select.Option value={-1}>{t('All companies')}</Select.Option>
                    {getCompanies().map(c => (
                        <Select.Option key={c.id} value={c.id}>
                            {c.name}
                        </Select.Option>
                    ))}
                </TdtSelect>
                <InputSearch
                    value={keyword}
                    onChange={e => setKeyword(e.target.value)}
                    onSearch={() => {}}
                    placeholder={'氏名で検索'}
                />
            </div>
            <div className="modal-search-result">{renderClients()}</div>
        </Modal>
    );
};

export default SearchClientModal;
SearchClientModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
};
