import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const HeaderQuestion = ({ no }) => {
    const { t } = useTranslation();

    return (
        <div className="header">
            <h3 className="question-title mb-0 fs-16 fw-700">{t('Question {{no}}.', { no })}</h3>
        </div>
    );
};

export default HeaderQuestion;

HeaderQuestion.propTypes = {
    no: PropTypes.number,
};
