import React from 'react';
import QuestionTypeEnum from 'enums/question_type.enum';
import { useTranslation } from 'react-i18next';
import QuestionCommon from 'components/questions/QuestionCommon';
import DailyLifeEvents from 'components/questions/input-event/DailyLifeEvents';

const DailyLifeQuestion = ({ info, isFilledIn, ...rest }) => {
    const { t } = useTranslation();

    const other = { id: rest.id, value: rest.value, onChange: rest.onChange };
    let currentDailyLifeQuestion = undefined;
    if (rest.errorsDailyLife) {
        currentDailyLifeQuestion = rest.errorsDailyLife[rest.id];
    }
    return (
        <div className="question multiple-select question-daily-life">
            <QuestionCommon
                title={info.value}
                addition={info.addition}
                type={t(`enum:${QuestionTypeEnum.DAILY_LIFE.text}`)}
                isRequired={info.isRequired || info.is_required}
            />
            <div className="mt-16">
                {currentDailyLifeQuestion !== undefined && currentDailyLifeQuestion.num !== -1 && (
                    <div className="ant-form-item-explain ant-form-item-explain-error">
                        <div role="alert">{currentDailyLifeQuestion.error}</div>
                    </div>
                )}
                <DailyLifeEvents
                    {...other}
                    currentDailyLifeQuestion={currentDailyLifeQuestion}
                    isFilledIn={isFilledIn}
                />
            </div>
        </div>
    );
};

export default DailyLifeQuestion;
