import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button } from 'antd';
import { ArrowLeftIcon, ArrowRightIcon } from 'components/SvgIcon';

const TdtCalendarControl = ({ displayType, startDate, endDate, goPrev, goNext, goToday }) => {
    const { t } = useTranslation();
    const mStartDate = moment(startDate);
    const mEndDate = moment(endDate);
    let dateStr;
    if (displayType === 'week') {
        dateStr = `${mStartDate.format('YYYY年M月D日(dd)')}〜${mEndDate.format('YYYY年M月D日(dd)')}`;
    } else if (displayType === 'month') {
        dateStr = mStartDate.format('YYYY年M月');
    } else {
        dateStr = mStartDate.format('YYYY年M月D日(dd)');
    }
    return (
        <div className="d-flex">
            <div className="d-flex mr-8">{dateStr}</div>
            <Button className="arrow-btn mr-8" icon={<ArrowLeftIcon />} onClick={() => goPrev()} />
            <Button className="today-btn fw-b" onClick={() => goToday()}>
                {t('Today')}
            </Button>
            <Button className="arrow-btn ml-8 mr-8" icon={<ArrowRightIcon />} onClick={() => goNext()} />
        </div>
    );
};

export default TdtCalendarControl;

TdtCalendarControl.propTypes = {
    startDate: PropTypes.any.isRequired,
    endDate: PropTypes.any.isRequired,
    goPrev: PropTypes.func.isRequired,
    goNext: PropTypes.func.isRequired,
    goToday: PropTypes.func.isRequired,
};
