import React from 'react';
import { useTranslation } from 'react-i18next';
import { APP_URLS } from 'constants/url.constant';
import api from 'helpers/api.helper';
import history from 'helpers/history.helper';
import BackBtn from 'components/BackBtn';
import CompanyForm from 'pages/admin/company/CompanyForm';
import { showNormal } from 'helpers/notification.helper';

const CompanyCreate = () => {
    const { t } = useTranslation();

    const createCompany = async values => {
        const response = await api.post(APP_URLS.ADMIN_COMPANIES_CREATE, values);
        if (response) {
            showNormal('', t('message:Company registration is complete'), 5);
            history.goBack();
        }
    };

    return (
        <div className="self-wrapper px-48">
            <h1 className="mb-0">
                <BackBtn label={t('Register {{entity}}', { entity: t('entity:company') })} />
            </h1>
            <CompanyForm onFinish={createCompany} />
        </div>
    );
};

export default CompanyCreate;
