import moment from 'moment';

export const getTimeFromMinutes = (minute, prefix = false) => {
    if (minute >= 24 * 60 || minute < 0) {
        throw new RangeError('Valid input should be greater than or equal to 0 and less than 1440.');
    }
    let h = (minute / 60) | 0,
        m = minute % 60 | 0;

    let time = moment.utc().hours(h).minutes(m).format('HH:mm');
    if (prefix) {
        let prefix = minute < 720 ? 'AM ' : 'PM ';
        time = prefix + time;
    }
    return time;
};

export const getMinutesFromTime = time => {
    let thisTime = moment(time, 'HH:mm');
    return thisTime.hours() * 60 + thisTime.minutes();
};

export const diffHourStartEnd = (start, end) => {
    let timeStart = moment(start, 'HH:mm');
    let timeEnd = moment(end, 'HH:mm');
    return Math.abs(timeEnd.diff(timeStart, 'minute'));
};
