import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReservationItem from 'pages/client/reservation/ReservationItem';
import { getPastReservations } from 'pages/client/reservation/past_reservation.slice';
import ReservationStatus from 'enums/reservation_status.enum';
import TdtPullable from 'components/TdtPullable';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import Loader from 'components/Loader';
import { Pagination } from 'antd';

const mapState = state => ({
    pastReservations: state.client.pastReservation.pastReservation,
    loading: state.client.pastReservation.loading,
});
const mapDispatch = { getPastReservations };
const PastReservation = ({ pastReservations, getPastReservations, loading }) => {
    const { t } = useTranslation();
    const pagination = pastReservations.pagination;
    const handlePagination = pageNum => {
        getPastReservations({
            pagination: { ...pagination, current: pageNum },
        });
    };

    useEffect(() => {
        getPastReservationList();
    }, []);

    const getPastReservationList = (
        pagination = pastReservations.pagination,
        filters = pastReservations.filters,
        sorter = pastReservations.sorter
    ) => {
        getPastReservations({
            pagination: { ...pagination, pageSize: 5 },
            filters: { ...filters },
            sorter,
        });
    };

    return (
        <>
            <BackBtnWithTitle title={t('Reservation history')} titleClassName={'past-reservation-back-title'} />

            <TdtPullable
                onRefresh={() => {
                    getPastReservationList();
                }}
            />
            {loading ? (
                <Loader className="loader-center" />
            ) : (
                <div>
                    {pastReservations.data.length > 0 && (
                        <>
                            <div className="mt-8 mb-72 reservation-item-container">
                                {pastReservations.data.map(reservation => {
                                    let badgeType;
                                    if (
                                        reservation.status === ReservationStatus.CANCELLED_TIMEOUT.value ||
                                        reservation.status === ReservationStatus.PENDING.value ||
                                        reservation.status === ReservationStatus.CANCELLED_BOOKING.value
                                    ) {
                                        badgeType = 'cancel';
                                    } else {
                                        badgeType = 'complete';
                                    }

                                    return (
                                        <ReservationItem
                                            key={reservation.id}
                                            data={reservation}
                                            showBadge={badgeType}
                                            showStart={false}
                                        />
                                    );
                                })}
                            </div>
                        </>
                    )}

                    <Pagination
                        {...pagination}
                        onChange={handlePagination}
                        hideOnSinglePage={true}
                        showSizeChanger={false}
                        className="ant-pagination mini ant-table-pagination ant-table-pagination-center mt-24"
                    />
                </div>
            )}
        </>
    );
};

export default connect(mapState, mapDispatch)(PastReservation);
