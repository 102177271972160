import React from 'react';
import PropTypes from 'prop-types';
import QuestionTypeEnum from 'enums/question_type.enum';
import { useTranslation } from 'react-i18next';
import { Input, InputNumber } from 'antd';
import QuestionCommon from 'components/questions/QuestionCommon';

const FreeInputQuestion = ({ info, ...rest }) => {
    const { t } = useTranslation();

    const other = { id: rest.id, value: rest.value, onChange: rest.onChange };

    const isParagraph = info.type === QuestionTypeEnum.FREE_INPUT_PARAGRAPH.value;
    const isNumber = info.type === QuestionTypeEnum.FREE_INPUT_NUMBER.value;

    return (
        <div className="question multiple-select">
            <QuestionCommon
                title={info.value}
                addition={info.addition}
                type={isNumber ? t(`enum:${QuestionTypeEnum.FREE_INPUT_NUMBER.text}`) : '自由記述'}
                isRequired={info.isRequired || info.is_required}
            />
            {isNumber ? (
                <>
                    <Input
                        {...other}
                        className={`mt-16 free-input-number h-40`}
                        type="text"
                        min={0}
                        style={{ width: 240 }}
                        inputmode="numeric"
                        pattern="[0-9]*"
                    />
                </>
            ) : (
                <>
                    <Input.TextArea
                        {...other}
                        className="mt-16"
                        autoSize
                        style={{ maxWidth: 480, minHeight: isParagraph ? 144 : 'unset' }}
                    />
                </>
            )}
        </div>
    );
};

export default FreeInputQuestion;

FreeInputQuestion.propTypes = {
    info: PropTypes.shape({
        value: PropTypes.string,
        isRequired: PropTypes.bool,
    }),
};
