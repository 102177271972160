import { createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'companyDetailSeminarDetail';

const seminarDetailSlice = createSlice({
    name,
    initialState: {
        seminar: null,
        loading: true,
    },
    reducers: {
        setSeminar(state, action) {
            state.seminar = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
    },
});

export const { setSeminar, setLoading } = seminarDetailSlice.actions;

export const getSeminar = id => async dispatch => {
    dispatch(setLoading(true));
    const data = await api.get(APP_URLS.ADMIN_COMPANIES_SEMINAR_DETAIL.replace(':id', id));
    if (data) {
        dispatch(setSeminar(data));
    }
    dispatch(setLoading(false));
};

export default seminarDetailSlice.reducer;
