import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCommonSession, setCommonSession } from 'pages/admin/feature/common_session_detail.slice';
import CommonSessionForm from 'pages/admin/feature/form/CommonSessionForm';

const mapState = state => ({
    commonSession: state.admin.commonSessionDetail.commonSession,
});
const mapDispatch = { getCommonSession, setCommonSession };

const CommonSessionUpdate = ({ commonSession, getCommonSession, setCommonSession }) => {
    const { id } = useParams();

    useEffect(() => {
        getCommonSession(id);

        return () => {
            setCommonSession(null);
        };
    }, [id]);

    if (!commonSession) {
        return <></>;
    }

    return <CommonSessionForm isUpdate={true} initialValues={commonSession} />;
};

export default connect(mapState, mapDispatch)(CommonSessionUpdate);
