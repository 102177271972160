import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';

const ResumeReservationModal = ({ visible, onClose, onUpdate }) => {
    const { t } = useTranslation();

    return (
        <Modal
            title={'相談の再開'}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <p>{'相談を再開します。よろしいですか？'}</p>
            <div className="delete-action-panel">
                <Button
                    type=""
                    className=" delete-button fw-b"
                    onClick={() => {
                        onUpdate && onUpdate();
                        onClose();
                    }}
                >
                    <span className={'title-button'}>{t('はい')}</span>
                </Button>
                <Button
                    type=""
                    className="cancel-button fw-b"
                    onClick={() => {
                        onClose();
                    }}
                >
                    <span className={'title-button'}>{t('Cancel')}</span>
                </Button>
            </div>
        </Modal>
    );
};

ResumeReservationModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default ResumeReservationModal;
