import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import history from 'helpers/history.helper';
import FloatBar from 'components/FloatBar';
import { Button, Input } from 'antd';
import { AddIcon, DeleteIcon, DotsHorizontalRoundedIcon, DuplicateIcon } from 'components/SvgIcon';
import TdtButton from 'components/TdtButton';
import DataTable from 'components/DataTable';
import CustomDropdown from 'components/CustomDropdown';
import Routes from 'routes';
import ProvideCompanyEmployeeModal from 'pages/admin/email/distribution/modal/company-employee/ProvideCompanyEmployeeModal';
import SelectCSVModal from 'pages/admin/email/distribution/modal/csv/SelectCSVModal';
import { connect } from 'react-redux';
import {
    addByEmailInput,
    checkDataSelector,
    convertDataToState,
    removeById,
    reset,
    setName,
    addByEmailList,
} from 'pages/admin/email/distribution/distribution_create.slice';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import DeleteDistributionListModal from 'pages/admin/email/distribution/modal/DeleteDistributionListModal';
import CloneDistributionListModal from 'pages/admin/email/distribution/modal/CloneDistributionListModal';
import { showNormal } from 'helpers/notification.helper';
import { csvDataToListEmail, isValidEmail } from 'helpers/email.helper';
import CorrectCSVUploadModal from 'pages/admin/email/distribution/modal/csv/CorrectCSVUploadModal';
import ErrorCSVUploadModal from 'pages/admin/email/distribution/modal/csv/ErrorCSVUploadModal';

const mapState = state => ({
    data: state.admin.distributionCreate.data,
    name: state.admin.distributionCreate.name,
    checkData: checkDataSelector(state.admin.distributionCreate),
    companyEmployeesData: state.admin.masterData.companyEmployeesData,
});
const mapDispatch = { addByEmailInput, removeById, setName, reset, convertDataToState, addByEmailList };

const DistributionForm = ({
    initialValues = {},
    isUpdate = false,
    data,
    addByEmailInput,
    removeById,
    checkData,
    name,
    setName,
    reset,
    convertDataToState,
    addByEmailList,
    companyEmployeesData,
}) => {
    const { t } = useTranslation();

    const [emailInput, setEmailInput] = useState('');
    const [modalVisible, setModalVisible] = useState({
        clone: false,
        delete: false,
        record: null,
        provide: false,
        selectCsv: false,
        correctCsv: false,
        errorCsv: false,
        csvData: {},
    });

    const closeModal = () =>
        setModalVisible({
            clone: false,
            delete: false,
            record: null,
            provide: false,
            selectCsv: false,
            correctCsv: false,
            errorCsv: false,
            csvData: {},
        });
    const showProvideCompanyStaffModal = r => setModalVisible({ ...modalVisible, provide: true, record: r });
    const showDeleteModal = r => setModalVisible({ ...modalVisible, delete: true, record: r });
    const showCloneModal = r => setModalVisible({ ...modalVisible, clone: true, record: r });
    const showSelectCSVModal = r => setModalVisible({ ...modalVisible, selectCsv: true, record: r });
    const showCorrectCsvModal = data =>
        setModalVisible({ ...modalVisible, correctCsv: true, selectCsv: false, csvData: data });
    const showErrorCsvModal = data =>
        setModalVisible({ ...modalVisible, errorCsv: true, selectCsv: false, csvData: data });

    const deleteDistributionList = async distributionListId => {
        const response = await api.delete(APP_URLS.ADMIN_EMAIL_DISTRIBUTIONS_DELETE.replace(':id', distributionListId));
        if (response) {
            history.push(Routes.private.ADMIN_EMAIL_DISTRIBUTION.path);
            showNormal('', t('message:Data has been deleted'), 3);
        }
    };

    useEffect(() => {
        if (isUpdate) {
            convertDataToState(initialValues);
        }

        return () => {
            reset();
        };
    }, []);

    const columns = [
        {
            title: 'メールアドレス',
            dataIndex: 'email',
            width: 292,
            sorter: (a, b) => {
                return a.email.localeCompare(b.email);
            },
        },
        {
            title: t('Company name'),
            dataIndex: 'company_name',
            width: 180,
            render: name => (name ? name : '-'),
            sorter: (a, b) => {
                const a1 = a.company_name ? a.company_name : '-';
                const b1 = b.company_name ? b.company_name : '-';
                return a1.localeCompare(b1);
            },
        },
        {
            title: '氏名',
            dataIndex: 'full_name',
            width: 160,
            render: full_name => (full_name ? full_name : '-'),
            sorter: (a, b) => {
                const a1 = a.full_name ? a.full_name : '-';
                const b1 = b.full_name ? b.full_name : '-';
                return a1.localeCompare(b1);
            },
        },
        {
            title: '',
            dataIndex: 'DELETE',
            width: 40,
            render: (_, r) => {
                return (
                    <TdtButton
                        buttonStyle="plain"
                        icon={<DeleteIcon className="color-red-100" />}
                        onClick={() => removeById(r.id)}
                    />
                );
            },
        },
    ];

    const submit = async () => {
        // process email list
        let tmp = {};
        for (const d of data) {
            !tmp[d.email] && (tmp[d.email] = d);
        }

        const params = {
            name: name,
            target_list: Object.values(tmp),
        };
        const res = isUpdate
            ? await api.put(APP_URLS.ADMIN_EMAIL_DISTRIBUTIONS_DETAIL.replace(':id', initialValues.id), params)
            : await api.post(APP_URLS.ADMIN_EMAIL_DISTRIBUTIONS, params);
        if (res) {
            history.push(Routes.private.ADMIN_EMAIL_DISTRIBUTION.path);
        }
    };

    const callbackCsvData = csvData => {
        const { data } = csvData;
        const { error, emails, hasError } = csvDataToListEmail(data);
        if (hasError) {
            showErrorCsvModal({ error, emails });
        } else {
            showCorrectCsvModal({ emails });
        }
    };

    const addEmailsFromCsvModal = emails => {
        addByEmailList({ emails, companyEmployeesData });
    };

    return (
        <div className="self-wrapper px-0 pb-80 px-48">
            <div className="d-flex justify-content-space-between align-items-center">
                <BackBtnWithTitle
                    title={isUpdate ? '配信リスト編集' : '配信リスト作成'}
                    onClick={() => history.push(Routes.private.ADMIN_EMAIL_DISTRIBUTION.path)}
                />
                <div>
                    {isUpdate ? (
                        <CustomDropdown
                            className=" ml-16 mt-30"
                            onClick={e => {
                                e.stopPropagation();
                            }}
                            trigger={['click']}
                            options={[
                                {
                                    key: 'duplicate',
                                    text: (
                                        <>
                                            <DuplicateIcon /> <span>{'複製'}</span>
                                        </>
                                    ),
                                    className: 'primary-color fw-b',
                                },
                                {
                                    key: 'delete',
                                    text: (
                                        <>
                                            <DeleteIcon /> <span>{'削除'}</span>
                                        </>
                                    ),
                                    className: 'pink-color fw-b',
                                },
                            ]}
                            onSelect={opt => {
                                opt.domEvent.stopPropagation();
                                if (opt.key === 'delete') {
                                    showDeleteModal(initialValues);
                                } else {
                                    showCloneModal(initialValues);
                                }
                            }}
                            separator
                        >
                            <DotsHorizontalRoundedIcon className=" primary-color c-pointer" />
                        </CustomDropdown>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div className="mt-40">
                <div className="fw-700 fs-16 color-blg-100">
                    <label>{t('field:List name')}</label>
                    <div className="mt-8">
                        <Input
                            className="w-100p bg-white"
                            style={{ maxWidth: 320, height: 40 }}
                            value={name}
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                </div>
                <div className="fw-700 fs-16 mt-24 color-blg-100">
                    <label>{t('field:Target audience')}</label>
                    <div className="mt-8 d-flex justify-content-space-between align-items-center">
                        <div className="d-flex">
                            <Input
                                className="w-100p bg-white"
                                style={{ minWidth: 320, height: 40 }}
                                placeholder="メールアドレスで追加"
                                value={emailInput}
                                onChange={e => setEmailInput(e.target.value)}
                            />
                            <TdtButton
                                buttonStyle="outline"
                                buttonSize="big"
                                className="bg-blue-purple-100 btn-radius-4 fw-b ml-8 "
                                onClick={() => {
                                    if (isValidEmail(emailInput)) {
                                        !!emailInput && addByEmailInput({ email: emailInput, companyEmployeesData });
                                        setEmailInput('');
                                    }
                                }}
                                style={{ width: 56, height: 39 }}
                            >
                                <span className={`fw-400 fs-16 white-space-nowrap color-white `}>{'追加'}</span>
                            </TdtButton>
                        </div>
                        <CustomDropdown
                            className={'distribution-dropdown '}
                            ovlClassName={'menu-less-py'}
                            trigger={['click']}
                            options={[
                                {
                                    key: 'CompanyOrEmployee_collective_registration',
                                    text: (
                                        <>
                                            <span>{'会社・社員一括登録'}</span>
                                        </>
                                    ),
                                },
                                {
                                    key: 'CSV-upload',
                                    text: (
                                        <>
                                            <span>{'CSVアップロード'}</span>
                                        </>
                                    ),
                                },
                            ]}
                            onSelect={opt => {
                                opt.domEvent.stopPropagation();
                                if (opt.key === 'CompanyOrEmployee_collective_registration') {
                                    showProvideCompanyStaffModal();
                                } else {
                                    showSelectCSVModal();
                                }
                            }}
                            separator
                        >
                            <Button className="fw-700" icon={<AddIcon />}>
                                {'一括登録'}
                            </Button>
                        </CustomDropdown>
                    </div>
                    <div className="mt-14">
                        <DataTable
                            className="mt-16 fw-400 collapse-table no-clickable-row-table"
                            columns={columns}
                            data={data}
                            noDataText={t('Audience is not registered')}
                            onTableChange={() => {}}
                        />
                    </div>
                </div>
            </div>
            <ProvideCompanyEmployeeModal
                onClose={() => closeModal()}
                visible={modalVisible.provide}
                record={modalVisible.record}
                onUpdate={emails => {
                    addByEmailList({ emails, companyEmployeesData });
                }}
            />
            <SelectCSVModal
                onClose={() => closeModal()}
                visible={modalVisible.selectCsv}
                callbackData={callbackCsvData}
            />
            <CorrectCSVUploadModal
                onClose={() => closeModal()}
                visible={modalVisible.correctCsv}
                csvData={modalVisible.csvData}
                onUpdate={addEmailsFromCsvModal}
            />
            <ErrorCSVUploadModal
                onClose={() => closeModal()}
                visible={modalVisible.errorCsv}
                csvData={modalVisible.csvData}
                onUpdate={addEmailsFromCsvModal}
            />
            <DeleteDistributionListModal
                onClose={() => closeModal()}
                onUpdate={() => deleteDistributionList(modalVisible.record.id)}
                visible={modalVisible.delete}
                record={modalVisible.record}
            />
            <CloneDistributionListModal
                onClose={() => closeModal()}
                visible={modalVisible.clone}
                record={modalVisible.record}
            />
            <FloatBar className="justify-content-space-between">
                <div>
                    <Button
                        className="ml-16 fw-b"
                        type="primary"
                        disabled={checkData.numberOfRegistrants < 1 || !name}
                        onClick={() => submit()}
                    >
                        {t('Save')}
                    </Button>
                </div>
                <div className="mr-16 ml-12">
                    <div>
                        <span>{'配信対象者数：'}</span>
                        <span className="fw-700 fs-20">{checkData.targetAudience}</span>
                        <span className="ml-4">{'人'}</span>
                    </div>
                    {/*<div className=" text-gray-color fw-400 fs-14 d-flex align-items-center">*/}
                    {/*    <div>*/}
                    {/*        <span>{'登録者数：'}</span>*/}
                    {/*        <span>{checkData.numberOfRegistrants}</span>*/}
                    {/*        <span className="ml-4">{'人'}</span>*/}
                    {/*    </div>*/}
                    {/*    <div className="ml-16">*/}
                    {/*        <span>{'重複しているメールアドレス：'}</span>*/}
                    {/*        <span>{checkData.duplicateEmailAddress}</span>*/}
                    {/*        <span className="ml-4">{'人'}</span>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </FloatBar>
        </div>
    );
};

export default connect(mapState, mapDispatch)(DistributionForm);
