import React from 'react';
import RenderHTMLMail from 'pages/admin/common/page-elements/RenderHTMLMail';
import EmailType from 'enums/email_type.enum';

const DeliveryContentContainer = ({ emailHtml }) => {

    const { additional_notes: note, type, email_html_details: details } = emailHtml;

    const getInfo = () => {
        if (emailHtml.type === EmailType.NEW_SESSION.value) {
            return emailHtml.session;
        } else if (emailHtml.type === EmailType.NEW_PROGRAM.value) {
            return emailHtml.program;
        }
        return { ...emailHtml, name: emailHtml.title, manual: true };
    }

    const getDetails = () => {
        if (emailHtml.type === EmailType.NEW_SESSION.value) {
            return emailHtml.session && emailHtml.session.seminar_details;
        } else if (emailHtml.type === EmailType.NEW_PROGRAM.value) {
            return emailHtml.program && emailHtml.program.program_details;
        }
        return details;
    }

    return (
        <div className="html-mail_wrapper pt-24">
            {note ? (
                <div className="preview-html-note-wrapper">
                    <div className="preview-html-note">{note}</div>
                </div>
            ) : (
                <></>
            )}
            <RenderHTMLMail info={getInfo()} isProgram={type !== EmailType.NEW_SESSION.value} details={getDetails()} />
        </div>
    );
};

export default DeliveryContentContainer;
