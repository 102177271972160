import React from 'react';
import PropTypes from 'prop-types';
import { Button, Col, Modal, Row } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import DeleteBtnInModal from 'components/DeleteBtnInModal';
import FloatBar from 'components/FloatBar';

const ProgramCantDeleteModal = ({ visible, onClose, onUpdate }) => {
    return (
        <Modal
            title={<p className="pink-color">このプログラムは削除できません</p>}
            visible={visible}
            width={320}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <p>このプログラムが適用されている企業があるため、削除できません。</p>
            <Row className="" justify="end">
                <Col>
                    <Button
                        htmlType="submit"
                        className="cancel-button fw-b"
                        onClick={() => {
                            onUpdate();
                        }}
                    >
                        {'OK'}
                    </Button>
                </Col>
            </Row>
        </Modal>
    );
};

ProgramCantDeleteModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default ProgramCantDeleteModal;
