import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { useTranslation } from 'react-i18next';
import DropCsvFileInput from 'pages/admin/email/distribution/modal/csv/DropCSVFileInput';

const SelectCsvModal = ({ visible, onClose, callbackData }) => {
    const { t } = useTranslation();

    return (
        <>
            <Modal
                title={t('field:Target registration')}
                visible={visible}
                width={640}
                footer={null}
                closeIcon={<CloseIcon />}
                onCancel={() => onClose()}
            >
                <DropCsvFileInput callbackData={callbackData} />
            </Modal>
        </>
    );
};

SelectCsvModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default SelectCsvModal;
