import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { Button } from 'antd';

const TdtButtonSubmit = ({ children, onClick, htmlType, ...rest }) => {
    const [loading, setLoading] = useState(false);

    const delay = async ms => new Promise(res => setTimeout(res, ms));

    const modOnClick = async () => {
        setLoading(true);

        // if (htmlType === 'submit') {
        //     await delay(1000);
        // } else {
        //     onClick && (await onClick());
        // }
        onClick && (await onClick());
        if (htmlType === 'submit') {
            await delay(1000 * 2);
        }

        setLoading(false);
    };

    // fix memory warning
    useEffect(() => {
        return () => {
            setLoading(false);
        };
    }, []);

    return (
        <Button {...rest} loading={loading} onClick={modOnClick} htmlType={htmlType}>
            {children}
        </Button>
    );
};

export default TdtButtonSubmit;

TdtButtonSubmit.propTypes = {
    children: PropType.any,
    onClick: PropType.func,
    htmlType: PropType.string,
};
