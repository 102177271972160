import { createSlice } from '@reduxjs/toolkit';

const coreSlice = createSlice({
    name: 'core',
    initialState: {
        loading: true,
        sidebarActive: null,
        backUrl: null,
        currentConsultant: null,
    },
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setSidebarActive(state, action) {
            state.sidebarActive = action.payload;
        },
        setBackUrl(state, action) {
            state.backUrl = action.payload;
        },
        setCurrentConsultant(state, action) {
            state.currentConsultant = action.payload;
        },
    },
});

export const { setLoading, setSidebarActive, setBackUrl, setCurrentConsultant } = coreSlice.actions;
export default coreSlice.reducer;
