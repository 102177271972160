import React, { useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import DragList from 'components/dnd/DragList';

const DragAndDropGroup = ({ data, order, groupOrder, headerRenderer, onOrderChange, rowComponent }) => {
    const [placeholderProps, setPlaceholderProps] = useState({});

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        setPlaceholderProps({});

        onOrderChange(
            result.source.index,
            result.destination.index,
            result.source.droppableId,
            result.destination.droppableId
        );
    };

    const onDragUpdate = update => {
        if (!update.destination) {
            return;
        }
        const draggableId = update.draggableId;
        const initialIndex = update.source.index;
        const destinationIndex = update.destination.index;

        const queryAttr = 'data-rbd-drag-handle-draggable-id';
        const domQuery = `[${queryAttr}='${draggableId}']`;
        const draggedDOM = document.querySelector(domQuery);

        if (!draggedDOM) {
            return;
        }
        const { clientHeight, clientWidth } = draggedDOM;

        const arr = [...draggedDOM.parentNode.parentNode.children];
        if (initialIndex < destinationIndex) {
            arr.splice(initialIndex, 1);
        }

        const clientY =
            parseFloat(window.getComputedStyle(draggedDOM.parentNode.parentNode).paddingTop) +
            arr.slice(0, destinationIndex).reduce((total, curr) => {
                const style = curr.currentStyle || window.getComputedStyle(curr);
                const marginBottom = parseFloat(style.marginBottom);
                return total + curr.clientHeight + marginBottom;
            }, 0);

        setPlaceholderProps({
            clientHeight,
            clientWidth,
            clientY,
        });
    };

    return (
        <DragDropContext onDragEnd={onDragEnd} onDragUpdate={onDragUpdate}>
            {groupOrder.map(groupId => {
                let newData = {};
                for (const id of order[groupId]) {
                    newData[id] = data[id];
                }

                return (
                    <div key={groupId} className="drop-group">
                        {headerRenderer(groupId)}
                        <Droppable droppableId={groupId} className="droppable-placeholder">
                            {(provided, snapshot) => (
                                <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    style={{ position: 'relative' }}
                                >
                                    <DragList
                                        groupId={groupId}
                                        data={newData}
                                        order={order[groupId]}
                                        rowComponent={rowComponent}
                                    />
                                    {provided.placeholder}
                                    {placeholderProps.clientHeight && snapshot.isDraggingOver && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: placeholderProps.clientY,
                                                left: 0,
                                                height: '2px',
                                                background: '#7046F1',
                                                width: '100%',
                                            }}
                                        />
                                    )}
                                </div>
                            )}
                        </Droppable>
                    </div>
                );
            })}
        </DragDropContext>
    );
};

export default DragAndDropGroup;
