import React from 'react';
import { Form, Input, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import MessageByScoreTable from 'pages/admin/interview_sheet/create_screen/tabs/mark/message_score/MessageByScoreTable';
import { setInfoDescription, setInfoTitle } from 'pages/admin/interview_sheet/interview_sheet_create.slice';

const mapState = state => ({
    infoTitle: state.admin.interviewSheetCreate.infoTitle,
    infoDescription: state.admin.interviewSheetCreate.infoDescription,
});
const mapDispatch = { setInfoTitle, setInfoDescription };
const MarkContainer = ({ infoTitle, infoDescription, setInfoTitle, setInfoDescription, setIsValueChange }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    return (
        <Form
            layout="vertical"
            form={form}
            className="bg-gray-5 flex-1"
            component="div"
            onChange={() => setIsValueChange(true)}
        >
            <div className="session-edit-wrapper session-detail-cards pt-24 border-top-none">
                <div className="session-detail-card">
                    <div className="session-detail-card--header">
                        <Typography className="fw-700 fs-16 color-blg-100">{t('回答送信後メッセージ')}</Typography>
                    </div>
                    <div className="session-detail-card--body  ">
                        <div className="fw-400 fs-14 text-gray-color">
                            {t('回答後に表示される画面を設定することができます')}
                        </div>
                        <div className="fw-700 fs-16 color-blg-100 mt-8">
                            <label>{t('field:Title')}</label>
                            <div className="mt-8">
                                <Input
                                    className="bg-white f-16-24 interview-sheet-input-create "
                                    value={infoTitle || 'ご回答ありがとうございました！'}
                                    onChange={e => setInfoTitle(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="fw-700 fs-16 color-blg-100 mt-16 ">
                            <label>{t('field:説明テキスト')}</label>
                            <div className="mt-8">
                                <Input
                                    className="bg-white interview-sheet-input-create "
                                    value={infoDescription || '回答を記録しました。'}
                                    onChange={e => setInfoDescription(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="session-detail-card ">
                    <div className="session-detail-card--header">
                        <Typography className="fs-16 fw-700">{t('スコア別表示メッセージ')}</Typography>
                    </div>
                    <div className="session-detail-card--body">
                        <Typography className="fs-14 fw-400 text-gray-color">
                            {t('アンケートの集計結果や、ユーザーが見る結果画面でのスコアごとの結果を設定できます。')}
                        </Typography>
                        <div className="mt-8">
                            <MessageByScoreTable />
                        </div>
                    </div>
                </div>
            </div>
        </Form>
    );
};

export default connect(mapState, mapDispatch)(MarkContainer);
