import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Tooltip } from 'antd';
import { ArrowBackIcon } from 'components/SvgIcon';
import history from 'helpers/history.helper';

const BackBtn = ({ label, action, className = '', textClassName = 'custom-text', noBackText }) => {
    const { t } = useTranslation();
    return (
        <>
            <div>
                <Tooltip placement="right" title="前に戻る" color="#666666">
                    <Button
                        type="link"
                        className={`back-btn with-title ${className}`}
                        icon={<ArrowBackIcon />}
                        onClick={() => (action ? action() : history.goBack())}
                    />
                </Tooltip>
                {!noBackText && <span className={`back-text ${textClassName}`}>{label || t('Go back')}</span>}
            </div>
        </>
    );
};

export default BackBtn;

BackBtn.propTypes = {
    label: PropTypes.string,
    action: PropTypes.func,
    className: PropTypes.string,
    textClassName: PropTypes.string,
};
