import api, { createQuery } from 'helpers/api.helper';
import moment from 'moment';

export const baseInitialState = {
    loading: false,
    fetched: false,
    data: [],
    filters: {},
    sorter: {
        order: 'descend',
        field: 'id',
    },
    pagination: {
        current: 1,
        pageSize: 10,
        total: 1,
    },
    hideColumns: [],
    selection: [],
};

export const baseReducers = {
    setLoading(state, action) {
        state.loading = action.payload;
    },
    setData(state, action) {
        state.data = action.payload;
        state.fetched = true;
    },
    setPagination(state, action) {
        state.pagination = action.payload;
    },
    setFilters(state, action) {
        state.filters = action.payload;
    },
    setSorter(state, action) {
        state.sorter = action.payload;
    },
    setHideColumns(state, action) {
        state.hideColumns = action.payload;
    },
    setSelection(state, action) {
        state.selection = action.payload;
    },
    resetData(state) {
        state.data = [];
        state.pagination = baseInitialState.pagination;
        state.sorter = baseInitialState.sorter;
    },
};

export const baseGetData = async ({ url, pagination, filters, sorter }) => {
    const data = await api.get(url, createQuery(pagination, filters, sorter), {}, true);
    const { data: respData, current_page: current, per_page: pageSize, total } = data;
    return { data: respData, pagination: { current, pageSize, total } };
};

export const baseExtraReducers = asyncThunk => ({
    [asyncThunk.pending]: state => {
        state.loading = true;
    },
    [asyncThunk.fulfilled]: (state, action) => {
        state.fetched = true;
        state.data = action.payload.data;
        state.pagination = action.payload.pagination;
        state.loading = false;
    },
    [asyncThunk.rejected]: state => {
        state.loading = false;
    },
});

export const baseChartInitState = {
    startDate: moment().subtract(6, 'days'),
    endDate: moment().endOf('day'),
    displayType: 'week',
    monthFilter: moment().endOf('month'),
};

export const baseChartReducer = {
    setStartDate(state, action) {
        state.startDate = action.payload;
    },
    setEndDate(state, action) {
        state.endDate = action.payload;
    },
    goPrev(state) {
        const startDate =
            state.displayType === 'year'
                ? state.startDate.clone()
                : state.startDate.clone().add('-1', state.displayType);
        state.startDate = startDate;
        if (state.displayType === 'month') {
            state.endDate = startDate.clone().endOf('month');
        } else if (state.displayType === 'year') {
            state.endDate = startDate.clone().subtract(1, 'month').endOf('month');
            state.startDate = state.endDate.clone().startOf('month').subtract(1, 'year').add(1, 'month');
        } else {
            state.endDate = state.endDate.clone().add('-1', state.displayType);
        }
    },
    goNext(state) {
        const startDate = state.startDate.clone().add('1', state.displayType);
        state.startDate = startDate;
        if (state.displayType === 'month') {
            state.endDate = startDate.clone().endOf('month');
        } else if (state.displayType === 'year') {
            state.startDate = state.endDate.clone().add(1, 'month').startOf('month');
            state.endDate = startDate.clone().endOf('month').add(1, 'year').subtract(1, 'month');
        } else {
            state.endDate = state.endDate.clone().add('1', state.displayType);
        }
    },
    goToday(state) {
        const now = moment();
        if (state.displayType === 'week') {
            state.startDate = now.clone().subtract(6, 'days');
            state.endDate = now.clone();
        } else if (state.displayType === 'year') {
            state.startDate = now.clone().startOf('month').subtract(1, 'years').add(1, 'month');
            state.endDate = now.clone().endOf('month');
        } else {
            state.startDate = now.clone().startOf(state.displayType);
            state.endDate = now.clone().endOf(state.displayType);
        }
    },
    setDisplayType(state, action) {
        if (state.displayType !== action.payload) {
            state.displayType = action.payload;
            const now = moment();
            if (action.payload === 'week') {
                state.startDate = now.clone().subtract(6, 'days');
                state.endDate = now.clone();
            } else if (action.payload === 'year') {
                state.startDate = now.clone().startOf('month').subtract(1, 'years').add(1, 'month');
                state.endDate = now.clone().endOf('month');
            } else {
                state.startDate = now.clone().startOf(action.payload);
                state.endDate = now.clone().endOf(action.payload);
            }
        }
    },
    setMonthFilter(state, action) {
        state.monthFilter = action.payload;
    },
};
