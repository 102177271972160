import React from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from 'components/SvgIcon';
import { Modal } from 'antd';
import ActionBtnInModal from 'components/ActionBtnInModal';
import { dateToStr } from 'helpers/date.helper';

const DeleteScheduledModal = ({ visible, onClose, onUpdate, record }) => {
    const { t } = useTranslation();

    if (!record) {
        return <></>;
    }

    return (
        <Modal
            title={t('Delete the scheduled automatic transmission date and send')}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <div className="mb-16 f16-24">
                {'自動送信予定日を削除して、手動でお知らせを送信します。よろしいですか？'}
            </div>
            <div className="fw-b f-14-18 mb-8">{'設定されている自動送信予定日'}</div>
            <div className="mb-16 pt-12 pb-12 pl-16 pr-16 bg-gray-5" style={{ borderRadius: 4 }}>
                {record.transmission_date && record.transmission_time
                    ? `${dateToStr(record.transmission_date, 'YYYY/M/D')} ${record.transmission_time}`
                    : ''}
            </div>
            <ActionBtnInModal
                onClose={onClose}
                onUpdate={onUpdate}
                confirmText={t('Delete and send')}
                isNotDelete={true}
                //manualClose={true}
            />
        </Modal>
    );
};

export default DeleteScheduledModal;
