import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Row, Select, Col, Checkbox } from 'antd';
import { updateDetail, removeDetail } from 'pages/admin/counselor/company_program/reservable_frame_programs.slice';
import { rdStr } from 'helpers/string.helper';
import TdtButton from 'components/TdtButton';
import { DeleteIcon } from 'components/SvgIcon';
import TdtSelect from 'components/TdtSelect';
import DayOfWeekEnum from 'enums/day_of_week.enum';
import TdtSelectReservationFrameTime from 'components/TdtSelectReservationFrameTime';
import { getTimeFromMinutes } from 'helpers/moment.helper';
import { isNullOrUndefined } from 'helpers/common.helper';

const mapState = (state, props) => ({
    value: state.admin.reservableFramePrograms.details[props.id],
});
const mapDispatch = { updateDetail, removeDetail };

const ReservationFrame = React.memo(({ id, value, updateDetail, removeDetail, sessionTime }) => {
    const [form] = Form.useForm();
    const [maxSession, setMaxSession] = useState(0);
    const [startTime, setStartTime] = useState(15);
    useEffect(() => {
        calculateMaxSession(0);
        form.setFieldsValue(value);
        if (isNullOrUndefined(value.id)) {
            form.setFieldsValue({ hour_start: getTimeFromMinutes(0) });
            form.setFieldsValue({ hour_end: getTimeFromMinutes(sessionTime) });
        }
    }, []);

    let timeout = null;
    const changeHandle = () => {
        if (timeout !== null) {
            clearTimeout(timeout);
        }
        timeout = setTimeout(() => {
            syncFormData();
        }, 300);
    };

    const syncFormData = () => {
        updateDetail({
            id,
            value: Object.assign({}, form.getFieldsValue()),
        });
    };

    const calculateMaxSession = startTime => {
        let maxSession = 0;
        let maxSessionMinute = 0;
        while (maxSessionMinute < 1440) {
            maxSession++;
            maxSessionMinute = startTime + sessionTime * maxSession;
        }
        setStartTime(startTime);
        setMaxSession(maxSession - 1);
    };
    return (
        <Form
            form={form}
            onValuesChange={changeHandle}
            name={rdStr()}
            className="reservation-frame-detail"
            component="div"
        >
            <Row align="middle">
                <Col style={{ flex: `0 0 180px` }} className="mr-24">
                    <Form.Item name="day" className="mb-0">
                        <TdtSelect style={{ width: 180 }} size="large" className="fs-14 select-gray">
                            {Object.values(DayOfWeekEnum).map(o => (
                                <Select.Option key={o.value} value={o.value}>
                                    {o.text}
                                </Select.Option>
                            ))}
                        </TdtSelect>
                    </Form.Item>
                </Col>
                <Col className="mr-24">
                    <Row align="middle">
                        <Col>
                            <Form.Item name="hour_start" className="mb-0 mr-8">
                                <TdtSelectReservationFrameTime
                                    onChange={indexMinute => {
                                        let startTime = indexMinute * 30;
                                        calculateMaxSession(startTime);
                                        form.setFieldsValue({ hour_start: getTimeFromMinutes(startTime) });
                                        form.setFieldsValue({ hour_end: getTimeFromMinutes(startTime + sessionTime) });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        {'〜'}
                        <Col>
                            <Form.Item name="hour_end" className="mb-0 ml-8">
                                <TdtSelectReservationFrameTime
                                    isEnd
                                    sessionTime={sessionTime}
                                    maxSession={maxSession}
                                    startTime={startTime}
                                    onChange={indexMinute => {
                                        let endTime = startTime + sessionTime * (indexMinute + 1);
                                        form.setFieldsValue({ hour_end: getTimeFromMinutes(endTime) });
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
                <Col style={{ flex: `0 0 40px` }}>
                    <TdtButton
                        buttonStyle="outline"
                        buttonSize="big"
                        className="pink-color btn-radius-4"
                        onClick={() => removeDetail({ id })}
                    >
                        <DeleteIcon />
                    </TdtButton>
                </Col>
            </Row>
        </Form>
    );
});

export default connect(mapState, mapDispatch)(ReservationFrame);

ReservationFrame.propTypes = {
    id: PropTypes.any,
    value: PropTypes.object,
    updateDetail: PropTypes.func,
    removeDetail: PropTypes.func,
    sessionTime: PropTypes.number,
};
