import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'antd';
import DataTable from 'components/DataTable';
import { AddIcon } from 'components/SvgIcon';
import BackBtn from 'components/BackBtn';
import FloatBar from 'components/FloatBar';
import { getData, setFilters, setSorter, resetData } from 'pages/admin/company/tabs/seminar/seminar.slice';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { useTranslation } from 'react-i18next';
import { dateWithForwardSlash } from 'helpers/date.helper';
import CompanyDetailRoutes from 'pages/admin/company/company_detail_routes';
import { getEnumText } from 'helpers/enum.helper';
import SeminarStateEnum from 'enums/seminar_state.enum';

const mapState = state => ({
    state: state.admin.companyDetailSeminar,
});
const mapDispatch = { getData, setSorter, setFilters, resetData };

const SeminarContainer = ({ company, renderTabList, baseUrl, state, getData, setSorter, resetData }) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            width: 288,
            render: (_, r) => <span className={'word-break-all'}>{r.name ? `${r.name}` : t('Not set')}</span>,
            sorter: true,
        },
        {
            title: '開催日時',
            dataIndex: 'date',
            width: 140,
            sorter: true,
            render: (_, r) => (
                <>
                    {r.date && r.start_time ? (
                        <div>
                            <span>{dateWithForwardSlash(r.date)}</span>
                            &nbsp;
                            <span>{r.start_time}</span>
                            <span>{'-'}</span>
                            <span>{r.end_time}</span>
                        </div>
                    ) : (
                        t('Not set')
                    )}
                </>
            ),
        },
        {
            title: t('Public status'),
            dataIndex: 'status',
            width: 96,
            render: (_, r) => <span>{t(`enum:${getEnumText(SeminarStateEnum, r.status)}`)}</span>,
        },
        {
            title: t('Implementation'),
            dataIndex: 'implementation',
            width: 246,
            render: (_, r) => {
                const heldThisWeek = r.held_this_week;
                const isExpired = r.is_expired;
                return (
                    <>
                        {!(r.status === 'private') && (
                            <span>
                                {isExpired
                                    ? t('End')
                                    : heldThisWeek
                                    ? t('Before the event')
                                    : !r.name || !r.date
                                    ? ''
                                    : t('Before the event')}
                            </span>
                        )}
                    </>
                );
            },
        },
    ];

    const getSeminars = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        if (!sorter || !sorter.field) {
            sorter = state.sorter;
        }
        setSorter(sorter);
        getData({ pagination, filters: { ...filters, company_id: company.id }, sorter });
    };

    useEffect(() => {
        getSeminars();
        return () => resetData();
    }, [company.id]);

    return (
        <>
            <BackBtn label={company.name} action={() => history.push(Routes.private.ADMIN_COMPANY.path)} />

            {renderTabList('seminar')}

            <div className="p-0">
                <div className="mt-16">
                    <DataTable
                        className="collapse-table"
                        columns={columns}
                        hideColumns={state.hideColumns}
                        data={state.data}
                        loading={state.loading}
                        pagination={state.pagination}
                        filters={state.filters}
                        sorter={state.sorter}
                        onTableChange={(pagination, filter, sorter) => {
                            getSeminars(pagination, state.filters, sorter);
                        }}
                        onRow={record => {
                            return {
                                onClick: () =>
                                    history.push(
                                        `${baseUrl}${CompanyDetailRoutes.SEMINAR_DETAIL.path.replace(
                                            ':seminarId',
                                            record.id
                                        )}`
                                    ),
                            };
                        }}
                        noDataText={'登録されているセッションはいません'}
                    />

                    <FloatBar>
                        <Button
                            icon={<AddIcon />}
                            className="fw-b"
                            onClick={() => history.push(`${baseUrl}${CompanyDetailRoutes.SEMINAR_CREATE.path}`)}
                        >
                            {t('Seminar creation')}
                        </Button>
                    </FloatBar>
                </div>
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(SeminarContainer);

SeminarContainer.propTypes = {
    company: PropTypes.any,
    renderTabList: PropTypes.func,
    state: PropTypes.any,
    getData: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
};
