import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper';
import { APP_URLS } from 'constants/url.constant';
import moment from 'moment';
import api from 'helpers/api.helper';

const name = 'log';

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.ADMIN_LOG, ...props })
);

export const defaultFilters = {
    'created_at:gte': moment().add('-1', 'week'),
    'created_at:lte': moment().add('+1', 'day'),
};

const logSlice = createSlice({
    name,
    initialState: {
        ...baseInitialState,
        filters: defaultFilters,
        sorter: {
            field: 'id',
            order: 'descend',
        },
        pagination: {
            current: 1,
            pageSize: 50,
            total: 1,
        },
    },
    reducers: baseReducers,
    extraReducers: baseExtraReducers(getData),
});

export const { setLoading, setData, setPagination, setFilters, setSorter, setHideColumns, setSelection } =
    logSlice.actions;

export default logSlice.reducer;

export const callLogApi = (type, id = null) => {
    api.post(APP_URLS.ADMIN_LOG, { type, id }).then(() => {});
}