import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Select } from 'antd';
import Routes from 'routes';
import { BaseStatePropShape, UserPropTypes } from 'constants/proptypes.constant';
import RoleEnum from 'enums/role.enum';
import { getEnumText } from 'helpers/enum.helper';
import history from 'helpers/history.helper';
import DataTable from 'components/DataTable';
import FloatBar from 'components/FloatBar';
import { AddIcon } from 'components/SvgIcon';
import TdtSelect from 'components/TdtSelect';
import { getData, setSorter, setFilters, setPagination } from 'pages/admin/counselor/counselor.slice';

const mapState = state => ({ counselorState: state.admin.counselor });
const mapDispatch = { getData, setSorter, setFilters, setPagination };
const Counselor = ({ counselorState, getData, setFilters, setPagination }) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: t('Full name'),
            dataIndex: 'first_name',
            width: 412,
            sorter: (a, b) => {
                return a.first_name.localeCompare(b.first_name);
            },
            render: (firstName, r) => r.full_name,
        },
        {
            title: t('Role'),
            dataIndex: 'roles',
            width: 128,
            sorter: false,
            render: roles => {
                if (roles.length === 0) return '';
                const names = roles.map(r => t(`enum:${getEnumText(RoleEnum, r.name)}`));
                return names.join(', ');
            },
        },
        {
            title: t('Company in charge'),
            dataIndex: 'companies',
            width: 238,
            sorter: false,
            render: (companies, r) => {
                if (companies.length > 0) {
                    const names = companies.map(c => c.name);
                    return names.join(', ');
                } else if (r.company_program_details.length > 0) {
                    let companies = [];
                    for (const detail of r.company_program_details) {
                        if (detail.company_program.is_disabled || !detail.company_program.company) {
                            continue;
                        }
                        const name = detail.company_program.company.name;
                        if (companies.indexOf(name) === -1) {
                            companies.push(name);
                        }
                    }
                    return companies.join(', ');
                }
                return '';
            },
        },
    ];

    const getCounselors = (
        pagination = counselorState.pagination,
        filters = counselorState.filters,
        sorter = counselorState.sorter
    ) => {
        getData({ pagination, filters, sorter });
    };

    useEffect(() => {
        getCounselors();
    }, []);

    return (
        <div className="self-wrapper d-block">
            <h1 className="page-title mb-32">{t('Member')}</h1>
            <TdtSelect
                style={{ width: 140 }}
                size="large"
                className="fs-14 mr-8"
                value={counselorState.filters.role || ''}
                onChange={value => {
                    const newFilters = { ...counselorState.filters, role: value };
                    const newPagination = { ...counselorState.pagination, current: 1 };
                    setFilters(newFilters);
                    setPagination(newPagination);
                    getCounselors(newPagination, newFilters, counselorState.sorter);
                }}
            >
                <Select.Option value="">{t('All roles')}</Select.Option>
                <Select.Option value={RoleEnum.DOCTOR.value}>{t(`enum:${RoleEnum.DOCTOR.text}`)}</Select.Option>
                {/*<Select.Option value={RoleEnum.STAFF.value}>{t(`enum:${RoleEnum.STAFF.text}`)}</Select.Option>*/}
                <Select.Option value={RoleEnum.ADMIN_STAFF.value}>{t(`enum:${RoleEnum.ADMIN_STAFF.text}`)}</Select.Option>
            </TdtSelect>
            <DataTable
                className="mt-24 collapse-table"
                columns={columns}
                noDataText={'データがありません'}
                hideColumns={counselorState.hideColumns}
                data={counselorState.data}
                loading={counselorState.loading}
                pagination={counselorState.pagination}
                filters={counselorState.filters}
                sorter={counselorState.sorter}
                onTableChange={pagination => {
                    getCounselors(pagination, counselorState.filters, counselorState.sorter);
                }}
                onRow={record => {
                    return {
                        onClick: () => {
                            history.push(Routes.private.ADMIN_COUNSELORS_DETAIL.path.replace(':id', record.id));
                        },
                    };
                }}
            />
            <FloatBar>
                <Button
                    icon={<AddIcon />}
                    className="fw-b"
                    onClick={() => history.push(Routes.private.ADMIN_COUNSELORS_CREATE.path)}
                >
                    {t('Register new')}
                </Button>
            </FloatBar>
        </div>
    );
};

export default connect(mapState, mapDispatch)(Counselor);

Counselor.propTypes = {
    counselorState: PropTypes.shape(
        Object.assign({}, BaseStatePropShape, {
            data: PropTypes.arrayOf(UserPropTypes).isRequired,
        })
    ),
    getData: PropTypes.func.isRequired,
};
