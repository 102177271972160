import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Col, Row, Select } from 'antd';
import TdtSelect from 'components/TdtSelect';
import DataTable from 'components/DataTable';
import { getData, getMetaData, setFilters, setPagination } from 'pages/counselor/consultant-list/consultant.slice';
import InputSearch from 'components/InputSearch';
import { getEnumText } from 'helpers/enum.helper';
import AuthenticationStatusEnum from 'enums/authentication_status.enum';
import SelectPagination from 'components/SelectPagination';
import history from 'helpers/history.helper';
import Routes from 'routes';
import { setBackUrl } from 'core.slice';
import { emailDeletedShow } from 'helpers/string.helper';

const mapState = state => ({ state: state.counselor.consultant });
const mapDispatch = { getData, getMetaData, setFilters, setPagination, setBackUrl };

const Consultant = ({ state, getData, getMetaData, setFilters, setPagination, setBackUrl }) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: t('Full name'),
            dataIndex: 'consultant',
            width: 280,
            sorter: (a, b) => {
                return a.first_name.localeCompare(b.first_name);
            },
            render: (_, record) => {
                return (
                    <>
                        <div className="fw-b">{`${record.full_name}`}</div>
                        <span className="text-small">{emailDeletedShow(record.email)}</span>
                    </>
                );
            },
        },
        {
            title: t('Company'),
            dataIndex: 'company',
            width: 184,
            sorter: (a, b) => {
                const first_empl = a.employee_company[0] || {};
                const second_empl = b.employee_company[0] || {};
                return first_empl.name.localeCompare(second_empl.name);
            },
            render: (_, record) => {
                const company = record.employee_company[0] || {};
                return company.name;
            },
        },
        {
            title: t('Authentication status'),
            dataIndex: 'email_verified_at',
            width: 128,
            sorter: (a, b) => {
                return (a.email_verified_at || '').localeCompare(b.email_verified_at || '');
            },
            render: verifiedAt => t(`enum:${getEnumText(AuthenticationStatusEnum, !!verifiedAt)}`),
        },
        {
            title: t('Program name'),
            dataIndex: 'used_programs',
            width: 184,
            render: used_programs => {
                if (used_programs && used_programs.length > 0) {
                    let tmp = [];
                    const names = used_programs
                        .filter(p => {
                            if (tmp.includes(p.id)) return false;
                            else tmp.push(p.id);
                            return true;
                        })
                        .map(p => p.name);
                    return names.join('・');
                }
                return '';
            },
        },
    ];

    const getConsultants = (pagination = state.pagination, filters = state.filters, sorter = state.sorter) => {
        getData({ pagination, filters, sorter });
    };

    // const [isCheckedPic, setIsCheckedPic] = useState(false);

    useEffect(() => {
        getMetaData();
        getConsultants();
    }, []);

    const metaData = state.metaData;

    const handleFilterByCompany = key => {
        let programId = state.filters.program_id;
        if (programId && key) {
            const program = metaData.programs.find(p => p.id === programId);
            if (program && !program.companyIds.includes(key)) {
                programId = null;
            }
        }
        const newFilters = { ...state.filters, company_id: key, program_id: programId };
        const newPagination = { ...state.pagination, current: 1 };
        setFilters(newFilters);
        setPagination(newPagination);
        getConsultants(newPagination, newFilters, state.sorter);
    };

    const handleFilterByProgram = key => {
        const newFilters = { ...state.filters, program_id: key };
        const newPagination = { ...state.pagination, current: 1 };
        setFilters(newFilters);
        setPagination(newPagination);
        getConsultants(newPagination, newFilters, state.sorter);
    };

    const handlerFilterByKeyword = value => {
        const newFilters = { ...state.filters, keyword: value };
        const newPagination = { ...state.pagination, current: 1 };
        setFilters(newFilters);
        setPagination(newPagination);
        getConsultants(newPagination, newFilters, state.sorter);
    };

    return (
        <div className="self-wrapper d-block">
            <h1 className="page-title mb-24">{t('Consultant list')}</h1>

            <div className="d-flex mb-16">
                <InputSearch
                    value={state.filters.keyword}
                    onChange={e => setFilters({ ...state.filters, keyword: e.target.value })}
                    style={{ maxWidth: 240 }}
                    onSearch={handlerFilterByKeyword}
                    placeholder={'氏名・プログラムで検索'}
                />
            </div>

            <Row gutter={16}>
                <Col span={24} className="d-flex">
                    <TdtSelect
                        value={state.filters.company_id}
                        size="large"
                        className="fs-14 mr-8"
                        onChange={handleFilterByCompany}
                    >
                        <Select.Option value={null}>{'全ての企業'}</Select.Option>
                        {Object.values(metaData.companies).map(c => (
                            <Select.Option key={c.id} value={c.id}>
                                {c.name}
                            </Select.Option>
                        ))}
                    </TdtSelect>
                    <TdtSelect
                        value={state.filters.program_id}
                        size="large"
                        className="fs-14 mr-8"
                        onChange={handleFilterByProgram}
                    >
                        <Select.Option value={null}>{'全てのプログラム'}</Select.Option>
                        {metaData.programs
                            .filter(p => {
                                if (state.filters.company_id) {
                                    return p.companyIds.includes(state.filters.company_id);
                                }
                                return true;
                            })
                            .map(p => (
                                <Select.Option key={p.id} value={p.id}>
                                    {p.name}
                                </Select.Option>
                            ))}
                    </TdtSelect>

                    <SelectPagination
                        onChange={pagination => getConsultants(pagination)}
                        pageSizeOptions={[10, 50, 100]}
                        pageSize={state.pagination.pageSize}
                    />

                    {/*<div className="flex-1 d-flex justify-content-flex-end align-items-center">*/}
                    {/*    <Checkbox checked={isCheckedPic} onChange={e => setIsCheckedPic(e.target.checked)}>*/}
                    {/*        {'自分の担当者'}*/}
                    {/*    </Checkbox>*/}
                    {/*</div>*/}
                </Col>
            </Row>
            <DataTable
                className="collapse-table mt-16"
                columns={columns}
                hideColumns={state.hideColumns}
                data={state.data}
                loading={state.loading}
                pagination={state.pagination}
                filters={state.filters}
                sorter={state.sorter}
                onTableChange={(pagination, filter, sorter) => {
                    getConsultants(pagination, state.filters, sorter);
                }}
                onRow={record => {
                    return {
                        onClick: () => {
                            setBackUrl(Routes.private.COUNSELOR_CONSULTANT_LIST.path);
                            history.push(Routes.private.COUNSELOR_DETAIL_CONSULTATION.path.replace(':id', record.id));
                        },
                    };
                }}
                noDataText={'データがありません'}
            />
        </div>
    );
};

export default connect(mapState, mapDispatch)(Consultant);

Consultant.propTypes = {
    state: PropTypes.any,
    getData: PropTypes.func.isRequired,
    getMetaData: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
    setPagination: PropTypes.func.isRequired,
    setBackUrl: PropTypes.any,
};
