import { createSlice } from '@reduxjs/toolkit';
import { APP_URLS } from 'constants/url.constant';
import api from 'helpers/api.helper';
import history from 'helpers/history.helper';
import { setLoading } from 'core.slice';
import { showNormal } from 'helpers/notification.helper';
import Routes from 'routes';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isAuth: false,
        user: null,
        count: {
            answer: null,
            message: null,
            reservation: null,
            reservationToday: null,
        },
        event: {
            hasNewMessage: {},
        },
        isVerified: false,
        registerAccount: {},
        passwordResetAccount: {},
    },
    reducers: {
        setUser(state, action) {
            let tempUser = action.payload;
            state.user = tempUser;
            state.isAuth = tempUser !== null;
            state.isVerified = tempUser !== null && tempUser.email_verified_at !== null;
        },
        setCountAnswer(state, action) {
            state.count.answer = parseInt(action.payload);
            //console.log("INTERVIEW-SHEET-COUNT_" + state.count.answer); // mobile app badge
            badgeConsole("INTERVIEW-SHEET-COUNT_" + state.count.answer)
        },
        setCountMessage(state, action) {
            state.count.message = parseInt(action.payload);
            //console.log("MESSAGE-COUNT_" + state.count.message);  // mobile app badge
            badgeConsole("MESSAGE-COUNT_" + state.count.message)
        },
        setCountReservation(state, action) {
            state.count.reservation = action.payload;
        },
        setCountReservationToday(state, action) {
            state.count.reservationToday = action.payload;
        },
        increaseCountMessage(state) {
            if (state.count.message !== null) {
                const messageCount = state.count.message + 1;
                state.count.message = messageCount;
                //console.log("MESSAGE-COUNT_" + messageCount);  // mobile app badge
                badgeConsole("MESSAGE-COUNT_" + messageCount);
            }
        },
        decreaseCountMessage(state) {
            if (state.count.message !== null) {
                const messageCount = state.count.message + (-1);
                state.count.message = messageCount;
                //console.log("MESSAGE-COUNT_" + messageCount);  // mobile app badge
                badgeConsole("MESSAGE-COUNT_" + messageCount);
            }
        },
        setHasNewMessage(state, action) {
            state.event.hasNewMessage[action.payload] = new Date().getTime();
        },
        setUserAvatar(state, action) {
            state.user.avatar = action.payload;
        },
        setRegisterAccount(state, action) {
            let payload = action.payload;
            state.registerAccount = {
                first_name: payload.first_name,
                last_name: payload.last_name,
                first_name_kana: payload.first_name_kana,
                last_name_kana: payload.last_name_kana,
                email: payload.email,
                password: payload.password,
                company_code: action.payload.company_code,
            };
        },
        setRegisterErrorCode(state, action) {
            let payload = action.payload;
            state.registerAccount = {
                error_code: payload.error_code,
            };
        },
        setPasswordResetAccount(state, action) {
            let payload = action.payload;
            state.passwordResetAccount = {
                email: payload.email,
                url: payload.url,
            };
        },
    },
});

export const {
    setUser,
    setCountAnswer,
    setCountMessage,
    setCountReservation,
    setCountReservationToday,
    increaseCountMessage,
    decreaseCountMessage,
    setHasNewMessage,
    setUserAvatar,
    setRegisterAccount,
    setRegisterErrorCode,
    setPasswordResetAccount,
} = authSlice.actions;
export default authSlice.reducer;

export const logout = () => async dispatch => {
    const data = await api.delete(APP_URLS.LOGOUT, { device_token_mobile: localStorage.getItem('device_token') });
    if (data) {
        localStorage.removeItem('access_token');
        api.accessToken = null;
        dispatch({ type: 'RESET' });
        dispatch(setLoading(false));
        localStorage.removeItem('device_token');
        history.push('/login');
    }
};

export const checkAuth = () => async dispatch => {
    if (!api.accessToken) {
        dispatch(setLoading(false));
        return;
    }
    dispatch(setLoading(true));
    try {
        const data = await api.get(APP_URLS.AUTH_CHECK, null, {}, true);
        if (data) {
            const { user, count } = data;
            dispatch(setUser(user));
            dispatch(setCountAnswer(count.answer));
            dispatch(setCountMessage(count.message));
            dispatch(setCountReservation(count.reservation));
            dispatch(setCountReservationToday(count.reservation_today));
        }
    } catch (e) {
        console.error(e);
    }
    dispatch(setLoading(false));
};

export const verifyEmail = (queryString, message) => async dispatch => {
    try {
        const data = await api.get(APP_URLS.VERIFY_EMAIL + queryString, {}, {}, true);
        if (data) {
            dispatch(setLoading(false));
            localStorage.setItem('access_token', data.token);
            api.accessToken = data.token;
            const { user } = data;
            dispatch(setUser(user));
            showNormal('', message, 5);
            history.push('/client/reservation');
        }
    } catch (e) {
        history.push(Routes.private.CLIENT_RESERVATION.path);
    }
};

export const checkPasswordResetSession = queryString => async dispatch => {
    try {
        const data = await api.get(APP_URLS.PASSWORD_RESET_VERIFY + queryString, null, {}, true);
        if (data) {
            dispatch(setLoading(false));
            return true;
        }
    } catch (e) {
        return false;
    }
};

const badgeConsole = text => {
    try {
        // eslint-disable-next-line no-undef
        Toaster.postMessage(text)

        // eslint-disable-next-line no-empty
    } catch (e) {}

    try {
        // eslint-disable-next-line no-undef
        window.flutter_inappwebview.callHandler(
            "teamdoctorNavtive",
            text
        )
        // eslint-disable-next-line no-empty
    } catch (e) {}
}