import React from 'react';
import PropTypes from 'prop-types';

const FloatBar = ({ className, style, children }) => {
    return (
        <div className={`float-bar ${className ? className : ''}`} style={style}>
            {children}
        </div>
    );
};

export default FloatBar;

FloatBar.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any,
};
