import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FloatBar from 'components/FloatBar';
import { useTranslation } from 'react-i18next';
import api from 'helpers/api.helper';
import history from 'helpers/history.helper';
import { APP_URLS } from 'constants/url.constant';
import { setUser } from 'pages/auth/auth.slice';
import { showNormal } from 'helpers/notification.helper';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import TdtButtonSubmit from 'components/TdtButtonSubmit';
import { handleScrollOnFocus } from 'helpers/form_event.helper';

const mapState = state => ({
    account: state.auth.user,
});
const mapDispatch = { setUser };
const UpdateAccount = ({ account, setUser }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    const updateName = async values => {
        const response = await api.patch(APP_URLS.CLIENT_UPDATE_ACCOUNT, values);
        if (response) {
            showNormal('', t('message:Saved your changes'), 5);
            setUser(response);
            history.goBack();
        }
    };

    let initialValues = Object.assign({}, { ...account });

    return (
        <>
            <BackBtnWithTitle title={t('Account editing')} onClick={() => history.goBack()} />
            <div className="mt-32">
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={initialValues}
                    onFinish={async values => {
                        await updateName(values);
                    }}
                    requiredMark={false}
                    className="mb-64"
                    onFocus={handleScrollOnFocus}
                    onChange={handleScrollOnFocus}
                >
                    <Row className="mb-24" align="middle">
                        <Col className="mr-16 input-name-setting">
                            <Form.Item
                                name="first_name"
                                label={t('field:first name')}
                                validateTrigger={['onBlur']}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input className="input-setting" />
                            </Form.Item>
                        </Col>
                        <Col className="mr-16 input-name-setting">
                            <Form.Item
                                name="last_name"
                                label={t('field:Last name')}
                                validateTrigger={['onBlur']}
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input className="input-setting" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row className="mb-24" align="middle">
                        <Col className="mr-16 input-name-setting">
                            <Form.Item
                                name="first_name_kana"
                                label={t('field:First name kana')}
                                validateTrigger={['onBlur']}
                                rules={[
                                    {
                                        required: true,
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (!value || /^[ぁ-ん]+$/.test(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(t('validation:Please enter in hiragana')));
                                        },
                                    }),
                                ]}
                            >
                                <Input className="input-setting" />
                            </Form.Item>
                        </Col>
                        <Col className="mr-16 input-name-setting">
                            <Form.Item
                                name="last_name_kana"
                                label={t('field:Last name kana')}
                                validateTrigger={['onBlur']}
                                rules={[
                                    {
                                        required: true,
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (!value || /^[ぁ-ん]+$/.test(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(t('validation:Please enter in hiragana')));
                                        },
                                    }),
                                ]}
                            >
                                <Input className="input-setting" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <FloatBar>
                        <TdtButtonSubmit type="primary" htmlType="submit" className="fw-b btn-float-bar">
                            {t('Save')}
                        </TdtButtonSubmit>
                    </FloatBar>
                </Form>
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(UpdateAccount);

UpdateAccount.propTypes = {
    account: PropTypes.any,
    setUser: PropTypes.func,
};
