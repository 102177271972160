import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { rootRedirect } from 'helpers/root_redirect.helper';

const RootPage = ({ user }) => {
    useEffect(() => {
        rootRedirect(user);
    }, []);
    return <></>;
};
export default RootPage;

RootPage.propTypes = {
    user: PropTypes.any,
};
