import React from 'react';
import PropTypes from 'prop-types';

const MessageWithIcon = ({ className, imageUrl, title, description }) => {
    return (
        <div className={`message-with-icon ${className ? className : ''}`}>
            {imageUrl && <img src={imageUrl} alt="Icon" />}
            <h1>{title}</h1>
            {description && <p>{description}</p>}
        </div>
    );
};

export default MessageWithIcon;

MessageWithIcon.propTypes = {
    className: PropTypes.string,
    imageUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
};
