import React, { useEffect, useState } from 'react';
import TdtSelect from 'components/TdtSelect';
import { Select, Typography } from 'antd';
import { dateToStr } from 'helpers/date.helper';

const ScreenAfterAnswer = ({ state }) => {
    const { name, infoByScores } = state;
    const orderIdsHasRev = infoByScores.order.filter(k => infoByScores.byId[k] && infoByScores.byId[k].rev);
    const [selectedId, setSelectedId] = useState(orderIdsHasRev.length > 0 ? orderIdsHasRev[0] : null);
    const [isMobileView, setIsMobileView] = useState(false)

    useEffect(() => {
        if (!orderIdsHasRev.includes(selectedId)) {
            setSelectedId(orderIdsHasRev.length > 0 ? orderIdsHasRev[0] : null);
        }
        return () => {
            setSelectedId(null);
        };
    }, [state.infoByScores]);

    return (
        <div>
            {orderIdsHasRev.length > 0 && (
                <TdtSelect
                    size="large"
                    className="fs-16 mb-24 h-40 bg-white select-40"
                    style={{ width: 200 }}
                    value={selectedId}
                    onChange={v => setSelectedId(v)}
                >
                    {orderIdsHasRev.map(key => {
                        const info = infoByScores.byId[key];
                        return (
                            <Select.Option key={key} value={key}>
                                {info.title}
                            </Select.Option>
                        );
                    })}
                </TdtSelect>
            )}
            <TdtSelect
                size="large"
                className="fs-16 mb-24 h-40 bg-white select-40 ml-12"
                defaultValue={'pc_view'}
                value={isMobileView ? 'mobile_view' : 'pc_view'}
                onChange={v => setIsMobileView(v === 'mobile_view')}
            >
                <Select.Option value={'pc_view'}>
                    {'PCビュー'}
                </Select.Option>
                <Select.Option value={'mobile_view'}>
                    {'モバイルビュー'}
                </Select.Option>
            </TdtSelect>
            <div className="f-24-36 fw-700">{name}</div>
            {/*<Typography className="mt-8 fw-400 fs-14 text-gray-color">*/}
            {/*    {`回答日時: ${dateToStr(category.created_at, 'YYYY/MM/DD HH:mm')}`}*/}
            {/*</Typography>*/}
            {orderIdsHasRev.length > 0 && !!selectedId && infoByScores.byId[selectedId] && (
                <div className={`label-display mt-24 mb-48 ${isMobileView ? 'screen-after-answer-preview-mobile-res' : ''}`}>
                    <div className="fs-24 fw-700 ">{infoByScores.byId[selectedId].title || ''}</div>
                    <div className="fs-14 fw-400 color-blg-60 counselor-questionnaire-render-page-description">{infoByScores.byId[selectedId].description || ''}</div>
                </div>
            )}
        </div>
    );
};

export default ScreenAfterAnswer;
