import React from 'react';

export const HMDivider = () => {
    const raw = `<tr>
    <td align="center" style="font-size:0px;padding: 8px 16px 24px;word-break:break-word;">
        <p style="border-top:solid 1px rgba(0, 0, 0, 0.2);font-size:1px;margin:0px auto;width:100%;">
        </p>
        <!--[if mso | IE]><table align="center" border="0" cellpadding="0" cellspacing="0" style="border-top:solid 1px rgba(0, 0, 0, 0.2);font-size:1px;margin:0px auto;width:568px;" role="presentation" width="568px" ><tr><td style="height:0;line-height:0;"> &nbsp;
</td></tr></table><![endif]-->
    </td>
</tr>
`;

    return <tr dangerouslySetInnerHTML={{ __html: raw }} />;
};
