import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import DeleteBtnInModal from 'components/DeleteBtnInModal';

const CounselorDeleteStaffModal = ({ visible, onClose, onUpdate }) => {
    return (
        <Modal
            title={'スタッフ削除'}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <p>{'スタッフを削除します。この操作はやり直しできません。よろしいですか？'}</p>
            <DeleteBtnInModal onClose={onClose} onUpdate={onUpdate} />
        </Modal>
    );
};

CounselorDeleteStaffModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
};

export default CounselorDeleteStaffModal;
