import React from 'react';
import QuestionCommonFields from 'pages/admin/interview_sheet/questions/QuestionCommonFields';
import FloatBarQuestion from './FloatBarQuestion';
import HeaderQuestion from './HeaderQuestion';

const DailyLifeQuestion = ({
    className,
    id,
    question,
    addition,
    setQuestion,
    setAddition,
    no,
    type,
    setType,
    isRequired,
    setIsRequired,
}) => {
    return (
        <div className={`question-block ${className ? className : ''}`}>
            <HeaderQuestion no={no} />
            <QuestionCommonFields
                id={id}
                question={question}
                addition={addition}
                setAddition={setAddition}
                setQuestion={setQuestion}
                type={type}
                setType={setType}
            />
            <span className="fs-12 color-blg-60 d-block" style={{ marginTop: '-8px' }}>
                {
                    '時間別に「睡眠」「仕事」「授業/研究」「勉強」「食事」「運動」「家事」「休憩」「余暇」の9項目を入力可能とする設問が表示されます'
                }
            </span>
            <FloatBarQuestion id={id} isRequired={isRequired} setIsRequired={setIsRequired} />
        </div>
    );
};

export default DailyLifeQuestion;
