import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { baseExtraReducers, baseGetData, baseInitialState, baseReducers } from 'helpers/slice.helper';
import { APP_URLS } from 'constants/url.constant';
import api from 'helpers/api.helper';

const name = 'commonSession';

export const getData = createAsyncThunk(
    `${name}/getData`,
    async props => await baseGetData({ url: APP_URLS.ADMIN_COMMON_SESSIONS, ...props })
);

export const defaultFilters = {
    company_id: null,
};

const common_sessionSlice = createSlice({
    name,
    initialState: {
        ...baseInitialState,
        filters: defaultFilters,
        sorter: {
            field: 'created_at',
            order: 'descend',
        },
        master: {
            loading: true,
            companies: [],
        },
    },
    reducers: {
        ...baseReducers,
        setCompany(state, action) {
            state.filters.company_id = action.payload;
        },
        setMasterCompanies(state, action) {
            state.master.companies = action.payload;
            state.master.loading = false;
        },
    },
    extraReducers: baseExtraReducers(getData),
});

export const {
    setMasterCompanies,
    setCompany,
    setLoading,
    setData,
    setPagination,
    setFilters,
    setSorter,
    setHideColumns,
    setSelection,
} = common_sessionSlice.actions;

export default common_sessionSlice.reducer;

export const getMasterData = () => async dispatch => {
    const data = await api.get(APP_URLS.ADMIN_COMMON_SESSIONS_MASTER);
    if (data) {
        dispatch(setMasterCompanies(data.companies));
    }
};
