import { createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'commonSessionDetail';

const commonSessionDetailSlice = createSlice({
    name,
    initialState: {
        commonSession: null,
        loading: true,
    },
    reducers: {
        setCommonSession(state, action) {
            state.commonSession = action.payload;
        },
        setLoading(state, action) {
            state.loading = action.payload;
        },
    },
});

export const { setCommonSession, setLoading } = commonSessionDetailSlice.actions;

export const getCommonSession = id => async dispatch => {
    dispatch(setLoading(true));
    const data = await api.get(APP_URLS.ADMIN_COMMON_SESSIONS_DETAIL.replace(':id', id));
    if (data) {
        dispatch(setCommonSession(data));
    }
    dispatch(setLoading(false));
};

export default commonSessionDetailSlice.reducer;
