import React from 'react';
import logo from 'assets/images/html-mail/logo.png';
import { HMFooter } from 'pages/admin/common/page-elements/html_mail/HMFooter';
import { HMNotice } from 'pages/admin/common/page-elements/html_mail/HMNotice';
import { HMText } from './html_mail/HMText';
import { HMDivider } from 'pages/admin/common/page-elements/html_mail/HMDivider';

const RenderHTMLMailFreeText = ({ message }) => {
    const renderLogo = () => {
        return (
            <div className="hm_logo">
                <img src={logo} />
            </div>
        );
    };

    const raw = `<tr>
    <td align="center" style="font-size:0px;padding:4px 16px 24px;word-break:break-word;">
        <div style="font-family: Noto Sans JP; font-size: 28px; font-weight: 700; line-height: 125%; text-align: left; color: #000000;">${message['title']}</div>
    </td>
</tr>
`;

    const renderTitle = () => {
        return <tr dangerouslySetInnerHTML={{ __html: raw }} />;
    };

    const renderFooter = () => <HMFooter />;

    return (
        <div className="html-mail">
            <table
                role="presentation"
                cellSpacing="0"
                cellPadding="0"
                border="0"
                width="100%"
                style={{ margin: 'auto' }}
            >
                {renderLogo()}
                {renderTitle()}
                <HMText detail={{ content: message['content'] }} />
                <HMDivider />
                <HMNotice />
                {renderFooter()}
            </table>
        </div>
    );
};

export default RenderHTMLMailFreeText;
