import ReservationStatus from 'enums/reservation_status.enum';

export const filterReservations = (filter, layoutType, reservations) => {
    if (layoutType !== 'calendar') return reservations;
    if (!filter.company_id && !filter.program_id) return reservations;
    let filtered = {};
    for (const date of Object.keys(reservations)) {
        const tmpArr = reservations[date].filter(item => {
            if (filter.company_id && item.company_id !== filter.company_id) return false;
            return !(filter.program_id && item.program_id !== filter.program_id);
        });
        if (tmpArr.length > 0) {
            filtered[date] = tmpArr;
        }
    }
    return filtered;
};

export const filterRegistered = (filter, layoutType, registered) => {
    if (layoutType !== 'calendar') return {};
    let result = {};
    if (!filter.company_id && !filter.program_id) {
        for (const value of Object.values(registered)) {
            mergeRegister(result, value);
        }
    } else if (!filter.program_id) {
        for (const key of Object.keys(registered)) {
            if (key.startsWith(`${filter.company_id}-`)) {
                mergeRegister(result, registered[key]);
            }
        }
    } else if (!filter.company_id) {
        for (const key of Object.keys(registered)) {
            if (key.endsWith(`-${filter.program_id}`)) {
                mergeRegister(result, registered[key]);
            }
        }
    } else {
        const key = `${filter.company_id}-${filter.program_id}`;
        if (registered[key]) {
            return registered[key];
        }
    }
    return result;
};

const mergeRegister = (current, data) => {
    for (const day of Object.keys(data)) {
        if (!current[day]) current[day] = data[day];
        else {
            current[day] = [...new Set([...current[day], ...data[day]])];
        }
    }
};

export const convertReservationToCalendarEvent = r => {
    let status = 'processing';
    if (r.status === ReservationStatus.END.value || r.status === ReservationStatus.COMPLETED.value) {
        status = 'done';
    } else if (
        r.status === ReservationStatus.PENDING.value ||
        r.status === ReservationStatus.CANCELLED_TIMEOUT.value ||
        r.status === ReservationStatus.CANCELLED_BOOKING.value
    ) {
        status = 'cancelled';
    }
    return {
        id: r.id,
        user_id: r.client_id,
        status: status,
        date: r.date,
        start: r.start_time,
        end: r.session_end_time,
        title: r.client.full_name,
        subtitle: r.company_program.company.name,
        colorCode: r.program.color,
        company_id: r.company_program.company.id,
        program_id: r.program_id,
        program_name: r.program.name,
        program_name_mngt: r.program.name_mngt,
        deleted_by_id: r.client.deleted_by_id,
    };
};

export const filteredBusySlots = (filter, layoutType, reservedAndBlockedFrames, depends) => {
    if (layoutType !== 'calendar') return {};

    let dependsFiltered = [];
    if (!filter.company_id && !filter.program_id) {
        for (const value of Object.values(depends)) {
            dependsFiltered = [...new Set([...dependsFiltered, ...value])];
        }
    } else if (!filter.program_id) {
        for (const key of Object.keys(depends)) {
            if (key.startsWith(`${filter.company_id}-`)) {
                dependsFiltered = [...new Set([...dependsFiltered, ...depends[key]])];
            }
        }
    } else if (!filter.company_id) {
        for (const key of Object.keys(depends)) {
            if (key.endsWith(`-${filter.program_id}`)) {
                dependsFiltered = [...new Set([...dependsFiltered, ...depends[key]])];
            }
        }
    } else {
        const key = `${filter.company_id}-${filter.program_id}`;
        if (depends[key]) {
            dependsFiltered = depends[key];
        }
    }

    return { reservedAndBlockedFrames, dependsFiltered };
};

export const checkBusySlot = ({ reservedAndBlockedFrames, dependsFiltered }, dayStr, timeStr) => {
    if (reservedAndBlockedFrames && reservedAndBlockedFrames[dayStr]) {
        let allBusy = true;
        const counselorIds = reservedAndBlockedFrames[dayStr];
        for (const key of Object.keys(counselorIds)) {
            if (counselorIds[key].data.indexOf(timeStr) === -1 && dependsFiltered.indexOf(Number(key)) > -1) {
                allBusy = false;
                break;
            }
        }

        if (allBusy) {
            return !dependsFiltered.some(o => !counselorIds[o]);
        }

        return allBusy;
    }
    return false;
};
