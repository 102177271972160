import QuestionTypeEnum from 'enums/question_type.enum';
import { dateToStr, momentToStr, strToMoment } from 'helpers/date.helper';
import DateTimeOptionTypeEnum from 'enums/date_time_option_type.enum';
import moment from 'moment';

export const parseQuestionnaire = ({ questions = [], listAnswers = [] }) => {
    let result = { totalScore: 0, questions: [], scoreById: {}, questionById: {} };

    for (const question of questions) {
        let tmpQuestion = {
            id: question.id,
            value: question.value,
            isRequired: question.is_required,
            type: question.type,
            answers: [],
            score: 0,
            useScore:
                question.type === QuestionTypeEnum.RADAR_CHART.value ||
                ((!question.display_columns ||
                        (question.display_columns && question.display_columns.includes('set_score'))) &&
                    [QuestionTypeEnum.MULTIPLE_SELECTION.value, QuestionTypeEnum.SINGLE_SELECTION.value].includes(
                        question.type,
                    )),
        };

        const answer = listAnswers && listAnswers.find(o => o.qid === question.id);
        if (!answer) {
            result.questions.push(tmpQuestion);
            continue;
        }

        let selectedOptions;
        let dateTimeAnswers;
        switch (question.type) {
            case QuestionTypeEnum.MULTIPLE_SELECTION.value:
                selectedOptions = question.options.filter(o =>
                    answer.values.ids ? answer.values.ids.includes(o.id) : false,
                );
                for (const opt of selectedOptions) {
                    const { id, value, score = 0, type } = opt;
                    let tmpValue = null;

                    if (type === 'other') {
                        tmpValue = answer.values.others[id];
                        if (tmpValue === undefined) {
                            tmpValue = '';
                        }
                        tmpValue = tmpValue.trim();
                    }

                    tmpQuestion.answers.push({ id, value, score, type, otherValue: tmpValue });
                    tmpQuestion.score += score;
                }
                break;
            case QuestionTypeEnum.SINGLE_SELECTION.value:
            case QuestionTypeEnum.RADAR_CHART.value:
                selectedOptions = question.options.find(
                    o => answer.values === o.id || (answer.values.ids && answer.values.ids.includes(o.id)),
                );
                if (selectedOptions) {
                    const { id, value, score = 0, type } = selectedOptions;
                    let tmpValue = null;

                    if (type === 'other') {
                        tmpValue = answer.values.others[id];
                        if (tmpValue === undefined) {
                            tmpValue = '';
                        }
                        tmpValue = tmpValue.trim();
                    }

                    tmpQuestion.answers.push({ id, value, score, type, otherValue: tmpValue });
                    tmpQuestion.score += score;
                }
                break;
            case QuestionTypeEnum.FREE_INPUT.value:
            case QuestionTypeEnum.FREE_INPUT_PARAGRAPH.value:
            case QuestionTypeEnum.FREE_INPUT_NUMBER.value:
                tmpQuestion.answers.push({ id: answer.id, value: answer.values });
                break;
            case QuestionTypeEnum.DATE_TIME.value:
                dateTimeAnswers = Object.keys(answer.values).map(key => ({ ...answer.values[key], id: key }));
                for (const dateTimeAnswer of dateTimeAnswers) {
                    let valueByType;
                    const { id, value, is_range } = dateTimeAnswer;
                    switch (dateTimeAnswer.type) {
                        case DateTimeOptionTypeEnum.DATE.value:
                            valueByType = valueToStr(value, is_range, 'YYYY-MM-DD', 'YYYY年M月D日(dd)');
                            break;
                        case DateTimeOptionTypeEnum.TIME.value:
                            valueByType = valueToStr(value, is_range, 'HH:mm', 'HH:mm');
                            break;
                        case DateTimeOptionTypeEnum.DATE_TIME.value:
                            valueByType = valueToStr(value, is_range, 'YYYY-MM-DD HH:mm', 'YYYY年M月D日(dd) HH:mm');
                            break;
                        case DateTimeOptionTypeEnum.DURATION.value:
                            valueByType = valueToStr(value, is_range);
                            break;
                    }
                    tmpQuestion.answers.push({ id, value: valueByType });
                }
                break;
            case QuestionTypeEnum.DAILY_LIFE.value:
                tmpQuestion.answers.push({ id: answer.id, type: question.type, value: answer.values });
                break;
        }

        result.questions.push(tmpQuestion);
        if (tmpQuestion.useScore || tmpQuestion.score) {
            result.scoreById[tmpQuestion.id] = {
                id: tmpQuestion.id,
                score: tmpQuestion.score,
                useScore: tmpQuestion.useScore,
            };
        }
        result.questionById[tmpQuestion.id] = tmpQuestion;
    }

    const totalScoreArr = result.questions.filter(q => q.useScore).map(q => q.score);
    if (totalScoreArr.length > 0) {
        result.totalScore = totalScoreArr.reduce((prev = 0, next) => prev + next, 0);
    }

    // original questions
    result.originQuestions = questions;

    return result;
};

const valueToStr = (value, isRange, parser, formatter) => {
    if (parser) {
        return !isRange
            ? momentToStr(strToMoment(value, parser), formatter)
            : `${momentToStr(strToMoment(value[0], parser), formatter)} ~ ${momentToStr(
                strToMoment(value[1], parser),
                formatter,
            )}`;
    }
    return !isRange ? value : `${value[0]} ~ ${value[1]}`;
};

export const combineCategoriesWithScore = (result, categories, dataSetLabel) => {
    const combineResult = [];

    const { questions, scoreById } = result;
    for (let i = 1; i <= categories.length; i++) {
        const { title, start, end, maxTotalScore } = categories[i - 1];
        let score = 0;
        for (let k = start; k <= end; k++) {
            if (k < questions.length && questions[k] && questions[k].id) {
                const qScoreObj = scoreById[questions[k].id];
                score += qScoreObj ? qScoreObj.score : 0;
            }
        }

        combineResult.push({
            title,
            start,
            end,
            score,
            dataSetLabel,
            scorePercent: maxTotalScore > 0 ? score / maxTotalScore * 100 : 0,
        });
    }
    return combineResult;
};

export const combineCategoriesWithMultipleScore = (multiResults, categories) => {
    const combineResult = [];
    for (let i = 1; i <= categories.length; i++) {
        const { title, start, end, maxTotalScore } = categories[i - 1];
        let score = 0;
        for (let k = start; k <= end; k++) {
            for (const result of multiResults) {
                const { questions, scoreById } = result;
                if (k < questions.length && questions[k] && questions[k].id) {
                    const qScoreObj = scoreById[questions[k].id];
                    score += qScoreObj ? qScoreObj.score : 0;
                }
            }
        }
        combineResult.push({
            title,
            start,
            end,
            score,
            scorePercent: maxTotalScore > 0 ? score / maxTotalScore * 100 : 0,
        });
    }
    return combineResult;
};

export const getPrevAnswer = (otherAnswers, currentAnswer) => {
    let prev;
    for (let i = otherAnswers.length - 1; i >= 0; i--) {
        if (currentAnswer.updated_at > otherAnswers[i].updated_at) {
            prev = otherAnswers[i];
            break;
        }
    }
    return prev;
};

export const getLastAnswer = (otherAnswers, currentAnswer) => {
    const otherAnswerLast = otherAnswers.length > 0 ? otherAnswers[otherAnswers.length - 1] : null;
    if (!otherAnswerLast) {
        return currentAnswer;
    }
    const otherAnswerLastUpdatedAt = moment(otherAnswerLast.updated_at, 'YYYY-MM-DD HH:mm:ss');
    const currentAnswerUpdatedAt = moment(currentAnswer.updated_at, 'YYYY-MM-DD HH:mm:ss');

    return otherAnswerLastUpdatedAt.isAfter(currentAnswerUpdatedAt) ? otherAnswerLast : currentAnswer;
};

export const getNextAnswer = (otherAnswers, currentAnswer) => {
    let next;
    for (let i = 0; i < otherAnswers.length; i++) {
        if (currentAnswer.updated_at < otherAnswers[i].updated_at) {
            next = otherAnswers[i];
            break;
        }
    }
    return next;
};

const getMaxScoreFromAQuestion = (questions, questionId) => {
    const question = questions.find(q => q.id === questionId);
    if (!question) {
        return 0;
    }
    return Math.max(...[0, ...question.options.map(o => o.score)]);
};

export const parseCategoriesWithQuestions = (answer, questions, originQuestions = []) => {
    if (!answer || !answer.interview_sheet || !answer.interview_sheet.json_categories) {
        return [];
    }

    const arrCategories = JSON.parse(answer.interview_sheet.json_categories);
    return arrCategories.map(category => {
        let tmp = { ...category, children: [], maxTotalScore: 0 };

        for (let i = category.start; i <= category.end; i++) {
            if (i < questions.length) {
                const question = questions[i];
                tmp.children.push(question.id);

                tmp.maxTotalScore += (getMaxScoreFromAQuestion(originQuestions, question.id));
            }
        }

        return tmp;
    });
};

export const answersToRadarChartData = (answers = [], dateTimeFormat = 'YYYY/MM/DD') => {
    const combineResults = [];
    for (const answer of answers) {
        if (!answer) {
            continue;
        }

        const result = parseQuestionnaire({
            questions: answer.interview_sheet.questions,
            listAnswers: JSON.parse(answer.json_answers),
        });

        const categories = parseCategoriesWithQuestions(answer, result.questions, result.originQuestions);
        if (!categories || categories.length < 1) {
            continue;
        }
        const combineResult = combineCategoriesWithScore(result, categories, dateToStr(answer.updated_at, dateTimeFormat));
        combineResults.push(combineResult);
    }

    return combineResults;
};