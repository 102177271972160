import React, { useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Button } from 'antd';

const CsvDownloadTemplate = () => {
    const [data] = useState([['Email']]);
    const [fileName] = useState('Template_distribution_list.csv');
    const csvLink = useRef();

    return (
        <CSVLink data={data} filename={fileName} target="_blank" ref={csvLink}>
            <Button type="link" className="p-0 td-link-bolder fw-700 fs-14">
                {'こちら'}
            </Button>
        </CSVLink>
    );
};

export default CsvDownloadTemplate;
