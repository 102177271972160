import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getInterviewSheet } from 'pages/admin/interview_sheet/interview_sheet_create.slice';
import InterviewSheetForm from 'pages/admin/interview_sheet/InterviewSheetForm';
import { useParams } from 'react-router';

const mapDispatch = { getInterviewSheet };

const InterviewSheetUpdate = ({ getInterviewSheet }) => {
    const { id } = useParams();
    const canDelete = true;

    useEffect(() => {
        getInterviewSheet(id);
    }, [id]);

    return <InterviewSheetForm isUpdate={true} canDelete={canDelete} />;
};

export default connect(null, mapDispatch)(InterviewSheetUpdate);

InterviewSheetUpdate.propTypes = {
    getInterviewSheet: PropTypes.func.isRequired,
};
