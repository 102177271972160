import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Select, Row, Col, Typography } from 'antd';
import Routes from 'routes';
import { BaseStatePropShape, CommonSessionPropTypes } from 'constants/proptypes.constant';
import CustomDropdown from '../../../components/CustomDropdown';
import history from 'helpers/history.helper';
import DataTable from 'components/DataTable';
import FloatBar from 'components/FloatBar';
import { AddIcon, DuplicateIcon, DotsHorizontalRoundedIcon, DeleteIcon } from 'components/SvgIcon';
import {
    setCompany,
    getData,
    setFilters,
    setSorter,
    setPagination,
    getMasterData,
} from 'pages/admin/feature/common_session.slice';
import TdtSelect from 'components/TdtSelect';
import InputSearch from 'components/InputSearch';
import SeminarStateEnum from 'enums/seminar_state.enum';
import { fullDateFormatExceptHour } from 'helpers/date.helper';
import CloneCommonSessionModal from './modal/CloneCommonSessionModal';
import DeleteCommonSessionModal from './modal/DeleteCommonSessionModal';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';
import { showNormal } from 'helpers/notification.helper';
import ProvideCompaniesModal from 'pages/admin/feature/modal/ProvideCompaniesModal';
import CommonSessionChooseCompanyEnum from 'enums/common_session_choose_company.enum';
import { getEnumText } from 'helpers/enum.helper';

const mapState = state => ({
    commonSessionState: state.admin.commonSession,
    master: state.admin.commonSession.master,
});
const mapDispatch = { getData, setSorter, setFilters, setPagination, setCompany, getMasterData };
const CommonSession = ({
    commonSessionState,
    getData,
    setFilters,
    setPagination,
    setSorter,
    company,
    setCompany,
    master,
    getMasterData,
}) => {
    const { t } = useTranslation();
    const [modalVisible, setModalVisible] = useState({
        clone: false,
        delete: false,
        record: null,
        provide: false,
    });

    const closeModal = () =>
        setModalVisible({
            clone: false,
            delete: false,
            record: null,
            provide: false,
        });

    const deleteCommonSession = async commonSessionId => {
        const response = await api.delete(APP_URLS.ADMIN_COMPANIES_SEMINAR_DELETE.replace(':id', commonSessionId));
        if (response) {
            history.push(Routes.private.ADMIN_COMMON_SESSION.path);
            showNormal('', t('message:Deleted session'), 3);
            getCommonSessions();
        }
    };

    const handleFilterByCompany = key => {
        const newFilters = { ...commonSessionState.filters, company_id: key };
        const newPagination = { ...commonSessionState.pagination, current: 1 };
        setFilters(newFilters);
        setPagination(newPagination);
        getCommonSessions(newPagination, newFilters, commonSessionState.sorter);
    };

    const showDeleteModal = r => setModalVisible({ ...modalVisible, delete: true, record: r });
    const showCloneModal = r => setModalVisible({ ...modalVisible, clone: true, record: r });
    const showProvideModal = r => setModalVisible({ ...modalVisible, provide: true, record: r });

    const columns = [
        {
            title: t('Common_Session name'),
            dataIndex: 'name',
            height: 40,
            width: 193,
            padding: 8,
            sorter: true,
            render: (_, r) => <span className={'word-break-all'}>{r.name ? `${r.name}` : t('Not set')}</span>,
        },
        {
            title: t('Date and time'),
            dataIndex: 'date',
            height: 40,
            width: 193,
            left: 193,
            padding: 8,

            sorter: true,
            render: (_, r) => (
                <>
                    {r.date && r.start_time ? (
                        <div>
                            <span>{fullDateFormatExceptHour(r.date)}</span>
                            <br />
                            <span>{r.start_time}</span>
                            <span>{'-'}</span>
                            <span>{r.end_time}</span>
                        </div>
                    ) : (
                        t('Not set')
                    )}
                </>
            ),
        },
        {
            title: t('Public status'),
            dataIndex: 'status',
            width: 88,
            height: 40,
            left: 386,
            padding: 8,
            render: (_, r) => <span>{t(`enum:${getEnumText(SeminarStateEnum, r.status)}`)}</span>,
        },
        {
            title: t('Implementation'),
            dataIndex: 'implementation',
            width: 88,
            height: 40,
            left: 474,
            padding: 8,

            render: (_, r) => {
                const heldThisWeek = r.held_this_week;
                const isExpired = r.is_expired;
                return (
                    <>
                        {!(r.status === 'private') && (
                            <span>
                                {isExpired
                                    ? t('End')
                                    : heldThisWeek
                                    ? t('Before the event')
                                    : !r.name || !r.date
                                    ? ''
                                    : t('Before the event')}
                            </span>
                        )}
                    </>
                );
            },
        },
        {
            title: t('Archive video'),
            dataIndex: 'archive_status',
            className: 'text-align-center',
            width: 160,
            height: 40,
            left: 474,
            padding: 8,
            render: (archiveStatus, r) => {
                if (archiveStatus === 'no_video' || archiveStatus === 'fail') {
                    return <span>{'ー'}</span>;
                } else if (archiveStatus === 'success') {
                    return (
                        <span className={`archive-status-tag ${r.is_video_public ? 'is-video-public' : ''}`}>
                            {r.is_video_public ? 'アーカイブ' : 'アーカイブ非公開'}
                        </span>
                    );
                }

                return '';
            },
        },
        {
            title: t('Provide companies'),
            dataIndex: 'companies',
            width: 160,
            height: 40,
            left: 562,
            padding: 8,
            // sorter: true,
            render: (companies, record) => {
                const typeIsAllCompany = record.type === CommonSessionChooseCompanyEnum.ALL_COMPANY.value;

                const total = companies.length;
                const show = 5;
                return (
                    <>
                        <div
                            className="circle-listed"
                            onClick={e => {
                                e.stopPropagation();
                                showProvideModal(typeIsAllCompany ? master : record);
                            }}
                        >
                            {typeIsAllCompany ? (
                                <span className="body2 color-blue-100">{t('All companies')}</span>
                            ) : (
                                <>
                                    {companies.map((company, index) => {
                                        if (index > show - 1) {
                                            return null;
                                        }
                                        return (
                                            <span
                                                key={`seminar${record.id}_${company.id}_${index}`}
                                                className={`circle-list-item item-${index + 1}`}
                                            >
                                                {index === show - 1 && total > show
                                                    ? `+${total - show + 1}`
                                                    : company.name.substr(0, 2)}
                                            </span>
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            title: '',
            dataIndex: 'dots-select',
            render: (_, r) => {
                return (
                    <CustomDropdown
                        ovlClassName={'dots-dropdown z-index-above-modal'}
                        getPopupContainer={node => node}
                        onClick={e => {
                            e.stopPropagation();
                            e.preventDefault();
                        }}
                        trigger={['click']}
                        options={[
                            {
                                key: 'duplicate',
                                text: (
                                    <>
                                        <DuplicateIcon /> <span>{'複製'}</span>
                                    </>
                                ),
                                className: 'primary-color fw-b',
                            },
                            {
                                key: 'delete',
                                text: (
                                    <>
                                        <DeleteIcon /> <span>{'削除'}</span>
                                    </>
                                ),
                                className: 'pink-color fw-b',
                            },
                        ]}
                        onSelect={opt => {
                            opt.domEvent.stopPropagation();
                            if (opt.key === 'delete') {
                                showDeleteModal(r);
                            } else {
                                showCloneModal(r);
                            }
                        }}
                        separator
                    >
                        <DotsHorizontalRoundedIcon className=" dots-icon c-pointer" />
                    </CustomDropdown>
                );
            },
            width: 56,
            height: 40,
            left: 722,
            padding: 8,
        },
    ];

    const getCommonSessions = (
        pagination = commonSessionState.pagination,
        filters = commonSessionState.filters,
        sorter = commonSessionState.sorter
    ) => {
        if (!sorter || !sorter.field) {
            sorter = commonSessionState.sorter;
        }
        setSorter(sorter);
        getData({ pagination, filters, sorter });
    };

    useEffect(() => {
        getCommonSessions(commonSessionState.pagination, {});
        if (master.loading) {
            getMasterData();
        }
    }, []);

    const keywordChangeHandler = value => {
        const newFilters = Object.assign({}, commonSessionState.filters, {
            keyword: value,
        });
        setFilters(newFilters);
        getCommonSessions(commonSessionState.pagination, newFilters, commonSessionState.sorter);
    };

    return (
        <div className="self-wrapper d-block">
            <h1 className="page-title mb-24">{t('Common_Session')}</h1>
            <div className="p-0">
                <Row gutter={16}>
                    <Col span={24} className="d-flex">
                        <InputSearch
                            style={{ maxWidth: 240 }}
                            onSearch={value => keywordChangeHandler(value)}
                            placeholder={'名称で検索'}
                        />
                        <TdtSelect
                            value={company}
                            size="large"
                            className="fs-14 ml-8"
                            style={{ width: 240 }}
                            defaultValue={null}
                            onChange={value => {
                                setCompany(value);
                                handleFilterByCompany(value);
                            }}
                        >
                            <Select.Option value={null}>{t('All companies')}</Select.Option>
                            {master.companies
                                .filter(company => company.sessions_count > 0)
                                .map(company => {
                                    return (
                                        <Select.Option key={company.id} value={company.id}>
                                            {company.name}
                                        </Select.Option>
                                    );
                                })}
                        </TdtSelect>
                    </Col>
                </Row>
            </div>
            <DataTable
                className="mt-16 collapse-table"
                columns={columns}
                hideColumns={commonSessionState.hideColumns}
                data={commonSessionState.data}
                loading={commonSessionState.loading}
                pagination={commonSessionState.pagination}
                filters={commonSessionState.filters}
                sorter={commonSessionState.sorter}
                onTableChange={(pagination, filter, sorter) => {
                    getCommonSessions(pagination, commonSessionState.filters, sorter);
                }}
                onRow={record => {
                    return {
                        onClick: () => {
                            history.push(Routes.private.ADMIN_COMMON_SESSIONS_DETAIL.path.replace(':id', record.id));
                        },
                    };
                }}
                noDataText={'登録されている企業はありません'}
            />
            <DeleteCommonSessionModal
                onClose={() => closeModal()}
                onUpdate={() => deleteCommonSession(modalVisible.record.id)}
                visible={modalVisible.delete}
            />
            <CloneCommonSessionModal
                onClose={() => {
                    closeModal();
                }}
                visible={modalVisible.clone}
                commonSession={modalVisible.record}
            />
            <ProvideCompaniesModal
                onClose={() => closeModal()}
                visible={modalVisible.provide}
                record={modalVisible.record}
            />
            <FloatBar>
                <Button
                    icon={<AddIcon />}
                    className="fw-b"
                    onClick={() => history.push(Routes.private.ADMIN_COMMON_SESSIONS_CREATE.path)}
                >
                    {t('Register new')}
                </Button>
            </FloatBar>
        </div>
    );
};

export default connect(mapState, mapDispatch)(CommonSession);

CommonSession.propTypes = {
    commonSessionState: PropTypes.shape(
        Object.assign({}, BaseStatePropShape, {
            data: PropTypes.arrayOf(CommonSessionPropTypes).isRequired,
        })
    ),
    getData: PropTypes.func.isRequired,
    setSorter: PropTypes.func.isRequired,
    setFilters: PropTypes.func.isRequired,
};
