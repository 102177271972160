const RandomCompanyCodeEnum = Object.freeze({
    SIZE_5: { value: 5, text: '5桁' },
    SIZE_6: { value: 6, text: '6桁' },
    SIZE_7: { value: 7, text: '7桁' },
    SIZE_8: { value: 8, text: '8桁' },
    SIZE_9: { value: 9, text: '9桁' },
    SIZE_10: { value: 10, text: '10桁' },
    // SIZE_20: { value: 20, text: '20桁' },
});

export default RandomCompanyCodeEnum;
