import { isNullOrUndefined } from 'helpers/common.helper';
import { momentToStr } from 'helpers/date.helper';

export const valuesToFormData = (formData, values) => {
    Object.keys(values).map(k => {
        if (values[k]) {
            if (Array.isArray(values[k])) {
                const details = values[k];
                details.map((detail, i) => {
                    Object.keys(detail).map(d => {
                        !isNullOrUndefined(detail[d]) && formData.append(`${k}[${i}][${d}]`, detail[d]);
                    });
                });
            } else if (['date', 'delivery_date'].includes(k)) {
                !!values[k] &&
                    formData.append(k, typeof values[k].format === 'function' ? momentToStr(values[k]) : values[k]);
            } else {
                formData.append(k, values[k]);
            }
        }
    });

    return formData;
};
