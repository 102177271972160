import React from 'react';
import { Select, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/DataTable';
import { getEnumText } from 'helpers/enum.helper';
import SendEmailStatus from 'enums/send_email_status';
import TdtSelect from 'components/TdtSelect';
import { connect } from 'react-redux';
import {
    deliveryResultSelector,
    setFilterByCompany,
    setFilterByDeliveryStatus,
} from 'pages/admin/email/detail_screen/email_detail.slice';
import { percentToFixed } from 'helpers/number_format.helper';
import { dateToStr } from 'helpers/date.helper';

const mapState = state => ({
    filters: state.admin.emailDetail.filters,
    ...deliveryResultSelector(state.admin.emailDetail),
});
const mapDispatch = { setFilterByCompany, setFilterByDeliveryStatus };

const DeliveryResultContainer = ({
    emailHtml,
    filters,
    setFilterByCompany,
    setFilterByDeliveryStatus,
    companies,
    // deliveryResults,
    filteredDeliveryResults,
    countSuccessDelivery,
    countPermanentError,
    countTemporaryError,
    countUnknownError,
}) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: 'メールアドレス',
            dataIndex: 'email',
            width: 288,
            sorter: (a, b) => {
                return a.email.localeCompare(b.email);
            },
        },
        {
            title: '企業名',
            dataIndex: 'company_name',
            width: 144,
            render: name => (name ? name : '-'),
            sorter: (a, b) => {
                const a1 = a.company_name ? a.company_name : '-';
                const b1 = b.company_name ? b.company_name : '-';
                return a1.localeCompare(b1);
            },
        },
        {
            title: '氏名',
            dataIndex: 'full_name',
            width: 120,
            render: (full_name, r) => (full_name ? full_name : r.user ? r.user.full_name : '-'),
            sorter: (a, b) => {
                const a1 = a.full_name ? a.full_name : '-';
                const b1 = b.full_name ? b.full_name : '-';
                return a1.localeCompare(b1);
            },
        },
        {
            title: 'ステータス',
            dataIndex: 'status',
            width: 120,
            render: v =>
                (v && [SendEmailStatus.SUCCESSFUL_DELIVERY.value, SendEmailStatus.PERMANENT_ERROR.value, SendEmailStatus.TEMPORARY_ERROR.value, SendEmailStatus.UNKNOWN_ERROR.value].includes(v)) ? (
                    <span className={`${v !== SendEmailStatus.SUCCESSFUL_DELIVERY.value ? 'pink-color' : ''}`}>
                        {t(`enum:${getEnumText(SendEmailStatus, v)}`)}
                    </span>
                ) : (
                    '-'
                ),
            sorter: (a, b) => {
                const a1 = a.status ? t(`enum:${getEnumText(SendEmailStatus, a.status)}`) : '-';
                const b1 = b.status ? t(`enum:${getEnumText(SendEmailStatus, b.status)}`) : '-';
                return a1.localeCompare(b1);
            },
        },
    ];

    return (
        <div className="bg-gray-5 flex-1">
            <div className="session-edit-wrapper session-detail-cards">
                <div className="session-detail-card">
                    <div className="session-detail-card--header">
                        <Typography className="fs-16 fw-700">{'サマリー'}</Typography>
                    </div>
                    <div className="session-detail-card--body">
                        <div className="d-flex ">
                            <div>
                                <Typography className="fs-14 fw-700 color-blg-60">{'総合配信数'}</Typography>
                                <div className="d-flex justify-content-space-between mt-8 align-items-baseline ">
                                    <Typography className="ml-16 fw-100 health-data-numbers color-blg-60">
                                        {emailHtml.number_of_deliveries}
                                    </Typography>
                                    <span className="color-blg-60 fw-400 fs-14 ml-8 ">{'件'}</span>
                                </div>
                            </div>
                            <div className="ml-48">
                                <Typography className="fs-14 fw-700 color-blg-60">{'内訳'}</Typography>
                                <div className="d-flex justify-content-space-between mt-8 ">
                                    <div className="d-flex align-items-start mr-16 white-space-nowrap fw-400 fs-14">
                                        <div className="d-flex align-items-center">
                                            <span>{'配信成功：'}</span>
                                            <span className=" fw-700 fs-18">{countSuccessDelivery}</span>
                                            <div className="ml-4 text-gray-color">
                                                {'件('}
                                                <span>
                                                {percentToFixed(
                                                    countSuccessDelivery,
                                                    emailHtml.number_of_deliveries
                                                )}
                                            </span>
                                                {'%)'}
                                            </div>
                                        </div>
                                        <div className="ver-line-slim ml-8 mr-8" />
                                        <div className="">
                                            <div className="d-flex align-items-center">
                                                <span>{'永久的なエラー：'}</span>
                                                <span className=" fw-700 fs-18 pink-color">{countPermanentError}</span>
                                                <div className="ml-4 text-gray-color">
                                                    {'件'}
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <span>{'一時的なエラー：'}</span>
                                                <span className=" fw-700 fs-18 pink-color">{countTemporaryError}</span>
                                                <div className="ml-4 text-gray-color">
                                                    {'件'}
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <span>{'システムエラー：'}</span>
                                                <span className=" fw-700 fs-18 pink-color">{countUnknownError}</span>
                                                <div className="ml-4 text-gray-color">
                                                    {'件'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ml-24">
                                <Typography className="fs-14 fw-700 color-blg-60">{'送信日時'}</Typography>
                                <div className="d-flex justify-content-space-between mt-8 ">
                                    <div>
                                        <div className="d-flex align-items-center mr-16 white-space-nowrap fw-400 fs-14">
                                            <span className="mt-4">{dateToStr(`${emailHtml.delivery_date} ${emailHtml.delivery_time}`, 'YYYY/M/DD(ddd) HH:mm')}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-16">
                            <Typography className="fs-14 fw-700 mt-32 color-blg-60">{'スコア別サマリー'}</Typography>
                            <div className="mt-16 ant-select-arrow-interview">
                                <TdtSelect
                                    size="large"
                                    className=" fw-400 fs-16 bg-gray-5 interview-sheet-select "
                                    style={{ width: 182, height: 41 }}
                                    defaultValue={null}
                                    value={filters.companyId}
                                    onChange={v => setFilterByCompany(v)}
                                >
                                    <Select.Option value={null}>{'全ての企業'}</Select.Option>
                                    {companies.map(company => (
                                        <Select.Option key={company.id} value={company.id}>
                                            {company.name}
                                        </Select.Option>
                                    ))}
                                </TdtSelect>
                                <TdtSelect
                                    size="large"
                                    className=" ml-8 fw-400 fs-16 bg-gray-5 interview-sheet-select "
                                    style={{ height: 41 }}
                                    defaultValue={null}
                                    value={filters.deliveryStatus}
                                    onChange={v => setFilterByDeliveryStatus(v)}
                                >
                                    <Select.Option value={null}>{'全てのステータス'}</Select.Option>
                                    {Object.values(SendEmailStatus).map(o => (
                                        <Select.Option key={o.value} value={o.value}>
                                            {t(`enum:${getEnumText(SendEmailStatus, o.value)}`)}
                                        </Select.Option>
                                    ))}
                                </TdtSelect>
                            </div>
                        </div>
                        <DataTable
                            className="mt-16 fw-400 collapse-table"
                            columns={columns}
                            data={filteredDeliveryResults}
                            noDataText={t('No emails have been sent.')}
                            onTableChange={() => {}}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect(mapState, mapDispatch)(DeliveryResultContainer);
