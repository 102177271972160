import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DataTable from 'components/DataTable';
import { getMasterData } from 'pages/admin/feature/common_session.slice';
import { Button } from 'antd';

const mapState = state => ({
    master: state.admin.commonSession.master,
});
const mapDispatch = { getMasterData };

const CompaniesTable = ({ master, getMasterData, selectedCompanyIds, setSelectedCompanyIds }) => {
    const { t } = useTranslation();

    const columns = [
        {
            title: t('Company name'),
            dataIndex: 'name',
            width: 412,
            sorter: (a, b) => {
                return a.name.localeCompare(b.name);
            },
        },
        {
            title: t('Number of employees'),
            dataIndex: 'number_of_employees',
            className: 'text-align-right',
            render: total => total || 0,
            width: 120,
            sorter: (a, b) => {
                let a0 = a.number_of_employees || 0;
                let b0 = b.number_of_employees || 0;
                return a0 > b0 ? 1 : a0 < b0 ? -1 : 0;
            },
        },
    ];

    useEffect(() => {
        if (master.loading) {
            getMasterData();
        }
    }, []);

    const onSelectChange = newSelectedRowKeys => {
        setSelectedCompanyIds(newSelectedRowKeys);
    };

    const onRowClick = key => {
        if (!selectedCompanyIds.includes(key)) {
            setSelectedCompanyIds([...selectedCompanyIds, key]);
        } else {
            const newSelectedRowKeys = [...selectedCompanyIds];
            newSelectedRowKeys.splice(newSelectedRowKeys.indexOf(key), 1);
            setSelectedCompanyIds(newSelectedRowKeys);
        }
    };

    const isAllSelected = selectedCompanyIds.length === master.companies.length;
    const toggleSelectAll = () => {
        if (isAllSelected) {
            setSelectedCompanyIds([]);
        } else {
            setSelectedCompanyIds(master.companies.map(c => c.id));
        }
    };

    return (
        <div className="position-relative">
            <DataTable
                className="mt-16 collapse-table mb-32"
                columns={columns}
                data={master.companies}
                loading={master.loading}
                noDataText={' '}
                customRowSelection={{
                    selectedRowKeys: selectedCompanyIds,
                    onChange: onSelectChange,
                    type: 'checkbox',
                }}
                yScroll={{ y: 302 }}
                onTableChange={() => {}}
                onRow={record => ({
                    onClick: () => onRowClick(record.id),
                })}
            />
            {selectedCompanyIds.length > 0 && (
                <div className="table-info-selected-items">
                    <div className="d-flex align-items-center">
                        <div className="fs-12-15 mr-8">
                            {!isAllSelected ? (
                                <>
                                    {' '}
                                    <span className="fw-b">{selectedCompanyIds.length}</span>
                                    {' 件の企業が選択されています。'}
                                </>
                            ) : (
                                <>{'すべての企業が選択済みです'}</>
                            )}
                        </div>
                        <div className="fs-12-15">
                            <Button type="text" className="fw-b fs-12-15" onClick={() => toggleSelectAll()}>
                                {!isAllSelected ? '他ページを含むすべての企業を選択' : 'すべての選択を解除'}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default connect(mapState, mapDispatch)(CompaniesTable);
