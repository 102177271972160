import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Input, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';

const UpdateEmailModal = ({ visible, onClose, initialValue, onUpdate }) => {
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
        setValue(initialValue);
    }, [initialValue]);

    const { t } = useTranslation();
    return (
        <Modal
            title={t('Edit email')}
            visible={visible}
            width={400}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
        >
            <Input value={value} onChange={e => setValue(e.target.value)} />
            <Button
                type="primary"
                block
                className="mt-16"
                onClick={() => {
                    onUpdate && onUpdate(value);
                    onClose();
                }}
            >
                {t('Save')}
            </Button>
        </Modal>
    );
};

UpdateEmailModal.propTypes = {
    visible: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    initialValue: PropTypes.string,
    onUpdate: PropTypes.func.isRequired,
};

export default UpdateEmailModal;
