import React from 'react';

export const HMButton = ({ detail }) => {

    const style_wrapper = 'border-radius: 70px; background: #E63E55;';
    const style_button = 'border-radius: 70px; background: #E63E55; font-family: Noto Sans JP; font-size: 16px; line-height: 24px; font-weight: 700; text-decoration: none; padding: 10px 25px; color: #ffffff; display: block;';


    const raw = `<tr>
    <td style="padding: 12px 16px 24px;">
        <table align="center" role="presentation" cellspacing="0" cellpadding="0" border="0" style="border-collapse:separate;width:100%;line-height:100%;margin: auto;">
            <tr>
                <td align="center" class="button-td button-td-primary" style="${style_wrapper}">
                    <a class="button-a button-a-primary" href="${detail['media_url'] || '#'}" target="_blank" style="${style_button}">${detail['content']}</a>
                </td>
            </tr>
        </table>
    </td>
</tr>
`;

    if (!detail['content']) {
        return <></>
    }

    return <tr dangerouslySetInnerHTML={{ __html: raw }} />;
};
