import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Button, Col, DatePicker, Row, Typography } from 'antd';
import { APP_URLS } from 'constants/url.constant';
import HealthTypeEnum from 'enums/health_type.enum';
import { fullDateFormat } from 'helpers/date.helper';
import api from 'helpers/api.helper';
import { showNormal } from 'helpers/notification.helper';
import BackBtnWithTitle from 'components/BackBtnWithTitle';
import FloatBar from 'components/FloatBar';
import { AddIcon, DeleteIcon, EditIcon } from 'components/SvgIcon';
import RangeChart from 'components/chart/RangeChart';
import DeleteDataModal from 'pages/client/health_data/DeleteDataModal';
import CreateHeartBeatModal from 'pages/client/health_data/CreateHeartBeatModal';

import DateChangeComponent from 'pages/client/health_data/tabs/date_time/DateChangeComponent';

import {
    chartDataSelector,
    getHeartRates,
    goNext,
    goPrev,
    goToday,
    heartRatesFilterSelector,
    setDisplayType,
    setMonthFilter,
} from 'pages/client/health_data/charts/heart_rate.slice';
import { defaultConfig } from 'helpers/chart.helper';
import LineChart from 'components/chart/LineChart';
import NoData from 'components/NoData';
import ReservationStatus from 'enums/reservation_status.enum';

const mapState = state => ({
    startDate: state.client.healthDataHeartRate.startDate,
    endDate: state.client.healthDataHeartRate.endDate,
    displayType: state.client.healthDataHeartRate.displayType,
    monthFilter: state.client.healthDataHeartRate.monthFilter,
    hasData: state.client.healthDataHeartRate.heartRates.length > 0,
    heartRates: heartRatesFilterSelector(state.client.healthDataHeartRate),
    chartData: chartDataSelector(state.client.healthDataHeartRate),
    user: state.auth.user,
});

const mapDispatch = { goPrev, goNext, goToday, setDisplayType, setMonthFilter, getHeartRates };

const xLabelRender = date => moment(date).format('MM/DD');
const chartConfig = { rectWidth: 16 };
const chartConfigMonth = { rectWidth: 8 };

const HearRateChart = ({
    startDate,
    endDate,
    displayType,
    monthFilter,
    hasData,
    heartRates,
    chartData,
    goPrev,
    goNext,
    goToday,
    setDisplayType,
    setMonthFilter,
    getHeartRates,
    user,
}) => {
    const { t } = useTranslation();

    const weekFormat = 'YYYY年MM月';
    const isStateEnd = user.employee_company[0].state === ReservationStatus.END.value;
    const [value, setValue] = useState({});
    const [modalVisible, setModalVisible] = useState({
        heartBeat: false,
        deleteHearBeat: false,
        id: null,
    });
    const [modalCreateVisible, setModalCreateVisible] = useState({
        createHeartBeat: false,
    });

    const closeCreateModal = () => setModalCreateVisible({ createHeartBeat: false });
    const showCreateModal = () => setModalCreateVisible({ createHeartBeat: true });
    const showUpdateHeartBeatModal = id => setModalVisible({ heartBeat: true, deleteHearBeat: false, id });
    const showDeleteModal = id => setModalVisible({ deleteHearBeat: true, heartBeat: false, id });
    const closeModal = () => setModalVisible({ heartBeat: false, deleteHearBeat: false, id: null });

    const updateHeartBeatData = async value => {
        const response = await api.put(APP_URLS.CLIENT_HEALTH_DATA_UPDATE.replace(':id', modalVisible.id), {
            created_at: value.created_at,
            value: value.heart_beat,
            ext_value: value.ext_heart_beat,
        });
        if (response) {
            showNormal('', t('message:Data has been saved'), 3);
            getHeartRates();
        } else {
            showNormal('', t('message:Invalid data'), 3);
        }
    };

    const deleteHeartBeatData = async () => {
        const response = await api.delete(APP_URLS.CLIENT_HEALTH_DATA_DELETE.replace(':id', modalVisible.id));
        if (response) {
            showNormal('', t('message:Data has been deleted'), 3);
            getHeartRates();
        } else {
            showNormal('', t('message:Cannot delete data'), 3);
        }
    };

    useEffect(() => {
        getHeartRates();
    }, [startDate, endDate]);

    const creatEntryData = async (values, type) => {
        const response = await api.post(APP_URLS.CLIENT_HEALTH_DATA_CREATE, {
            value: values.heart_beat,
            ext_value: values.ext_heart_beat,
            created_at: values.created_at,
            health_images: [{ images: values.images, category: values.category }],
            // category: values.category,
            type,
        });
        if (response) {
            showNormal('', t('message:Data has been saved'), 3);
            getHeartRates();
        } else {
            showNormal('', t('message:Invalid data'), 3);
        }
    };

    const tooltipRender = useCallback(
        rect => {
            const tooltipWidth = 110;
            const x = rect.x - tooltipWidth / 2;
            const y = rect.y + rect.height + 8;
            return (
                <>
                    <rect x={x} y={y} width={tooltipWidth} height={52} rx={4} ry={4} fill="#ffffff" stroke="#D0D0D0" />
                    <text x={x + 8} y={y + 24} fill={defaultConfig.textColor} fontSize={12} alignmentBaseline="top">
                        <tspan fontSize={16} fontWeight={500}>
                            {Math.round(rect.value[0])}-{Math.round(rect.value[1])} 拍/分
                        </tspan>
                    </text>
                    <text x={x + 8} y={y + 42} fill={defaultConfig.textColor} fontSize={12} alignmentBaseline="top">
                        {moment(rect.name).format(displayType === 'year' ? 'M月' : 'M月D日(dd)')}
                    </text>
                </>
            );
        },
        [displayType]
    );

    return (
        <>
            <div className="mb-62">
                <BackBtnWithTitle title="心拍数" />
                <DateChangeComponent
                    startDate={startDate}
                    endDate={endDate}
                    displayType={displayType}
                    goPrev={goPrev}
                    goNext={goNext}
                    goToday={goToday}
                    setDisplayType={setDisplayType}
                />

                <div className="chart-wrapper">
                    <RangeChart
                        className="health-data-chart"
                        data={chartData}
                        config={displayType === 'month' ? chartConfigMonth : chartConfig}
                        width={1000}
                        height={320}
                        yStepSize={24}
                        yMin={!hasData ? 30 : null}
                        yCount={!hasData ? 5 : null}
                        xLabelRender={xLabelRender}
                        tooltipRender={tooltipRender}
                    />
                </div>

                <Row justify="space-between" align="bottom">
                    <Col className="mt-48">
                        <Typography className="fw-b data-title">{t('Data details')}</Typography>
                    </Col>
                    <Col>
                        {displayType === 'year' && (
                            <DatePicker
                                className="chart-month-picker"
                                value={monthFilter}
                                picker="month"
                                format={weekFormat}
                                allowClear={false}
                                onChange={value => {
                                    setMonthFilter(value);
                                }}
                                inputReadOnly={true}
                            />
                        )}
                    </Col>
                </Row>
                {heartRates.length === 0 ? (
                    <NoData />
                ) : (
                    heartRates.map(h => {
                        return (
                            <Row
                                key={h.id}
                                className="health-data-row data-details"
                                justify="space-between"
                                align="middle"
                            >
                                <Col className="health-data-col-center" xs={12} xl={4}>
                                    <Typography className="data-date-text">{fullDateFormat(h.created_at)}</Typography>
                                    <Typography className="health-data-value-none-pc">{h.value}</Typography>
                                </Col>
                                <Col xs={4} xl={4} className="flex">
                                    <Typography className="health-data-value-pc">{h.value}</Typography>
                                    {!isStateEnd && (
                                        <Button
                                            icon={<EditIcon />}
                                            type="link"
                                            className="edit-btn-pc pl-6"
                                            onClick={() => {
                                                setValue({
                                                    heart_beat: h.value,
                                                    ext_heart_beat: h.ext_value,
                                                    created_at: moment.utc(h.created_at),
                                                });
                                                showUpdateHeartBeatModal(h.id);
                                            }}
                                        />
                                    )}
                                </Col>
                                <Col className="health-data-col-flex-end" xs={4} xl={14}>
                                    {!isStateEnd && (
                                        <Button
                                            icon={<DeleteIcon size={30} />}
                                            type="link"
                                            className="btn-pink btn-delete-health-data"
                                            onClick={() => showDeleteModal(h.id)}
                                        />
                                    )}
                                    {!isStateEnd && (
                                        <Button
                                            icon={<EditIcon />}
                                            type="link"
                                            className="edit-btn-mb pr-12"
                                            onClick={() => {
                                                setValue({
                                                    heart_beat: h.value,
                                                    ext_heart_beat: h.ext_value,
                                                    created_at: moment.utc(h.created_at),
                                                });
                                                showUpdateHeartBeatModal(h.id);
                                            }}
                                        />
                                    )}
                                </Col>
                            </Row>
                        );
                    })
                )}

                <DeleteDataModal
                    visible={modalVisible.deleteHearBeat}
                    onClose={() => closeModal()}
                    initialValue={{}}
                    onUpdate={async () => {
                        await deleteHeartBeatData();
                        closeModal();
                    }}
                />

                <CreateHeartBeatModal
                    visible={modalVisible.heartBeat}
                    onClose={() => closeModal()}
                    value={value}
                    setValue={setValue}
                    onUpdate={async value => {
                        await updateHeartBeatData(value);
                    }}
                />
            </div>
            <FloatBar>
                {!isStateEnd && (
                    <Button
                        icon={<AddIcon />}
                        className="fw-b"
                        type="primary"
                        onClick={() => {
                            setValue({ created_at: moment.utc() });
                            showCreateModal();
                        }}
                    >
                        {t('Data entry')}
                    </Button>
                )}
                <CreateHeartBeatModal
                    visible={modalCreateVisible.createHeartBeat}
                    onClose={() => {
                        closeCreateModal();
                    }}
                    onUpdate={values => {
                        creatEntryData(values, HealthTypeEnum.HEART_BEAT.value).then(() => {});
                    }}
                    value={value}
                    setValue={setValue}
                />
            </FloatBar>
        </>
    );
};

export default connect(mapState, mapDispatch)(HearRateChart);

HearRateChart.propTypes = {
    tab: PropTypes.string,
    url: PropTypes.string,
};
