import { createSlice } from '@reduxjs/toolkit';
import api from 'helpers/api.helper';
import { APP_URLS } from 'constants/url.constant';

const name = 'programCreate';

const initialState = {
    name: '',
    name_mngt: '',
    description: '',
    interview_sheet_id: '',
    first_interview_sheet_id: '',
    questions: {
        byId: {},
        order: [],
    },
    options: {
        byId: {},
    },
    questionOptions: {},
};

const programCreateSlice = createSlice({
    name,
    initialState: initialState,
    reducers: {
        reset(state) {
            state.name = initialState.name;
            state.name_mngt = initialState.name_mngt;
            state.description = initialState.description;
            state.interview_sheet_id = initialState.interview_sheet_id;
            state.first_interview_sheet_id = initialState.first_interview_sheet_id;
            state.questions = initialState.questions;
            state.options = initialState.options;
            state.questionOptions = initialState.questionOptions;
        },
        setName(state, action) {
            state.name = action.payload;
        },
        setNameMngt(state, action) {
            state.name_mngt = action.payload;
        },
        setDescription(state, action) {
            state.description = action.payload;
        },
        //
        // convertDataToState(state, action) {
        //     const {
        //         name,
        //         name_mngt,
        //         description,
        //         interview_sheet_id,
        //         first_interview_sheet_id,
        //         questions,
        //     } = action.payload;
        //     state.name = name;
        //     state.name_mngt = name_mngt;
        //     state.description = description;
        //     state.interview_sheet_id = interview_sheet_id;
        //     state.first_interview_sheet_id = first_interview_sheet_id;
        //
        // },
    },
});
export const { reset, convertDataToState, setName, setNameMngt, setDescription } = programCreateSlice.actions;

export default programCreateSlice.reducer;
export const infoSelector = state => ({
    name: state.name,
    name_mngt: state.name_mngt,
    description: state.description,
    interview_sheet_id: state.interview_sheet_id,
    first_interview_sheet_id: state.first_interview_sheet_id,
});

export const getProgram = id => async dispatch => {
    const data = await api.get(APP_URLS.ADMIN_PROGRAM_DETAIL.replace(':id', id));
    if (data) {
        dispatch(convertDataToState(data));
    }
};
