import React from 'react';
import { Form, Typography } from 'antd';
import { parseQuestionnaire } from 'helpers/questionnaire.helper';
import QuestionTypeEnum from 'enums/question_type.enum';
import DailyLifeEvents from 'components/questions/input-event/DailyLifeEvents';
import ResultDailyLife from 'pages/admin/interview_sheet/detail_screen/common/aggregate_results/ResultDailyLife';
import SingleSelectQuestion from 'components/questions/SingleSelectQuestion';

const AnswerListWithScore = ({ questionnaire, hideScore = false, hideSkipped = false, resultDailyLife = false }) => {
    const { interview_sheet, json_answers } = questionnaire;

    const result = parseQuestionnaire({ questions: interview_sheet.questions, listAnswers: JSON.parse(json_answers) });

    const parseCategories = jsonCategories => {
        if (!jsonCategories) {
            return [];
        }
        const arrCategories = JSON.parse(jsonCategories);
        return arrCategories.map(category => {
            let tmp = { ...category, children: [] };

            for (let i = category.start; i <= category.end; i++) {
                if (i < result.questions.length) {
                    tmp.children.push(result.questions[i].id);
                }
            }

            return tmp;
        });
    };
    const categories = interview_sheet ? parseCategories(interview_sheet.json_categories) : [];

    const renderQuestion = (question, no) => {
        const { id, answers, isRequired, score, useScore, value } = question;

        return (
            <div key={question.id || no} className="mb-48">
                <Typography className="fs-12 fw-500 color-blg-80">{`Q${no}.`}</Typography>
                <div className="fs-16 fw-500 mb-8 d-flex justify-content-space-between">
                    <span>
                        {value}
                        {!!isRequired && <span className="pink-color">*</span>}
                    </span>
                    {!hideScore && !!score && (
                        <span className="fs-14 lh-24">
                            {'小計:'}
                            <span className="fw-700 ml-4 mr-4">{score}</span>
                            {'点'}
                        </span>
                    )}
                </div>
                <div className="fs-14">
                    {answers.map((o, index) => (
                        <React.Fragment key={o.id || index}>{answeredRow(o, index)}</React.Fragment>
                    ))}
                </div>
            </div>
        );
    };

    const answeredRow = (o, index) => {
        // case daily life
        if (o.type === QuestionTypeEnum.DAILY_LIFE.value) {
            if (resultDailyLife) {
                return (
                    <div key={o.id || index} className="mb-8 d-flex justify-content-space-between white-space-pre-line">
                        <ResultDailyLife value={o.value} />
                    </div>
                );
            }

            return (
                <div key={o.id || index} className="mb-8 d-flex justify-content-space-between white-space-pre-line">
                    <DailyLifeEvents value={o.value} isPreview={true} />
                </div>
            );
        }

        return (
            <div key={o.id || index} className="mb-8 d-flex justify-content-space-between white-space-pre-line">
                {o.otherValue ? <span>{`${o.value}: ${o.otherValue}`}</span> : <span>{o.value}</span>}
                {!hideScore && !!o.score && (
                    <span className="fs-12">
                        <span className="fw-700 mr-4">{o.score}</span>
                        {'点'}
                    </span>
                )}
            </div>
        );
    };

    let no = 1;

    return (
        <>
            {categories.length > 0 ? (
                <div>
                    {categories.map((category, index) => {
                        if (index === 0) {
                            no = 1;
                        }
                        return (
                            <div key={`rct-${index}`} className="mt-24">
                                <Typography
                                    className="fs-16 fw-700 bg-gray-5 mb-16"
                                    style={{ paddingLeft: 16, paddingTop: 8, paddingBottom: 8 }}
                                >
                                    {category.title}
                                </Typography>
                                {category.children.map(questionId =>
                                    renderQuestion(
                                        result.questions.find(q => q.id === questionId),
                                        no++
                                    )
                                )}
                            </div>
                        );
                    })}
                </div>
            ) : (
                <>
                    {result.questions
                        .filter(question => (hideSkipped ? question.answers && question.answers.length > 0 : true))
                        .map((question, index) => renderQuestion(question, index + 1))}
                </>
            )}
        </>
    );
};

export default AnswerListWithScore;
