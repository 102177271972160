import React, { useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import SidebarClient from 'layouts/SidebarClient';
import Routes from 'routes';
import NotFound from 'pages/error/NotFound';
import PropTypes from 'prop-types';
import { Button, Col, Drawer, Row } from 'antd';
import { CloseMenuIcon, LogoTextIcon, MenuIcon } from 'components/SvgIcon';
import { connect } from 'react-redux';

const mapState = state => ({
    count: state.auth.count,
});

const LayoutClient = ({ count }) => {
    const [visible, setVisible] = useState(false);
    const [isMenuResponsive] = useState(true);

    const badges = {
        INTERVIEW_SHEET: count.answer,
        MESSAGE: count.message,
    };

    return (
        <div id="main-layout">
            <SidebarClient />
            <nav>
                <Drawer
                    placement="left"
                    getContainer={true}
                    closeIcon={<CloseMenuIcon size={14} />}
                    onClick={() => setVisible(false)}
                    onClose={() => setVisible(false)}
                    visible={visible}
                >
                    <SidebarClient isMenuResponsive={isMenuResponsive} />
                </Drawer>
                <div className="responsive-title">
                    <Button
                        className="menu-icon"
                        type="link"
                        icon={
                            <MenuIcon
                                className={(badges.INTERVIEW_SHEET > 0 || badges.MESSAGE > 0) && 'dot-count'}
                                size={17}
                                width={'18px'}
                                height={'12px'}
                            />
                        }
                        onClick={() => setVisible(true)}
                    />
                    <LogoTextIcon className="logo-text" />
                </div>
            </nav>
            <div id="container">
                <div className="tdt-content">
                    <Switch>
                        {Object.keys(Routes.private).map(key => {
                            const r = Routes.private[key];
                            let component = r.component;
                            return <Route key={key} path={r.path} exact component={component} />;
                        })}
                        <Route path="*">
                            <NotFound />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
};

export default connect(mapState, null)(LayoutClient);

LayoutClient.propTypes = {
    verified: PropTypes.bool.isRequired,
};
