import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import { dateToStr } from 'helpers/date.helper';
import RenderHTMLMail from 'pages/admin/common/page-elements/RenderHTMLMail';

const MessageNewPreviewModal = ({ visible, onClose, record, isProgram = false, onUpdate, note = '' }) => {
    const { t } = useTranslation();

    if (!record) {
        return <></>;
    }

    const renderModalTitle = record => {
        if (!record) return '';
        return (
            <span className="fs-14-21">
                <span className="fw-b">{record.name}</span>
                {!isProgram ? (
                    <>
                        {'　'}
                        <span className="fw-400">{`開催日時 : ${dateToStr(record.date, 'YYYY/M/D(ddd)')} ${
                            record.start_time
                        }-${record.end_time}`}</span>
                    </>
                ) : (
                    <></>
                )}
            </span>
        );
    };

    return (
        <Modal
            title={renderModalTitle(record)}
            visible={visible}
            width={823}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
            //wrapClassName="preview-modal-container"
            footer={[
                <Button
                    key="send"
                    className="fw-b btn-w120 text-align-center"
                    onClick={() => onUpdate && onUpdate(record)}
                    type="primary"
                >
                    <span className="ml-auto mr-auto">{t('Send')}</span>
                </Button>,
            ]}
        >
            <div className="render-page-container html-mail_wrapper">
                {note ? (
                    <div className="preview-html-note-wrapper">
                        <div className="preview-html-note">{note}</div>
                    </div>
                ) : (
                    <></>
                )}
                <RenderHTMLMail info={record} isProgram={isProgram} details={isProgram ? record.program_details : record.seminar_details} />
            </div>
        </Modal>
    );
};

export default MessageNewPreviewModal;
