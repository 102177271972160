import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import { CloseIcon } from 'components/SvgIcon';
import TabList from 'components/TabList';
import QuestionnairePreview from 'pages/admin/interview_sheet/preview/QuestionnairePreview';
import ScreenAfterAnswer from 'pages/admin/interview_sheet/preview/ScreenAfterAnswer';

const InterviewSheetPreviewModal = ({ visible, onClose, state }) => {
    const { t } = useTranslation();

    const { infoByScores } = state;
    const orderIdsHasRev = infoByScores.order.filter(k => infoByScores.byId[k] && infoByScores.byId[k].rev);

    const tabList = [
        {
            key: 'interview_sheet',
            title: t('Interview sheet'),
            container: (
                <>
                    <div className="render-page-container pt-24 pl-32 pb-24 pr-32 white-space-pre-line">
                        <QuestionnairePreview state={state} />
                    </div>
                </>
            ),
        },
        {
            key: 'result-display',
            title: '回答後画面',
            container: (
                <>
                    <div className="render-page-container pt-24 pl-32 pb-24 pr-32 white-space-pre-line">
                        <ScreenAfterAnswer state={state} />
                    </div>
                </>
            ),
            disabled: orderIdsHasRev.length < 1,
        },
    ];

    return (
        <Modal
            title={t('Preview')}
            visible={visible}
            width={823}
            footer={null}
            closeIcon={<CloseIcon />}
            onCancel={() => onClose()}
            bodyStyle={{ padding: 0 }}
        >
            <TabList tabs={tabList} animated={false} defaultActiveKey="interview_sheet" />
        </Modal>
    );
};

export default InterviewSheetPreviewModal;

InterviewSheetPreviewModal.propTypes = {
    info: PropTypes.object,
    byId: PropTypes.object,
    order: PropTypes.array,
};
