import React from 'react';
import Axis from 'components/chart/Axis';
import { defaultConfig, getMinMax, getYAxisInfo } from 'helpers/chart.helper';

const ColumnChart = ({
    width = 1000,
    height = 320,
    data,
    config,
    yMin,
    yStepSize,
    yCount,
    yLabelRender,
    xLabelRender,
    tooltipRender,
    className,
}) => {
    let chartConfig;
    if (config) {
        chartConfig = { ...defaultConfig, ...config };
    } else {
        chartConfig = defaultConfig;
    }
    const { max: maxValue } = getMinMax(data.values);
    const minValue = 0 + yStepSize;

    // Y Axis
    const yInfo = getYAxisInfo(minValue, maxValue, yMin, yStepSize, yCount);
    const yStepHeight = (height - chartConfig.xLabelHeight) / yInfo.count;

    // X Axis
    const xStepWidth = (width - chartConfig.yLabelWidth - chartConfig.xPaddingRight) / data.names.length;

    // Reacts - bar
    let rects = [];
    for (let i = 0; i < data.values.length && i < data.names.length; i++) {
        const value = data.values[i];
        if (value === null || typeof value === 'undefined') {
            continue;
        }

        const x = (i + 0.5) * xStepWidth + chartConfig.yLabelWidth;
        const name = data.names[i];
        const y = height - chartConfig.xLabelHeight - ((value[0] - yInfo.min) / yInfo.size) * yStepHeight;
        const rectHeight = (value[0] / yInfo.size) * yStepHeight;
        rects.push({
            x,
            y,
            name,
            height: rectHeight,
            value,
        });
    }

    return (
        <svg viewBox={`0 0 ${width} ${height}`} className={className}>
            <Axis
                width={width - chartConfig.xPaddingRight}
                height={height}
                names={data.names}
                config={chartConfig}
                yInfo={yInfo}
                yStepHeight={yStepHeight}
                xStepWidth={xStepWidth}
                yLabelRender={yLabelRender}
                xLabelRender={xLabelRender}
                yLabelCenter={true}
            />
            {rects.map((rect, index) => {
                if (rect.height === 0) {
                    return <circle key={index} cx={rect.x} cy={rect.y} r={0} fill={data.colors[0]} />;
                }
                return (
                    <rect
                        key={index}
                        x={rect.x - chartConfig.rectWidth / 2}
                        y={rect.y}
                        width={chartConfig.rectWidth}
                        height={rect.height}
                        rx={0}
                        ry={0}
                        fill={data.colors[0]}
                    />
                );
            })}
            {rects.map((rect, index) => {
                return (
                    <g key={index} className="g-hover">
                        <rect
                            x={rect.x - xStepWidth / 2}
                            y={0}
                            width={xStepWidth}
                            height={height - chartConfig.xLabelHeight}
                            fillOpacity={0}
                        />
                        {tooltipRender && <g className="tooltip">{tooltipRender(rect)}</g>}
                    </g>
                );
            })}
        </svg>
    );
};

export default ColumnChart;
