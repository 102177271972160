import React from 'react';
import { DragIndicatorIcon } from 'components/SvgIcon';

const ProgramRow = React.memo(({ id, data, ...rest }) => {
    const item = data[id];
    return (
        <>
            <div {...rest}>
                <DragIndicatorIcon className="color-blg-50 mr-8 d-flex" />
            </div>
            <div>{item.name_mngt}</div>
        </>
    );
});

export default ProgramRow;
