import React from 'react';
import MessageByScoreRow from 'pages/admin/interview_sheet/create_screen/tabs/mark/message_score/MessageByScoreRow';
import { AddIcon } from 'components/SvgIcon';
import { Button } from 'antd';
import { addInfoByScore } from 'pages/admin/interview_sheet/interview_sheet_create.slice';
import { connect } from 'react-redux';
import { randomId } from 'helpers/common.helper';

const mapState = state => ({
    order: state.admin.interviewSheetCreate.infoByScores.order,
});
const mapDispatch = { addInfoByScore };

const infoByScoreInit = {
    score_from: null,
    score_to: null,
    title: null,
    description: null,
    isEditing: true,
    rev: null, // original data
};

const MessageByScoreTable = ({ order, addInfoByScore }) => {
    const colStyles = [
        { flex: '0 0 154px', padding: 8 },
        { flex: 460, padding: 8 },
        { flex: '0 0 106px', padding: 8 },
    ];

    return (
        <div>
            <div className="by-score-table">
                <div className="by-score-table-header subtitle2 bg-gray-10 d-flex">
                    <div style={colStyles[0]}>{'スコア'}</div>
                    <div style={colStyles[1]}>{'スコア名'}</div>
                    <div style={colStyles[2]} />
                </div>
                <div className="by-score-table-body">
                    {order.map((id, index) => (
                        <MessageByScoreRow key={id || index} colStyles={colStyles} id={id} />
                    ))}
                </div>
            </div>
            <Button
                className="fs-16 fw-700 mt-16"
                icon={<AddIcon />}
                onClick={() => addInfoByScore({ id: randomId(), infoByScore: infoByScoreInit })}
            >
                {'追加'}
            </Button>
        </div>
    );
};

export default connect(mapState, mapDispatch)(MessageByScoreTable);
