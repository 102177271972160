import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Col, DatePicker, Row, Typography } from 'antd';
import { fullDateFormat } from 'helpers/date.helper';
import { defaultConfig } from 'helpers/chart.helper';
import RangeChart from 'components/chart/RangeChart';
import DateChangeComponent from 'pages/client/health_data/tabs/date_time/DateChangeComponent';
import HealthDataSidebar from 'pages/counselor/calendar/health_data/HealthDataSidebar';
import {
    chartDataSelector,
    getHeartRates,
    goNext,
    goPrev,
    goToday,
    heartRatesFilterSelector,
    setDisplayType,
    setMonthFilter,
} from 'pages/counselor/calendar/health_data/heart_rate.slice';
import NoData from 'components/NoData';
import { isAdminStaff } from 'helpers/role.helper';
import TdtButtonRestrict from 'components/TdtButtonRestrict';

const mapState = state => ({
    startDate: state.counselor.healthDataHeartRate.startDate,
    endDate: state.counselor.healthDataHeartRate.endDate,
    displayType: state.counselor.healthDataHeartRate.displayType,
    monthFilter: state.counselor.healthDataHeartRate.monthFilter,
    hasData: state.counselor.healthDataHeartRate.heartRates.length > 0,
    heartRates: heartRatesFilterSelector(state.counselor.healthDataHeartRate),
    chartData: chartDataSelector(state.counselor.healthDataHeartRate),
    user: state.auth.user,
});

const mapDispatch = { goPrev, goNext, goToday, setDisplayType, setMonthFilter, getHeartRates };

const xLabelRender = date => moment(date).format('MM/DD');
const chartConfig = { rectWidth: 16 };
const chartConfigMonth = { rectWidth: 8 };

const HearRateChart = ({
    startDate,
    endDate,
    displayType,
    monthFilter,
    hasData,
    heartRates,
    chartData,
    goPrev,
    goNext,
    goToday,
    setDisplayType,
    setMonthFilter,
    getHeartRates,
    user,
}) => {
    const { t } = useTranslation();

    const weekFormat = 'YYYY年MM月';

    const { id } = useParams();

    useEffect(() => {
        getHeartRates(id, startDate, endDate);
    }, [id, startDate, endDate]);

    const tooltipRender = useCallback(
        rect => {
            const tooltipWidth = 110;
            const x = rect.x - tooltipWidth / 2;
            const y = rect.y + rect.height + 8;
            return (
                <>
                    <rect x={x} y={y} width={tooltipWidth} height={52} rx={4} ry={4} fill="#ffffff" stroke="#D0D0D0" />
                    <text x={x + 8} y={y + 24} fill={defaultConfig.textColor} fontSize={12} alignmentBaseline="top">
                        <tspan fontSize={16} fontWeight={500}>
                            {Math.round(rect.value[0])}-{Math.round(rect.value[1])} 拍/分
                        </tspan>
                    </text>
                    <text x={x + 8} y={y + 42} fill={defaultConfig.textColor} fontSize={12} alignmentBaseline="top">
                        {moment(rect.name).format(displayType === 'year' ? 'M月' : 'M月D日(dd)')}
                    </text>
                </>
            );
        },
        [displayType]
    );

    return (
        <>
            <div className="counselor-aside">
                <div className="consultation-aside">
                    <div className="p-16 pb-0">
                        <div className="h7 mt-16 mb-24">{t('Health data')}</div>
                    </div>
                </div>
                <HealthDataSidebar activeKey="heart_beat" />
            </div>
            <div className="counselor-main pl-24 pr-24 mt-40">
                <div className="f-24-36 fw-b">{t('Heart beat')}</div>

                {!isAdminStaff(user) && (
                    <DateChangeComponent
                        startDate={startDate}
                        endDate={endDate}
                        displayType={displayType}
                        goPrev={goPrev}
                        goNext={goNext}
                        goToday={goToday}
                        setDisplayType={setDisplayType}
                    />
                )}

                {isAdminStaff(user) ? (
                    <TdtButtonRestrict withImage={true} />
                ) : (
                    <>
                        <RangeChart
                            data={chartData}
                            config={displayType === 'month' ? chartConfigMonth : chartConfig}
                            width={1000}
                            height={320}
                            yStepSize={24}
                            yMin={!hasData ? 30 : null}
                            yCount={!hasData ? 5 : null}
                            xLabelRender={xLabelRender}
                            tooltipRender={tooltipRender}
                        />

                        <Row justify="space-between" align="bottom">
                            <Col className="mt-48">
                                <Typography className="fw-b">{t('Data details')}</Typography>
                            </Col>
                            <Col>
                                {displayType === 'year' && (
                                    <DatePicker
                                        value={monthFilter}
                                        picker="month"
                                        format={weekFormat}
                                        allowClear={false}
                                        onChange={value => {
                                            setMonthFilter(value);
                                        }}
                                        inputReadOnly={true}
                                    />
                                )}
                            </Col>
                        </Row>
                        {heartRates.length === 0 ? (
                            <NoData />
                        ) : (
                            heartRates.map(h => {
                                return (
                                    <Row
                                        key={h.id}
                                        className="health-data-row data-details"
                                        justify="space-between"
                                        align="middle"
                                    >
                                        <Col className="health-data-col-center" span={4}>
                                            <Typography>{fullDateFormat(h.created_at)}</Typography>
                                        </Col>
                                        <Col span={4} className="flex">
                                            <Typography>{h.value}</Typography>
                                        </Col>
                                        <Col className="health-data-col-flex-end" span={14} />
                                    </Row>
                                );
                            })
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default connect(mapState, mapDispatch)(HearRateChart);

HearRateChart.propTypes = {
    tab: PropTypes.string,
    url: PropTypes.string,
};
