// 0 -> 23, 0 -> 59
// mod: refer AddCounselorContainer.jsx line 58 3/30/2021 Duy
export const sessionTimeToPoint = ({ session_length_hour, session_length_minute, buffer_time }) => {
    // Process session time
    let totalTime, totalInteger, totalFloat;
    totalTime = session_length_hour * 60 + session_length_minute + buffer_time;
    totalFloat = totalTime / 30;
    totalInteger = parseInt(totalFloat);
    if (totalFloat > totalInteger) {
        totalInteger++;
    }
    return totalInteger * 30;
};

export const sessionTimeToHourAndMinute = ({ session_length_hour, session_length_minute, buffer_time }) => {
    let totalMinutes,
        hour,
        modMinute,
        minute = 0;
    totalMinutes = session_length_hour * 60 + session_length_minute + buffer_time;
    hour = Math.floor(totalMinutes / 60);
    modMinute = totalMinutes - hour * 60;

    if (modMinute > 0) {
        if (modMinute > 30) {
            hour++;
        } else {
            minute = 30;
        }
    }

    return { hour, minute };
};

export const realSessionTime = ({ session_length_hour, session_length_minute }, bufferTime = 0) => {
    return session_length_hour * 60 + session_length_minute + bufferTime;
};
