import React, { useEffect, useState } from 'react';
import moment from 'moment';
import TdtCalendarMonth from 'components/calendar/TdtCalendarMonth';
import TdtCalendarWeek from 'components/calendar/TdtCalendarWeek';
import TdtCalendarDay from 'components/calendar/TdtCalendarDay';
import TdtCalendarModal from 'components/calendar/TdtCalendarModal';

const TdtCalendar = ({
    type = 'month',
    startDate = moment(),
    events = {},
    active = {},
    disableDate,
    blocked,
    createMenu = null,
    eventModalRenderer = null,
    busySlots = {},
}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [event, setEvent] = useState(null);

    const onEventClick = event => {
        setModalVisible(true);
        setEvent(event);
    };

    let Component;
    switch (type) {
        case 'month':
            Component = TdtCalendarMonth;
            break;
        case 'day':
            Component = TdtCalendarDay;
            break;
        default:
            Component = TdtCalendarWeek;
            break;
    }

    useEffect(() => {
        const target = document.getElementById('anchor-time');
        if (target) {
            target.parentNode.parentNode.parentNode.scrollTop = target.offsetTop - 1;
        }
    }, [type]);

    return (
        <>
            <Component
                startDate={startDate}
                events={events}
                active={active}
                disableDate={disableDate}
                blocked={blocked}
                createMenu={createMenu}
                onEventClick={onEventClick}
                busySlots={busySlots}
            />
            <TdtCalendarModal
                visible={modalVisible}
                setVisible={setModalVisible}
                title={
                    event && (
                        <div>
                            <span className="event-subtitle">{event.subtitle}</span>
                            <br />
                            <span className="event-title">{event.title}</span>
                        </div>
                    )
                }
                body={eventModalRenderer && event ? eventModalRenderer(event) : null}
            />
        </>
    );
};

export default TdtCalendar;
